import produce from "immer";
import {
  ACTIVE_NODE,
  UPDATE_NODE,
  RESET_NODE,
  CLEAR_ACTIVE_ACTION_NODE,
  SET_ACTIVE_ACTION_NODE,
} from "../types";

const INITIAL_STATE = {
  activeNode: {},
};

const nodeReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIVE_NODE:
        draft.activeNode = action.payload;
        break;

      case UPDATE_NODE:
        const key = action.payload.obj.Key;
        draft.activeNode.extras.runbookNode.parameterInputs[key] =
          action.payload.obj.value;
        break;
      case SET_ACTIVE_ACTION_NODE:
        if (draft.activeNode && draft.activeNode.extras) {
          draft.activeNode.extras.runbookNode = action.payload;
        }
        draft.activeActionNode = action.payload;
        break;
      case CLEAR_ACTIVE_ACTION_NODE:
        draft.activeActionNode = undefined;
        break;

      case RESET_NODE:
        draft.activeNode = null;
        break;

      default:
        break;
    }
  });

export default nodeReducer;
