import produce from "immer";
import {
  AWS_TARGET_ACCOUNT_SAVE_SUCCESS,
  AWS_TARGET_ACCOUNTS_FETCH_ERROR,
  AWS_TARGET_ACCOUNTS_FETCH_SUCCESS,
  AWS_TARGET_ACCOUNT_UPDATE_SUCCESS,
  AWS_TARGET_ACCOUNT_DELETE,
} from "@redux/types";

const INITIAL_STATE = {
  isAccountDetailFetched: false,
  userDefaultAccounts: [],
};

const getStartedReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AWS_TARGET_ACCOUNT_SAVE_SUCCESS:
        // CASE: When new account is added as default, existing accounts have to be set as not default
        if (draft.userDefaultAccounts.length && action.payload["is_default"]) {
          draft.userDefaultAccounts = draft.userDefaultAccounts.map(val => {
            const returnVal = { ...val };
            returnVal["is_default"] = false;
            return returnVal;
          });
        }
        draft.userDefaultAccounts.push(action.payload);
        break;

      case AWS_TARGET_ACCOUNTS_FETCH_ERROR:
        draft.isAccountDetailFetched = true;
        break;

      case AWS_TARGET_ACCOUNTS_FETCH_SUCCESS:
        if (typeof action.payload === "string") {
          action.payload = JSON.parse(action.payload);
        }
        draft.userDefaultAccounts = action.payload;
        draft.isAccountDetailFetched = true;
        break;

      case AWS_TARGET_ACCOUNT_UPDATE_SUCCESS:
        draft.userDefaultAccounts = action.payload;
        break;

      case AWS_TARGET_ACCOUNT_DELETE:
        draft.userDefaultAccounts = draft.userDefaultAccounts.filter(
          val => val.alias !== action.payload.alias,
        );
        break;

      default:
        break;
    }
  });
export default getStartedReducer;
