import {
  REQUEST_SENT,
  RESPONSE_RECEIVED,
  REQUEST_ERROR,
  SHOW_LOADER,
  HIDE_LOADER,
  SET_LOADING_MESSAGE,
  RESET_STATE,
} from "@redux/types";

export const requestSent = () => dispatch =>
  dispatch({
    type: REQUEST_SENT,
  });

export const responseReceived = () => dispatch =>
  dispatch({
    type: RESPONSE_RECEIVED,
  });

export const requestError = () => dispatch =>
  dispatch({
    type: REQUEST_ERROR,
  });

export const hideGlobalLoader = () => dispatch =>
  dispatch({
    type: HIDE_LOADER,
  });

export const showGlobalLoader = () => dispatch =>
  dispatch({
    type: SHOW_LOADER,
  });

export const setLoaderMessage = (message = "Loading...") => dispatch =>
  dispatch({
    type: SET_LOADING_MESSAGE,
    payload: message,
  });

export const resetLoaderState = () => dispatch =>
  dispatch({
    type: RESET_STATE,
  });
