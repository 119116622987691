import React from "react";
import { Breadcrumbs } from "@components/ui";
import { useDispatch } from "react-redux";
import { authLogout } from "@components/authentication/authHelper";

export const ProfileHeader = () => {
  const dispatch = useDispatch();

  const clickLogout = e => {
    e.preventDefault();
    authLogout(dispatch);
  };

  return (
    <>
      <Breadcrumbs text={"Account"} navigateTo="/user-profile/information" />
      <div className="d-flex up-header">
        <h1 className="m-0 pl-2 up-header-label">My Profile</h1>
        <button type="button" onClick={clickLogout} className="up-header-btn">
          Sign Out
        </button>
      </div>
    </>
  );
};
