import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import { Wait } from "@components/ui";
import RequireAuthentication from "@components/authentication/requireAuthentication";
import * as utils from "@lib/utils";
import "@scss/app.scss";
import UserProfile from "@components/authentication/UserProfile/UserProfile";
import UpgradePlan from "@components/authentication/UserProfile/utils/UpgradePlan";
import ForgotPassword from "@components/authentication/ForgotPassword/ForgotPassword";
import AmplifyConfig from "@components/AmplifyConfig/index";
import ExecutionsRun from "@containers/ExecutionsRun/executions-run-lib/executions-run.connect";
import ExecutionDetails from "@containers/ExecutionDetails/execution-details-lib/execution-details.connect";
import ExecutionRunDetails from "@containers/ExecutionRunDetails/execution-run-details-lib/exeuction-run-details.connect";
import AWSSettings from "@containers/SettingsPanel/AWSSettings";
import SnippetDetails from "@containers/SnippetDetails/snippet-details-lib/snippet-details.connect";
import SettingsAccountInfo from "@containers/SettingsPanel/SettingsAccountInfo";
import AWSTargetSettings from "@containers/GetStarted/Profile";
import AppInfo from "@containers/AppInfo/AppInfo";
import JiraSetup from "@containers/SettingsPanel/ConnectorSetup/JIRA/JiraSetup";
import SlackSetup from "@containers/SettingsPanel/ConnectorSetup/Slack/SlackSetup";
import APIKeys from "@containers/SettingsPanel/APIKeys";
import GitRepos from "@containers/SettingsPanel/GIT/GITRepos";
import S3Buckets from "@containers/SettingsPanel/S3Buckets/S3Buckets";
import SSHTargets from "@containers/SettingsPanel/SSH";
import SmallScreenError from "@components/SmallScreenError/SmallScreenError";
import { MIN_SUPPORTED_WIDTH } from "@lib/utils/constants";
import { RouteConstants } from "./Constants";
import CloudFormation from "@containers/SettingsPanel/ConnectorSetup/CloudFormation/CloudFormation";
import CloudwatchTargets from "@containers/SettingsPanel/Cloudwatch";
import CloudwatchSetup from "@containers/SettingsPanel/Cloudwatch/setup";
import withSpashScreen from "../withSplashScreen";
import TerraformCLI from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/TerraformCLI";
import EKSActionRoles from "@containers/SettingsPanel/EKSActionRoles/EKSActionRoles";
import EKSActionRoleSetup from "@containers/SettingsPanel/EKSActionRoles/EKSActionRoleSetup";
import TaskDetails from "@containers/TaskDetails/TaskDetails";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";
import { ErrorFallback } from "@components/shared/ErrorFallback/ErrorFallback";
import ZoomSetup from "@containers/SettingsPanel/ConnectorSetup/Zoom/ZoomSetup";
import Dashboard from "@containers/Dashboard/Dashboard";
import UserOnboarding from "@containers/UserOnboarding";

const LazyLogin = React.lazy(() =>
  import(
    /* webpackChunkName: "Login" */ "@components/authentication/Login/Login"
  ),
);

const LazySignup = React.lazy(() =>
  import(
    /* webpackChunkName: "Signup" */ "@components/authentication/Signup/Signup"
  ),
);

const LazyRunbooks = React.lazy(() =>
  import(
    /* webpackChunkName: "Runbooks" */ "@containers/Runbooks/runbooks-lib/runbooks.connect"
  ),
);

const LazyRunbookDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "RunbookDetails" */ "@containers/RunbookDetails/runbook-details-lib/runbook-details.connect"
  ),
);

const LazyRunbookEditor = React.lazy(() =>
  import(
    /* webpackChunkName: "RunbookEditor" */ "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.connect"
  ),
);

const LazySettingsPanel = React.lazy(() =>
  import(
    /* webpackChunkName: "SettingsPanel" */ "@containers/SettingsPanel/SettingsPanel"
  ),
);

const LazyTaskManagement = React.lazy(() =>
  import(
    /* webpackChunkName: "TaskManagement" */ "@containers/TaskManagement/TaskManagement"
  ),
);

const LazySnippets = React.lazy(() =>
  import(
    /* webpackChunkName: "Snippets" */ "@containers/Snippets/snippets-lib/snippets.connect"
  ),
);

const LazyExecutions = React.lazy(() =>
  import(
    /* webpackChunkName: "Executions" */ "@containers/Executions/executions-lib/executions.connect"
  ),
);

const LazySchedules = React.lazy(() =>
  import(
    /* webpackChunkName: "Schedules" */ "@containers/Schedules/schedules-lib/schedules.connect"
  ),
);

utils.removeHashFromAddressBar();

// const myErrorHandler = (error: Error, info: { componentStack: string }) => {
//   // Do something with the error
//   // E.g. log to an error logging client here
// };

// function to throw a error
// function Bomb() {
//   // Function to throw an error
//   throw new Error("💥 CABOOM 💥");
//   return <div>Hello</div>;
// }
class RoutedApp extends React.Component {
  render() {
    return (
      <Router>
        <GlobalLoader>
          <AmplifyConfig>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              // onError={myErrorHandler}
            >
              {/* <Bomb /> */}
              <Suspense fallback={<Wait text={`Loading...`} />}>
                <Switch>
                  <Route
                    exact
                    path={RouteConstants.forgotPassword.url}
                    component={ForgotPassword}
                  />
                  <Route exact path="/" component={LazyLogin} />
                  <Route
                    exact
                    path={RouteConstants.login.url}
                    component={LazyLogin}
                  />
                  <Route
                    exact
                    path={RouteConstants.signUp.url}
                    component={LazySignup}
                  />
                  {window.innerWidth > MIN_SUPPORTED_WIDTH ? (
                    <RequireAuthentication>
                      <Switch>
                        <Route
                          exact
                          path={`${RouteConstants.runbookEditor.url}/:runbookId`}
                          component={LazyRunbookEditor}
                        />
                        <Route
                          path={`${RouteConstants.runbookDetails.url}`}
                          component={LazyRunbookDetails}
                        />
                        <Redirect
                          from={RouteConstants.runbookDetails.baseUrl}
                          to={`${RouteConstants.runbookDetails.baseUrl}/parameters`}
                        />
                        <Route
                          exact
                          path={RouteConstants.runbooks.url}
                          component={LazyRunbooks}
                        />
                        <Route
                          exact
                          path={RouteConstants.schedules.url}
                          component={LazySchedules}
                        />
                        <Route
                          exact
                          path={RouteConstants.aws.url}
                          component={AWSTargetSettings}
                        />
                        <Route
                          exact
                          path={RouteConstants.awsSettings.url}
                          component={AWSSettings}
                        />
                        <Route
                          exact
                          path={RouteConstants.git.url}
                          component={GitRepos}
                        />
                        <Route
                          exact
                          path={RouteConstants.ssh.url}
                          component={SSHTargets}
                        />
                        <Route
                          exact
                          path={RouteConstants.jira.url}
                          component={JiraSetup}
                        />

                        <Route
                          exact
                          path={RouteConstants.slack.url}
                          component={SlackSetup}
                        />

                        <Route
                          exact
                          path={RouteConstants.zoom.url}
                          component={ZoomSetup}
                        />
                        <Route
                          exact
                          path={RouteConstants.terraform_cli.url}
                          component={TerraformCLI}
                        />

                        <Route
                          exact
                          path={RouteConstants.s3.url}
                          component={S3Buckets}
                        />
                        <Route
                          exact
                          path={RouteConstants.cloudformation.url}
                          component={CloudFormation}
                        />

                        <Route
                          exact
                          path={RouteConstants.apikeys.url}
                          component={APIKeys}
                        />
                        <Route
                          exact
                          path={`${RouteConstants.awsSettings.url}/:alias`}
                          component={SettingsAccountInfo}
                        />
                        <Route
                          exact
                          path={RouteConstants.actions.url}
                          component={LazySnippets}
                        />
                        <Route
                          exact
                          path={RouteConstants.actionDetails.url}
                          component={SnippetDetails}
                        />
                        <Redirect
                          from={RouteConstants.actionDetails.baseUrl}
                          to={`${RouteConstants.actionDetails.baseUrl}/inputs`}
                        />
                        <Route
                          path={RouteConstants.executionsRun.url}
                          component={ExecutionsRun}
                        />
                        <Route
                          exact
                          path={RouteConstants.executionDetails.url}
                          component={ExecutionDetails}
                        />
                        <Route
                          exact
                          path={`${RouteConstants.executionDetails.url}/:executionId/:subTab`}
                          component={ExecutionDetails}
                        />
                        <Redirect
                          from={`${RouteConstants.executionDetails.url}/:executionId`}
                          to={`${RouteConstants.executionDetails.url}/:executionId/steps`}
                        />
                        <Route
                          exact
                          path={RouteConstants.executionRunDetails.url}
                          component={ExecutionRunDetails}
                        />
                        <Redirect
                          from={RouteConstants.executionRunDetails.baseUrl}
                          to={`${RouteConstants.executionRunDetails.baseUrl}/inputs`}
                        />
                        <Route
                          exact
                          path={RouteConstants.executions.url}
                          component={LazyExecutions}
                        />
                        <Route
                          exact
                          path={`${RouteConstants.userProfile.url}`}
                          component={UserProfile}
                        />
                        <Redirect
                          from={RouteConstants.userProfile.baseUrl}
                          to={`${RouteConstants.userProfile.baseUrl}/information`}
                        />
                        <Route
                          exact
                          path={RouteConstants.appInfo.url}
                          component={AppInfo}
                        />

                        <Route
                          exact
                          path={RouteConstants.cloudwatch.url}
                          component={CloudwatchTargets}
                        />
                        <Route
                          exact
                          path={RouteConstants.cloudwatchTarget.url}
                          component={CloudwatchSetup}
                        />
                        <Route
                          exact
                          path={RouteConstants.eks.url}
                          component={EKSActionRoles}
                        />
                        <Route
                          exact
                          path={RouteConstants.setupEks.url}
                          component={EKSActionRoleSetup}
                        />

                        <Route
                          exact
                          path={RouteConstants.taskManagement.url}
                          component={LazyTaskManagement}
                        />
                        <Route
                          exact
                          path={RouteConstants.taskDetails.url}
                          component={TaskDetails}
                        />
                        <Redirect
                          from={`${RouteConstants.taskDetails.baseUrl}/:taskId`}
                          to={`${RouteConstants.taskDetails.baseUrl}/:taskId/workflows`}
                        />

                        <Route
                          exact
                          path={RouteConstants.dashboard.url}
                          component={Dashboard}
                        />
                        <Route
                          exact
                          path={RouteConstants.userOnboarding.url}
                          component={UserOnboarding}
                        />
                        <Route
                          exact
                          path={`${RouteConstants.upgradePlan.url}`}
                          component={UpgradePlan}
                        />
                        <Route
                          exact
                          path={RouteConstants.terraform_cli.url}
                          component={TerraformCLI}
                        />

                        <Route
                          path={RouteConstants.integration.url}
                          component={GetStarted}
                          exact
                        />
                        <Route
                          exact
                          path={RouteConstants.settings.url}
                          component={LazySettingsPanel}
                        />
                        <Redirect
                          from={RouteConstants.settings.baseUrl}
                          to={`${RouteConstants.settings.baseUrl}/integrations`}
                        />
                        <Route path="*">
                          <Redirect
                            to={RouteConstants.runbooks.defaultUrl}
                            push
                          />
                        </Route>
                      </Switch>
                    </RequireAuthentication>
                  ) : (
                    <SmallScreenError />
                  )}
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </AmplifyConfig>
        </GlobalLoader>
      </Router>
    );
  }
}

export default withSpashScreen(RoutedApp);
