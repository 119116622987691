import * as Yup from "yup";

export const getValidationSchema = function(editMode, terraformCLIList) {
  return Yup.object().shape({
    name: editMode === "create" ? Yup.string().required("Please enter module name").when("alias", (alias, schema) => {
      // Find all duplicate entries 
      let moduleList = terraformCLIList.filter(item => item.alias === alias)
      return editMode === "create" ? schema.notOneOf(moduleList ? moduleList.map(item => item.name) : [], "Terraform Module already exists") : schema
    }) : Yup.string(),
    root: Yup.string("Please enter valid root name"),
    selectedOption: Yup.string().required("Please select the input value"), 
    alias: Yup.string().required("Please select Target Account"),
    source: Yup.string().required("Please select the input value")
  })
}
