import React, { useState } from "react";
import "./TaskDetailsHeader.scss";
import { Link } from "react-router-dom";
import iconArrow from "@assets/images/icons/icon-arrow-right.svg";

type breadcrumbObj = {
  title: string;
  url: string;
};
type TaskDetailsHeaderProps = {
  breadcrumbList: Array<breadcrumbObj>;
  id: string;
  title: string;
  taskStatus: string;
  onEditTask: (boolean) => void;
  onDeleteTask: (boolean) => void;
  onCloseTask: (boolean) => void;
  onOpenTask: (boolean) => void;
  onAddWorkflowsToTask: (boolean) => void;
};

const TaskDetailsHeader: React.FC<TaskDetailsHeaderProps> = ({
  breadcrumbList,
  id,
  title,
  onEditTask,
  onDeleteTask,
  onCloseTask,
  onOpenTask,
  onAddWorkflowsToTask,
  taskStatus,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const optionClicked = option => {
    setIsDropdownOpen(false);
    switch (option) {
      case "edit":
        onEditTask(true);
        break;
      case "close":
        onCloseTask(true);
        break;
      case "open":
        onOpenTask(true);
        break;
      case "delete":
        onDeleteTask(true);
        break;
    }
  };
  return (
    <div className="hb">
      <div className="breadcrumbs">
        {breadcrumbList.map((bc, i) => {
          return (
            <React.Fragment key={bc.title}>
              {bc.url ? (
                <Link className="link" to={bc.url}>
                  {bc.title}
                </Link>
              ) : (
                <span className="link link-disabled">{bc.title}</span>
              )}
              {i !== breadcrumbList.length - 1 && (
                <span className="divider"></span>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div id="detailsHeader" className="header">
        <div className="text">
          <span className="id">{`#${id ? id : ""}`}</span>
          <span className="title">{title}</span>
        </div>
        <div className="actions">
          <button
            className={`${taskStatus === "Closed" ? "opaque no-pointer" : ""}`}
            onClick={() =>
              taskStatus !== "Closed" ? onAddWorkflowsToTask(true) : null
            }
          >
            Add Workflow
          </button>
          <button
            className="dropdown-btn"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <img alt="arrow" src={iconArrow}></img>
          </button>
          {isDropdownOpen && (
            <div className="dropdown">
              {taskStatus !== "Closed" && (
                <div className="option" onClick={() => optionClicked("edit")}>
                  Edit Incident
                </div>
              )}
              {taskStatus === "Closed" ? (
                <div className="option" onClick={() => optionClicked("open")}>
                  Reopen Incident
                </div>
              ) : (
                <div className="option" onClick={() => optionClicked("close")}>
                  Close Incident
                </div>
              )}
              <div
                className="option delete"
                onClick={() => optionClicked("delete")}
              >
                Delete Incident
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsHeader;
