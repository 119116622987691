import React from "react";
import { Button } from "../";
import { PropTypes } from "prop-types";
import "./confirm.scss";
import { connect } from "react-redux";
import { SHOW_CONFIRM } from "@redux/types";
import { TextInput } from "../";

class Confirm extends React.PureComponent {
  state = {
    errorMessage: "",
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  getButtonClass = text => {
    if (text.includes("Cancel")) {
      return "cancel-button";
    } else if (text.includes("Delete")) {
      return "delete-button";
    }
  };
  confirm = () => {
    const elementChild = this.getInputElement();
    const confirmValue = elementChild?.value;
    if (this.props.confirmRequiredText) {
      this.textMustMatchProp(confirmValue);
    } else if (this.props.callBack) {
      this.props.callBack();
    }
  };

  textMustMatchProp = confirmValue => {
    if (
      confirmValue === this.props.confirmValue &&
      this.props.verifiedCallback
    ) {
      const input = this.props.originalValue
        ? this.props.originalValue
        : confirmValue;
      this.props.verifiedCallback(input);
    } else if (confirmValue !== this.props.confirmValue) {
      this.setState(
        {
          errorMessage: `The value "${confirmValue}" does not match ${this.props.confirmValue}`,
          confirmValue,
        },
        () => this.inputBorder(true),
      );
    }
  };

  getInputElement = () => {
    const elementParent = this.ref.current;
    return elementParent?.querySelector("input");
  };

  inputBorder = hasError => {
    const inputElement = this.getInputElement();
    if (hasError && inputElement) {
      inputElement.style.border = "1px solid red";
      inputElement.focus();
    } else {
      inputElement.style.border = "none";
    }
  };

  render() {
    return (
      <div className="confirm-cover">
        <div className="confirm-container">
          <div className="confirm-message-container">
            <div className="confirm-heading-container">
              <div className="confirm-heading">{this.props.heading}</div>
              <div
                className="confirm-close"
                onClick={this.props.cancelDelete}
              ></div>
            </div>
            <div className="confirm-message">{this.props.message}</div>
            {this.props.confirmValue && (
              <div className="confirm-requirement">
                <div>{this.props.confirmRequiredText}</div>
                <div className="confirm-message" ref={this.ref}>
                  <TextInput
                    value={this.state.confirmValue}
                    name="confirm-text-input"
                  />
                </div>
                <div className="input-feedback pt-5-px">
                  {this.state.errorMessage}
                </div>
              </div>
            )}
          </div>

          <div className="confirm-footer">
            <Button
              text={this.props.cancelText}
              onClick={this.props.cancelDelete}
              type="button"
              style={{ width: "50%" }}
              className={this.getButtonClass(this.props.cancelText)}
            ></Button>
            <Button
              text={this.props.confirmText}
              type="button"
              isLoading={this.props.isLoading}
              style={{ width: "50%" }}
              className={this.getButtonClass(this.props.confirmText)}
              onClick={this.confirm}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  confirmRequiredText: PropTypes.string,
  verifiedCallback: PropTypes.func,
  callBack: PropTypes.func,
};

Confirm.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Confirm",
  isLoading: false,
};

const mapDispatch = dispatch => ({
  cancelDelete: () => dispatch({ type: SHOW_CONFIRM, payload: false }),
});

export default connect(() => ({}), mapDispatch)(Confirm);
