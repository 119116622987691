import Api from "@lib/api/api";
import { isEmpty } from "@lib/utils";
import {
  SSH_TARGETS_DELETE_SUCCESS,
  SSH_TARGETS_FETCH_SUCCESS,
  SSH_TARGETS_SAVE_SUCCESS,
  SSH_TARGETS_UPDATE_SUCCESS,
} from "@redux/types";
import { isFetching } from "@redux/actions/settingsPanel.action";

export const fetchSSHTargets = () => {
  return async dispatch => {
    dispatch(isFetching(true, "Fetching SSH targets"));
    const response = await Api.fetchSSHTargets().catch(e => console.log(e));
    if (!isEmpty(response)) {
      const body =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;
      const sshTargets =
        typeof body === "string"
          ? JSON.parse(body).sshTargets
          : body?.sshTargets;

      if (!isEmpty(sshTargets)) {
        dispatch({
          type: SSH_TARGETS_FETCH_SUCCESS,
          payload: sshTargets,
        });
      }
    }
    dispatch(isFetching(false, ""));
  };
};

export const addSSHTarget = value => {
  return async dispatch => {
    dispatch({
      type: SSH_TARGETS_SAVE_SUCCESS,
      payload: value,
    });
  };
};

export const updateSSHTarget = value => {
  return async dispatch => {
    dispatch({
      type: SSH_TARGETS_UPDATE_SUCCESS,
      payload: value,
    });
  };
};

export const removeSSHTarget = value => {
  return async dispatch =>
    dispatch({
      type: SSH_TARGETS_DELETE_SUCCESS,
      payload: value,
    });
};
