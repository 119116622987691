import React from "react";

import "./BarChart.scss";

const BarChart = ({ barData = [] }) => {
  const max = Math.max(...barData.map(item => item?.value));

  return barData.map(item => {
    return (
      <div className="incidents-barchart-wrapper" key={item?.name}>
        <div className="incidents-barchart-legend">
          <div>{item?.name}</div>
          <div>{item?.value}</div>
        </div>
        <div className="incidents-barchart-filler">
          <div
            className="incidents-barchart-progress"
            style={{
              width: `${Math.ceil((item?.value * 100) / (max + max / 4))}%`,
            }}
          ></div>
        </div>
      </div>
    );
  });
};

export default BarChart;
