export const mapResponse = (repoObj: Array<object>) => {
  const repos = [];
  Object.keys(repoObj).forEach(key => {
    const repo = repoObj[key];
    repo["name"] = key;
    repos.push(repo);
  });
  return repos;
};

export const mapPayload = values => {
  const payload = { ...values };
  delete payload.name;
  return payload;
};

export const mapData = values => {
  const data = {
    url: values.url,
    name: values.name,
  };
  if (values.hasOwnProperty("accessToken") && values.accessToken !== "")
    data["access_token"] = values.accessToken;
  if (values.hasOwnProperty("sshKey") && values.sshKey !== "")
    data["ssh-key"] = values.sshKey;
  return data;
};
