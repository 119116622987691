import React from "react";
import { Formik } from "formik";
import get from "lodash/get";
import { getValidationSchema } from "./utils/ValidationSchema";
import { Modal, Button, FTNotification } from "@components/ui";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { isEmpty } from "@lib/utils";
import { fetchAllS3BucketList } from "@redux/actions/s3Buckets.actions";
import "./ManageS3Bucket.scss";
import Api from "@lib/api";

type ManageS3BucketProps = {
  closeForm: (flag: boolean) => void;
  fetchSelectedS3BucketList: () => void;
};

const ManageS3Bucket: React.FC<ManageS3BucketProps> = ({
  closeForm,
  fetchSelectedS3BucketList,
  ...props
}) => {
  const dispatch = useDispatch();

  const aliasList = useSelector(
    (state: RootStateOrAny) => state.getStartedReducer.userDefaultAccounts,
  );
  const allS3BucketList = useSelector(
    (state: RootStateOrAny) => state.S3BucketsReducer.allS3BucketList,
  );
  const isAllS3BucketListLoading = useSelector(
    (state: RootStateOrAny) => state.S3BucketsReducer.isAllS3BucketListLoading,
  );
  const initialValues = {
    alias: "",
    s3Bucket: "",
  };
  const validationSchema = getValidationSchema();

  const getFooter = handleSubmit => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          size="large"
          type="button"
          onClick={() => closeForm(false)}
        />
        <Button
          text="Add S3 Bucket"
          style={{ width: "50%" }}
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const onSubmitHandler = async (formik, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Adding S3 Bucket"));
    try {
      await Api.addS3Bucket(
        formik.alias,
        formik.s3Bucket.Name,
        formik.s3Bucket,
      );
      await fetchSelectedS3BucketList();
      FTNotification.success({
        title: "S3 Bucket Added Successfully!",
      });
    } catch (error) {
      FTNotification.error({
        title: "Could not add S3 Bucket",
        message: error.message,
      });
    }
    dispatch(isFetching(false, ""));
    actions.setSubmitting(false);
    closeForm(false);
  };

  const setFieldValue = async (key, value, formik) => {
    formik.setFieldValue(key, value);
    setTimeout(() => formik.setFieldTouched(key, true));
    if (key === "alias") {
      formik.setTouched({});
      dispatch(fetchAllS3BucketList(value));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          const { touched, errors, handleSubmit } = formik;
          return (
            <Modal
              onClose={() => {
                closeForm(false);
              }}
              title="Add S3 Bucket"
              footer={getFooter(handleSubmit)}
              showClose={true}
              containerClass={`w-50`}
              contentClass={`s3-modal-content`}
              contentContainerClass={`overflow-unset`}
            >
              <div className="s3-input">
                <label className="s3-input-label">Select Target Account</label>
                <ReactSelect
                  id="alias"
                  name="alias"
                  value={{
                    value: formik.values.alias,
                    label: formik.values.alias
                      ? formik.values.alias
                      : "Select from below",
                  }}
                  handleChange={data => {
                    if(data === null){
                      setFieldValue("alias", "", formik)
                    } else if (!isEmpty(data) && !!data.value) {
                      setFieldValue("alias", data.value, formik);
                      // setFieldValue("s3Bucket", "", formik);
                    }
                  }}
                  selectOptions={aliasList.map(r => {
                    return {
                      value: r.alias,
                      label: r.alias,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                  required
                />
                {errors["alias"] && touched["alias"] && (
                  <div className="input-feedback">{errors["alias"]}</div>
                )}
              </div>
              {!!formik.values.alias && (
                <div className="s3-input">
                  {isAllS3BucketListLoading ? (
                    <label className="s3-input-label loading-text mt-2">
                      <i>Loading S3 buckets...</i>
                    </label>
                  ) : (
                    <>
                      <label className="s3-input-label mt-2">
                        Select S3 Bucket
                      </label>
                      <ReactSelect
                        id="s3-buckets"
                        name="s3-buckets"
                        value={{
                          value: formik.values.s3Bucket,
                          label: formik.values.s3Bucket
                            ? get(formik, "values.s3Bucket.Name", "")
                            : "Select from below",
                        }}
                        handleChange={data => {
                          if(data === null){
                            setFieldValue("s3Bucket", "", formik)
                          } else if (!isEmpty(data) && !!data.value) {
                            setFieldValue("s3Bucket", data.value, formik);
                          }
                        }}
                        selectOptions={allS3BucketList.map(r => {
                          return {
                            value: r,
                            label: r.Name,
                          };
                        })}
                        customMenuClass="default-select-options-container"
                        customMenuListClass="default-select-options-list"
                        customValueContainerClass="default-select-value-container"
                        customControlClass="default-select-control"
                        customOptionClass="default-select-list-item"
                        required
                      />
                      {errors["s3Bucket"] && touched["s3Bucket"] && (
                        <div className="input-feedback">
                          {errors["s3Bucket"]}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageS3Bucket;
