import * as Yup from "yup";

export const getValidationSchema = (mode, repos) => {
  if (mode === "create") {
    return Yup.object().shape(
      {
        name: Yup.string()
          .required("Please enter Repo Id")
          .notOneOf(
            repos ? repos.map(e => e.name) : [],
            "GitHub Repo already exists",
          )
          .test("caseInsensitive", "GitHub Repo already exists", item =>
            item !== undefined
              ? !repos?.some(e => e.name.toLowerCase() === item?.toLowerCase())
              : true,
          )
          .trim("Please enter Repo Id"),
        sshKey: Yup.string().when(["accessToken"], {
          is: accessToken => !accessToken,
          then: Yup.string()
            .required("Please enter SSH key")
            .trim("Please enter SSH key"),
        }),
        accessToken: Yup.string().when(["sshKey"], {
          is: sshKey => !sshKey,
          then: Yup.string()
            .required("Please enter Access Token")
            .trim("Please enter Access Token"),
        }),
        url: Yup.string()
          .required("Please enter URL")
          .test("httpsNotAllowed", "Please enter valid URL", item =>
            item !== undefined ? !/^(http|https)/.test(item) : true,
          )
          .trim("Please enter URL"),
      },
      ["sshKey", "accessToken"],
    );
  } else {
    return Yup.object().shape(
      {
        sshKey: Yup.string().when(["accessToken"], {
          is: accessToken => !accessToken,
          then: Yup.string()
            .required("Please enter SSH key")
            .trim("Please enter SSH key"),
        }),
        accessToken: Yup.string().when(["sshKey"], {
          is: sshKey => !sshKey,
          then: Yup.string()
            .required("Please enter Access Token")
            .trim("Please enter Access Token"),
        }),
        url: Yup.string()
          .required("Please enter URL")
          .test("httpsNotAllowed", "Please enter valid URL", item =>
            item !== undefined ? !/^(http|https)/.test(item) : true,
          )
          .trim("Please enter URL"),
      },
      ["sshKey", "accessToken"],
    );
  }
};
