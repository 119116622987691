import React from "react";

type TaskStatusProps = {
  className?: string;
  status: string;
};

const TaskStatus: React.FC<TaskStatusProps> = ({ className, status }) => {
  return (
    <div className="table table-task-status">
      <span
        className={`dot ${
          status === "Pending" ? "pending" : status === "Active" ? "active" : ""
        }`}
      ></span>
      <span className={`status ${className}`}>{status}</span>
    </div>
  );
};

export default TaskStatus;
