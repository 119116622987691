import produce from "immer";
import {
  GIT_REPOS_FETCH_SUCCESS,
  GIT_REPOS_SAVE_SUCCESS,
  GIT_REPOS_UPDATE_SUCCESS,
  GIT_REPOS_DELETE_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  gitRepoList: [],
};

const GITReposReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GIT_REPOS_FETCH_SUCCESS:
        draft.gitRepoList = action.payload;
        break;

      case GIT_REPOS_SAVE_SUCCESS:
        draft.gitRepoList = [...state.gitRepoList, action.payload];
        break;

      case GIT_REPOS_UPDATE_SUCCESS:
        const repoList = state.gitRepoList.filter(
          val => val?.name !== action.payload?.name,
        );
        repoList.push(action.payload);
        draft.gitRepoList = repoList;
        break;

      case GIT_REPOS_DELETE_SUCCESS:
        draft.gitRepoList = state.gitRepoList.filter(
          val => val?.name !== action.payload?.name,
        );
        break;

      default:
        break;
    }
  });

export default GITReposReducer;
