import React from "react";
import { connect } from "react-redux";

class Version extends React.PureComponent {
  render() {
    return (
      <div className="lm-version">Version: {this.props.RunbookApiVersion}</div>
    );
  }
}

const mapStateToProps = () => ({
  RunbookApiVersion: process.env.REACT_APP_TAG,
});

export default connect(mapStateToProps)(Version);
