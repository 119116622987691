import { connect } from "react-redux";
import SnippetDetails from "../SnippetDetails";
import { fetchingAsnippetDetails } from "@redux/actions/snippetDetails.actions";

const mapState = ({ snippetDetailsReducer }) => {
  return {
    snippetDetails: snippetDetailsReducer.snippetDetails,
    isFetchingAsnippet: snippetDetailsReducer.isFetchingAsnippet,
    snippetDetailsError: snippetDetailsReducer.snippetDetailsError,
    selectedSnippet: snippetDetailsReducer.selectedSnippet,
  };
};

const mapDispatch = dispatch => ({
  fetchSnippetDetails: (name, version) => {
    dispatch(fetchingAsnippetDetails(name, version));
  },
});

export default connect(mapState, mapDispatch)(SnippetDetails);
