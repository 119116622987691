import React from "react";
import { Consumer } from "../execution-run-details-lib/execution-run-details.context";
import {
  convertToObject,
  getStepInputs,
} from "../execution-run-details-lib/execution-run-details.helpers";
import { JsonView } from "@components/ui";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

export default class ExecutionRunInputs extends React.Component {
  state = {
    showJson: true,
    displayChoice: "View Raw",
  };

  updateChoice = () => {
    this.setState({
      showJson: !this.state.showJson,
      displayChoice: this.state.showJson ? "View as JSON" : "View Raw",
    });
  };

  render() {
    return (
      <Consumer>
        {({ executionRunDetails }) => {
          let inputObject = convertToObject(getStepInputs(executionRunDetails));
          if (inputObject) {
            return (
              <>
                <div className="mb-20-px">
                  This execution step uses the following input parameters:
                </div>
                <div className="d-flex flex-column mb-20-px">
                  {/* The Filter logic removes the internal Parameters from Inputs */}
                  {typeof inputObject === "object" &&
                    Object.keys(inputObject)
                      .filter(
                        key =>
                          !(
                            key === "automation_assume_role" ||
                            key === "workflow_session"
                          ),
                      )
                      .map(key => {
                        let input = inputObject[key];
                        let inputString;
                        let isObject;
                        try {
                          isObject =
                            typeof input === "object" ||
                            typeof JSON.parse(input) === "object";
                        } catch {
                          isObject = false;
                        }

                        if (isObject) {
                          inputString = JSON.stringify(input);
                        } else {
                          inputString = input;
                        }
                        return (
                          <div className="detail-table-row" key={key}>
                            <div className="detail-table-row-child detail-table-row-child-left">
                              {key}
                            </div>
                            <div className="detail-table-row-child detail-table-row-child-right">
                              {true ? (
                                <div className="w-100">
                                  <div className="detail-table-row-child-right-container">
                                    <div
                                      className="link-light-blue detail-table-row-child-right-label pr-2"
                                      onClick={this.updateChoice}
                                    >
                                      {this.state.displayChoice}
                                    </div>
                                  </div>
                                  {this.state.showJson ? (
                                    <JsonView
                                      id={`execution-run-detail-input`}
                                      className="detail-table-json-container"
                                      jsonViewClass="detail-table-json-view"
                                      data={input}
                                      showCopy={false}
                                    />
                                  ) : (
                                    <div
                                      className={"detail-table-json-container"}
                                    >
                                      {inputString}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={"detail-table-json-container"}>
                                  <div>{inputString}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </>
            );
          } else {
            return (
              <NoResultFound
                message="No results found"
                detail="This step has no input parameters."
                className="mt-100-px"
              />
            );
          }
        }}
      </Consumer>
    );
  }
}
