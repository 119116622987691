import React from "react";
import { Link } from "react-router-dom";

const logo = () => (
  <Link to="/workflows/my-workflows">
    <div className="logo-wrap"></div>
  </Link>
);

export default logo;
