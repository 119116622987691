import React, { useState } from "react";
import "./Headers.scss";
import { Logo } from "@components/modules";
import { Link } from "react-router-dom";
import { Modal } from "@components/ui";
import { RouteConstants } from "../../../../routes/Constants";

const Headers = ({ showWarning }) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  return (
    <>
      {showWarningModal && (
        <Modal
          title={`Are you sure you want to leave this page?`}
          showClose={false}
          submitButtonText={`Leave this page`}
          cancelButtonText={`Stay on this page`}
          onCancel={() => {
            setShowWarningModal(false);
          }}
          onClose={() => {
            setShowWarningModal(false);
          }}
          onSubmit={() => {
            window.location.href = `${RouteConstants.settings.baseUrl}/integrations`;
          }}
        >
          <div className="mb-20-px">
            You have unsaved changes. If you leave the page, these changes will
            be lost
          </div>
        </Modal>
      )}
      <div className="header-container">
        <div className="header-container-inner">
          {showWarning ? (
            <span
              className="breadcrumb-link"
              onClick={() => setShowWarningModal(showWarning)}
            >
              ← Back To Settings
            </span>
          ) : (
            <Link to="/settings/integrations" className="breadcrumb-link">
              ← Back To Settings
            </Link>
          )}

          <Logo />
        </div>
      </div>
    </>
  );
};

export default Headers;
