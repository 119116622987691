import React from "react";
import { OverflowMenu } from "@components/ui";

const tableColumns = onRemove => {
  return [
    {
      name: "Target Account",
      selector: "alias",
      grow: 2,
    },
    {
      name: "S3 Bucket",
      selector: "Name",
      grow: 3,
    },
    {
      name: "Creation Date",
      selector: "CreationDate",
      grow: 2,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};

export default tableColumns;
