import React, { useState } from "react";
import moment from "moment";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "./DatePicker.scss";



function DatePicker({ handleDateSelection, dateRange }) {
  const [ranges, setRanges] = useState([dateRange]);

  const staticRanges = createStaticRanges([
    {
      label: "Today",
      key: "Today",
      range: () => ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 24 Hours",
      key: "Last 24 Hours",
      range: () => ({
        startDate: moment().subtract(24, "hours").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 7 days",
      key: "Last 7 days",
      range: () => ({
        startDate: moment().subtract(6, "days").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Current Month",
      key: "Current Month",
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 30 Days",
      key: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(29, "days").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Last 6 Months",
      key: "Last 6 Months",
      range: () => ({
        startDate: moment().subtract(5, "months").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "Year To Date",
      key: "Year To Date",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().toDate(),
      }),
    },
    {
      label: "All Times",
      key: "All Times",
      range: () => ({
        startDate: moment().subtract(3, "years").startOf("year").toDate(),
        endDate: moment().toDate(),
      }),
    },
  ]);

  const handleRangeSelection = range => {
    handleDateSelection(range.range1);
    setRanges([range.range1]);
  };

  return (
    <DateRangePicker
      startDatePlaceholder="Start Date"
      endDatePlaceholder="End Date"
      ranges={ranges}
      onChange={ranges => handleRangeSelection(ranges)}
      staticRanges={staticRanges}
      inputRanges={[]}
    />
  );
}

export default DatePicker;
