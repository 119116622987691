import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import React from "react";
import { Consumer } from "../execution-details-lib/execution-details.context";

export default class Inputs extends React.Component {
  getInputValues(executionDetails) {
    // The Filter logic removes the internal Parameters from Inputs
    let result = Object.keys(executionDetails.Parameters)
      .filter(
        key => !(key === "AutomationAssumeRole" || key === "WorkflowSession"),
      )
      .map(key => {
        const value = executionDetails.Parameters[key];
        return (
          <div key={key} className="d-flex w-100">
            <div className="execution-details-table-col execution-details-table-col-left">
              {key}
            </div>
            <div className="execution-details-table-col execution-details-table-col-right">
              {value}
            </div>
          </div>
        );
      });

    return result && result.length !== 0 ? (
      <>
        <div className="ml-2">
          This execution uses the following input parameters:
        </div>
        <div className="mt-20-px mb-20-px">{result}</div>
      </>
    ) : (
      <NoResultFound
        message="No results found"
        detail="This execution has no input parameters."
        className="mt-100-px"
      />
    );
  }
  render() {
    return (
      <Consumer>
        {({ executionDetails }) => (
          <React.Fragment>
            {executionDetails && (
              <div className="mt-20-px">
                <div className="content-text">
                  <div className="mt-20-px mb-20-px">
                    {this.getInputValues(executionDetails)}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
