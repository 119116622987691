import React, { useEffect, useState } from "react";
import "./PasswordStrength.scss";
import { toProperCase } from "@lib/utils";
import { checkCognitoPasswordStrength, isPassed } from "./helpers";
import iconCheck from "@assets/images/icons/icon-success-filled.svg";
import iconCross from "@assets/images/icons/icon-failed-filled.svg";

type passwordStrengthProps = {
  password: string;
  setPasswordScore: (number: boolean) => void;
};

const PasswordStrength: React.FC<passwordStrengthProps> = ({
  password,
  setPasswordScore,
  ...props
}) => {
  const [passwordStrength, setPasswordStrength] = useState("weak");

  useEffect(() => {
    setPasswordStrength("weak");
    const score = checkCognitoPasswordStrength(password);
    const strength = score < 2 ? "weak" : score < 4 ? "medium" : "strong";
    setPasswordStrength(strength);
    setPasswordScore(score > 3);
  }, [password, setPasswordScore]);

  return (
    <>
      <div className="pw-strength-wrap">
        <div
          className={`pw-strength-block pw-strength-block-${passwordStrength}`}
        ></div>
      </div>
      <div className="pw-strength-text-label mt-5-px">
        <div className="d-flex">
          <div>Password strength: </div>
          <span className={`pw-strength-text-${passwordStrength}`}>
            {toProperCase(passwordStrength)}
          </span>
        </div>
        <div className="pointer pw-strength-help-icon">
          <div className="pw-strength-help-text">
            <div className="pw-strength-carrot"></div>

            <div className="d-flex password-policy mb-5-px">
              {isPassed("minLength", password) ? (
                <img src={iconCheck} alt="iconCheck" width={14} height={14} />
              ) : (
                <img src={iconCross} alt="iconCross" width={14} height={14} />
              )}
              <span className="password-policy-text">Minimum 8 characters</span>
            </div>
            <div className="d-flex password-policy mb-5-px">
              {isPassed("upperCase", password) ? (
                <img src={iconCheck} alt="iconCheck" width={14} height={14} />
              ) : (
                <img src={iconCross} alt="iconCross" width={14} height={14} />
              )}
              <span className="password-policy-text">Uppercase letters</span>
            </div>
            <div className="d-flex password-policy mb-5-px">
              {isPassed("lowerCase", password) ? (
                <img src={iconCheck} alt="iconCheck" width={14} height={14} />
              ) : (
                <img src={iconCross} alt="iconCross" width={14} height={14} />
              )}
              <span className="password-policy-text">Lowercase letters</span>
            </div>
            <div className="d-flex password-policy mb-5-px">
              {isPassed("number", password) ? (
                <img src={iconCheck} alt="iconCheck" width={14} height={14} />
              ) : (
                <img src={iconCross} alt="iconCross" width={14} height={14} />
              )}
              <span className="password-policy-text">Numbers</span>
            </div>
            <div className="d-flex password-policy">
              {isPassed("specialCharacter", password) ? (
                <img src={iconCheck} alt="iconCheck" width={14} height={14} />
              ) : (
                <img src={iconCross} alt="iconCross" width={14} height={14} />
              )}
              <span className="password-policy-text">Special characters</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordStrength;
