export class ParameterSpecification {
  constructor(ssm) {
    // { type, description, default?,... }
    Object.assign(this, ssm);
    this.ssm = ssm;
  }
  setDescription(desc) {
    this.description = desc;
    this.ssm.description = desc;
  }

  setDefault(dflt) {
    this.default = dflt;
    this.ssm.default = dflt;
  }

  toSSM() {
    return this.ssm;
  }
}

export class Parameter {
  static createFylamyntParameter(name, description, defaultValue) {
    // Fylamynt runbook parameters are always type String
    return new Parameter(name, {
      type: "String",
      description,
      default: defaultValue, // may be undefined
    });
  }
  constructor(name, ssmSpec) {
    this.spec = new ParameterSpecification(ssmSpec);
    this.name = name;
    this.Type = "String";
    this.isRequired = parameterIsRequired(ssmSpec);
    this.isOptional = parameterIsOptional(ssmSpec);
    this.isDisplayed = parameterIsDisplayed(name);
  }
}

export class ParameterSet {
  constructor(ssm) {
    // ssm has a map from parameter name to parameter specs
    this.parameters = {};
    if (ssm) {
      let name;
      for (name of Object.keys(ssm)) {
        this.parameters[name] = new Parameter(name, ssm[name]);
      }
    }
  }

  toSSM() {
    const ssm = {};
    let name;
    for (name of Object.keys(this.parameters)) {
      ssm[name] = this.parameters[name].spec.toSSM();
    }
    return ssm;
  }

  displayedParameters() {
    return Object.keys(this.parameters)
      .map(name => this.parameters[name])
      .filter(param => param.isDisplayed);
  }

  requiredParameters(onlyShowDisplayed = true) {
    return Object.keys(this.parameters)
      .map(name => this.parameters[name])
      .filter(
        param => param.isRequired && (!onlyShowDisplayed || param.isDisplayed),
      );
  }

  optionalParameters(onlyShowDisplayed = true) {
    return Object.keys(this.parameters)
      .map(name => this.parameters[name])
      .filter(
        param => !param.isRequired && (!onlyShowDisplayed || param.isDisplayed),
      );
  }
}

// TODO: Is this really how we want to do it?
export function parameterIsRequired(ssmParameter) {
  return (
    ssmParameter.description &&
    ssmParameter.description.indexOf("(Required") !== -1
  );
}

export function parameterIsOptional(ssmParameter) {
  return (
    ssmParameter.description &&
    ssmParameter.description.indexOf("(Optional") !== -1
  );
}

export function parameterIsDisplayed(parameterName) {
  return (
    parameterName !== "AutomationAssumeRole" &&
    parameterName !== "WorkflowSession" &&
    parameterName !== "ActionNodeLambda" &&
    parameterName !== "executionId"
  );
}
