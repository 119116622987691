import { isValidNumber } from "@lib/utils";
import { FTNotification } from "@components/ui";

export const getParsedPayload = (payload = {}) => {
  try {
    let lambdaInput = payload;
    if (
      lambdaInput &&
      (lambdaInput.includes("\n") ||
        lambdaInput.includes("\t") ||
        lambdaInput.includes("\r"))
    ) {
      lambdaInput = lambdaInput
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/\t/g, "\\t");
    }
    return JSON.parse(convertPayloadSpecToJSON(lambdaInput) || "{}");
  } catch (e) {
    FTNotification.error({
      title: "Could not parse ssm payload",
      message: e,
    });
  }
};
export function convertPayloadSpecToJSON(payload) {
  if (!payload) {
    return;
  }

  const replaced =
    payload &&
    payload
      .replace(/("\w+":\s*)({{\s*[\w.]+\s*}})(\s*,?)/g, '$1"$2"$3')
      .replace(/("\w+":\s*\[\s*)({{\s*[\w.]+\s*}})(\s*]\s*,?)/g, '$1"$2"$3');
  return replaced;
}

export function awsFriendlyName(s) {
  const result = s
    .replace(/([-_][a-z])/gi, $1 => {
      return $1.toUpperCase();
    })
    .replace(/[-_]/g, "");
  return result;
}

/**
 * This array contains the optional input which does not have character limit
 * since input component is generated dynamically.
 **/
export const NO_LIMIT_INPUTS = ["ca_cert"];

/**
 * This function returns the char length of given input
 * @param {string} inputName the name of input field
 * @returns {number} length of character allowed
 **/
export const getInputLimit = inputName =>
  NO_LIMIT_INPUTS.indexOf(inputName) > -1 ? 2048 : 256;

/**
 * This function returns the boolean value, specifies if the step is healthy or not
 * @param {string} parameterInputs input list
 * @returns {boolean}
 **/
export function checkInvalidRequiredInput(parameterInputs) {
  let boolList = [true];
  if (parameterInputs) {
    parameterInputs.forEach(param => {
      if (
        (param.required &&
          ["constant", "actionNode"].includes(param.source.type) &&
          (param.source.sourceValue === null ||
            (param.source.sourceValue &&
              Object.keys(param.source.sourceValue).length === 0 &&
              typeof param.source.sourceValue === "object" &&
              param.type !== "Object") ||
            ((param.type === "Integer" || param.type === "Decimal") &&
              !isValidNumber(param.source.sourceValue, param.type)))) ||
        (param.type === "URL" &&
          typeof param.source.sourceValue === "string" &&
          !Array.isArray(
            param.source.sourceValue?.match(
              param?.allowedPattern ||
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
            ),
          )) ||
        (param.type === "StringList" &&
          Array.isArray(param.source.sourceValue) &&
          param?.allowedPattern &&
          param.source.sourceValue?.some(
            val => !Array.isArray(val?.match(param?.allowedPattern)),
          ))
      ) {
        boolList.push(false);
      }
    });
  }
  return boolList.every(Boolean);
}
