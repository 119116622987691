import React from "react";
import DetailLine from "../RunbookDetails/runbook-details-components/runbook-details-block-line";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAWSTargetAccountInfo } from "@redux/actions/AWSSettings.actions";
import moment from "moment";
import { dateTimeDisplayFormat } from "@lib/utils/constants";
import { capitalizeFirstLetter } from "@lib/utils";
import { SettingsPanelHeader } from "./settings-panel-components";

type SettingsAccountInfoProps = {
  loadingMessage: string;
  accountInfo: any;
  fetchAWSTargetAccountInfo: any;
  match: any;
};

type SettingsAccountInfoStateProps = {
  infoFields: any;
};

class SettingsAccountInfo extends React.Component<
  SettingsAccountInfoProps,
  SettingsAccountInfoStateProps
> {
  componentDidMount() {
    this.props.fetchAWSTargetAccountInfo(this.props.match.params.alias);
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column">
          <SettingsPanelHeader
            isBreadCrumb={true}
            heading={capitalizeFirstLetter(this.props.accountInfo.alias)}
            breadCrumbText={`Settings/AWS-Settings/${capitalizeFirstLetter(
              this.props.accountInfo.alias,
            )}`}
          />
          <div className="target-account-info-container">
            <h4>Details</h4>
            <div className="details__block-wrap">
              <div className="details-block">
                <DetailLine
                  title={"Alias"}
                  text={this.props.accountInfo.alias}
                />

                <DetailLine
                  title={"Created At"}
                  text={
                    this.props.accountInfo.created_at
                      ? moment
                          .unix(this.props.accountInfo.created_at)
                          .format(dateTimeDisplayFormat)
                      : "-"
                  }
                />
                <DetailLine
                  title={"Updated At"}
                  text={
                    this.props.accountInfo.updated_at
                      ? moment
                          .unix(this.props.accountInfo.updated_at)
                          .format(dateTimeDisplayFormat)
                      : "-"
                  }
                />
                <DetailLine
                  title={"Assume Role ARN"}
                  text={this.props.accountInfo.role_arn}
                />
              </div>
              <div className="details-block">
                <DetailLine
                  title={"Default"}
                  text={this.props.accountInfo.is_default ? "Yes" : "No"}
                />
                <DetailLine
                  title={"Preferred Region"}
                  text={
                    this.props.accountInfo.preferred_region
                      ? this.props.accountInfo.preferred_region
                      : "-"
                  }
                />
                <DetailLine
                  title={"External ID"}
                  text={
                    this.props.accountInfo.external_id
                      ? this.props.accountInfo.external_id
                      : "-"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  accountInfo: state.AWSSettingsReducer.AWSTargetAccountInfo,
  loadingMessage: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchAWSTargetAccountInfo,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsAccountInfo);
