const testing = false;

export const runDetailsObjectLive = {
  Action: null,
  ExecutionEndTime: null,
  ExecutionStartTime: null,
  Inputs: {
    FunctionName: null,
    Payload: null,
  },
  MaxAttempts: null,
  NextStep: null,
  OnFailure: null,
  Outputs: null,
  FailureDetails: null,
  OverriddenParameters: null,
  StepExecutionId: null,
  StepName: null,
  StepStatus: null,
  ValidNextSteps: [],
  stepNumber: null,
};

export const runDetailsObjectTest = {
  Action: "aws:invokeLambdaFunction",
  ExecutionEndTime: "2019-11-07 09:09:03.909000+00:00",
  ExecutionStartTime: "2019-11-07 09:09:03.111000+00:00",
  Inputs: {
    FunctionName:
      '"arn:aws:lambda:us-west-2:362726853720:function:check-elb-test-check_elb"',
    Payload: '"{ \\"elb_name\\": \\"dev-elbscenario-elb1\\" }"',
  },
  MaxAttempts: 3,
  NextStep: "Check_ELB_Result",
  OnFailure: "Abort",
  Outputs: {
    OutputPayload: [
      '{"Payload":{"no_bad_vms": 0, "instance_ids": "[]", "execution_status": "S_OK", "statusCode": 200},"StatusCode":200}',
    ],
    Payload: [
      '{"no_bad_vms": 0, "instance_ids": "[]", "execution_status": "S_OK", "statusCode": 200}',
    ],
    StatusCode: ["200"],
    instance_ids: ["[]"],
    no_bad_vms: ["0"],
  },
  OverriddenParameters: {},
  StepExecutionId: "2acd4f6b-d029-465e-9584-a3cb2832e6e2",
  StepName: "Check_ELB",
  StepStatus: "Success",
  ValidNextSteps: ["Check_ELB_Result"],
  stepNumber: 1,
};

export const runDetailsObject = testing
  ? runDetailsObjectTest
  : runDetailsObjectLive;

export const getStepInputs = stepDetails => {
  switch (stepDetails.Action) {
    case "aws:branch":
      return stepDetails.Inputs;
    case "aws:executeStateMachine":
      return stepDetails.Inputs.input;
    default:
      return stepDetails.Inputs.Payload;
  }
};

export const getStepOutputs = stepDetails => {
  switch (stepDetails.Action) {
    case "aws:branch":
      return stepDetails.Outputs.OutputPayload[0];
    case "aws:executeStateMachine":
      return stepDetails.Outputs.output[0];
    default:
      return stepDetails.Outputs.Payload[0];
  }
};

export const getStepFailure = stepDetails => {
    return stepDetails.FailureDetails.Details;
};

export const convertToObject = item => {
  if (!item) {
    return;
  }
  try {
    let json = JSON.parse(item);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }

    return json;
    //return Array.isArray(json) ? json[0] : json;
  } catch (e) {
    if (typeof item == "string") {
      return {
        Payload: item
      };
    };
    return item;
  }
};

export const findStepInExectuionById = (executionDetails, stepExecutionId) => {
  let count = 0;
  const stepExecution = executionDetails?.StepExecutions.find(execution => {
    count++;
    return execution.StepExecutionId === stepExecutionId;
  });
  return { ...stepExecution, ...{ stepNumber: count } };
};
