import React from "react";

const Status = ({ statusMessage = "" }) => {
  const statusLookup = {
    OK: "ok",
    DISRUPTED: "disrupted",
    DEGRADED: "degraded",
    UNKNOWN: "unknown",
  };

  return (
    <div className={`status-type-block ${statusLookup[statusMessage]}`}>
      {statusMessage}
    </div>
  );
};

export default Status;
