import Api from "@lib/api/api";
import {
  FETCH_WORKSPACE_USERS_LIST_SUCCESS,
  FETCH_COLLABORATION_DETAILS_SUCCESS,
  UPDATE_COLLABORATION_DETAILS_SUCCESS,
  CREATE_JIRA_ISSUE_SUCCESS,
  CREATE_SLACK_CHANNEL_SUCCESS,
  CREATE_ZOOM_LINK_SUCCESS,
} from "@redux/types";

export function getWorkspaceUsersList() {
  return async dispatch => {
    try {
      const response = await Api.fetchWorkspaceUsersList();
      return dispatch({
        type: FETCH_WORKSPACE_USERS_LIST_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function getCollaborationDetails() {
  return async dispatch => {
    try {
      const response = await Api.fetchCollaborationDetails();
      return dispatch({
        type: FETCH_COLLABORATION_DETAILS_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function updateCollaborationDetails(data) {
  return async dispatch => {
    try {
      const response = await Api.editCollaborationDetails(data);
      return dispatch({
        type: UPDATE_COLLABORATION_DETAILS_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function createJiraIssue(data) {
  return async dispatch => {
    try {
      const response = await Api.createJiraIssue(data);
      return dispatch({
        type: CREATE_JIRA_ISSUE_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function createSlackChannel(data) {
  return async dispatch => {
    try {
      const response = await Api.createSlackChannel(data);
      return dispatch({
        type: CREATE_SLACK_CHANNEL_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function createZoomLink(data) {
  return async dispatch => {
    try {
      const response = await Api.createZoomLink(data);
      return dispatch({
        type: CREATE_ZOOM_LINK_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}
