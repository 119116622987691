import React from "react";
import "./FormSubmitError.scss";
import errorIcon from "@assets/images/icons/icon-error.svg";
import closeIcon from "@assets/images/icons/icon-close.svg";

type FormSubmitErrorProps = {
  errorMsg: string;
  toggleVisibility?: () => void;
};

const FormSubmitError: React.FC<FormSubmitErrorProps> = ({
  errorMsg,
  toggleVisibility,
  ...props
}) => {
  return (
    <div className="fs-error-container">
      <div className="d-flex align-items-center">
        <img src={errorIcon} alt="errorIcon" width="16" height="16" />
        <span className="fs-error-text">{errorMsg}</span>
      </div>
      <img
        src={closeIcon}
        alt="closeIcon"
        width="10"
        height="10"
        onClick={toggleVisibility}
        className="pointer mt-5-px"
      />
    </div>
  );
};
export default FormSubmitError;
