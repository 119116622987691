import produce from "immer";
import {
  TM_FETCH_TASK_LIST_SUCCESS,
  TM_SET_SELECTED_TASK,
  TM_FETCH_TASK_SUCCESS,
  TM_SET_TASK_LIST,
  TM_FETCH_TASK_TYPE_LIST_SUCCESS,
  CREATE_JIRA_ISSUE_SUCCESS,
  CREATE_ZOOM_LINK_SUCCESS,
  CREATE_SLACK_CHANNEL_SUCCESS,
} from "@redux/types";

const INITIAL_STATE = {
  allTaskList: [],
  selectedTask: null,
  allTaskTypesList: [],
};

const taskManagementReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TM_FETCH_TASK_LIST_SUCCESS:
        let response =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.allTaskList = response;
        break;

      case TM_SET_TASK_LIST:
        draft.allTaskList = action.allTaskList;
        break;

      case TM_SET_SELECTED_TASK:
        draft.selectedTask = action.task;
        break;

      case TM_FETCH_TASK_SUCCESS:
        let res =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.selectedTask = res;
        break;

      case TM_FETCH_TASK_TYPE_LIST_SUCCESS:
        let list =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.allTaskTypesList = list;
        break;
      case CREATE_JIRA_ISSUE_SUCCESS:
        let jiraObj =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.selectedTask.incident_response.jira = jiraObj?.jira;
        break;
      case CREATE_ZOOM_LINK_SUCCESS:
        let zoomObj =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.selectedTask.incident_response.zoom = zoomObj?.zoom;
        break;
      case CREATE_SLACK_CHANNEL_SUCCESS:
        let slackObj =
          typeof action.response === "string"
            ? JSON.parse(action.response)
            : action.response;
        draft.selectedTask.incident_response.slack = slackObj?.slack;
        break;

      default:
        break;
    }
  });

export default taskManagementReducer;
