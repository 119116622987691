import React from "react";
import "./Profile.scss";
import LeftPanel from "./utils/InstructionPanel";
import ConnectorForm from "./utils/ConnectorForm";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const AWSTargetSettings = props => {
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  return (
    <>
      <div className="d-flex flex-column">
        <div className="ml-n1 mb-20-px">
          {isAccountDetailFetched && !userDefaultAccounts.length ? (
            <Link
              to="/settings/integrations"
              className="breadcrumb-link pointer"
            >
              Settings
            </Link>
          ) : (
            <div className="d-flex align-items-baseline">
              <Link
                to="/settings/integrations"
                className="breadcrumb-link pointer"
              >
                Settings
              </Link>{" "}
              <span className="breadcrumb-pike">/</span>
              <Link
                to="/settings/aws-settings"
                className="breadcrumb-link pointer"
              >
                AWS Target Accounts
              </Link>
            </div>
          )}
        </div>
        <div className="d-flex flex-column">
          <div className="profile-title">Create AWS AssumeRole</div>
          <div className="profile-panel">
            <LeftPanel />
            <ConnectorForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default AWSTargetSettings;
