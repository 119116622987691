import Api from "@lib/api/api";
import {
  GIT_REPOS_FETCH_SUCCESS,
  GIT_REPOS_SAVE_SUCCESS,
  GIT_REPOS_UPDATE_SUCCESS,
  GIT_REPOS_DELETE_SUCCESS,
} from "@redux/types";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { isEmpty } from "@lib/utils";
import { mapResponse } from "@containers/SettingsPanel/GIT/utils/helper";

/* Taking spinner title as degault param, because this function is being
/ from one more comonent and we need to override the title */
export function fetchGitRepos(spinnerTitle="Fetching GitHub Repos") {
  return async dispatch => {
    dispatch(isFetching(true, spinnerTitle));
    const response = await Api.getGitRepoList().catch(e => console.log(e));
    if (!isEmpty(response)) {
      const body =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;
      const repos =
        typeof body === "string" ? JSON.parse(body).repos : body?.repos;

      if (!isEmpty(repos)) {
        dispatch({
          type: GIT_REPOS_FETCH_SUCCESS,
          payload: mapResponse(repos),
        });
      }
    }
    dispatch(isFetching(false, ""));
  };
}

export const addGitRepo = repo => {
  return async dispatch => {
    dispatch({
      type: GIT_REPOS_SAVE_SUCCESS,
      payload: repo,
    });
  };
};

export const updateGitRepo = repo => {
  return async dispatch => {
    dispatch({
      type: GIT_REPOS_UPDATE_SUCCESS,
      payload: repo,
    });
  };
};

export const removeGitRepo = repo => {
  return async dispatch =>
    dispatch({
      type: GIT_REPOS_DELETE_SUCCESS,
      payload: repo,
    });
};
