import produce from "immer";
import {
  REQUEST_SENT,
  RESPONSE_RECEIVED,
  REQUEST_ERROR,
  SHOW_LOADER,
  HIDE_LOADER,
  SET_LOADING_MESSAGE,
  RESET_STATE,
} from "@redux/types";

const INITIAL_STATE = {
  loaderCount: 0,
  loaderFlag: false,
  loaderMessage: "Loading...",
};

const globalLoaderReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_SENT:
        draft.loaderCount = state.loaderCount + 1;
        break;
      case RESPONSE_RECEIVED:
        draft.loaderCount = state.loaderCount - 1;
        break;
      case REQUEST_ERROR:
        draft.loaderCount = state.loaderCount - 1;
        break;
      case HIDE_LOADER:
        draft.loaderFlag = true;
        break;
      case SHOW_LOADER:
        draft.loaderFlag = false;
        break;
      case SET_LOADING_MESSAGE:
        draft.loaderMessage = action.payload || "Loading...";
        break;
      case RESET_STATE:
        draft.loaderCount = 0;
        draft.loaderFlag = false;
        draft.loaderMessage = "Loading...";
        break;
      default:
        break;
    }
  });

export default globalLoaderReducer;
