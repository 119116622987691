import React from "react";
import "./LogoHeader.scss";

type LogoHeaderProps = {};

const LogoHeader: React.FC<LogoHeaderProps> = () => (
  <div className="lh-container">
    <div className="logo-wrap"></div>
  </div>
);

export default LogoHeader;
