import React from "react";
import "./wait.scss";
import { ReactComponent as WaitImg } from "@assets/images/icons/wait.svg";
import { connect } from "react-redux";
import { SHOW_WAIT, SET_WAIT_MESSAGE } from "@redux/types";

class WaitMessage extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.showWait && (
          <div className="wait__cover">
            <div>
              <div className="wait-text">
                {!!this.props.waitMessage
                  ? this.props.waitMessage
                  : "Loading..."}
              </div>
              <div className="wait-spinner">
                <WaitImg />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ uiReducer }) => ({
  waitMessage: uiReducer.waitMessage,
  showWait: uiReducer.showWait,
  requestCount: uiReducer.requestCount,
});

const mapDispatch = dispatch => ({
  setShowWait: showWait =>
    dispatch({
      type: SHOW_WAIT,
      payload: showWait,
    }),
  setMessage: message =>
    dispatch({
      type: SET_WAIT_MESSAGE,
      payload: message,
    }),
});

export default connect(mapStateToProps, mapDispatch)(WaitMessage);
