import React, { useState } from "react";
import { useParams } from "react-router";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import Incident from "./Incidents";
import EnvironmentHealth from "./EnvironmentHealth/EnvironmentHealth";

function DashboardTabs() {
  const tabs = ["incidents", "environment-health"];
  const { subTab } = useParams();
  const [selectedTab, setSelectedTab] = useState(subTab ? subTab : tabs[0]);

  return (
    <div className="runbook-tabs mt-40-px ml-2 position-relative">
      <TabCollection
        tabs={tabs}
        tabChange={tab => {
          setSelectedTab(tab);
        }}
      >
        <Tab title="Incidents" name="incidents">
          <TabContent>{selectedTab === "incidents" && <Incident />}</TabContent>
        </Tab>
        <Tab title="Environment Health" name="environment-health">
          <TabContent>
            {selectedTab === "environment-health" && <EnvironmentHealth />}
          </TabContent>
        </Tab>
      </TabCollection>
    </div>
  );
}

export default DashboardTabs;
