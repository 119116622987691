import produce from "immer";
import { TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS } from "@redux/types";
const INITIAL_STATE = {
  taskTypeAssignments: [],
};

const TaskTypeAssignmentReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS:
        draft.taskTypeAssignments = action.payload;
        break;

      default:
        break;
    }
  });

export default TaskTypeAssignmentReducer;
