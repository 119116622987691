import Api from "@lib/api/api";
import {
  IS_FETCHING_OPERATIONS,
  IS_FETCHING_SERVICES,
  SET_SERVICES,
  SET_SELECTED_SERVICE,
  SET_SELECTED_OPERATION,
  SERVICES_NEEDED,
  SET_OPERATIONS,
  IS_FETCHING_OPERATION_DETAILS,
  SET_OPERATION_DETAILS,
  SET_OUTPUTS,
  REMOVE_CACHED_AWS_DATA,
  CACHED_AWS_SERVICES,
  CACHED_AWS_EC2_OPERATIONS,
} from "../types";
import { store } from "@redux/store";
import { showGlobalLoader } from "./globalLoader.actions";

export const fetchServices = () => {
  return async dispatch => {
    try {
      let data;
      dispatch(isFetchingServices(true));
      const services = store.getState()?.commonReducer?.aws?.services;
      const documentation = store.getState()?.commonReducer?.aws?.awsDocLink;

      if (Array.isArray(services) && services.length > 0) {
        data = { services, documentation };
      } else {
        data = await Api.getAWSServices();
        dispatch({
          type: CACHED_AWS_SERVICES,
          payload: data,
        });
      }

      dispatch({
        type: SERVICES_NEEDED,
        payload: false,
      });
      dispatch({ type: SET_SERVICES, payload: data });
      dispatch(isFetchingServices(false));
    } catch (e) {
      console.log("Error fetching api.getAWSService: ", e);
    }
  };
};

export const setSelectedService = selectedService => {
  return async dispatch => {
    dispatch({
      type: SET_SELECTED_SERVICE,
      payload: selectedService,
    });
    let cachedService = store.getState().actionNodeReducer
      .serviceOperationsCache[selectedService];
    let operations = cachedService?.operations;
    let serviceDocLink = cachedService?.documentation;
    if (operations) {
      dispatch({
        type: SET_OPERATIONS,
        payload: {
          selectedService,
          operations,
          documentation: serviceDocLink,
        },
      });
      return;
    }
    dispatch(fetchOperations(selectedService));
  };
};

export const fetchOperations = selectedService => {
  return async dispatch => {
    try {
      dispatch(isFetchingOperations(true));
      let data;

      const cachedEC2Operations = store.getState().commonReducer.aws
        .ec2Operations;
      const cachedEC2DocLink = store.getState().commonReducer.aws
        .ec2ServiceDocLink;

      if (selectedService) {
        if (
          selectedService === "ec2" &&
          Array.isArray(cachedEC2Operations) &&
          cachedEC2Operations.length > 0
        ) {
          data = {
            operations: cachedEC2Operations,
            documentation: cachedEC2DocLink,
          };
        } else {
          data = await Api.getAWSServiceOperations(selectedService);
          // If ec2 operations are not cached, cache the operations
          if (selectedService === "ec2") {
            dispatch({
              type: CACHED_AWS_EC2_OPERATIONS,
              payload: data,
            });
          }
        }
      } else {
        dispatch({
          type: SET_OPERATIONS,
          payload: { selectedService: [], operations: [] },
        });
        dispatch(isFetchingOperations(false));
      }

      if (data) {
        const { operations, documentation } = data;
        dispatch({
          type: SET_OPERATIONS,
          payload: { selectedService, operations, documentation },
        });
        dispatch(isFetchingOperations(false));
      } else {
        dispatch(isFetchingOperations(false));
      }
    } catch (e) {
      console.log("Error fetching AWS operations for a service: ", e);
    }
  };
};

export const fetchAwsOperationDetails = operation => {
  return async dispatch => {
    dispatch(showGlobalLoader());
    dispatch(isFetchingOperationDetails(true));
    const selectedService = store.getState().actionNodeReducer.selectedService;
    const data = await Api.getAWSServiceOperationDetails(
      selectedService,
      operation,
    ).catch(e => {
      console.log(
        `Could not fetch aws details for ${selectedService} ${operation}: ${e}`,
        e,
      );
      return;
    });
    dispatch({ type: SET_OPERATION_DETAILS, payload: data });
    dispatch(isFetchingOperationDetails(false));
  };
};

export const fetchAwsOutputs = (service, operation) => {
  return async dispatch => {
    const data = await Api.getAWSServiceOperationDetails(
      service,
      operation,
    ).catch(e => {
      console.log(
        `Could not fetch aws details for ${service} ${operation}: ${e}`,
        e,
      );
      return;
    });
    dispatch({
      type: SET_OUTPUTS,
      payload: data?.output || [],
      key: `${service}-${operation}`,
    });
  };
};

export const setSelectedOperation = operation => {
  return async dispatch => {
    dispatch({
      type: SET_SELECTED_OPERATION,
      payload: operation,
    });
    if (operation) {
      dispatch(fetchAwsOperationDetails(operation));
    }
  };
};

/* is fetching ? */
export const isFetchingOperationDetails = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_OPERATION_DETAILS, payload: bool });
  };
};

export const isFetchingServices = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_SERVICES, payload: bool });
  };
};

export const isFetchingOperations = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_OPERATIONS, payload: bool });
  };
};

export const resetCachedAWSDATA = () => ({
  type: REMOVE_CACHED_AWS_DATA,
});
