import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  Sector,
  Label,
  ResponsiveContainer,
} from "recharts";
import BarChart from "./BarChart";
import EmptyChart from "./EmptyChart";
import { getPaddingAngle, getTotalPrioritites } from "./utils";

import "./Chart.scss";

const COLORS = ["#b177ff", "#55bab9", "#bae6ff", "#548af7"];
const MULTILINE_COLORS = [
  "#804af3",
  "#6fdc8c",
  "#548af7",
  "#c3e5fc",
  "#55bab9",
];

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        <tspan fontSize="16">
          {payload.name}: {value}
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const CustomLabel = ({ viewBox, value }) => {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      fill="#fff"
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <tspan fontSize="16">Total: {value}</tspan>
    </text>
  );
};

const ChartSelector = ({
  type,
  text,
  piChart,
  barData,
  lineData,
  multiLineData,
  unit = "Minutes",
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const onPieLeave = (_, index) => {
    setActiveIndex(-1);
  };

  return (
    <>
      {type === "multipleLine" && (
        <ResponsiveContainer width="100%" debounce={300} height="100%">
          <LineChart>
            <XAxis
              dataKey="category"
              type="category"
              allowDuplicatedCategory={false}
              tick={{ fill: "#fff" }}
              stroke="#fff"
              padding={{ right: 40 }}
            />
            <YAxis
              dataKey="value"
              label={{
                value: unit,
                angle: -90,
                position: "insideLeft",
                textAnchor: "middle",
                fill: "#fff",
              }}
              tick={{ fill: "#fff" }}
              stroke="#fff"
            />
            <Tooltip />
            <Legend />
            {multiLineData.map((s, idx) => (
              <Line
                dataKey="value"
                type="monotone"
                data={s.data}
                name={s.name}
                key={s.name}
                stroke={MULTILINE_COLORS[idx % MULTILINE_COLORS.length]}
                strokeWidth={3}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
      {type === "singleLine" && (
        <ResponsiveContainer width="100%" debounce={300} height="94.5%">
          <LineChart data={lineData}>
            <XAxis
              dataKey="name"
              tick={{ fill: "#fff" }}
              stroke="#fff"
              padding={{ right: 40 }}
            />
            <YAxis
              dataKey={text}
              label={{
                value: unit,
                angle: -90,
                position: "insideLeft",
                textAnchor: "middle",
                fill: "#fff",
              }}
              tick={{ fill: "#fff" }}
              stroke="#fff"
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={text}
              fill="#b177ff"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
      {type === "donut" && (
        <ResponsiveContainer width="100%" debounce={300} height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={piChart}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={130}
              fill="#82ca9d"
              paddingAngle={getPaddingAngle(piChart)}
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
            >
              {activeIndex === -1 && (
                <Label
                  width={30}
                  position="center"
                  content={<CustomLabel value={getTotalPrioritites(piChart)} />}
                ></Label>
              )}
              {piChart.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            {/* <Tooltip /> */}
            <Legend margin={{ left: 32 }} />
          </PieChart>
        </ResponsiveContainer>
      )}
      {type === "bar" && (
        <ResponsiveContainer width="100%" debounce={300} height="100%">
          <BarChart barData={barData} />
        </ResponsiveContainer>
      )}
    </>
  );
};

function Chart({
  width,
  text,
  type,
  isEmpty = false,
  emptyStateSubtext,
  ...otherProps
}) {
  return (
    <div className="ch-container" style={{ width: width }}>
      <div className="d-flex">
        <div className="text">{text}</div>
        <div></div>
      </div>
      {/* <div className="subText">{subText}</div> */}
      <div
        style={type === "donut" ? null : { marginTop: "3rem" }}
        className={
          type === "donut" ? "donutWrapper" : "multilineLagend chartWrapper"
        }
      >
        {isEmpty ? (
          <EmptyChart type={type} emptyStateSubtext={emptyStateSubtext} />
        ) : (
          <ChartSelector text={text} type={type} {...otherProps} />
        )}
      </div>
    </div>
  );
}

export default Chart;
