import React from "react";
import ReactTooltip from "react-tooltip";

export default class DropDownIcon extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.iconImage ? (
          <React.Fragment>
            <div
              className="dropdown-icon"
              style={{
                background: `url(${this.props.iconImage})`,
              }}
              id={this.props.toggleId}
              onClick={this.props.toggle}
              data-for={`drop-down-${this.props.toggleId}`}
              data-tip={this.props.tooltip}
            />
            <ReactTooltip
              id={`drop-down-${this.props.toggleId}`}
              place={
                this.props.tooltipPosition ? this.props.tooltipPosition : "left"
              }
              effect="solid"
              type="light"
            />
          </React.Fragment>
        ) : (
          <div
            style={{
              transform: this.props.toggleStyle.arrow,
              transformStyle: "preserve-3d",
              transition: "transform .2s ease-in-out",
            }}
            className={this.props.showArrow ? "dropdown-toggle__icon" : ""}
            onClick={this.props.toggle}
            id={this.props.toggleId}
          ></div>
        )}
      </React.Fragment>
    );
  }
}
