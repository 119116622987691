import React from "react";
import { Modal, Button } from "@components/ui";
import { connect } from "react-redux";
import Api from "@lib/api";
import { FTNotification } from "@components/ui";
import { store } from "@redux/store";
import { RUNBOOK_SCHEDULE } from "@redux/types";
import Schedule from "@components/shared/Schedule/Schedule";

const getFooter = (toggleRunbookModal, isLoading, scheduleAction) => (
  <div className="modal-buttons-footer">
    <Button
      text="Cancel"
      buttonStyle="secondary"
      style={{ width: "50%" }}
      onClick={toggleRunbookModal}
      type="button"
    />
    <Button
      text={`Schedule`}
      buttonStyle="primary"
      style={{ width: "50%" }}
      type={"button"}
      size="large"
      isLoading={isLoading}
      onClick={scheduleAction}
    />
  </div>
);

class RunbookScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedRate: null,
      scheduleExpression: null,
    };
  }

  setValues = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  scheduleAction = async (runbookName, runbookVersion, toggleScheduleModal) => {
    let exp = this.state.fixedRate
      ? this.state.fixedRate
      : `cron(${this.state.scheduleExpression.join(" ")})`;
    let body = {
      version: parseInt(runbookVersion),
      expression: exp,
      params: this.props.selectedRequiredParameters || {},
    };
    try {
      let data = await Api.scheduleRunbook(runbookName, body);
      if (data.ERROR) {
        FTNotification.error({
          title: "Could not schedule runbook execution",
          message: data.ERROR,
        });
      } else {
        FTNotification.success({
          title: "Runbook execution scheduled successfully",
        });
        toggleScheduleModal();

        let currentSchedule = this.props.currentSchedule.filter(
          a => a.version !== parseInt(runbookVersion),
        );
        currentSchedule.push({
          schedule_expression: exp,
          version: parseInt(runbookVersion),
        });
        store.dispatch({
          type: RUNBOOK_SCHEDULE,
          payload: currentSchedule,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { toggleScheduleModal, runbook } = this.props;
    const runbookName = runbook.name || runbook.Name;
    const currentSchedule = this.props.currentSchedule?.find(
      s => s.version === parseInt(this.props.runbook.DocumentVersion),
    );

    return (
      <React.Fragment>
        {this.props.runbook && (
          <Modal
            onClose={toggleScheduleModal}
            title={`Schedule workflow "${runbookName}:v${runbook.DocumentVersion}" for execution`}
            backgroundCanClose={false}
            showClose={true}
            footer={getFooter(toggleScheduleModal, false, () =>
              this.scheduleAction(
                runbookName,
                runbook.DocumentVersion,
                toggleScheduleModal,
              ),
            )}
          >
            <Schedule
              currentSchedule={currentSchedule}
              fixedRate={this.state.fixedRate}
              scheduleExpression={this.state.scheduleExpression}
              setValues={this.setValues}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ runbookReducer }) => {
  return {
    runbook: runbookReducer.activeRunbook,
  };
};
export default connect(mapState)(RunbookScheduleModal);
