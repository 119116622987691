import produce from "immer";
import { AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS } from "@redux/types";

const INITIAL_STATE = {
  AWSTargetAccountInfo: {},
};

const AWSSettingsReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS:
        draft.AWSTargetAccountInfo = action.payload;
        break;

      default:
        break;
    }
  });
export default AWSSettingsReducer;
