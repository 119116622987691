import React from "react";

const RunbookDetailsBlockLine = props => (
  <div className="meta-wrap">
    <div className="meta-title">{props.title}</div>
    <div className="meta-text">{props.text}</div>
  </div>
);

export default RunbookDetailsBlockLine;
