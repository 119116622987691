import { isEmpty } from "@lib/utils";

export const cardsInitialInfo = [
  { label: "Today", value: 0 },
  { label: "Open", value: 0 },
  { label: "Active", value: 0 },
  { label: "Pending", value: 0 },
  { label: "Closed", value: 0 },
];

export const getCardsInfo = (cards) => {
  
  if(isEmpty(cards)) {    
    return cardsInitialInfo
  }
  const cardsCopy = JSON.parse(JSON.stringify(cardsInitialInfo))
  
  Object.keys(cards).forEach(item => {
    const targetCard = cardsCopy.find(idx => idx.label === item);
    if (targetCard) {
      targetCard.value = cards[item];
    }
  });

  return cardsCopy
}

export const arrangeChartData = (data, key) => {
  if(isEmpty(data)) return []

  return Object.keys(data).map(item => ({name: item, [key]: data[item]}))
}

export const arrangeMultilineChartData = (data, key = "data") => {
  if(isEmpty(data)) return []

  return Object.keys(data).map(item => ({name: item, [key]: Object.keys(data[item]).map(date => ({ category: date, value: data[item][date]}))}))
}

export const getTotalPrioritites = (data) => {
  if(isEmpty(data)) return 0
  
  return data.reduce((acc, index) => {
    if(typeof(index?.value) === "number") {
      return acc + index.value
    }
    return acc
  }, 0)
}

export const getPaddingAngle = (data) => {
  if(!Array.isArray(data)) return 2.5
  // Check if 3 item have their value = 0
  let resetCount = 0
  data.forEach(item => {
    if(item?.value === 0) {
      resetCount++
    }
  })
  return resetCount === 3 ? 0 : 2.5
}

export const isPriorityListEmpty = (data) => {
  if(!Array.isArray(data)) return true
  let resetCount = 0
  data.forEach(item => {
    if(item?.value === 0) {
      resetCount++
    }
  })
  return resetCount === 4
}