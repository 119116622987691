import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Cards from "./Cards";
import Chart from "./Chart";
import Title from "./Title";

import Api from "@lib/api";
import {
  cardsInitialInfo,
  getCardsInfo,
  arrangeChartData,
  isPriorityListEmpty,
  arrangeMultilineChartData,
} from "./utils";

import "./Incidents.scss";

function Incidents() {
  const [cardsInfo, setCardsInfo] = useState(cardsInitialInfo);
  const [topIncidentTypes, setTopIncidentTypes] = useState([]);
  const [incidentByPriority, setIncidentByPriority] = useState([]);
  const [mtta, setMtta] = useState([]);
  const [mttr, setMttr] = useState([]);
  const [mttaByType, setMttaByType] = useState([]);
  const [mttrByType, setMttrByType] = useState([]);

  const dateRange = useSelector(state => state.dashboardReducer);
  const fetchDashboardData = async payload => {
    try {
      const response = await Api.getIncidentsData(payload);

      const {
        incident_status_dashboard,
        priority_dashboard,
        task_type_dashboard,
        mtta_dashboard,
        mttr_dashboard,
        mtta_inc_type_dashboard,
        mttr_inc_type_dashboard,
      } = response || {};

      const cardsData = getCardsInfo(incident_status_dashboard);
      const priorityData = arrangeChartData(priority_dashboard, "value");
      const incidentTypes = arrangeChartData(task_type_dashboard, "value");
      const MTTA = arrangeChartData(mtta_dashboard, "MTTA");
      const MTTR = arrangeChartData(mttr_dashboard, "MTTR");
      const MTTAByType = arrangeMultilineChartData(mtta_inc_type_dashboard);
      const MTTRByType = arrangeMultilineChartData(mttr_inc_type_dashboard);

      // Set dashboard data
      setCardsInfo(cardsData);
      setIncidentByPriority(priorityData);
      setTopIncidentTypes(incidentTypes);
      setMttr(MTTR);
      setMtta(MTTA);
      setMttaByType(MTTAByType);
      setMttrByType(MTTRByType);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDashboardData(dateRange);
  }, [dateRange]);

  return (
    <>
      <Cards items={cardsInfo} />
      <Title text="Incidents Summary" />
      <div className="chartContainer">
        <Chart
          width="55%"
          text="Top Incident Types"
          subText="Showing top incident types for the selected date range"
          type="bar"
          barData={topIncidentTypes}
          isEmpty={
            Array.isArray(topIncidentTypes) && topIncidentTypes.length === 0
          }
        />
        <Chart
          width="44%"
          text="Incidents by Priority"
          subText="Showing all incidents for selected date range"
          type="donut"
          piChart={incidentByPriority}
          isEmpty={isPriorityListEmpty(incidentByPriority)}
        />
      </div>
      <Title text="Mean Time To Remediate (MTTR)" />
      <div className="chartContainer">
        <Chart
          width="44%"
          text="MTTR"
          type="singleLine"
          lineData={mttr}
          isEmpty={Array.isArray(mttr) && mttr.length === 0}
        />
        <Chart
          width="55%"
          text="MTTR by Type"
          type="multipleLine"
          multiLineData={mttrByType}
          isEmpty={Array.isArray(mttrByType) && mttrByType.length === 0}
        />
      </div>
      <Title text="Mean Time To Action (MTTA)" />
      <div className="chartContainer">
        <Chart
          width="44%"
          text="MTTA"
          type="singleLine"
          lineData={mtta}
          unit={"Seconds"}
          isEmpty={Array.isArray(mtta) && mtta.length === 0}
        />
        <Chart
          width="55%"
          text="MTTA by Type"
          type="multipleLine"
          multiLineData={mttaByType}
          unit={"Seconds"}
          isEmpty={Array.isArray(mttaByType) && mttaByType.length === 0}
        />
      </div>
    </>
  );
}

export default Incidents;
