import { SHOW_CONFIRM, SET_WAIT_MESSAGE, SHOW_WAIT } from "../types";
import produce from "immer";

const INITIAL_STATE = {
  showConfirm: false,
  confirmHeading: "",
  confirmMessage: "",
  cancelText: "",
  confirmText: "",
  waitMessage: "My default message",
  showWait: false,
};

const uiReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_CONFIRM:
        draft.showConfirm = action.payload;
        break;

      case SHOW_WAIT:
        draft.showWait = action.payload;
        break;

      case SET_WAIT_MESSAGE:
        draft.waitMessage = action.payload;
        break;

      default:
        break;
    }
  });

export default uiReducer;
