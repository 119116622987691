import Api from "@lib/api/api";
import { isEmpty } from "@lib/utils";
import {
  TERRAFORM_CLI_FETCH_SUCCESS,
  TERRAFORM_CLI_SAVE_SUCCESS,
  TERRAFORM_CLI_UPDATE_SUCCESS,
  TERRAFORM_CLI_DELETE_SUCCESS,
} from "@redux/types";
import { isFetching } from "@redux/actions/settingsPanel.action";

export const fetchTerraformModule = () => {
  return async dispatch => {
    dispatch(isFetching(true, "Fetching Terraform Modules..."));
    const response = await Api.fetchTerraformCLIModules().catch(e =>
      console.log(e),
    );
    if (!isEmpty(response)) {
      const body =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;
      const terraformModules =
        typeof body === "string" ? JSON.parse(body) : body;

      if (!isEmpty(terraformModules)) {
        dispatch({
          type: TERRAFORM_CLI_FETCH_SUCCESS,
          payload: terraformModules,
        });
      }
    }
    dispatch(isFetching(false, ""));
  };
};

export const addTerraformModule = value => {
  return async dispatch => {
    dispatch({
      type: TERRAFORM_CLI_SAVE_SUCCESS,
      payload: value,
    });
  };
};

export const updateTerraformModule = value => {
  return async dispatch => {
    dispatch({
      type: TERRAFORM_CLI_UPDATE_SUCCESS,
      payload: value,
    });
  };
};

export const removeTerraformModule = value => {
  return async dispatch =>
    dispatch({
      type: TERRAFORM_CLI_DELETE_SUCCESS,
      payload: value,
    });
};
