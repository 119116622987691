import React from "react";

export default class Switch extends React.Component {
  getComponent = () => {
    let returnComponent = null;
    let defaultComponent;

    React.Children.map(this.props.children, component => {
      let key = "value";
      if (component && component.props?.caseValue) {
        if (component.props.caseValue) {
          key = "caseValue";
        }
        const foundComponent = this.findComponent(key, component);
        if (foundComponent) {
          returnComponent = component;
        }
        if (component.props[key] === "default") {
          defaultComponent = component;
        }
      }
    });
    if (!returnComponent) {
      return defaultComponent;
    } else {
      return returnComponent;
    }
  };

  findComponent = (key, component) => {
    let returnComponent;
    const caseValueArray = component.props[key].split("|");
    for (let i = 0; i < caseValueArray.length; i++) {
      let caseValue = caseValueArray[i];
      /* replace spaces */
      const reg = new RegExp("/s/", "g");
      caseValue = caseValue.replace(reg);
      if (caseValue === this.props.value) {
        returnComponent = component;
        break;
      }
    }

    return returnComponent;
  };

  render() {
    return <React.Fragment>{this.getComponent()}</React.Fragment>;
  }
}
