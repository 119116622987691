import React from "react";

const BeginBasicsCard = ({ title, description, buttonText, url }) => {
  return (
    <div className="uo-basics-card">
      <div className="uo-basics-card-image"></div>
      <div className="uo-basics-card-title">{title}</div>
      <div className="uo-basics-card-description">{description}</div>
      <a
        className="uo-basics-card-btn"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText}
      </a>
    </div>
  );
};

const BeginWithBasics = () => {
  const data = [
    {
      title: "Welcome to Fylamynt",
      description: "",
      buttonText: "Go To Video",
      url: "https://www.youtube.com/watch?v=YbJDkg3TQLo",
    },
    {
      title: "Incident Response with Fylamynt (Product Walkthrough)",
      description: "",
      buttonText: "Go To Video",
      url: "https://youtu.be/hxxw2gJvgVY",
    },
    {
      title: "Fylamynt introduces “SlackOps” for DevOps and SREs",
      description: "",
      buttonText: "Go To Video",
      url: "https://www.youtube.com/watch?v=jQ5DUiFJnGE",
    },
  ];

  return (
    <div className="uo-wrapper">
      <h3 className="uo-checklist-header">Begin with the basics</h3>
      <p className="uo-checklist-description">
        Get up and running quickly by checking off common tasks
      </p>
      <div className="uo-basics-wrapper">
        {data.map(item => (
          <BeginBasicsCard key={item.title} {...item} />
        ))}
      </div>
    </div>
  );
};

export default BeginWithBasics;
