import React from "react";
import { HeaderMain } from "@components/modules";
import { Button } from "@components/ui";
import { useHistory } from "react-router-dom";

const SettingsPanelHeader = props => {
  const history = useHistory();
  return (
    <HeaderMain
      title={props.heading}
      showBreadcrumbs={props.isBreadCrumb}
      breadCrumbText={props.breadCrumbText}
    >
      {props.page === "aws-settings" && (
        <Button
          buttonStyle="primary"
          onClick={() => history.push("/settings/get-started")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "apikeys" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleAPIKeysModal()}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "git-repos" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleGitRepoFormVisibility("create")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "terraform-cli-modules" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleTerraformCLIFormVisibility("create")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "ssh-targets" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleGitRepoFormVisibility("add")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "s3-buckets" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleS3BucketFormVisibility("create")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "cloudformation-templates" && (
        <Button
          buttonStyle="primary"
          onClick={() => props.toggleCloudFormationFormVisibility("create")}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
      {props.page === "eks" && (
        <Button
          buttonStyle="primary"
          onClick={props.toggleHandler}
          size="small"
          text="Add New"
          icon="icon-plus"
        ></Button>
      )}
    </HeaderMain>
  );
};

export default SettingsPanelHeader;
