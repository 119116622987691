import React from "react";
import "./overflow-menu.scss";
//import { clickOutside, generateId } from "@lib/utils";

export default class OverflowMenu extends React.PureComponent {
  state = {
    showMenuItems: false,
    menuBackground: "",
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.listener = null;
  }

  showHide = e => {
    e.stopPropagation();
    this.setState(
      {
        showMenuItems: !this.state.showMenuItems,
        menuBackground: this.state.showMenuItems ? "" : "#3d3d3d",
      },
      () => {
        if (this.state.showMenuItems) {
          document.body.addEventListener("click", this.hideMenu, true);
        }
      },
    );
  };

  hideMenu = e => {
    if (e.target === this.ref.current) {
      return;
    }

    if (this.state.showMenuItems) {
      this.setState({
        showMenuItems: false,
        menuBackground: "",
      });
      document.body.removeEventListener("click", this.hideMenu, true);
      this.listener = null;
    }
  };

  componentWillUnmount() {
    document.body.removeEventListener("click", this.hideMenu, true);
  }

  render() {
    return (
      <div className="overflow-menu-container overflow-items">
        <div
          className={this.props.icon || "overflow-menu-icon"}
          id={this.state.id}
          ref={this.ref}
          onClick={this.showHide}
          style={{
            backgroundColor: this.state.menuBackground,
            ...this.props.menuIconStyle,
          }}
        />
        {this.state.showMenuItems && (
          <div
            className="overflow-menu-items"
            style={{
              ...this.props.menuStyle,
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}
