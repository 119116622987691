import React, { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { store } from "@redux/store";
import { getAwsConfig } from "@lib/utils";

const AmplifyConfig = props => {
  useEffect(() => {
    const appConfig = store.getState().appReducer.appConfig;
    const awsConfig = getAwsConfig(appConfig);
    Auth.configure(awsConfig);
  }, []);

  return <>{props.children}</>;
};

export default AmplifyConfig;
