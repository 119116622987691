import Api from "@lib/api";
import { store } from "@redux/store";
import Bugsnag from "@bugsnag/js";
import {
  WEBSOCKET_CONNECTION_ID,
  WEBSOCKET_EXECUTION_DETAILS,
  WEBSOCKET_EXECUTION_STEPS,
} from "@redux/types";

/**
 * File named as a memory of "NeurOps" name
 *
 * NeuropsWebSocket client
 * Trying to connect to neurops websocket lambda server
 * and keep the connection open with the app life span,
 * if server disconneted for other reasons, it will try to connect automatically
 * and keep a connection_id in the store reducer.
 *
 * how to use:
 * init new instance of the class
 * let ws = new NeuropsWebSocket()
 * then call connect() method to init connection
 * get the connection_id from websocketReducer.connectionId
 *
 */
export default class NeuropsWebSocket {
  attemts = 1;
  maxAttempts = 5;

  connect = () => {
    let state = store.getState();
    if (
      (this.ws &&
        this.ws.readyState === WebSocket.OPEN &&
        state.websocketReducer.connectionId.length) ||
      (this.ws && this.ws.readyState === WebSocket.CONNECTING) ||
      this.attemts >= this.maxAttempts
    ) {
      return;
    }
    //console.log('connecting to websocket 🎬 scene#', this.attemts)
    let websocketUrl = state.appReducer.appConfig.websocketURI;
    let api = new Api();
    this.ws = new WebSocket(`${websocketUrl}?token=${api.Token}`);
    this.ws.onopen = this.onopen;
    this.ws.onmessage = this.onmessage;
    this.ws.onclose = this.onclose;
    this.ws.onerror = this.onerror;
    this.attemts++;
  };

  send = message => {
    try {
      this.ws.send(message);
    } catch (e) {
      this.ws.emit("error", e);
    }
  };

  onopen = e => {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.send(`{}`);
    }
  };

  onmessage = e => {
    let data = JSON.parse(e.data);
    if (data.connection_id) {
      store.dispatch({
        type: WEBSOCKET_CONNECTION_ID,
        payload: data.connection_id,
      });
    } else if (data.detail && data.detail.StepName) {
      store.dispatch({
        type: WEBSOCKET_EXECUTION_STEPS,
        payload: data.detail,
      });
    } else if (data.detail && !data.detail.StepName) {
      store.dispatch({
        type: WEBSOCKET_EXECUTION_DETAILS,
        payload: data,
      });
    }
  };

  onerror = e => {
    switch (e.code) {
      case "ECONNREFUSED":
        this.connect();
        break;
      default:
        this.closed(e);
        break;
    }
  };

  onclose = e => {
    switch (e.code) {
      case 1000:
        this.closed(e);
        break;
      default:
        this.connect();
        break;
    }
  };

  closed = (error = "") => {
    store.dispatch({
      type: WEBSOCKET_CONNECTION_ID,
      payload: "",
    });

    // notify error
    const message = `Websocket connection was closed. Error: ${error}`;
    Bugsnag.notify(new Error(message));
  };
}
