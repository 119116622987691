import React from "react";

export default class IconRight extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.iconRight && (
          <div onClick={this.props.toggle}>
            <img
              src={this.props.iconRight}
              alt="icon"
              onClick={this.props.toggle}
              className="dropdown-ignore"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
