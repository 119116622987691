import Api from "@lib/api/api";
import {
  API_KEYS_FETCHING,
  API_KEYS_FETCH_ERROR,
  API_KEYS_FETCH_SUCCESS,
  API_KEYS_CREATING,
  API_KEYS_CREATE_ERROR,
  API_KEYS_CREATE_SUCCESS,
  API_KEYS_DELETING,
  API_KEYS_DELETE_ERROR,
  API_KEYS_DELETE_SUCCESS,
  API_KEYS_TOGGLE_FIRST_LOGIN,
  SHOW_CONFIRM,
  API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN,
} from "@redux/types";

export function fetchingAPIKeys(bool) {
  return async dispatch =>
    dispatch({
      type: API_KEYS_FETCHING,
      payload: bool,
    });
}

export function fetchAPIKeys() {
  return async dispatch => {
    dispatch(fetchingAPIKeys(true));
    const response = await Api.getAPIKeys().catch(e =>
      dispatch({ type: API_KEYS_FETCH_ERROR, payload: false }),
    );
    if (response) {
      dispatch({
        type: API_KEYS_FETCH_SUCCESS,
        payload: response,
      });
    }
  };
}

export function creatingAPIKey(bool) {
  return async dispatch =>
    dispatch({
      type: API_KEYS_CREATING,
      payload: bool,
    });
}

export function createAPIKey(data) {
  return async dispatch => {
    dispatch(creatingAPIKey(true));
    let response = await Api.createAPIKey(data).catch(e => {
      return dispatch({
        type: API_KEYS_CREATE_ERROR,
        payload: "Error in saving Account",
      });
    });
    if (response.statusCode !== 201) {
      return dispatch({
        type: API_KEYS_CREATE_ERROR,
        payload: "Error in saving Account",
      });
    }
    if (response) {
      return dispatch({
        type: API_KEYS_CREATE_SUCCESS,
        payload: response,
      });
    }
  };
}

export function deletingAPIKey(bool) {
  return async dispatch =>
    dispatch({
      type: API_KEYS_DELETING,
      payload: bool,
    });
}

export function deleteAPIKey(name) {
  return async dispatch => {
    dispatch(deletingAPIKey(true));
    let response = await Api.deleteAPIKey(name).catch(e => {
      dispatch({ type: API_KEYS_DELETE_ERROR, payload: false });
    });
    if (response) {
      dispatch({
        type: API_KEYS_DELETE_SUCCESS,
        payload: name,
      });
    }
  };
}

export function toggleDeleteConfirmBox(showFlag) {
  return async dispatch => {
    dispatch({ type: SHOW_CONFIRM, payload: showFlag });
  };
}

export function toggleFirstLogin(bool) {
  return async dispatch =>
    dispatch({
      type: API_KEYS_TOGGLE_FIRST_LOGIN,
      payload: bool,
    });
}

export function disableSplashScreen(bool) {
  return async dispatch =>
    dispatch({
      type: API_KEYS_TOGGLE_DISABLE_SPLASH_SCREEN,
      payload: bool,
    });
}
