import React from "react";
import { OverflowMenu } from "@components/ui";
import { useHistory } from "react-router-dom";
import { ConnectorType } from "../Constants";
import "./SettingsPanelTile.scss";

const opacityArr = [
  "datadog",
  "humio",
  "twilio",
  "teleport",
  "ssh",
  "s3",
  "terraform_cli",
  "terraform",
  "lightstep",
];

const getMenuOptions = (props, history) => {
  switch (props.type) {
    case ConnectorType.cloud:
      if (props.status === "Connected") {
        return (
          <>
            <div
              className="button-grey"
              title={props.name}
              id={`node_${props.name?.toLowerCase()}`}
              onMouseDown={e => {
                let eventObj = e;
                props.handleClick(eventObj);
              }}
            >
              Edit Settings
            </div>
          </>
        );
      }
      break;
    case ConnectorType.integration:
      if (props.status === "Connected") {
        return (
          <>
            <div
              className="button-grey"
              title={props.name}
              id={`node_${props.name?.toLowerCase()}`}
              onMouseDown={e => {
                let eventObj = e;
                props.handleClick(eventObj);
              }}
            >
              Edit Settings
            </div>
            {props.name?.toLowerCase() !== "aws" && (
              <div
                onMouseDown={e => {
                  let eventObj = e;
                  props.handleRemove(eventObj, props.name);
                }}
                className="button-delete"
              >
                Remove Integration
              </div>
            )}
          </>
        );
      }
      return (
        <div
          onMouseDown={e => {
            if (props.addIntegrationUrl) {
              history.push(props.addIntegrationUrl);
            } else {
              props.handleClick(e);
            }
          }}
          className="button-grey"
          title={props.name}
        >
          Add Integration
        </div>
      );

    case ConnectorType.resource:
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Resource
        </div>
      );

    default:
      break;
  }
};

const handleTileClick = (e, props, history) => {
  if (props.status !== "Connected") {
    if (props.addIntegrationUrl)
      history.push({
        pathname: props.addIntegrationUrl,
        state: { from: "Settings" },
      });
  } else {
    props.handleClick(e);
  }
};

// Dynamically import images
const getBackgroundImg = name => {
  if (!name) {
    return {};
  }
  try {
    const imgURL = require(`@assets/images/snippets/icon-${name}.svg`);

    return {
      backgroundImage: `url(${imgURL})`,
      opacity: opacityArr.includes(name) ? 0.5 : 0.13,
      filter: name === "ssh" ? `contrast(50%)` : "",
    };
  } catch (e) {}
};

const SettingsPanelTile = props => {
  let history = useHistory();
  return (
    <div
      className="integration-tile d-inline-block fade-in"
      title={props.name}
      onClick={e => handleTileClick(e, props, history)}
    >
      <OverflowMenu
        menuIconStyle={{ zIndex: 1, top: "18px" }}
        menuStyle={{ top: "45px", zIndex: 1 }}
      >
        {getMenuOptions(props, history)}
      </OverflowMenu>
      <div className="settings-icon radio__xl">
        <h4 className="integration-title">{props.displayName}</h4>
        <div
          style={getBackgroundImg(
            props.name?.replace(/\s/g, "")?.toLowerCase(),
          )}
          className="setting-tile"
        ></div>
      </div>
      {props.type !== ConnectorType.resource && (
        <div className="settings-name radio__xl">
          <div
            className={`status-icon ${
              props.status?.toLowerCase() === "connected"
                ? ""
                : " status-icon-inactive"
            }`}
          ></div>
          <div className="status">{props.status}</div>
        </div>
      )}
    </div>
  );
};

export default SettingsPanelTile;
