import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../../settings-panel-components";
import { Confirm, FTNotification } from "@components/ui";
import CloudFormationModal from "./CloudFormationModal";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  fetchCloudFormationTemplates,
  deleteCloudFormationTemplate,
} from "@redux/actions/settingsPanel.action";
import { fetchGitRepos } from "@redux/actions/gitRepo.actions";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

type CloudFormationProps = {};

const CloudFormation: React.FC<CloudFormationProps> = props => {
  const newCloudFormationValues = {
    templateId: "",
    repoId: "",
    filePath: "",
    ref: "",
  };
  const dispatch: any = useDispatch();
  const showConfirm = useSelector(
    (state: RootStateOrAny) => state.uiReducer.showConfirm,
  );
  const loadingMessage = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.message,
  );
  const cloudFormationTemplateList = useSelector(
    (state: RootStateOrAny) =>
      state.settingsPanelReducer.cloudFormationTemplateList,
  );

  const [selectedTemplate, setSelectedTemplate] = useState(
    newCloudFormationValues,
  );

  const [showCloudFormationModal, setShowCloudFormationModal] = useState(false);
  const [cloudFormationMode, setCloudFormationMode] = useState("create");
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleCloudFormationFormVisibility = mode => {
    setShowCloudFormationModal(true);
    if (mode === "create") {
      setSelectedTemplate(newCloudFormationValues);
      setCloudFormationMode(mode);
    }
  };

  const onEditHandler = row => {
    setSelectedTemplate(row);
    setCloudFormationMode("edit");
    toggleCloudFormationFormVisibility("edit");
  };

  const onRemoveHandler = row => {
    setSelectedTemplate(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const cloudFormationColumns = tableColumns(onRemoveHandler, onEditHandler);

  useEffect(() => {
    dispatch(fetchGitRepos());
    dispatch(fetchCloudFormationTemplates());
  }, [dispatch]);

  return (
    <>
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This CloudFormation Template will be deleted permanently."
          confirmValue={selectedTemplate.templateId}
          confirmRequiredText={`Confirm by typing '${selectedTemplate.templateId}' below`}
          isLoading={isDeleting}
          verifiedCallback={async () => {
            setIsDeleting(true);
            const action = await dispatch(
              deleteCloudFormationTemplate(selectedTemplate.templateId),
            );
            dispatch(toggleDeleteConfirmBox(false));
            setIsDeleting(false);
            if (action.type === "DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS") {
              FTNotification.success({
                title: "CloudFormation Template Deleted Successfully!",
              });
            } else {
              FTNotification.error({
                title: "Could not delete CloudFormation Template",
                message: "",
              });
            }
          }}
        />
      )}
      {showCloudFormationModal && (
        <CloudFormationModal
          selectedTemplate={selectedTemplate}
          closeForm={setShowCloudFormationModal}
          mode={cloudFormationMode}
          templateList={cloudFormationTemplateList}
        />
      )}

      <div className="d-flex flex-column">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="cloudformation-templates"
          heading="CloudFormation Templates"
          breadCrumbText="Settings/CloudFormation Templates"
          toggleCloudFormationFormVisibility={
            toggleCloudFormationFormVisibility
          }
        />
        {!loadingMessage &&
          (cloudFormationTemplateList.length > 0 ? (
            <div className="mt-40-px ml-2 gr-list-container table-overflow-list">
              <DataTable
                className="snippet-list-table"
                columns={cloudFormationColumns}
                data={cloudFormationTemplateList}
                responsive={true}
                fixedHeader={true}
              />
            </div>
          ) : (
            <NoResultFound
              message="Add your first CloudFormation Template"
              detail="To add CloudFormation Template, click the 'Add New' button in the top-right of this screen."
              className="mt-100-px"
              errImgSrc="settings-es"
            />
          ))}
      </div>
    </>
  );
};

export default CloudFormation;
