import React from "react";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./settings-panel-lib/settings-helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  toggleDeleteConfirmBox,
  fetchAWSTargetAccountInfo,
} from "@redux/actions/AWSSettings.actions";
import {
  fetchDefaultAccounts,
  updateMessage,
  deleteAWSTargetAccount,
  editAWSTargetAccount,
} from "@redux/actions/getStarted.actions";
import { Confirm, FTNotification } from "@components/ui";
import { Redirect } from "react-router";
import Api from "@lib/api";
import { isEmpty } from "@lib/utils";
import { RouteConstants } from "../../routes/Constants";

type AWSSettingsListPropType = {
  awsTargetAccountList: [];
  fetchDefaultAccounts: () => void;
  deleteAWSTargetAccount: (item: any) => void;
  updateMessage: (message: string) => void;
  enableEditAccount: any;
  loadingMessage: string;
  editAWSTargetAccount: any;
  showConfirm: boolean;
  toggleDeleteConfirmBox: any;
  fetchAWSTargetAccountInfo: any;
  AWSTargetAccountInfo: any;
};

type AWSSettingsListStateType = {
  tableColumns: any;
  currentRow: any;
  confirmRequiredText: string;
  doRedirect: boolean;
  redirectUrl: string;
};

class AWSSettingsList extends React.Component<
  AWSSettingsListPropType,
  AWSSettingsListStateType
> {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: tableColumns(
        this.onDeleteHandler,
        this.onSetDefaultHandler,
        this.onEditHandler,
        this.onAliasClick,
      ),
      confirmRequiredText: "",
      currentRow: {},
      doRedirect: false,
      redirectUrl: "",
    };
  }

  componentDidMount() {
    if (!this.props?.awsTargetAccountList?.length)
      this.props.fetchDefaultAccounts();
  }

  onDeleteHandler = (row: any) => {
    this.setState({
      currentRow: row,
      confirmRequiredText: `Confirm by typing '${row.alias}' below`,
    });
    this.props.toggleDeleteConfirmBox(true);
  };

  onEditHandler = async (row: any) => {
    this.props.updateMessage("Fetching Account Information");
    await this.props.fetchAWSTargetAccountInfo(row.alias);
    this.props.updateMessage("");
    this.props.enableEditAccount(this.props.AWSTargetAccountInfo);
  };

  onSetDefaultHandler = async (row: any) => {
    this.props.updateMessage("Updating Target Account ...");
    let item = { ...row };
    item["is_default"] = true;
    const alias = item.alias;
    delete item.alias;
    try {
      const response = await Api.updateAWSTargetAccount(item, alias);
      this.props.updateMessage("");
      if (
        !isEmpty(response) &&
        (response.status === 200 || response.status === 201)
      ) {
        const data =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;

        // Update AWS account list with the filtered list in case of EDIT
        const updatedAccountsList = this.props.awsTargetAccountList.map(
          (val: any) => {
            if (val.alias === data.alias) {
              return data;
            }
            const returnVal = { ...val };
            returnVal["is_default"] = false;
            return returnVal;
          },
        );
        this.props.editAWSTargetAccount(updatedAccountsList);
      } else {
        FTNotification.error({
          title: "Could not update account",
          message: "",
        });
      }
    } catch (error) {
      FTNotification.error({
        title: "Could not update account",
        message: error.message,
      });
    }
  };

  onAliasClick = (row: any) => {
    const redirectUrl = `${RouteConstants.awsSettings.url}/${row.alias}`;
    this.setState({ doRedirect: true, redirectUrl });
  };

  deleteAWSAccount = async () => {
    this.props.toggleDeleteConfirmBox();
    this.props.updateMessage("Deleting AWS Account");
    try {
      const response = await Api.deleteAWSTargetAccount(this.state.currentRow);
      if (!isEmpty(response?.ERROR) && response?.ERROR?.isAxiosError) {
        FTNotification.error({
          title: "Could not delete account",
          message: response?.ERROR?.response?.data?.message,
        });
      } else {
        this.props.deleteAWSTargetAccount(this.state.currentRow);
        this.props.updateMessage("");
      }
    } catch (error) {
      FTNotification.error({
        title: "Could not delete account",
        message: error.message,
      });
    }
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        <div className="table-overflow-list mt-40-px ml-2">
          <DataTable
            columns={this.state.tableColumns}
            data={this.props.awsTargetAccountList}
            responsive={true}
            fixedHeader={true}
          />
        </div>
        {this.props.showConfirm && (
          <Confirm
            heading="Delete Confirmation"
            cancelText="Cancel"
            confirmText="Delete"
            message="This target account  will be deleted permanently."
            confirmValue={this.state.currentRow.alias}
            confirmRequiredText={this.state.confirmRequiredText}
            verifiedCallback={this.deleteAWSAccount}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  awsTargetAccountList: state.getStartedReducer.userDefaultAccounts,
  loadingMessage: state.runbooksReducer.message,
  showConfirm: state.uiReducer.showConfirm,
  AWSTargetAccountInfo: state.AWSSettingsReducer.AWSTargetAccountInfo,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchDefaultAccounts,
      deleteAWSTargetAccount,
      editAWSTargetAccount,
      toggleDeleteConfirmBox,
      fetchAWSTargetAccountInfo,
      updateMessage,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AWSSettingsList);
