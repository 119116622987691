import React, { useState } from "react";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { RouteConstants } from "../../../../routes/Constants";
import { FormFooter } from "@components/shared/GetStarted/Utils/Helpers";
import "./Cloudwatch.scss";

type ConnectorFormPropsModel = {
  aliasChanged: any;
  alias: any;
};

const ConnectorForm = (props: ConnectorFormPropsModel) => {
  /**
   * Redux connect props
   */

  const dispatch = useDispatch();

  const aliasList = useSelector(
    (state: RootStateOrAny) => state.getStartedReducer.userDefaultAccounts,
  );

  /**
   * Application local state
   */

  const [redirect, setRedirect] = useState({
    doRedirect: false,
    redirectUrl: "",
  });

  /**
   * Sets state to navigate to settings page
   */
  const goBack = () => {
    setRedirect({
      doRedirect: true,
      redirectUrl: RouteConstants.cloudwatch.url,
    });
  };

  /**
   * check if backend can fetch list of alarms from target account
   */
  const validateToken = async (alias: string, actions: any) => {
    const response = await Api.fetchTargetAccountAlarmsList(alias).catch(e => {
      dispatch(isFetching(false, ""));
      actions.setStatus({
        isError: true,
        formSubmitMessage:
          "Could not fetch CloudWatch alarms, double check step number 10.",
      });
    });

    return !!response;
  };

  /**
   * This function handles formik submission
   * @param formInput Formik input data
   * @param actions formik actions object
   */
  const onSubmitHandle = async (formInput: any, actions: any) => {
    dispatch(isFetching(true, "Testing integration..."));
    actions.setSubmitting(true);
    actions.setStatus({
      isError: false,
      formSubmitMessage: "",
    });
    // Validate credentials before saving it
    let validateResponse = await validateToken(formInput.alias.alias, actions);
    if (!validateResponse) return;
    const saveResponse = await Api.saveCloudwatchIntegration(
      formInput.alias.alias,
    );
    actions.setSubmitting(false);
    dispatch(isFetching(false, ""));

    if (saveResponse?.ERROR) {
      let error = JSON.parse(saveResponse?.ERROR?.response?.data);
      actions.setStatus({
        isError: true,
        formSubmitMessage: error.error,
      });
      return;
    }

    actions.setStatus({
      formSubmitMessage: "Integration was successful",
    });

    // Navigate to settings page
    setTimeout(() => {
      goBack();
    }, 1000);
  };

  return (
    <>
      {redirect.doRedirect && <Redirect to={redirect.redirectUrl} push />}
      <Formik
        initialValues={{
          alias: props.alias || "",
        }}
        onSubmit={onSubmitHandle}
      >
        {formikProps => (
          <div className="d-flex flex-column">
            <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
            <div className="gs-panel-right-field-wrapper">
              <label className="label">Select Target Account</label>
              <ReactSelect
                id="container-alias"
                name="container-alias"
                value={{
                  value: props?.alias?.alias,
                  label: props?.alias?.alias
                    ? props?.alias?.alias
                    : "Select from below",
                }}
                handleChange={data => {
                  if (data === null) {
                    props.aliasChanged("");
                    formikProps.setFieldValue("alias", "");
                  } else {
                    props.aliasChanged(data.value);
                    formikProps.setFieldValue("alias", data.value);
                  }
                }}
                selectOptions={aliasList?.map(alias => {
                  return {
                    value: alias,
                    label: alias.alias,
                  };
                })}
                customMenuClass="default-select-options-container"
                customMenuListClass="default-select-options-list"
                customValueContainerClass="default-select-value-container"
                customControlClass="default-select-control"
                customOptionClass="default-select-list-item"
                required
              />
            </div>
            <FormFooter onSubmit={formikProps.handleSubmit} />
            <div
              className={`${
                formikProps.status?.isError ? "input-feedback" : ""
              } pt-2`}
            >
              {formikProps.status?.formSubmitMessage}
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ConnectorForm;
