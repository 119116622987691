import React from "react";

type FormFooterProps = {
  onSubmit: () => void;
  isEditMode?: boolean;
  disableSubmit?: boolean;
  isNewTaskTypeAssignmentOpen?: boolean;
};

export const FormFooter: React.FC<FormFooterProps> = ({
  onSubmit,
  isEditMode = false,
  disableSubmit = false,
  isNewTaskTypeAssignmentOpen,
}) => (
  <div className="gs-form-footer">
    <button
      type="button"
      className={`gs-form-footer-btn gs-form-footer-btn-submit ${
        disableSubmit && !isNewTaskTypeAssignmentOpen ? "no-pointer" : ""
      }`}
      onClick={onSubmit}
    >
      {isEditMode ? "Update" : "Authorize"}
    </button>
  </div>
);

export const getRemoveMessage = () => {
  return (
    <div className="p-10-px">
      <li>
        Removing the integration will cause ongoing scheduled workflows with
        this dependency to fail.
      </li>
      <li>
        For trigger based integrations (data ingestion), incident type
        assignments will also be removed.
      </li>
    </div>
  );
};
