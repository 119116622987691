export const recursiveDataParser = data => {
  let parsedData = {};
  for (const key in data) {
    let value = data[key];
    parsedData[key] = value;
    try {
      let parsedValue = JSON.parse(value);
      parsedData[key] = recursiveDataParser(parsedValue);
    } catch (err) {}
  }
  return parsedData;
};

export const limitExceeded = (usage, limit) => {
  return usage >= limit;
};

/**
 *
 * @function addScriptToBody function to add external script to body tag
 * @param file src for external file to load in body tag
 */
export function addScriptToBody(file) {
  var sc = document.createElement("script");
  sc.setAttribute("type", "text/javascript");
  sc.setAttribute("src", file);
  sc.setAttribute("defer", "defer");
  document.getElementsByTagName("body")[0].appendChild(sc);
}
