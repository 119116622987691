import React from "react";
import "./HeaderWithNavigation.scss";
import FylamyntLogo from "@assets/images/logo/fylamynt-text-logo.svg";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";

const HeaderWithNavigation = ({ showLink = false, linkText = "" }) => {
  return (
    <div className="hwn-container">
      {showLink && (
        <div className="navigation-link">
          <Link
            to={`${RouteConstants.userProfile.baseUrl}/account-plan`}
            className="breadcrumb-link"
          >
            ← {linkText}
          </Link>
        </div>
      )}
      <Link to="/workflows/my-workflows">
        <img
          src={FylamyntLogo}
          alt="FylamyntLogo"
          width=" 112px"
          height="18px"
        />
      </Link>
    </div>
  );
};

export default HeaderWithNavigation;
