import React from "react";
import ReactDOM from "react-dom";
import RoutedApp from "./routes/Routes";
import { Provider } from "react-redux";
import { store, persistor } from "@redux/store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AppConfig from "@components/AppConfig";
import { PersistGate } from "redux-persist/integration/react";
import { addScriptToBody } from "./utils/common";
const target = document.getElementById("root");

// Add dynamic scripts
(function () {
  // Add HubSpot script in prod
  if (
    process.env.NODE_ENV === "production" ||
    window.location.href.includes("app.fylamynt.com")
  ) {
    addScriptToBody("//js.hs-scripts.com/5419588.js");
  }
})();

// Initialize Bugsnag
const apiKey =
  process.env.BUGSNAG_API_KEY || "f6de3704d912cf8cee79c89651b3f3fd";
const appVersion = process.env.REACT_APP_VERSION || "1.0.0";
const releaseStage = process.env.REACT_APP_STAGE || "development";

Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
  appVersion,
  releaseStage,
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppConfig>
        <ErrorBoundary>
          <RoutedApp />
        </ErrorBoundary>
      </AppConfig>
    </PersistGate>
  </Provider>,
  target,
);
