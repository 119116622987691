import Api from "@lib/api/api";
import {
  TM_FETCH_TASK_LIST_SUCCESS,
  TM_SET_SELECTED_TASK,
  TM_FETCH_TASK_SUCCESS,
  TM_SET_TASK_LIST,
  TM_FETCH_TASK_TYPE_LIST_SUCCESS,
} from "@redux/types";

export function fetchAllTaskList() {
  return async dispatch => {
    try {
      const response = await Api.fetchTaskList();
      dispatch({
        type: TM_FETCH_TASK_LIST_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function setAllTaskList(allTaskList) {
  return async dispatch =>
    dispatch({
      type: TM_SET_TASK_LIST,
      allTaskList,
    });
}

export function setSelectedTask(task) {
  return async dispatch =>
    dispatch({
      type: TM_SET_SELECTED_TASK,
      task,
    });
}

export function fetchTask(id) {
  return async dispatch => {
    try {
      const response = await Api.fetchTask(id);
      return dispatch({
        type: TM_FETCH_TASK_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function fetchAllTaskTypes() {
  return async dispatch => {
    try {
      const response = await Api.fetchTaskTypesList();
      return dispatch({
        type: TM_FETCH_TASK_TYPE_LIST_SUCCESS,
        response,
      });
    } catch (e) {
      console.log(e);
    }
  };
}
