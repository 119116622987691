import React, { useEffect } from "react";
import Button from "@components/ui/button/button";

export function ErrorFallback({ error }) {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      // If we want to implicitly reload the page
      //window.location.reload();
    }
  }, [error]);

  return (
    <div className="d-flex flex-column align-items-center h-100vh justify-content-center">
      <div className="d-flex flex-column align-items-center">
        <p className="pb-10-px">This is a cached session. Please Reload.</p>
        <Button
          text="Reload"
          buttonStyle="primary"
          size="large"
          type="button"
          onClick={() => {
            window.location.reload();
          }}
          className="fp-form-submit-btn"
        />
      </div>
    </div>
  );
}
