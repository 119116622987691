import React from "react";
import { Provider } from "../lib/ui.context";
import "./Form.scss";
import { generateId } from "@lib/utils";

const allowableTypes = ["text", "select", "radio", "checkbox", "textarea"];

const allowableType = type => {
  return allowableTypes.indexOf(type) > -1;
};

const verify = el => {
  const verified = meetsMininumLength(el);
  return verified;
};

const meetsMininumLength = el => {
  if (!el.hasOwnProperty("required") && !el.hasOwnProperty("minLength")) {
    return true;
  }
  if (el.hasOwnProperty("minLength") && el.length > el.minLength) {
    return true;
  } else if (el.length > 0) {
    return true;
  }
  return false;
};

export default class Form extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.formIsSubmitting !== this.props.formIsSubmitting) {
      if (!this.props.formIsSubmitting) {
        this.removeCover();
      }
    }
  }

  submitForm = e => {
    e.preventDefault();
    const elements = e.target.elements;
    let parameters = {};
    let verified = true;
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];
      if (!allowableType(el.type)) continue;
      if (verify(el)) {
        verified = true;
        parameters[el.name] = el.value;
      } else {
        verified = false;
        break;
      }
    }
    if (verified) {
      if (this.props.onSubmit) {
        if (this.props.showCoverOnSubmit) {
          this.createCover();
        }
        this.props.onSubmit(parameters);
      } else {
        console.warn(
          "This form has no onSubmit prop. add <Form onSubmit={yourSubmitMethod} >",
        );
      }
    }
    return false;
  };

  createCover = (message = "Submitting form, please wait") => {
    const div = document.createElement("div");
    div.className = "form-cover";
    div.innerHTML = `<div class='form-center'>${message}</div>`;
    document.body.appendChild(div);
  };

  removeCover = () => {
    const div = document.querySelector(".form-cover");
    if (div) {
      div.parentNode.removeChild(div);
    }
  };

  render() {
    const formName = generateId("form-");
    const contextValue = {
      submissionError: this.props.submissionError,
    };
    return (
      <Provider value={contextValue}>
        <form
          className={`${this.props.className}`}
          name={formName}
          onSubmit={e => this.submitForm(e)}
          id={this.props.id}
        >
          {this.props.children}
        </form>
      </Provider>
    );
  }
}
