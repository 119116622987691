import Api from "@lib/api/api";
import {
  FETCHING_A_SNIPPET_SUCCESS,
  IS_FETCHING_A_SNIPPET,
  FETCHING_A_SNIPPET_ERROR,
  SET_SELECTED_SNIPPET,
} from "@redux/types";

export const fetchingAsnippetDetails = (name, version) => {
  return async dispatch => {
    dispatch(_isFetchingAsnippet(true));
    const data = await Api.getSnippetDetails(name, version).catch(e => {
      dispatch({
        type: FETCHING_A_SNIPPET_ERROR,
        payload: true,
      });
    });

    if (data) {
      dispatch({
        type: FETCHING_A_SNIPPET_SUCCESS,
        payload: data,
      });
    }
    dispatch(_isFetchingAsnippet(false));
  };
};

export function _isFetchingAsnippet(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_A_SNIPPET,
      payload: bool,
    });
}

export function setSelectedSnippet(snippet) {
  return async dispatch =>
    dispatch({
      type: SET_SELECTED_SNIPPET,
      payload: snippet,
    });
}
