import React from "react";
import PropTypes from "prop-types";
import { Breadcrumbs } from "@components/ui";

const HeaderMain = props => (
  <React.Fragment>
    <div>
      {props.showBreadcrumbs && (
        <Breadcrumbs
          text={props.breadCrumbText}
          navigateTo={props.navigateTo}
        />
      )}
      <div className="title-wrap">
        <h1 className="page-title">{props.title}</h1>
        {props.children}
      </div>
    </div>
  </React.Fragment>
);

HeaderMain.propTypes = {
  showBreadcrumbs: PropTypes.bool,
  breadCrumbText: PropTypes.string,
};

HeaderMain.defaultProps = {
  showBreadcrumbs: true,
  breadCrumbText: "",
};

export default HeaderMain;
