import { SSMActionNames } from "./strings";
import { Step } from "./steps";
import moment from "moment";

export class SleepStep extends Step {
  static ACTION = SSMActionNames.SLEEP;
  constructor(stepJSON, snippetDef = null, trigger = null) {
    super(stepJSON);
    this.iso8601Duration = moment.duration(stepJSON.inputs?.Duration);
    this.untilTimestamp = stepJSON.inputs?.Timestamp;
    this.trigger = trigger;
    this.snippetDef = snippetDef;
  }

  toSSM() {
    return { ...this.ssm, nextStep: this.nextStep };
  }
}
