import Api from "@lib/api/api";
import { TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS } from "@redux/types";
import { mapResponse } from "@components/shared/GetStarted/Utils/HelperFunctions";

export function getTaskTypeAssignment(connector) {
  return async dispatch => {
    try {
      const list = await Api.fetchTaskTypeAssignment(connector);
      if (list) {
        dispatch({
          type: TASK_TYPE_ASSIGNMENT_FETCH_SUCCESS,
          payload: mapResponse(list, connector),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
}
