import { SchemaError } from "../exceptions";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { SnippetType, ControlNames, StepTypes } from "./strings";
import { BranchStep } from "../ssm/branchstep";
import { SleepStep } from "../ssm/sleepstep";
import { PauseStep } from "../ssm/pausestep";
import { hasKeys } from "@lib/utils";
import { WaitForResourceStep } from "./waitforresourcestep";

export class StepTypeChecker {
  constructor(snippetLibrary) {
    this.snippetLibrary = snippetLibrary;
    this.isStep = StepTypeChecker.isStep;
    this.isInvokeLambdaStep = StepTypeChecker.isInvokeLambdaStep;
    this.isBranchStep = StepTypeChecker.isBranchStep;
  }

  static isStep(json) {
    return !!(json.name && json.action && json.inputs);
  }

  static assertIsSSMStep(json) {
    if (!StepTypeChecker.isStep(json)) {
      throw new SchemaError("SSMStep", json);
    }
  }

  static isInvokeLambdaStep(json) {
    return (
      StepTypeChecker.isStep(json) && json.action === InvokeLambdaStep.ACTION
    );
  }

  static isPauseStep(json) {
    return StepTypeChecker.isStep(json) && json.action === PauseStep.ACTION;
  }

  static isDatadogConnectorStep(json) {
    return json.name.startsWith(ControlNames.DatadogAlert);
  }

  static isTerraformUpdateVarsNode(json) {
    return json.name.startsWith("Terraform_Update_Vars");
  }

  static isInstanaAlertStep(json) {
    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      json.name.startsWith("Instana_Alert")
    );
  }

  static isWaitForResourceStep(json) {
    // TODO: make a better regex!
    const statemachineRegex = /^.*WaitForResource.*/;
    return (
      json.action === WaitForResourceStep.action &&
      statemachineRegex.test(json.inputs.stateMachineArn)
    );
  }

  static isLoopStep(json) {
    const loopARNRegex = /^arn:aws:lambda:.*:.*:function:loop-for-each-.*-loop$/;
    const loopNameRegex = /^loop-for-each-.*-loop$/;

    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      (loopARNRegex.test(json.inputs.FunctionName) ||
        loopNameRegex.test(json.inputs.FunctionName))
    );
  }

  static isLoopSupportStep(stepJSON) {
    const loopPauseRegex = /^.*LoopPause/;
    const loopPostProcessRegex = /^.*LoopPost[Pp]rocess/;
    const isLoopPause =
      stepJSON.action === "aws:pause" && loopPauseRegex.test(stepJSON.name);
    const isLoopPostProcess =
      stepJSON.action === "aws:invokeLambdaFunction" &&
      loopPostProcessRegex.test(stepJSON.name);

    return isLoopPause || isLoopPostProcess;
  }

  static isActionNodeStep(json) {
    const actionNodeARNRegex = /^arn:aws:lambda:.*:.*:function:action-node-.*-action_node$/;
    const actionNodeParamRegex = /\{\{\s*ActionNodeLambda\s*\}\}/;

    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      (actionNodeParamRegex.test(json.inputs.FunctionName) ||
        actionNodeARNRegex.test(json.inputs.FunctionName))
    );
  }

  static isTriggerStep = json => {
    return (
      json.name.startsWith(ControlNames.Trigger) ||
      json.name.startsWith(ControlNames.Manual)
    );
  };

  isSnippetStep(json) {
    const snippetDef = this.snippetLibrary.getSnippetDefinitionForStepID(
      json.name,
    );
    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      snippetDef &&
      snippetDef.type === SnippetType.SNIPPET
    );
  }

  static isBranchStep(json) {
    return StepTypeChecker.isStep(json) && json.action === BranchStep.ACTION;
  }

  isConditionalStep(json) {
    const snippetDef = this.snippetLibrary.getSnippetDefinitionForStepID(
      json.name,
    );
    return (
      StepTypeChecker.isBranchStep(json) &&
      snippetDef &&
      snippetDef.type === SnippetType.CONTROL &&
      (snippetDef.name === ControlNames.Conditional ||
        snippetDef.name === ControlNames.CheckELBResult ||
        snippetDef.name === ControlNames.CheckExeStatus)
    );
  }

  isNeurOpsCustomConditionalStep(json) {
    return (
      this.isConditionalStep(json) &&
      (json.name.startsWith(ControlNames.CheckExeStatus) ||
        json.name.startsWith(ControlNames.CheckELBResult))
    );
  }

  static isSleepStep(json) {
    return StepTypeChecker.isStep(json) && json.action === SleepStep.ACTION;
  }

  static isSleepWaitStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      json.name.startsWith(ControlNames.Wait)
    );
  }

  static isJSONPathStep(json) {
    return json.name.startsWith(ControlNames.JSONPathStep);
  }

  static getType(node) {
    if (node && Object.keys(node).length === 0) {
      return;
    }

    if (hasKeys(node, "extras.runbookNode")) {
      if (node?.extras?.runbookNode?.ssm) {
        if (StepTypeChecker.isActionNodeStep(node.extras.runbookNode.ssm)) {
          return "ActionNode";
        }
        if (
          StepTypeChecker.isDatadogConnectorStep(node.extras.runbookNode.ssm)
        ) {
          return ControlNames.DatadogAlert;
        }

        if (
          StepTypeChecker.isTerraformUpdateVarsNode(node.extras.runbookNode.ssm)
        ) {
          return "TerraformUpdateVarsNode";
        }

        if (StepTypeChecker.isLoopStep(node.extras.runbookNode.ssm)) {
          return "LoopForEach";
        }

        if (
          StepTypeChecker.isWaitForResourceStep(node.extras.runbookNode.ssm)
        ) {
          return "WaitForResource";
        }
        if (this.isSleepWaitStep(node.extras.runbookNode.ssm)) {
          return "WaitTimeNode";
        }

        if (this.isInstanaAlertStep(node.extras.runbookNode.ssm)) {
          return "Instana_Alert";
        }
        if (this.isJSONPathStep(node.extras.runbookNode.ssm)) {
          return ControlNames.JSONPathStep;
        }

        if (
          StepTypeChecker.isStep(node.extras.runbookNode.ssm) &&
          node.extras.runbookNode.ssm.name.startsWith("Slack_Send_Message")
        ) {
          return ControlNames.SlackConnector;
        }

        if (node.extras.runbookNode.ssm.name.startsWith("Jira_Update_Issue")) {
          return "Jira_Existing_Issue";
        }
        if (this.isWebhookStep(node.extras.runbookNode.ssm)) {
          return "Webhook";
        }

        if (this.isStringTransformersStep(node.extras.runbookNode.ssm)) {
          return ControlNames.StringTransformers;
        }

        if (StepTypeChecker.isApprovalNodeStep(node.extras.runbookNode.ssm)) {
          return ControlNames.Approval;
        }

        if (StepTypeChecker.isTriggerStep(node.extras.runbookNode.ssm)) {
          return ControlNames.Trigger;
        }
      }

      if (node.extras.runbookNode?.hasOwnProperty("snippetDef")) {
        switch (node.extras.runbookNode.snippetDef?.type) {
          case "SNIPPET":
            return StepTypes.SnippetAction;
          case "CONTROL":
            return node.extras.runbookNode.snippetDef.content.action ===
              "aws:branch"
              ? "ConditionalAction"
              : "SSMStepAction";
          default:
            return "SSMStepAction";
        }
      } else {
        return node.extras.runbookNode.action === "aws:branch"
          ? "ConditionalAction"
          : "SSMStepAction";
      }
    }
  }

  /**
   * WaitStep is the NeurOps Wait control, implemented with aws:sleep
   */
  isWaitStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      json.name.startsWith(ControlNames.Wait)
    );
  }

  isNeurOpsStatusStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      (json.name.startsWith(ControlNames.Success) ||
        json.name.startsWith(ControlNames.Stop) ||
        json.name.startsWith(ControlNames.Fail))
    );
  }

  static isWebhookStep(json) {
    return json.name.startsWith(ControlNames.Webhook);
  }

  static isStringTransformersStep(json) {
    return (
      StepTypeChecker.isStep(json) &&
      json.name.startsWith(ControlNames.StringTransformers)
    );
  }

  static isApprovalNodeStep(json) {
    return json.name.startsWith(ControlNames.Approval);
  }

  static isApprovalNodeSupportStep(stepJSON) {
    const approvalPauseRegex = /^Pause_Approval/;
    const approvalTimeoutRegex = /^Timeout_Approval/;
    const approvalRetrieveRegex = /^Retrieve_Decision_Approval/;
    const approvalConditionalRegex = /^Conditional_Approval/;
    const isApprovalPause =
      stepJSON.action === "aws:pause" && approvalPauseRegex.test(stepJSON.name);
    const isApprovalTimeout =
      stepJSON.action === "aws:invokeLambdaFunction" &&
      approvalTimeoutRegex.test(stepJSON.name);

    const isApprovalRetrieve =
      stepJSON.action === "aws:invokeLambdaFunction" &&
      approvalRetrieveRegex.test(stepJSON.name);
    const isApprovalConditional =
      stepJSON.action === "aws:branch" &&
      approvalConditionalRegex.test(stepJSON.name);

    return (
      isApprovalPause ||
      isApprovalTimeout ||
      isApprovalRetrieve ||
      isApprovalConditional
    );
  }
}
