import * as Yup from "yup";
import { TypeSSHTarget } from "../index";

type TypeSSHTargets = Array<TypeSSHTarget>;

const ERRORS = {
  /* Target Name Errors */
  targetName: "Please enter Target Name",
  targetAlreadyExist: "SSH target already exists",
  targetNameRegex:
    "Target Name can only contain [upercase, lowercase, numbers, dashes, underscore]",

  /* Private Key Errors */
  privateKey: "Please enter Private Key",
};

const isTargetExists = (targets: TypeSSHTargets, item: any) =>
  item !== undefined
    ? !targets?.some(e => e.targetName.toLowerCase() === item?.toLowerCase())
    : true;

export const getValidationSchema = (mode: string, targets: TypeSSHTargets) => {
  if (mode === "add") {
    return Yup.object({
      targetName: Yup.string()
        .required(ERRORS.targetName)
        .notOneOf(
          targets ? targets.map(e => e.targetName) : [],
          ERRORS.targetAlreadyExist,
        )
        .test("caseInsensitive", ERRORS.targetAlreadyExist, (item: any) =>
          isTargetExists(targets, item),
        )
        .matches(/^[a-zA-Z0-9_-]*$/, {
          message: ERRORS.targetNameRegex,
          excludeEmptyString: true,
        })
        .trim(ERRORS.targetName),
      privateKey: Yup.string()
        .required(ERRORS.privateKey)
        .trim(ERRORS.privateKey),
    });
  } else {
    return Yup.object({
      privateKey: Yup.string()
        .required(ERRORS.privateKey)
        .trim(ERRORS.privateKey),
    });
  }
};
