import { toProperCase } from "@lib/utils";

export const mapPlans = (planObj: Array<object>) => {
  const plans = [];

  Object.keys(planObj)
    .forEach((key, index) => {
      const plan = planObj[key];
      plan["name"] = key;
      plan["displayName"] = toProperCase(key);
      plans.push(plan);
    });

  plans.sort((a, b) => {
    return a.pricing - b.pricing;
  });

  return plans;
};
