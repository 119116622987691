import React from "react";
import {
  scrollParentToChild,
  removeFoundElement,
  setFoundElement,
} from "@lib/utils";

/* constants */
const MIN_CHARS_TO_SEARCH = 2;

export const closedStyle = {
  arrow:
    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
  transition: "max-height .2s ease-in-out",
  toggleClass: "dropdown-toggle",
};
export const openStyle = {
  arrow:
    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-180deg) skew(0deg, 0deg)",
  transition: "max-height .1s ease",
  toggleClass: "dropdown-list w-dropdown-list w--open",
};

export const setIconImage = propsIcon => {
  if (propsIcon) {
    const icon = propsIcon.match(/\./g) ? propsIcon : `${propsIcon}.svg`;
    return require(`@assets/images/icons/${icon}`);
  }
};

export const computeHeight = (showExpanded, numberOfChildren) => {
  const n = showExpanded ? 100 : 0;
  let height = n * numberOfChildren;
  if (height > 400) {
    height = 400;
  }
  return height + "px";
};

export const closeDropdown = (e, self) => {
  try {
    const node =
      e.target.parentNode.id === self.toggleId ||
      e.target.parentNode.id === self.toggleTitleId
        ? e.target.parentNode
        : e.target;

    const id = node.getAttribute("id");
    if (id && (id === self.toggleId || id === self.toggleTitleId)) {
      return;
    }
    if (node.classList.contains("dropdown-ignore")) {
      return;
    }
    if (e.target.nodeName === "INPUT") {
      return;
    }
    if (self.ref.current && self.navRef.current) {
      self.setState({
        showExpanded: false,
        height: "0px",
        searchText: "",
      });
    }
  } catch (err) {
    console.log(err, e);
  }
};

export function searchDropDown(e, self) {
  if (self.state.showExpanded) {
    if (self.navRef.current) {
      e.preventDefault();
      const letter = e.key;

      if (letter === "Escape") {
        self.setState(
          {
            searchText: "",
          },
          () => {
            removeFoundElement();
          },
        );
        return;
      }
      if (letter === "Backspace") {
        removeFoundElement();
        self.setState(
          {
            searchText: self.state.searchText.slice(0, -1),
          },
          () => {
            _searchDropDown(self);
          },
        );
      }
      if (letter.length > 1) {
        return;
      } else {
        _searchDropDown(self, letter);
      }
    }
  }
}

const _searchDropDown = (self, letter = "") => {
  _removeHighlights();

  let foundOnce = false;
  let foundItems = [];
  if (letter) {
    const regExp = new RegExp("[a-zA-Z0-9]");
    if (!regExp.test(letter)) {
      self.setState({
        searchText: "",
      });
      return;
    }
  }
  self.setState(
    prevState => {
      return {
        searchText: prevState.searchText + letter,
      };
    },
    () => {
      if (self.state.searchText.length < MIN_CHARS_TO_SEARCH) {
        return;
      }
      React.Children.map(self.props.children, (child, idx) => {
        try {
          const reg = new RegExp("^" + self.state.searchText, "gmi");
          const element = document.getElementById(child.props.text);
          if (!foundOnce && reg.test(child.props.text)) {
            const rect = element.getBoundingClientRect();
            if (rect.y !== 0) {
              scrollParentToChild(self.navRef.current, element);
              foundOnce = true;
            }
          } else if (reg.test(child.props.text)) {
            foundItems.push(element);
          }
        } catch (e) {
          //take no action for now
        }
      });
      if (!foundOnce) {
        removeFoundElement();
      } else {
        _highlightFoundElements(foundItems);
      }
    },
  );
};

export const toggle = self => {
  self.setState({
    showExpanded: !self.state.showExpanded,
    height: computeHeight(
      !self.state.showExpanded,
      self.state.numberOfChildren,
    ),
  });
};

export function optionClickHandler(option, value) {
  if (this.props.changeAble && !this.props.multi) {
    this.setState({
      title: option,
    });
  }

  if (this.props.onChange) {
    this.props.onChange(option, value);
  }
}

function _highlightFoundElements(foundItems) {
  foundItems.forEach(element => setFoundElement(element));
}

function _removeHighlights() {
  const elements = document.querySelectorAll(".found-text");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.classList.remove("found-text");
  }
}
