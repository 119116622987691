import React from "react";
import "./CustomLabel.scss";

const LabelLeft = props => (
  <div className="label-inline" style={props.style}>
    <label className={`label-left ${props.labelClassName}`}>
      {props.label}
    </label>
    {props.children}
  </div>
);

export default LabelLeft;
