import React from "react";
import Header from "./Components/DashboardHeader";
import Tabs from "./Components/DashboardTabs";
import "./Dashboard.scss";

function Dashboard() {
  return (
    <>
      <Header />
      <Tabs />
    </>
  );
}

export default Dashboard;
