import Auth from "@aws-amplify/auth";
import { userResetState } from "@redux/reducers/";
import { resetCachedAWSDATA } from "@redux/actions/action-node.action";
import { resetCachedActions } from './../../redux/actions/settingsPanel.action';

export const authLogout = async dispatch => {
  try {
    await Auth.signOut({ global: true });

    // Notify other tabs that user has logged out
    localStorage.setItem("logoutUser", "true")

    // Remove cached get started actions
    dispatch(resetCachedAWSDATA())
    dispatch(resetCachedActions())
    dispatch(userResetState());
  } catch (e) {
    dispatch(userResetState());
    return;
  }
};
