import { Step } from "../ssm/steps";
import { SSMActionNames, ParameterType } from "../ssm/strings";
import { RunbookStepInput, readLambdaParam } from "../ssm/nodeinputoutput";
import { StepTypes } from "./strings";
import { ActionNodeOperationDetails } from "./actionnodestep";
import { convertPayloadSpecToJSON } from "../ssm/util";

export class WaitForResourceStep extends Step {
  static action = SSMActionNames.EXECUTE_STATE_MACHINE;
  static stepType = StepTypes.WaitForResourceStep;
  static create(
    snippetDef: any,
    service: string,
    operation: string,
    operationDescription?: any,
  ): WaitForResourceStep {
    const aws_call = {
      service,
      operation,
      inputs: "",
    };
    const input = JSON.stringify({
      count: 15,
      step: 1,
      wait_seconds: 15,
      index: 0,
      aws_call,
      result_jsonpath: "$.output.Reservations[0].Instances[0].State.Name",
      desired_state: "running",
      workflow_session: "{{ WorkflowSession }}",
    });

    const json = {
      name: `WaitForResource_${
        snippetDef.insertionOrder
          ? snippetDef.insertionOrder
          : Math.floor(1000 * Math.random())
      }`,
      action: WaitForResourceStep.action,
      inputs: {
        stateMachineArn: snippetDef.content.state_machine_arn,
        input,
      },
    };
    return new WaitForResourceStep(json, snippetDef);
  }

  stepType: typeof StepTypes.WaitForResourceStep;
  snippetDef: any;
  name: string;
  isEnd: boolean;
  nextStep: string;

  // Raw data from SSM
  action: typeof SSMActionNames.EXECUTE_STATE_MACHINE;
  count: number;
  index = 0; // this will only be changed in the StateMachine
  step: number;
  wait_seconds: number;
  aws_call: SerializedAwsCall;
  result_jsonpath: string;
  desired_state: string;
  workflow_session: string;
  state_machine_arn: string;

  // Processed data
  private _awsCall: AwsCall;
  private _operationDetails?: ActionNodeOperationDetails;

  get operation(): string {
    return this._awsCall.operation;
  }

  set operation(op: string) {
    this.setOperation(op);
  }
  setOperation(op: string) {
    if(op){

      this._awsCall.operation = op;
      this.aws_call.operation = op;
    }
  }

  get service(): string {
    return this._awsCall.service;
  }

  set service(s: string) {
    this.setService(s);
  }
  setService(s: string) {
    if (s) {
      this._awsCall.service = s;
      this.aws_call.service = s;
    }
  }

  get parameterInputs(): RunbookStepInput[] {
    return this._awsCall.inputs;
  }

  updateInput(input: RunbookStepInput): void {
    if (input.snippetAction !== this) {
      throw new Error("attempting to set input on wrong workflow step");
    }
    const i = this._awsCall.inputs.findIndex(ri => ri.name === input.name);
    this._awsCall.inputs[i] = input;
    this.aws_call.inputs = this.writeParameterInputs();
  }

  private writeParameterInputs(): string {
    throw new Error("not implemented");
  }

  private _writeOutputs(): any {
    return undefined;
  }

  constructor(stepJSON: any, snippetDef: any) {
    super(stepJSON);
    const parsedInput = JSON.parse(
      convertPayloadSpecToJSON(stepJSON.inputs.input),
    );
    Object.assign(this, parsedInput);
    this.name = stepJSON.name;
    this.action = stepJSON.action;
    this.editable = true;
    this.snippetDef = snippetDef;
    this.nextStep = stepJSON.nextStep;
    this.stepType = StepTypes.WaitForResourceStep;
    if (this.action !== WaitForResourceStep.action) {
      throw new Error(
        "Invalid JSON definition for WaitForResourceStep: wrong `action`: " +
          this.action,
      );
    }

    this.state_machine_arn = snippetDef
      ? snippetDef.content.state_machine_arn
      : {};
    this._awsCall = new AwsCall(this.aws_call, this);
  }

  consumedOutputs() {
    return this.parameterInputs
      ? this.parameterInputs
          .filter(
            input =>
              input.source.type === "snippetOutput" ||
              input.source.type === "actionNode",
          )
          .map(input => input.source.sourceValue)
      : [];
  }

  toSSM(): { [k: string]: any } {
    const {
      name,
      action,
      count,
      step,
      wait_seconds,
      index,
      result_jsonpath,
      desired_state,
      workflow_session,
      state_machine_arn: lambda_arn,
      isEnd,
      nextStep,
    } = this;

    const aws_call = this._awsCall.serialize();
    const inputs = {
      stateMachineArn: lambda_arn,
      input: JSON.stringify({
        count,
        index,
        step,
        wait_seconds,
        alias: "{{alias}}",
        aws_call,
        result_jsonpath,
        desired_state,
        workflow_session,
      }),
    };

    inputs.input = this._awsCall.substituteCallInputs(inputs.input);
    console.log({ inputs });
    return {
      name,
      action,
      inputs,
      isEnd,
      nextStep,
      onFailure: "Abort",
      outputs: this._writeOutputs(),
    };
  }

  setOperationDetails = operationDetails => {
    if (!operationDetails.attachToSSMStep) {
      operationDetails = new ActionNodeOperationDetails(operationDetails);
    }
    operationDetails.attachToSSMStep(this);
    this._operationDetails = operationDetails;
    this._awsCall.setInputs(this._operationDetails.parameterInputs);
    this.outputs = this._operationDetails.outputs;
  };

  readInputSources = runbook => {
    this._awsCall.readInputs(runbook);
    console.log(`waitforresource readInputs`, { aws_call: this._awsCall });
  };
}

export class AwsCall {
  service: string;
  operation: string;
  private _inputs?: RunbookStepInput[];
  private _serializedInputs: any;

  constructor(serialized: SerializedAwsCall, private _ssmStep: Step) {
    this.service = serialized.service;
    this.operation = serialized.operation;
    this._serializedInputs = serialized.inputs;
  }

  get inputs(): RunbookStepInput[] {
    if (!this._inputs) {
      //   throw new Error(
      //     "attempting to read WaitForResourceStep inputs before loading them"
      //   );
    }
    return this._inputs || [];
  }

  //intentionally making it more awkward to set inputs
  setInputs(inputs: RunbookStepInput[]) {
    this._inputs = inputs;
  }

  readInputs(runbook: any): void {
    console.log(this._serializedInputs);
    const parsed =
      typeof this._serializedInputs === "string"
        ? (this._serializedInputs &&
            JSON.parse(convertPayloadSpecToJSON(this._serializedInputs))) ||
          {}
        : this._serializedInputs;

    console.log(parsed);
    if (!this._inputs) {
      this._inputs = [];
    }

    const inputsWithSources = readActionNodeInputSourcesFromSSM(
      parsed,
      runbook,
    );

    let inputName;
    for (inputName of Object.keys(inputsWithSources)) {
      // -isarray is coming from readActionNodeInputSourcesFromSSM
      // and it;s used to know if the value of current key name was an array
      if (inputName.includes("-isarray")) continue;
      // eslint-disable-next-line no-loop-func
      let found = this._inputs.find(input => input.name === inputName);
      if (!found) {
        const input = new RunbookStepInput(
          this._ssmStep,
          inputName,
          inputsWithSources[`${inputName}-isarray`]
            ? ParameterType.StringList
            : ParameterType.String,
          true,
          inputsWithSources[inputName],
        );
        this._inputs.push(input);
      }
    }
  }

  _serializeInputs(): string {
    const inputlist = this._inputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .join(", ");
    return `{ ${inputlist} }`;
  }

  substituteCallInputs(template: string): string {
    return template.replace(/"AWS_CALL_INPUT"/, this._serializeInputs());
  }
  serialize(): SerializedAwsCall {
    const { service, operation } = this;
    return {
      service: service,
      operation,
      inputs: "AWS_CALL_INPUT",
    };
  }
}

export class SerializedAwsCall {
  service: string;
  operation: string;
  inputs: any;
}

function readActionNodeInputSourcesFromSSM(inputs, runbook) {
  const sources = {};

  let name;
  for (name of Object.keys(inputs || [])) {
    const readLambdaParamReturnValue = readLambdaParam(runbook, inputs[name]);

    const hasInput = name in inputs;
    const source = hasInput && readLambdaParamReturnValue[0];
    if (source) {
      sources[name] = source;
      sources[`${name}-isarray`] = readLambdaParamReturnValue[1];
    }
  }
  return sources;
}
