import React from "react";
import { Consumer } from "../snippet-details-lib/snippet-details.context";
import DetailLine from "../../RunbookDetails/runbook-details-components/runbook-details-block-line";

const SnippetDetailsBlock = () => {
  return (
    <Consumer>
      {({ snippet, selectedSnippet }) => {
        return (
          <div className="details-wrap">
            <h4>Details</h4>
            <div className="details__block-wrap">
              <div className="details-block">
                <DetailLine
                  title={"Name"}
                  text={selectedSnippet?.display_name || snippet.name}
                />
                <DetailLine title={"Version"} text={snippet.version} />
                <DetailLine title={"Description"} text={snippet.description} />
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default SnippetDetailsBlock;
