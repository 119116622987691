import React from "react";
import "./Cards.scss";

function Cards({ items }) {
  return (
    <div className="dc-container">
      {" "}
      {items.map(item => (
        <div className="dc-card" key={item.label}>
          <div className="dc-card-label">{item.label}</div>
          <div className="dc-card-value">{item.value}</div>
        </div>
      ))}
    </div>
  );
}

export default Cards;
