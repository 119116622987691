import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./utils/tableColumns";
import { SettingsPanelHeader } from "../settings-panel-components";
import { getEksActions, removeEksActionRole } from "@redux/actions/eks.actions";
import { Confirm, FTNotification } from "@components/ui";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import Api from "@lib/api";
import { isFetching } from "@redux/actions/settingsPanel.action";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";
import { ConnectorType } from "../Constants";

const EKSActionRoles = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);
  const newEksActionRole = {
    name: "",
    alias: "",
    arn: "",
  };
  const [selectedRow, setSelectedRow] = useState(newEksActionRole);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const eksActionList = useSelector(state => state.eksReducer.eksActionList);

  const addEksAction = () => {
    history.push({
      state: { from: ConnectorType.resource },
      pathname: RouteConstants.setupEks.defaultUrl,
    });
  };

  const onRemoveHandler = row => {
    setSelectedRow(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const eksActionRoleCol = tableColumns(onRemoveHandler);

  useEffect(() => {
    dispatch(getEksActions());
  }, [dispatch]);

  return (
    <>
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This EKS Permission will be deleted permanently."
          confirmValue={selectedRow.name}
          confirmRequiredText={`Confirm by typing '${selectedRow.name}' below`}
          verifiedCallback={async () => {
            dispatch(toggleDeleteConfirmBox(false));
            dispatch(isFetching(true, "Deleting EKS Permission"));
            try {
              await Api.deleteEksActionRole(
                selectedRow.alias,
                selectedRow.name,
              );
              dispatch(removeEksActionRole(selectedRow));

              FTNotification.success({
                title: "EKS Permission Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete EKS Permission",
                message: error?.message || "",
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}

      <div className="d-flex flex-column">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="eks"
          heading="EKS Permissions"
          breadCrumbText="Settings/EKS Permissions"
          toggleHandler={addEksAction}
        />
        {!loadingMessage &&
          (eksActionList.length > 0 ? (
            <div className="mt-40-px ml-2 gr-list-container table-overflow-list">
              <DataTable
                columns={eksActionRoleCol}
                data={eksActionList}
                responsive={true}
                fixedHeader={true}
              />
            </div>
          ) : (
            <NoResultFound
              message="Add your EKS Permission first"
              detail="To add EKS Permission, click the 'Add New' button in the top-right of this screen."
              className="mt-100-px"
              errImgSrc="settings-es"
            />
          ))}
      </div>
    </>
  );
};

export default EKSActionRoles;
