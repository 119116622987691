import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { getResourcesList } from "@redux/actions/runbook.action";

type ParameterObj = {
  Name: string;
  Description: string;
};

type RuntimeParametersProps = {
  formik: { handleChange; touched; errors; values; setFieldValue };
  requiredParameters: Array<ParameterObj>;
};

const RuntimeParameters: React.FC<RuntimeParametersProps> = ({
  formik,
  requiredParameters,
}) => {
  const { handleChange, touched, errors } = formik;
  const dispatch = useDispatch();

  const aliasList =
    useSelector(
      (state: RootStateOrAny) => state.runbookReducer.resourcesList.targets,
    ) || [];

  useEffect(() => {
    if (!(aliasList.length > 0))
      (async () => {
        try {
          dispatch(getResourcesList());
        } catch (e) {
          console.log(e);
        }
      })();
  }, [dispatch, aliasList.length]);

  return (
    <div className="create-task">
      {requiredParameters
        .filter(
          p => !["AutomationAssumeRole", "WorkflowSession"].includes(p.Name),
        )
        .map(param => {
          return (
            <React.Fragment key={param.Name}>
              {!param.Name.startsWith("alias") ? (
                <>
                  <FormInput
                    fieldName={param.Name}
                    name={param.Name}
                    id={param.Name}
                    subText={param.Description.replace("Optional - ", "")
                      .replace("Required - ", "")
                      .replace("(Optional)", "")
                      .replace("(Required)", "")}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    autoComplete="off"
                  />
                </>
              ) : (
                <div className="mb-10-px">
                  <div className="d-flex flex-column">
                    <label className="label mb-0">{param.Name}</label>
                    <label className="label">{param.Description}</label>
                  </div>
                  <ReactSelect
                    id="runtime-param-alias"
                    name="runtime-param-alias"
                    value={{
                      value: formik.values.alias,
                      label: formik.values.alias
                        ? formik.values.alias
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if (data === null) {
                        formik.setFieldValue("alias", "");
                      } else if (!isEmpty(data) && !!data.value) {
                        formik.setFieldValue("alias", data.value);
                      }
                    }}
                    selectOptions={aliasList.map(r => {
                      return {
                        value: r.alias,
                        label: r.alias,
                      };
                    })}
                    isSearchable={false}
                    required
                  />
                  {errors[param.Name] && touched[param.Name] ? (
                    <div className="input-feedback">{errors[param.Name]}</div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default RuntimeParameters;
