import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import later from "later";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";
import { DataTable, Button, Modal, FTNotification } from "@components/ui";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import {
  appendDecimalZeroToInteger,
  replaceAllSpecialCharWithSpace,
} from "@lib/utils";
import iconHelp from "@assets/images/icons/icon-help.svg";
import Api from "@lib/api";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

later.date.localTime();

type SchedulesProps = {};

const Schedules: React.FC<SchedulesProps> = () => {
  const [schedules, setSchedules] = useState([]);
  const [isDeleteScheduleModelOpen, setIsDeleteScheduleModelOpen] = useState(
    false,
  );
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const { taskId } = useParams();
  const dispatch = useDispatch();

  const schedulesCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.schedulesCPage,
  );

  const schedulesRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.schedulesRecords,
  );

  const { sortField, sortFieldDirection } = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.sortingDetails?.schedules || {},
  );

  const changeScheduleCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "schedulesCPage",
        cPage: cPage,
      }),
    );
  };

  const changeScheduleRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "schedulesRecords",
        cPageRecords: rows,
      }),
    );
  };

  const handleScheduleSort = (column, sortDirection) => {
    dispatch(
      updateSortColumn({
        tableName: "schedules",
        sortField: column?.selector,
        sortFieldDirection: sortDirection,
      }),
    );
  };

  const columns = [
    {
      name: "Workflow Name & Version",
      selector: "workflow",
      sortable: true,
      width: "25%",
      cell: row => {
        let workflow = parseWorkflowName(row.workflow);
        return (
          <Link
            to={`${RouteConstants.runbooks.url}/${workflow.name}?v=${workflow.version}`}
          >
            {replaceAllSpecialCharWithSpace(workflow.name)} : v
            {appendDecimalZeroToInteger(workflow.version)}
          </Link>
        );
      },
    },
    {
      name: "Created",
      selector: "created_at",
      sortable: true,
      format: row =>
        moment(parseInt(row.created_at)).format("ddd DD MMM YYYY hh:mm:ss.SSS"),
      width: "25%",
    },
    {
      name: "Rate/Expression",
      selector: "schedule_expression",
      width: "15%",
      format: row => {
        let workflow = parseWorkflowName(row.workflow);
        if (row.schedule_expression.indexOf("rate(") > -1) {
          const match = row.schedule_expression
            ?.match(/rate\((.*)\)/i)[1]
            ?.split(" ");
          return `Every ${match[0]} ${match[1]}`;
        } else {
          const match = row.schedule_expression?.match(/cron\((.*)\)/i)[1];
          return (
            <span>
              {match}{" "}
              <img
                alt="view"
                src={iconHelp}
                data-for={`tooltip-${replaceAllSpecialCharWithSpace(
                  workflow.name,
                )}-${workflow.version}`}
                data-tip={getSchedules(match)}
                data-html
              />
              <ReactTooltip
                id={`tooltip-${replaceAllSpecialCharWithSpace(workflow.name)}-${
                  workflow.version
                }`}
                place="bottom"
                effect="solid"
                type="light"
              />
            </span>
          );
        }
      },
    },
    {
      name: "Status",
      selector: "enabled",
      sortable: true,
      width: "15%",
      format: row =>
        row.enabled ? (
          <span className="Success-block block">Enabled</span>
        ) : (
          <span className="Failed-block block">Disabled</span>
        ),
    },
    {
      name: "Actions",
      selector: "",
      cell: row => {
        return (
          <div className="actions-wrapper">
            <Button
              className="act-btn"
              text={row.enabled ? `Disable` : `Enable`}
              buttonStyle={row.enabled ? "warning" : "primary"}
              size="medium"
              onClick={() => enableDisableSchedule(row)}
              icon={row.enabled ? `icon-close.svg` : `icon-play.svg`}
            />

            <Button
              className="act-icon"
              buttonStyle="secondary"
              icon={`icon-trash-v2.svg`}
              onClick={() => {
                setSelectedSchedule(row);
                setIsDeleteScheduleModelOpen(!isDeleteScheduleModelOpen);
              }}
            />
          </div>
        );
      },
    },
  ];

  const fetchSchedules = useCallback(async () => {
    try {
      const response = await Api.fetchTaskSchedules(taskId);
      let schedules =
        typeof response === "string" ? JSON.parse(response) : response;
      setSchedules(schedules);
    } catch (e) {
      console.log(e);
    }
  }, [taskId]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  const getSchedules = cronExpression => {
    try {
      const cron = later.parse.cron(cronExpression);
      const occurrences = later.schedule(cron).next(10);
      let text = `<div class="next-schedule-list"><div class="title">Next ${occurrences.length} executions at:<br/></div>`;
      for (var i = 0; i < occurrences.length; i++) {
        text += `<div>${occurrences[i]}<br/></div>`;
      }
      text += `</div>`;
      return text;
    } catch (e) {
      return "";
    }
  };

  const getFooter = () => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          onClick={() =>
            setIsDeleteScheduleModelOpen(!isDeleteScheduleModelOpen)
          }
          buttonStyle="secondary"
          style={{ width: "50%" }}
        />
        <Button
          text="Delete"
          onClick={() => deleteSchedule(selectedSchedule)}
          buttonStyle="warning"
          style={{ width: "50%" }}
        />
      </div>
    );
  };

  const parseWorkflowName = workflow => {
    workflow = workflow.split("/");
    return {
      name: workflow[0],
      version: workflow[1],
    };
  };

  const enableDisableSchedule = async schedule => {
    let payload = {
      rule: schedule.rule,
      state: !schedule.enabled,
    };
    let workflow = parseWorkflowName(schedule.workflow);
    try {
      await Api.enableDisableSchedule(workflow.name, workflow.version, payload);
      fetchSchedules();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSchedule = async schedule => {
    setIsDeleteScheduleModelOpen(false);
    let workflow = parseWorkflowName(schedule.workflow);
    try {
      await Api.deleteSchedule(workflow.name, workflow.version, schedule.rule);
      fetchSchedules();
    } catch (e) {
      console.log(e);
      FTNotification.success({ message: `Deleted successfully` });
    }
  };

  return (
    <div className="mt-20-px">
      {isDeleteScheduleModelOpen && (
        <Modal
          title={`Confirm Deleting Schedule`}
          showClose={true}
          footer={getFooter()}
          onClose={() =>
            setIsDeleteScheduleModelOpen(!isDeleteScheduleModelOpen)
          }
        >
          <div>
            <p>
              Are you sure you want to delete&nbsp;
              <span className="failed-stats">
                {parseWorkflowName(selectedSchedule.workflow).name}(v:
                {parseWorkflowName(selectedSchedule.workflow).version})
              </span>{" "}
              schedule?
            </p>
          </div>
        </Modal>
      )}
      {Array.isArray(schedules) && schedules.length > 0 ? (
        <DataTable
          columns={columns}
          data={schedules}
          className="w-100"
          fixedHeader={true}
          fixedScrollHeight={"60px"}
          pagination={true}
          currentPage={schedulesCPage}
          recordsPerPage={schedulesRecords}
          onChangeCurrentPage={changeScheduleCurrentPage}
          onChangeRowsPerPage={changeScheduleRowsPerPage}
          defaultSortField={sortField}
          defaultSortAsc={sortFieldDirection === "asc" ? true : false}
          onSort={handleScheduleSort}
        />
      ) : (
        <NoResultFound
          message="There are no Schedules yet"
          detail="You can schedule a workflow inside the workflow editor or from the workflow detail page."
          className="mt-100-px"
          errImgSrc="schedules-es"
        />
      )}
    </div>
  );
};

export default Schedules;
