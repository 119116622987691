import React from "react";
import PropTypes from "prop-types";
import { Provider } from "./radio.context";
import "./radio.scss";

export default class RadioSet extends React.PureComponent {
  state = {
    selected: this.props.defaultSelected,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.defaultSelected !== this.props.defaultSelected) {
      this.setState({ selected: this.props.defaultSelected });
    }
  }

  onLoad = selectedValue => {
    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  };

  onChange = selected => {
    if (this.props.onChange) {
      this.props.onChange(selected);
      this.setState({
        selected,
      });
    }
  };

  render() {
    const contextValue = {
      onChange: this.onChange,
      name: this.props.name,
      radioClassName: this.props.className,
      onLoad: this.onLoad,
      defaultSelected: this.state.selected,
    };
    return (
      <Provider
        value={contextValue}
        checked={this.state.selected}
        onSelect={this.onSelect}
      >
        <div className="radio-list" style={this.props.style}>
          {this.props.children}
        </div>
      </Provider>
    );
  }
}

RadioSet.propTypes = {
  name: PropTypes.string.isRequired,
  radioClassName: PropTypes.string,
  selected: PropTypes.string,
};
