import React from "react";
import Header from "./components/Header";
import StarterCheckList from "./components/StarterCheckList";
import BeginWithBasics from "./components/BeginWithBasics";

import "./index.scss";

const UserOnboarding = () => {
  return (
    <>
      <Header />
      <StarterCheckList />
      <BeginWithBasics />
    </>
  );
};

export default UserOnboarding;
