import React from 'react';
import "./SmallScreenError.scss";
const SmallScreenError = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://www.fylamynt.com';
    }
    return (
        <div className="error-container">
            <div className="header">
                <h1>&#9432;</h1>
                <h1 className="up-header-label">Device Not Supported</h1>
            </div>
            <p className="message-container">
                Sorry, Fylamynt is not available on Mobile Devices.<br />
                Please give it a try on your desktop computer instead.
            </p>
            <button onClick={handleButtonClick}>Return To Fylamynt.com</button>
        </div>
    );
}

export default SmallScreenError;
