import produce from "immer";
import { FETCH_QUOTAS_INFO_SUCCESS } from "@redux/types";

const INITIAL_STATE = {
  quotas: null,
};

const accountPlanReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_QUOTAS_INFO_SUCCESS:
        draft.quotas = action.payload;
        break;

      default:
        break;
    }
  });
export default accountPlanReducer;
