import React from "react";
import { DataTable } from "@components/ui";

import "./Description.scss";

type Actions = {
  name: string;
  display_name: string;
  description: string;
};

type DescriptionProps = {
  overview: string;
  actions: [Actions?];
};

const Description: React.FC<DescriptionProps> = ({ overview, actions }) => {
  const columns = [
    {
      name: "Action",
      selector: row => row.display_name,
      width: "30%",
    },
    {
      name: "Description",
      selector: row => row.description,
      width: "70%",
    },
  ];

  return (
    <div className="description-container">
      <div className="description-overview">
        <div className="description-header">Overview</div>
        <div className="description-summary">{overview}</div>
      </div>
      <div className="description-table">
        <div className="description-header">Supported Actions</div>
        <DataTable data={actions} columns={columns} />
      </div>
    </div>
  );
};

export default Description;
