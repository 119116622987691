export class SchemaError extends Error {
  constructor(expectedType, val) {
    super(
      `Schema Error: The value '${JSON.stringify(
        val,
      )}' is not of expected type '${expectedType}'`,
    );
    this.expectedType = expectedType;
    this.val = val;
  }
}

export class NotImplementedError extends Error {
  constructor(method, message) {
    super(
      `Method '${method} not implemented${message ? ":" + message : "."} }`,
    );
  }
}
