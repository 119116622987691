import React from "react";
import PropTypes from "prop-types";
import "./text-input.scss";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { LabelLeft, LabelRight } from "../";
import Header from "../InputTypes/Layouts/Header/Header";
export default class TextInput extends React.Component {
  state = {
    value: this.props.value || "",
    dirty: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.onLoad && typeof this.props.onLoad === "function") {
      this.props.onLoad();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value, dirty: false });
    }
  }

  setInputValue = value => {
    this.setState({
      value,
      dirty: true,
    });
  };

  renderTextInput() {
    const disabledStyle = this.props.disabled ? { opacity: "50%" } : {};
    const style = { ...this.props.style, ...disabledStyle };

    return (
      <div className={`d-flex mt-2-px`}>
        {this.props.options?.length > 0 ? (
          <div className="react-select-container">
            <ReactSelect
              id={this.props.name}
              name={this.props.name}
              value={{
                value: this.state.value || "",
                label: this.state.value
                  ? this.state.value
                  : "Select from below",
              }}
              required
              selectOptions={this.props.options.map(option => {
                return { value: option.alias, label: option.alias };
              })}
              handleChange={data => {
                if (data === null) {
                  this.setInputValue("");
                  this.props.onChange("");
                }
                if (!isEmpty(data) && !!data.value && !!data.label) {
                  this.setInputValue(data.value);
                  this.props.onChange(data.value);
                }
              }}
              customMenuClass="default-select-options-container"
              customMenuListClass="default-select-options-list"
              customValueContainerClass="default-select-value-container"
              customControlClass="default-select-control"
              customOptionClass="default-select-list-item"
            />
          </div>
        ) : (
          <input
            type={this.props.type || `text`}
            autoComplete="off"
            autoFocus={this.props.autoFocus || false}
            className={this.props.className}
            maxLength={this.props.maxLength}
            name={this.props.name}
            data-name={this.props.name}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            id={this.props.id}
            onChange={event => {
              event.cancelBubble = true;
              this.setInputValue(event.target.value);
              if (this.props.onChange) {
                this.props.onChange(event.target.value);
              }
            }}
            value={this.state.value ? this.state.value : ""}
            style={style}
            ref={this.ref}
            onKeyUp={event => {
              event.stopPropagation();
              if (event.keyCode === 8) {
                event.cancelBubble = true;
              }
              this.setInputValue(event.target.value);
              this.props?.onKeyUp && this.props.onKeyUp(event.target.value);
            }}
            onKeyPress={e => {
              e.cancelBubble = true;
              if (this.props.onKeyPress) {
                this.props.onKeyPress(e);
              }
            }}
            minLength={this.props.minLength}
            required={this.props.minLength > 0 || this.props.required}
            onClick={this.props?.onClick}
            onBlur={this.props?.onBlur}
            size={this.props?.size}
            max={this.props?.max}
            readOnly={this.props.readOnly}
          ></input>
        )}

        {this.props.onReset ? (
          <input
            type="reset"
            onClick={() => {
              this.props.onReset();
              this.setInputValue("");
              this.ref.current.focus();
            }}
            className="reset-button__darker"
            value="✕"
          ></input>
        ) : null}
        {this.props.onSave && this.state.dirty ? (
          <input
            type="submit"
            onClick={() => {
              this.props.onSave(this.state.value);
              this.setState({ dirty: false });
              this.ref.current.blur();
            }}
            value="✓ change name"
          ></input>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div
        className={
          !this.props.options?.length > 0 &&
          `text-input__field-wrapper ${this.props.containerClass}`
        }
      >
        {this.props.label ? (
          <React.Fragment>
            {this.props.labelPosition === "left" && (
              <LabelLeft
                labelClassName={this.props.labelClassName}
                label={this.props.label}
              >
                {this.renderTextInput()}
              </LabelLeft>
            )}
            {this.props.labelPosition === "right" && (
              <LabelRight
                labelClassName={this.props.labelClassName}
                label={this.props.label}
              >
                {this.renderTextInput()}
              </LabelRight>
            )}
            {this.props.labelPosition === "top" && (
              <div className="d-flex flex-column">
                <Header
                  title={this.props.label}
                  description={this.props.description}
                  helpText={this.props.helpText}
                />
                {this.renderTextInput()}
                {this.props.showError && (
                  <div className="input-feedback">
                    {this.props.label} is Required
                  </div>
                )}
              </div>
              // </LabelTop>
            )}
          </React.Fragment>
        ) : (
          this.renderTextInput()
        )}
      </div>
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["left", "top", "right"]),
  labelClassName: PropTypes.string,
  containerClass: PropTypes.string,
  onChange: PropTypes.func,
  minLength: PropTypes.number,
  value: PropTypes.string,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  description: PropTypes.string,
};

TextInput.defaultProps = {
  className: "text-input",
  containerClass: "",
  labelPosition: "top",
  labelClassName: "",
  maxLength: 256,
  value: "",
  containerStyle: {},
  disabled: false,
  readOnly: false,
};
