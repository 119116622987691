import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import { DataTable } from "@components/ui";
import { StatusBlock } from "@components/modules";
import { RouteConstants } from "../../../routes/Constants";
import {
  appendDecimalZeroToInteger,
  replaceAllSpecialCharWithSpace,
} from "@lib/utils";
import Api from "@lib/api";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

type ExecutionsProps = {};

const columns = [
  {
    name: "Workflow Name & Version",
    selector: "DocumentName",
    sortable: true,
    width: "25%",
    cell: row => {
      const runbookName = replaceAllSpecialCharWithSpace(row.DocumentName);
      const id = row.AutomationExecutionId;
      return (
        <Link to={`${RouteConstants.executionDetails.url}/${id}/steps`}>
          {runbookName} : v{appendDecimalZeroToInteger(row.DocumentVersion)}
        </Link>
      );
    },
  },
  {
    name: "Overall Status",
    selector: "AutomationExecutionStatus",
    sortable: true,
    width: "12%",
    cell: row => {
      const status = row.AutomationExecutionStatus;
      return <StatusBlock status={status} />;
    },
  },
  {
    name: "Start Time",
    selector: "ExecutionStartTime",
    sortable: true,
    width: "21%",
    cell: row => {
      const startTime = row.ExecutionStartTime;
      return (
        <div className="table">
          {moment(startTime).format("ddd DD MMM YYYY hh:mm:ss.SSS")}
        </div>
      );
    },
  },
  {
    name: "End Time",
    selector: "ExecutionEndTime",
    sortable: true,
    width: "21%",
    cell: row => {
      const endTime = row.ExecutionEndTime;
      return (
        <div className="table">
          {moment(endTime).format("ddd DD MMM YYYY hh:mm:ss.SSS")}
        </div>
      );
    },
  },
  {
    name: "Duration",
    selector: "duration",
    sortable: true,
    width: "21%",
    cell: row => {
      return (
        <div className="table">
          {!["InProgress", "Waiting", "Pending"].includes(
            row.AutomationExecutionStatus,
          )
            ? row.duration
            : ""}
        </div>
      );
    },
  },
];

const Executions: React.FC<ExecutionsProps> = () => {
  const dispatch = useDispatch();
  const executionsCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.executionsCPage,
  );

  const executionsRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.executionsRecords,
  );

  const { sortField, sortFieldDirection } = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.sortingDetails?.executions || {},
  );

  const changeExecutionCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "executionsCPage",
        cPage: cPage,
      }),
    );
  };

  const changeExecutionRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "executionsRecords",
        cPageRecords: rows,
      }),
    );
  };

  const handleExecutionSort = (column, sortDirection) => {
    dispatch(
      updateSortColumn({
        tableName: "executions",
        sortField: column?.selector,
        sortFieldDirection: sortDirection,
      }),
    );
  };

  const { taskId } = useParams();
  const [executions, setExecutions] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await Api.fetchTaskExecutions(taskId);
        let executions =
          typeof response === "string" ? JSON.parse(response) : response;
        setExecutions(executions.executions);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [taskId]);

  return (
    <div className="executions">
      <DataTable
        columns={columns}
        data={executions}
        className="w-100"
        fixedHeader={true}
        fixedScrollHeight={"60px"}
        pagination={true}
        currentPage={executionsCPage}
        recordsPerPage={executionsRecords}
        onChangeCurrentPage={changeExecutionCurrentPage}
        onChangeRowsPerPage={changeExecutionRowsPerPage}
        defaultSortField={sortField}
        defaultSortAsc={sortFieldDirection === "asc" ? true : false}
        onSort={handleExecutionSort}
      />
    </div>
  );
};

export default Executions;
