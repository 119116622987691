import React from "react";
import "./ModalWithSidebar.scss";

type ModalWithSidebarProps = {
  title?: string;
  formTitle?: string;
  showClose?: boolean;
  sidebarComponent: React.ReactNode;
  footer: React.ReactNode;
  closeClass?: string;
  titleClass?: string;
  containerClass?: string;
  contentClass?: string;
  footerClass?: string;
  contentContainerClass?: string;
  children: React.ReactNode;
  onClose: () => void;
  formDescription?: string;
};

const ModalWithSidebar: React.FC<ModalWithSidebarProps> = props => {
  const {
    title,
    formTitle,
    showClose,
    footer,
    closeClass,
    titleClass,
    containerClass,
    contentClass,
    contentContainerClass,
    sidebarComponent,
    onClose,
    children,
    footerClass,
    formDescription,
  } = props;

  const closeModal = event => {
    if (
      event.target.id === "modal-cover" ||
      event.target.id === "modal-close"
    ) {
      document.querySelector("#portal").classList.remove("background-content");
      onClose();
    }
  };

  return (
    <div className="modal-cover" id="modal-cover">
      <div
        className={`modal-container-sidebar modal-slide-in ${
          containerClass ? containerClass : ""
        }`}
      >
        <div className="sidebar">
          {title && (
            <div
              className={`modal-header-sidebar ${titleClass ? titleClass : ""}`}
            >
              {title}
            </div>
          )}
          {sidebarComponent ? sidebarComponent : null}
        </div>

        <div
          className={`modal-content-container-sidebar ${
            contentContainerClass ? contentContainerClass : ""
          }`}
        >
          {showClose && (
            <div
              className={`modal-close ${closeClass ? closeClass : ""}`}
              id="modal-close"
              onClick={closeModal}
            ></div>
          )}
          <div className="form-header">
            <span className="title">{formTitle}</span>
            <span className="description">
              {formDescription ? formDescription : ""}
            </span>
          </div>

          <div
            className={`modal-content-sidebar ${
              contentClass ? contentClass : ""
            }`}
          >
            {children}
          </div>
          <div
            className={`modal-footer modal-footer__wizard ${
              footerClass ? footerClass : ""
            }`}
          >
            {footer ? footer : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWithSidebar;
