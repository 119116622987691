import React from "react";
import PropTypes from "prop-types";

const getClassName = (status, showBg, executionStatus) => {
  switch (status) {
    case "InProgress":
      return {
        bg: `data-cell`,
        block: `Loading-block`,
      };

    default:
      const dataCell = showBg ? `data-cell-${status}` : "";
      const pendingFlag =
        executionStatus &&
        (executionStatus === "Failed" || executionStatus === "Success") &&
        status === "Pending";
      return {
        bg: `data-cell ${dataCell}`,
        block: pendingFlag ? "Cancelled-block" : `${status}-block`,
      };
  }
};

const getStatus = (stepStatus, executionStatus) => {
  let statusContent = <div>{stepStatus}</div>;
  if (
    executionStatus &&
    (executionStatus === "Failed" || executionStatus === "Success") &&
    stepStatus === "Pending"
  ) {
    statusContent = <div>Cancelled</div>;
  }
  return statusContent;
};

const StatusBlock = props => {
  const css = getClassName(props.status, props.showBg, props.executionStatus);
  return (
    <div className={props.className} style={props.style}>
      <div className={css.bg}>
        <div className={css.block}></div>
        <div>{getStatus(props.status, props.executionStatus)}</div>
      </div>
    </div>
  );
};

StatusBlock.propTypes = {
  status: PropTypes.string,
  showBg: PropTypes.bool,
  style: PropTypes.object,
};

StatusBlock.defaultProps = {
  showBg: true,
  style: {},
};

export default StatusBlock;
