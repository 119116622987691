import React, { useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import { RouteConstants } from "../../../../routes/Constants";
import { formatDateToLocal } from "@lib/utils";
import Api from "@lib/api";

type AccountPlanProps = {};

const AccountPlan: React.FC<AccountPlanProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const quotas = useSelector(
    (state: RootStateOrAny) => state.accountPlanReducer.quotas,
  );

  // Quota plans used for internal users only
  const INTERNAL_PLANS = ["free", "devtest", "internal"];

  useEffect(() => {
    if (!quotas) dispatch(fetchAccountPlanInfo());
  }, [dispatch, quotas]);

  const capitalize = s => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const contactSales = () => {
    window.open("https://www.fylamynt.com/contact", "_blank");
  };

  const managePlan = async () => {
    const currentPlan = quotas?.current_tier;
    if (!currentPlan) {
      return;
    }
    if (INTERNAL_PLANS.includes(currentPlan)) {
      history.push(`${RouteConstants.upgradePlan.url}`);
    } else {
      let { portal_url } = await Api.fetchStripePortalURL();
      window.open(portal_url, "_self");
    }
  };

  const workflowsWidth =
    (quotas?.workflows?.current_usage / quotas?.workflows?.limit) * 100;
  const executionsWidth =
    (quotas?.executions?.current_usage / quotas?.executions?.limit) * 100;

  return (
    <div className="d-flex">
      <div className="account-details">
        <div className="account-details-plan-header">
          <h2>Plan Details</h2>
        </div>
        <div className="account-details-usage">
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Plan</div>
            <h4>
              {quotas?.current_tier ? capitalize(quotas?.current_tier) : "NA"}
            </h4>
          </div>
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Workflows</div>
            <h4>{quotas?.workflows?.limit || "NA"}</h4>
          </div>
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Runs/mo</div>
            <h4>{quotas?.executions?.limit || "NA"}</h4>
          </div>
          <div className="account-details-usage-plan-meta">
            <div className="meta-title">Plan renews</div>
            <h4>
              {quotas?.plan_renew ? formatDateToLocal(quotas.plan_renew) : "-"}
            </h4>
          </div>
        </div>
        <div className="account-details-plan-usage">
          <div className="meta-title__bold">Plan Usage</div>
          <div className="meta-title__fw">{`Usage for Runs will reset on the plan renewal date.`}</div>
          <div className="account-details-plan-usage__usage-wrap">
            <div className="account-details-plan-usage__usage-wrap__top">
              <h4>Workflows</h4>
              <h4>
                {quotas?.workflows?.current_usage} / {quotas?.workflows?.limit}
              </h4>
            </div>
            <div className="account-details-plan-usage__bar-wrap">
              <div
                className={`account-details-plan-usage__bar-wrap__bar-use ${
                  workflowsWidth === 100 && "red"
                }`}
                style={{
                  width: `${workflowsWidth}%`,
                }}
              ></div>
            </div>
          </div>
          <div className="account-details-plan-usage__usage-wrap">
            <div className="account-details-plan-usage__usage-wrap__top">
              <h4>Runs</h4>
              <h4>
                {quotas?.executions?.current_usage} /{" "}
                {quotas?.executions?.limit}
              </h4>
            </div>
            <div className="account-details-plan-usage__bar-wrap">
              <div
                className={`account-details-plan-usage__bar-wrap__bar-use ${
                  executionsWidth === 100 && "red"
                }`}
                style={{
                  width: `${executionsWidth}%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-actions">
        <div className="account-details-plan-header">
          <h2 className="pb-1">Manage Plan</h2>
          <div className="meta-title__fw">
            To upgrade or downgrade your current plan:
          </div>
        </div>
        <button
          type="button"
          className="account-actions-btn account-actions-btn__primary"
          onClick={managePlan}
        >
          {INTERNAL_PLANS.includes(quotas?.current_tier)
            ? "Upgrade Plan"
            : "Manage Plan"}
        </button>
        <button
          className="account-actions-btn"
          onClick={contactSales}
          type="button"
        >
          Contact Sales
        </button>
      </div>
    </div>
  );
};

export default AccountPlan;
