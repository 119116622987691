import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as Yup from "yup";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { Formik } from "formik";
import { Modal } from "@components/ui";
import { MultiSelect } from "carbon-components-react";
import Header from "@components/ui/InputTypes/Layouts/Header/Header";
import { isEmpty } from "@lib/utils";
import {
  getWorkspaceUsersList,
  createJiraIssue,
  createSlackChannel,
  createZoomLink,
} from "@redux/actions/collaboration.actions";
import {
  fetchJiraDetails,
  getJiraProjectsIssueFields,
} from "@redux/actions/snippets.actions";
import { fetchSettingsConnectors } from "@redux/actions/settingsPanel.action";

const slackInfoValidationSchema = () => {
  return Yup.object({
    users: Yup.array().required("Please select workspace users"),
  });
};

const jiraInfoValidationSchema = () => {
  return Yup.object({
    project: Yup.string().required("Please select a project"),
    issueType: Yup.string().required("Please select an issue type"),
  });
};

type CollaborationDetailsProps = {
  name: string;
  incidentId: string;
  description: string;
  incidentResponse: object;
};

const CollaborationDetails: React.FC<CollaborationDetailsProps> = ({
  name,
  incidentId,
  description,
  incidentResponse = {},
}) => {
  const dispatch = useDispatch();
  const [isSlackInfoModalOpen, setIsSlackInfoModalOpen] = useState(false);
  const [isJiraInfoModalOpen, setIsJiraInfoModalOpen] = useState(false);

  const workspaceUsersList = useSelector(
    (state: RootStateOrAny) => state.collaborationReducer.workspaceUsersList,
  );

  const jiraProjectsList = useSelector(
    (state: RootStateOrAny) => state.snippetsReducer.jiraDetails.projects,
  );

  const jiraIssueTypesList = useSelector(
    (state: RootStateOrAny) => state.snippetsReducer.jiraDetails.issueTypes,
  );

  const connectors = useSelector(
    (state: RootStateOrAny) => state.settingsPanelReducer.connectors,
  );

  const { slack, zoom, jira } = connectors;

  const handleCreateSlackChannel = async (formik, actions) => {
    actions.setSubmitting(true);
    const { users } = formik;
    const payload = {
      IncidentId: incidentId.toString(),
      SlackAddUsers: users.map(option => option.value),
    };
    const res = await dispatch(createSlackChannel(payload));
    if (res["type"] === "CREATE_SLACK_CHANNEL_SUCCESS") {
      setIsSlackInfoModalOpen(false);
      actions.resetForm({ formik });
      actions.setSubmitting(false);
    }
  };

  const handleCreateZoomLink = async () => {
    const payload = {
      IncidentId: incidentId.toString(),
      IncidentName: name,
    };
    await dispatch(createZoomLink(payload));
  };

  const handleCreateJiraTicket = async (formik, actions) => {
    actions.setSubmitting(true);
    const { issueType, project } = formik;
    const payload = {
      JiraProject: project,
      JiraIssueType: issueType,
      IncidentId: incidentId.toString(),
      IncidentName: name,
      IncidentDescription: description,
    };
    const res = await dispatch(createJiraIssue(payload));
    if (res["type"] === "CREATE_JIRA_ISSUE_SUCCESS") {
      setIsJiraInfoModalOpen(false);
      actions.resetForm({ formik });
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (slack?.is_configured) {
      dispatch(getWorkspaceUsersList());
    }
  }, [dispatch, slack]);

  useEffect(() => {
    if (jira?.is_configured) {
      dispatch(fetchJiraDetails());
    }
  }, [dispatch, jira]);

  useEffect(() => {
    if (isEmpty(connectors)) {
      dispatch(fetchSettingsConnectors());
    }
  }, [dispatch, connectors]);

  return (
    <div className="collaboration">
      {isSlackInfoModalOpen && (
        <Formik
          initialValues={{
            users: [],
          }}
          onSubmit={handleCreateSlackChannel}
          validationSchema={slackInfoValidationSchema}
        >
          {formik => {
            const { errors, touched, handleSubmit } = formik;
            return (
              <Modal
                onClose={() => setIsSlackInfoModalOpen(false)}
                title="Collaboration: Create New Slack Channel"
                showClose={true}
                onSubmit={handleSubmit}
                onCancel={() => setIsSlackInfoModalOpen(false)}
                submitButtonText={`Create Channel`}
                contentClass="add-wf-content"
                contentContainerClass="add-wf-content-container"
              >
                <label className="label">Select Workspace users</label>
                <MultiSelect.Filterable
                  ariaLabel="MultiSelect"
                  placeholder="Select workspace users"
                  default
                  id="multiselect-filterable"
                  onChange={({ selectedItems }) => {
                    formik.setFieldValue("users", selectedItems);
                  }}
                  className={`multiselect-filterable ${
                    !(formik.values.users.length > 0) ? "error" : ""
                  }`}
                  initialSelectedItems={formik.values.users}
                  items={workspaceUsersList}
                  label="Select workspace users"
                  itemToString={item => (item ? item.label : "")}
                />
                {errors["users"] && touched["users"] && (
                  <div className="input-feedback">{errors["users"]}</div>
                )}
              </Modal>
            );
          }}
        </Formik>
      )}
      {isJiraInfoModalOpen && (
        <Formik
          initialValues={{
            issueType: "",
            project: "",
          }}
          onSubmit={handleCreateJiraTicket}
          validationSchema={jiraInfoValidationSchema}
        >
          {formik => {
            const { errors, touched, handleSubmit } = formik;
            return (
              <Modal
                onClose={() => setIsJiraInfoModalOpen(false)}
                title="Collaboration: Create New Jira Issue"
                showClose={true}
                onSubmit={handleSubmit}
                onCancel={() => setIsJiraInfoModalOpen(false)}
                submitButtonText={`Create Issue`}
                contentClass="add-wf-content"
                contentContainerClass="add-wf-content-container"
              >
                <div className="wrapper" style={{ width: "100%" }}>
                  <div className="mb-4">
                    <label className="label mb-10-px">
                      Select Jira Project to create new tickets
                    </label>
                    <ReactSelect
                      id="projects"
                      name="projects"
                      value={{
                        value: formik.values.project,
                        label: formik.values.project
                          ? formik.values.project
                          : "Select from below",
                      }}
                      handleChange={data => {
                        if (data === null) {
                          formik.setFieldValue("project", "");
                          formik.setFieldValue("issueType", "");
                        }
                        if (
                          !isEmpty(data) &&
                          !!data.value &&
                          !!data.label &&
                          formik.values.project !== data.value
                        ) {
                          formik.setFieldValue("project", data.value);
                          dispatch(getJiraProjectsIssueFields(data.value));
                        }
                      }}
                      selectOptions={jiraProjectsList?.map(option => {
                        return {
                          value: option,
                          label: option || "",
                        };
                      })}
                      customMenuClass="default-select-options-container"
                      customMenuListClass="default-select-options-list"
                      customValueContainerClass="default-select-value-container"
                      customControlClass="default-select-control"
                      customOptionClass="default-select-list-item"
                    />
                    {errors["project"] && touched["project"] ? (
                      <div className="input-feedback">{errors["project"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="label mb-10-px">
                      Select Issue Type for new tickets
                    </label>
                    <ReactSelect
                      id="issueType"
                      name="issueType"
                      value={{
                        value: formik.values.issueType,
                        label: formik.values.issueType
                          ? formik.values.issueType
                          : "Select from below",
                      }}
                      handleChange={data => {
                        if (data === null) {
                          formik.setFieldValue("issueType", "");
                        }
                        if (!isEmpty(data) && !!data.value && !!data.label) {
                          formik.setFieldValue("issueType", data.value);
                        }
                      }}
                      selectOptions={
                        formik.values.project
                          ? jiraIssueTypesList?.map(option => {
                              return {
                                value: option,
                                label: option || "",
                              };
                            })
                          : []
                      }
                      customMenuClass="default-select-options-container"
                      customMenuListClass="default-select-options-list"
                      customValueContainerClass="default-select-value-container"
                      customControlClass="default-select-control"
                      customOptionClass="default-select-list-item"
                    />
                    {errors["issueType"] && touched["issueType"] ? (
                      <div className="input-feedback">
                        {errors["issueType"]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Modal>
            );
          }}
        </Formik>
      )}
      <div className="heading">
        <span className="title">Collaboration</span>
        <Header
          title=""
          helpText=""
          description="Centralize your team's communication around incidents. Go to Settings to configure Full Collaboration Mode."
        />
      </div>
      <div className="wrapper">
        {incidentResponse["slack"] ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={incidentResponse["slack"].channel_url}
          >
            <div className="box hov">
              <div className="image slack"></div>
              <div>{incidentResponse["slack"].channel_name}</div>
            </div>
          </a>
        ) : (
          <div
            className={`box ${!slack?.is_configured ? "disable" : ""}`}
            onClick={
              slack?.is_configured ? () => setIsSlackInfoModalOpen(true) : null
            }
          >
            <div className="image slack"></div>
            <div>New Slack Channel</div>
          </div>
        )}
        {incidentResponse["zoom"] ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={incidentResponse["zoom"].meeting_url}
          >
            <div className="box hov">
              <div className="image zoom"></div>
              <div>{`ID: ${incidentResponse["zoom"].meeting_id}`}</div>
            </div>
          </a>
        ) : (
          <div
            className={`box ${!zoom?.is_configured ? "disable" : ""}`}
            onClick={zoom?.is_configured ? handleCreateZoomLink : null}
          >
            <div className="image zoom"></div>
            <div>Launch Zoom Room</div>
          </div>
        )}
        {incidentResponse["jira"] ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={incidentResponse["jira"].issue_url}
          >
            <div className="box hov">
              <div className="image jira"></div>
              <div>{incidentResponse["jira"].issue_key}</div>
            </div>
          </a>
        ) : (
          <div
            className={`box ${!jira?.is_configured ? "disable" : ""}`}
            onClick={
              jira?.is_configured ? () => setIsJiraInfoModalOpen(true) : null
            }
          >
            <div className="image jira"></div>
            <div>Create Jira Ticket</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollaborationDetails;
