import produce from "immer";
import {
  DATADOG_MONITOR_LIST_SET,
  DATADOG_MONITOR_LIST_FETCHING,
  DATADOG_MONITOR_SET,
  DATADOG_MONITOR_FETCHING,
  DATADOG_MONITOR_ERROR,
} from "../types";

const INITIAL_STATE = {
  datadogMonitorList: [],
  currentDatadogMonitor: null,
};

const connectorsReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DATADOG_MONITOR_LIST_SET:
        draft.datadogMonitorList = action.payload;
        break;

      case DATADOG_MONITOR_SET:
        draft.currentDatadogMonitor = action.payload;
        break;
      case DATADOG_MONITOR_LIST_FETCHING:
        draft.isFetchingDatadogMonitorList = action.payload;
        break;
      case DATADOG_MONITOR_FETCHING:
        draft.isFetchingDatadogMonitor = action.payload;
        break;

      case DATADOG_MONITOR_ERROR:
        draft.datadogError = action.payload;
        break;

      default:
        break;
    }
  });
export default connectorsReducer;
