import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { HeaderMain } from "@components/modules";
import DatePicker from "../../../components/shared/DatePicker";
import Button from "@components/ui/button/button";

import { updateDateRangeSelection } from "@redux/actions/dashboard.actions";

function DashboardHeader() {
  const ref = useRef();
  const dispatch = useDispatch();
  const { subTab } = useParams();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dateRange = useSelector(state => state.dashboardReducer.dateRange);
  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateSelection = range => {
    // Close Datepicker after selection
    if (
      moment(range.startDate).format("MM-DD-YYYY, hh:mm:ss") !==
      moment(range.endDate).format("MM-DD-YYYY, hh:mm:ss")
    ) {
      setIsDatePickerOpen(false);
    } else if (range.startDate === "" && range.endDate === "") {
      setIsDatePickerOpen(false);
    }
    dispatch(updateDateRangeSelection(range));
  };

  const formatDateRange = range => {
    let start = `${
      range.startDate.getMonth() + 1
    }/${range.startDate.getDate()}/${range.startDate.getFullYear()}`;
    let end = `${
      range.endDate.getMonth() + 1
    }/${range.endDate.getDate()}/${range.endDate.getFullYear()}`;
    return `${start} - ${end}`;
  };

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isDatePickerOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDatePickerOpen]);

  return (
    <>
      <HeaderMain title="Dashboard" showBreadcrumbs={false}>
        {subTab === "incidents" && (
          <div ref={ref} className="rangeBtnContainer">
            <Button
              text={formatDateRange(dateRange) || "Select Date Range"}
              buttonStyle="primary"
              size="large"
              onClick={toggleDatePicker}
              className="rangeBtn"
            />
            {isDatePickerOpen && (
              <DatePicker
                handleDateSelection={handleDateSelection}
                dateRange={dateRange}
              />
            )}
          </div>
        )}
      </HeaderMain>
    </>
  );
}

export default DashboardHeader;
