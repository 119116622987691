import React from "react";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import Inputs from "./execution-run-inputs";
import Outputs from "./execution-run-outputs";

class RunbookDetailsTabs extends React.Component {
  render() {
    return (
      <div className="runbook-details-tabs">
        <TabCollection tabs={["inputs", "outputs"]}>
          <Tab title="Inputs" name="inputs">
            <TabContent className="runbook-details-tabs-content">
              <Inputs />
            </TabContent>
          </Tab>
          <Tab title="Outputs" name="outputs">
            <TabContent className="runbook-details-tabs-content">
              <Outputs />
            </TabContent>
          </Tab>
        </TabCollection>
      </div>
    );
  }
}

export default RunbookDetailsTabs;
