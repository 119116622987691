import React from "react";
import moment from "moment";
import { getPriorityDisplayText, getPriorityIcon } from "@lib/utils";

interface Task {
  task_type: { TaskType: string };
  task_priority: string;
  created: string;
  modified: string;
  trigger: boolean;
  description: string;
}

interface TaskInformationProps {
  selectedTask: Task;
}

const TaskInformation: React.FC<TaskInformationProps> = ({ selectedTask }) => {
  return (
    <div className="details">
      <div className="details-heading">Details</div>
      <div className="details-table">
        <div className="detail detail-key">Priority</div>
        <div className="detail detail-value">
          <img
            src={getPriorityIcon(selectedTask?.task_priority)}
            alt="priority-icon"
          ></img>
          {getPriorityDisplayText(selectedTask?.task_priority)}
        </div>
        <div className="detail detail-key">Trigger</div>
        <div className="detail detail-value">
          {selectedTask?.trigger ? "Auto" : "Manual"}
        </div>
        <div className="detail detail-key">Type</div>
        <div className="detail detail-value">
          {selectedTask?.task_type?.TaskType}
        </div>
        <div className="detail detail-key">Created</div>
        <div className="detail detail-value">
          {moment
            .utc(selectedTask?.created)
            .local()
            .format("ddd DD MMM YYYY hh:mm:ss.SSS")}
        </div>
        <div className="detail detail-key">Description</div>
        <div className="detail detail-value">{selectedTask?.description}</div>
        <div className="detail detail-key">Modified</div>
        <div className="detail detail-value">
          {selectedTask?.modified
            ? moment
                .utc(selectedTask?.modified)
                .local()
                .format("ddd DD MMM YYYY hh:mm:ss.SSS")
            : moment
                .utc(selectedTask?.created)
                .local()
                .format("ddd DD MMM YYYY hh:mm:ss.SSS")}
        </div>
      </div>
    </div>
  );
};

export default TaskInformation;
