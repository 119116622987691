import React from "react";
import { Button } from "@components/ui";
import { Consumer } from "../../execution-details-lib/execution-details.context";

export default class ResumeButton extends React.PureComponent {
  state = {
    text: this.props.executionStatus === "Success" ? "Done" : this.props.text[0],
    disabled: this.props.executionStatus === "Success",
    icon: "icon-play.svg",
  };

  clickHandler = callBack => {
    this.setState({
      text: this.props.text[1],
      disabled: true,
    });
    callBack("Resume_Pause", this.props.step.StepName)
      .then(data => {
        this.setState({ text: this.props.text[2] });
      })
      .catch(e => {
        this.setState({ text: "Error ...", disabled: false });
      });
  };
  render() {
    return (
      <Consumer>
        {({ stepActionButtonClick }) => (
          <div style={{ width: "100%" }}>
            <Button
              text={this.state.text}
              buttonStyle="primary"
              size="medium"
              style={{ width: "125px", height: "30px" }}
              disabled={this.state.disabled}
              onClick={() => this.clickHandler(stepActionButtonClick)}
              icon={this.state.icon}
            />
          </div>
        )}
      </Consumer>
    );
  }
}
