import {
  EXECUTION_UPDATE_SEARCH_TERM,
  EXECUTION_UPDATE_STATUS_FILTER,
  EXECUTION_UPDATE_TIME_FILTER,
  UPDATE_RECORDS_PER_PAGE,
  UPDATE_RECORDS_CURRENT_PAGE,
  UPDATE_SORT_FIELD,
  SET_SELECTED_TRIGGER_FOR_WF,
  FETCH_TRIGGERS_SUCCESS,
} from "@redux/types";
import { defaultExecutionTimeFilter } from "@lib/utils/constants";
import Api from "@lib/api/api";

export const updateExecutionSearchTerm = (searchTerm = "") => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_SEARCH_TERM,
      payload: searchTerm,
    });
  };
};

export const updateExecutionStatusFilter = (status = []) => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_STATUS_FILTER,
      payload: status,
    });
  };
};

export const updateExecutionTimeFilter = (
  time = defaultExecutionTimeFilter,
) => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_TIME_FILTER,
      payload: time,
    });
  };
};

export const updateRecordsPerPage = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_RECORDS_PER_PAGE,
      payload: data,
    });
  };
};

export const updateRecordsCurrentPage = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_RECORDS_CURRENT_PAGE,
      payload: data,
    });
  };
};

export const updateSortColumn = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_SORT_FIELD,
      payload: data,
    });
  };
};

export const setSelectedTriggerForWF = trigger => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_TRIGGER_FOR_WF,
      payload: trigger,
    });
  };
};

/**
 * Action to fetch triggers
 * @returns LIST OF TRIGGERS
 */
export function fetchTriggers() {
  return async dispatch => {
    try {
      let response = await Api.getSettingsTriggers();
      let triggers =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;
      dispatch({ type: FETCH_TRIGGERS_SUCCESS, payload: triggers });
    } catch (e) {
      console.log(e);
    }
  };
}
