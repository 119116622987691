export const convertToList = modules => {
  const moduleList = [];
  Object.keys(modules).forEach(key => {
    const module = modules[key];
    module["module_id"] = key;
    moduleList.push(module);
  });

  return moduleList;
};

export const convertToPayload = module => {
  let { module_id, ...payload } = module;
  return payload;
};

export const convertToModuleObject = (module, data) => {
  const moduleObj = { ...data };
  moduleObj["module_id"] = module;
  return moduleObj;
};
