import React from "react";
import getInstructions from "./InstructionHelper";
import TerraFormZip from "@assets/archives/fylamynt-aws-integration-terraform.zip";
import FylamyntPythonAWS from "@assets/archives/fylamynt-aws-integration-python.zip";

const LeftPanel = () => {
  const instructions = getInstructions();

  return (
    <div className="profile-left">
      <div className="gs-panel-inner d-flex flex-column mb-20-px">
        <h2 className="h2-margin">
          Option 1:&nbsp;Use Terraform template for Creating Assume Role
        </h2>
        <div className="d-inline-flex">
            <a href={TerraFormZip} download className="link-white no-text-decoration">
            <div className="btn-download mt-2">
              Terraform Template
              </div>
            </a>
          
        </div>
      </div>
      <div className="gs-panel-inner d-flex flex-column mb-20-px">
        <h2 className="h2-margin">
          Option 2:&nbsp;Use Python script for Creating Assume Role
        </h2>
        <div className="d-inline-flex">
            <a href={FylamyntPythonAWS} download className="link-white no-text-decoration">
            <div className="btn-download mt-2">
              Python Script
              </div>
            </a>
        </div>
      </div>
      <div className="gs-panel-inner d-flex flex-column">
        <h2 className="h2-margin mb-2">
          Option 3: Manually Create AWS AssumeRole
        </h2>
        {instructions}
      </div>
    </div>
  );
};

export default LeftPanel;
