import React from "react";
import { Provider } from "./executions-run-lib/executions-run.context";
import { ExecutionsHeader } from "./executions-run-components";
import { getUrlParameter } from "@lib/utils";

export default class ExecutionsRun extends React.PureComponent {
  state = {
    runbookName: getUrlParameter("last"),
    message: "",
    isPreparing: false,
  };

  componentDidMount() {
    this.checkIfIsPreparing();
  }

  componentDidUpdate(prevProps) {
    this.checkIfIsPreparing(prevProps);
  }

  checkIfIsPreparing = prevProps => {
    if (!prevProps) {
      this.setState({
        isPreparing: this.props.executionIsRunning,
        message: this.props.executionMessage,
      });
      return;
    }
    if (prevProps.executionIsRunning !== this.props.executionIsRunning) {
      this.setState({
        isPreparing: this.props.executionIsRunning,
        message: this.props.executionMessage,
      });
    }
  };

  render() {
    const contextValue = {
      runbookName: this.state.runbookName,
    };
    return (
      <Provider value={contextValue}>
        <div>
          <>
            <div className="d-flex flex-column">
              <ExecutionsHeader />
              <div className="mt-40-px ml-2">( Executions run area )</div>
            </div>
          </>
        </div>
      </Provider>
    );
  }
}
