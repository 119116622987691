import React from "react";
import { Modal } from "@components/ui";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { SettingsPanelHeader } from "./settings-panel-components";
import AWSSettingsList from "./AWSSettingsList.tsx";
import FormInput from "./settings-panel-components/SettingsRightConfigPanel/FormInput";
import * as Yup from "yup";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  editAWSTargetAccount,
  updateMessage,
} from "@redux/actions/getStarted.actions";
import { camelToSnake } from "@lib/utils";
import Api from "@lib/api";
import { isEmpty } from "@lib/utils";

class AWSSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingsPopup: false,
      nodeName: "AWS Settings",
      showFormSubmitMessage: false,
      isAccountEdit: false,
      currentRow: {},
      isDefault: false,
      ec2: { status: "", list: [] },
    };
    this.validationSchema = Yup.object().shape({
      alias: Yup.string()
        .required("Required")
        .min(3, "Alias name should have minimum 3 letters")
        .matches(/^\S*$/, { message: "Alias cannot have spaces" }),
      roleArn: Yup.string()
        .required("Required")
        .matches(/^arn:aws:iam::\d{12}:role(\/[A-Za-z0-9]+)/, {
          message: "Invalid ARN",
        }),
      externalId: Yup.string().default(""),
      preferredRegion: Yup.string().default(""),
    });
  }
  toggleAWSSettingsModal = async actionType => {
    try {
      this.setState({
        showFormSubmitMessage: false,
        showSettingsPopup: !this.state.showSettingsPopup,
        currentRow: actionType === "create" ? "" : this.state.currentRow,
        isDefault: actionType === "create" ? false : this.state.isDefault,
        isAccountEdit: actionType === "create" ? false : true,
        ec2: { list: this.state.ec2.list, status: "loading" },
      });
      const response = await Api.fetchEc2Regions();
      this.setState({ ec2: { list: response, status: "success" } });
    } catch (error) {
      console.error(error);
      this.setState({ ec2: { list: [], status: "failed" } });
    }
  };

  enableEditAccount = currentRow => {
    this.setState(
      {
        isAccountEdit: true,
        currentRow,
        showFormSubmitMessage: false,
        isDefault: currentRow.is_default,
      },
      () => {
        this.toggleAWSSettingsModal();
      },
    );
  };

  formatData = formData => {
    let finalData = {};
    const ignoreVal = ["preferredRegion", "externalId"];
    Object.keys(formData).forEach(val => {
      if (
        (this.state.isAccountEdit && ignoreVal.indexOf(val) !== -1) ||
        formData[val] !== ""
      ) {
        finalData[camelToSnake(val)] = formData[val];
      }
    });
    finalData["is_default"] = this.state.isDefault;
    return finalData;
  };

  render() {
    const { ec2 } = this.state;
    return (
      <>
        <div className="d-flex flex-column">
          {/* <Breadcrumbs text={"Settings"} navigateTo="settings" /> */}
          <SettingsPanelHeader
            isBreadCrumb={true}
            page="aws-settings"
            toggleAWSSettingsModal={this.toggleAWSSettingsModal}
            heading="AWS Target Accounts"
            breadCrumbText="Settings/AWS Settings"
          />
          <>
            {this.state.showSettingsPopup && (
              <Formik
                initialValues={{
                  alias:
                    this.state.currentRow && this.state.currentRow.alias
                      ? this.state.currentRow.alias
                      : "",
                  roleArn:
                    this.state.currentRow && this.state.currentRow.role_arn
                      ? this.state.currentRow.role_arn
                      : "",
                  externalId:
                    this.state.currentRow && this.state.currentRow.external_id
                      ? this.state.currentRow.external_id
                      : "",
                  preferredRegion:
                    this.state.currentRow &&
                    this.state.currentRow.preferred_region
                      ? this.state.currentRow.preferred_region
                      : "",
                  isDefault: this.state.isDefault,
                }}
                onSubmit={async (values, actions) => {
                  this.props.updateMessage("Updating Target Account ...");
                  const finalFormData = this.formatData(values);
                  try {
                    const alias = finalFormData.alias;
                    delete finalFormData.alias;
                    const response = await Api.updateAWSTargetAccount(
                      finalFormData,
                      alias,
                    );
                    this.props.updateMessage("");
                    if (
                      !isEmpty(response) &&
                      (response.status === 200 || response.status === 201)
                    ) {
                      const data =
                        typeof response.data === "string"
                          ? JSON.parse(response.data)
                          : response.data;

                      // Update AWS account list with the filtered list in case of EDIT
                      const updatedAccountsList = this.props.awsTargetAccountList.map(
                        val => {
                          if (val.alias === data.alias) {
                            return data;
                          }
                          return val;
                        },
                      );

                      this.props.editAWSTargetAccount(updatedAccountsList);
                      this.toggleAWSSettingsModal();
                      this.setState({
                        currentRow: {},
                        isDefault: false,
                      });
                    } else {
                      actions.setStatus({
                        formSubmitMessage: "Could not update information",
                      });
                    }
                  } catch (error) {
                    this.props.updateMessage("");
                    actions.setStatus({
                      formSubmitMessage: "Could not update information",
                    });
                  }
                }}
                validationSchema={this.validationSchema}
              >
                {props => {
                  const {
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    status,
                    values,
                  } = props;
                  return (
                    <Modal
                      onClose={this.toggleAWSSettingsModal}
                      title="AWS Settings"
                      showClose={true}
                      onSubmit={handleSubmit}
                      onCancel={this.toggleAWSSettingsModal}
                      submitButtonText={`Update`}
                      footerClass={`${
                        ec2.status === "loading" ? "disabled" : ""
                      }`}
                    >
                      <FormInput
                        fieldName="Account Alias"
                        name={"alias"}
                        id="alias"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        readOnly={this.state.isAccountEdit}
                      />
                      <FormInput
                        fieldName="AssumeRole ARN"
                        name="roleArn"
                        id="roleArn"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                      />
                      <FormInput
                        fieldName="External ID"
                        id="externalId"
                        name="externalId"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                      />
                      {ec2.status === "loading" ? (
                        <label className="label mb-0 s3-input-label loading-text mt-2">
                          <i>Loading EC2 Regions...</i>
                        </label>
                      ) : ec2.status === "success" ? (
                        <div className="fi-item">
                          <label className="label">
                            Select Preferred Region
                          </label>
                          <ReactSelect
                            id="preferredRegion"
                            name="preferredRegion"
                            value={{
                              value: values.preferredRegion,
                              label: values.preferredRegion
                                ? values.preferredRegion
                                : "Select from below",
                            }}
                            handleChange={data => {
                              if (data === null) {
                                setFieldValue("preferredRegion", "");
                              } else if (!isEmpty(data) && !!data.value) {
                                setFieldValue("preferredRegion", data.value);
                              }
                            }}
                            selectOptions={ec2.list.map(r => {
                              return {
                                value: r.RegionName,
                                label: r.RegionName,
                              };
                            })}
                            customMenuClass="default-select-options-container"
                            customMenuListClass="default-select-options-list"
                            customValueContainerClass="default-select-value-container"
                            customControlClass="default-select-control"
                            customOptionClass="default-select-list-item"
                            required
                          />
                          {errors["preferredRegion"] &&
                            touched["preferredRegion"] && (
                              <div className="input-feedback">
                                {errors["preferredRegion"]}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="input-feedback">
                          Error while fetching ec2 regions, Please contact the
                          administrator
                        </div>
                      )}
                      {!this.state.isAccountEdit && (
                        <>
                          <input
                            type="checkbox"
                            className="styled-check-box"
                            checked={this.state.isDefault}
                            onChange={() =>
                              this.setState({
                                isDefault: !this.state.isDefault,
                              })
                            }
                            value={this.state.isDefault}
                            id={`dropdown-option-checkbox-default-target-account`}
                          />
                          <label
                            className="label-for-check-box"
                            htmlFor={`dropdown-option-checkbox-default-target-account`}
                          >
                            Is Default
                          </label>
                        </>
                      )}
                      <br />
                      {!!status && (
                        <div className="input-feedback">
                          {status.formSubmitMessage}
                        </div>
                      )}
                    </Modal>
                  );
                }}
              </Formik>
            )}
          </>
          <AWSSettingsList enableEditAccount={this.enableEditAccount} />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  awsTargetAccountList: state.getStartedReducer.userDefaultAccounts,
  message: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      editAWSTargetAccount,
      updateMessage,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AWSSettings);
