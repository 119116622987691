import React, { useState, useRef } from "react";
import "./FormInput.scss";
import { Field } from "formik";
import JSONPretty from "react-json-pretty";
import useOutsideClickDetector from "@hooks/useOutsideClickDetector";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";
import copyIcon from "@assets/images/icons/icon-copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

const FormInput = props => {
  const [jsonViewVisible, setJsonViewVisibility] = useState(false);
  const [inputType, setInputType] = useState(
    props.isMasked ? "password" : "text",
  );
  let targetRef = useRef(null);
  useOutsideClickDetector(targetRef, () => setJsonViewVisibility(false));

  return (
    <div className={`fi-item ${props.containerClass || ""}`}>
      {props.showJson ? (
        <div className="fi-label-container">
          <div htmlFor={props.fieldName} className="d-flex flex-column mb-1">
            <label className="label mb-0">{props.fieldName}</label>
            {props.subText && (
              <span className="fi-subtext">{props.subText}</span>
            )}
          </div>
          {props.fieldValue?.constructor.name === "Object" ? (
            <div className={"json-detail"}>
              <div
                style={{
                  position: "relative",
                  top: 0,
                  right: 0,
                }}
                className="editor-toolbar-json-content-button"
                ref={targetRef}
                onClick={e => setJsonViewVisibility(!jsonViewVisible)}
              >
                {jsonViewVisible && (
                  <div
                    onClick={event => event.stopPropagation()}
                    style={{
                      position: "absolute",
                      backgroundColor: "#3d3d3d",
                      padding: "0px 10px",
                      top: "23px",
                      right: "0%",
                    }}
                  >
                    <JSONPretty
                      id="json-pretty"
                      data={props.fieldValue}
                    ></JSONPretty>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <label
          htmlFor={props.fieldName}
          className={`label ${props.labelClassName}`}
        >
          {props.fieldName}
          {props.subText && <br />}
          {props.subText}
        </label>
      )}
      <div className="d-flex w-100">
        <Field
          as={`${props.as || ""}`}
          type={props.isMasked ? inputType : "text"}
          name={props.name}
          onKeyUp={props.onKeyUp}
          placeholder={!!props.placeholder ? props.placeholder : ``}
          className={`${props.className || ""} ${
            props.as === "textarea"
              ? `text-area compact-text-area ${props.textAreaClassName}`
              : "fi-input"
          } ${props.className} ${
            props.as === "textarea" &&
            inputType === "password" &&
            "text-area-blur"
          } ${
            props.errors[props.name] && props.touched[props.name] ? "error" : ""
          }`}
          readOnly={props.readOnly}
          autoComplete={`new-password`}
        />
        <div data-for="copyTip" data-tip>
          {props.copyable && (
            <CopyToClipboard text={props.fieldValue}>
              <button className="fi-icon-container">
                <img
                  src={copyIcon}
                  alt="invisibleIcon"
                  className="fi-icon-copy"
                />
              </button>
            </CopyToClipboard>
          )}
        </div>

        <ReactTooltip
          id="copyTip"
          place="bottom"
          effect="solid"
          type="light"
          event="click"
          isCapture={true}
          afterShow={() => {
            setTimeout(ReactTooltip.hide, 1000);
          }}
        >
          {" "}
          Copied!
        </ReactTooltip>

        {props.isMasked &&
          (inputType === "password" ? (
            <button
              type="button"
              className="fi-icon-container"
              onClick={() => setInputType("text")}
            >
              <IconEyeCancelled className="fi-icon-mask" />
            </button>
          ) : (
            <button
              type="button"
              className={`fi-icon-container`}
              onClick={() => setInputType("password")}
            >
              <IconEye className="fi-icon-mask" />
            </button>
          ))}
      </div>
      {props.errors[props.name] && props.touched[props.name] && (
        <div className="input-feedback">{props.errors[props.name]}</div>
      )}
    </div>
  );
};

export default FormInput;
