import Api from "@lib/api/api";
import {
  S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING,
  S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS,
} from "@redux/types";
import { isEmpty } from "@lib/utils";

export function fetchAllS3BucketList(alias) {
  return async dispatch => {
    try {
      dispatch({
        type: S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING,
        payload: true,
      });
      const response = await Api.fetchAllS3BucketList(alias);
      if (!isEmpty(response)) {
        const body =
          typeof response === "string" ? JSON.parse(response) : response;

        if (!isEmpty(body)) {
          dispatch({
            type: S3_BUCKET_ALL_BUCKETS_FETCH_SUCCESS,
            payload: body,
          });
          dispatch({
            type: S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING,
            payload: false,
          });
        }
      }
    } catch (e) {
      dispatch({
        type: S3_BUCKET_IS_ALL_BUCKET_LIST_LOADING,
        payload: false,
      });
      console.log(e);
    }
  };
}
