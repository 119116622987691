import Api from "@lib/api/api";
import {
  EKS_ACTIONS_FETCH_SUCCESS,
  EKS_ACTION_ROLE_DELETE_SUCCESS,
} from "@redux/types";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { isEmpty } from "@lib/utils";

export function getEksActions(spinnerTitle = "Fetching EKS Permissions") {
  return async dispatch => {
    dispatch(isFetching(true, spinnerTitle));
    const response = await Api.fetchEksActionList().catch(e => console.log(e));
    if (!isEmpty(response)) {
      const eksActionRoles =
        typeof response?.body === "string"
          ? JSON.parse(response?.body)
          : response?.body;

      if (!isEmpty(eksActionRoles)) {
        dispatch({
          type: EKS_ACTIONS_FETCH_SUCCESS,
          payload: eksActionRoles,
        });
      }
    }
    dispatch(isFetching(false, ""));
  };
}

export const removeEksActionRole = role => {
  return async dispatch =>
    dispatch({
      type: EKS_ACTION_ROLE_DELETE_SUCCESS,
      payload: role,
    });
};
