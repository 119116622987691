import { useEffect } from "react";

const useOutsideClickDetector = (targetRef, outsideClickHandler) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                outsideClickHandler()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [targetRef, outsideClickHandler]);
}

export default useOutsideClickDetector;