import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable, Modal } from "@components/ui";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import RunbookDetailsTags from "@containers/RunbookDetails/runbook-details-components/runbook-details-tags";
import { Formik } from "formik";
import * as Yup from "yup";
import Api from "@lib/api";
import RuntimeParameters from "@containers/TaskManagement/utils/CreateTask/utils/RuntimeParameters";
import Schedule from "@components/shared/Schedule/Schedule";
import {
  getParsedRuntimeParameters,
  getRuntimeParameterInitialValues,
  getRuntimeParametersValidationSchema,
} from "@containers/TaskManagement/utils/CreateTask/utils/Helpers";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { doExecution } from "@redux/actions/execution.action";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";
import {
  updateRecordsCurrentPage,
  updateRecordsPerPage,
  updateSortColumn,
} from "@redux/actions/common.actions";

type WorkflowsProps = {
  workflows: [{ DisplayName: "" }];
  allWorkflows: [{ Name: ""; DocumentVersion: "" }];
  taskId: string;
};

const Workflows: React.FC<WorkflowsProps> = ({
  workflows,
  taskId,
  allWorkflows,
}) => {
  const columns = [
    {
      name: "Workflow Name",
      selector: "DisplayName",
      sortable: true,
      width: "25%",
      cell: row => {
        const runbookName = row.DisplayName;
        return (
          <>
            <Link to={`/workflow/${row.DisplayName}/parameters`}>
              {replaceAllSpecialCharWithSpace(runbookName)}
            </Link>
            {row.default && <div className="workflows-default">DEFAULT</div>}
          </>
        );
      },
    },
    {
      name: "Description",
      selector: "Description",
      width: "35%",
    },
    {
      name: "Tags",
      selector: "Tags",
      width: "20%",
      cell: row => <RunbookDetailsTags tags={row.Tags} />,
    },
    {
      name: "Actions",
      selector: "",
      width: "20%",
      cell: row => {
        return (
          <div className="workflows-actions">
            <button onClick={() => runNowClicked(row)}>Run Now</button>
            <button
              onClick={() => {
                setIsScheduleRunClicked(true);
                runNowClicked(row);
              }}
            >
              Schedule Run
            </button>
          </div>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  const websocketConnectionId = useSelector(
    (state: RootStateOrAny) => state.websocketReducer.connectionId,
  );

  const workflowsCPage = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.currentPages?.workflowsCPage,
  );

  const workflowsRecords = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.recordsPerPage?.workflowsRecords,
  );

  const { sortField, sortFieldDirection } = useSelector(
    (state: RootStateOrAny) =>
      state.commonReducer?.sortingDetails?.workflows || {},
  );

  const [isRunNowModalOpen, setIsRunNowModalOpen] = useState(false);
  const [requiredParameters, setRequiredParameters] = useState([]);
  const [selectedRequiredParameters, setSelectedRequiredParameters] = useState(
    {},
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [
    isFetchingRuntimeParameters,
    setIsFetchingRuntimeParameters,
  ] = useState(false);

  const [isScheduleRunClicked, setIsScheduleRunClicked] = useState(false);
  const [isScheduleRunModalOpen, setIsScheduleRunModalOpen] = useState(false);

  /**
   * To be used in Schedule run
   */
  const [fixedRate, setFixedRate] = useState(null);
  const [scheduleExpression, setScheduleExpression] = useState(null);

  /**
   * Fetch RuntimeParameters on WF change
   */
  useEffect(() => {
    if (selectedWorkflow) {
      (async () => {
        try {
          setIsFetchingRuntimeParameters(true);
          let response = await Api.getRunbookVersion(selectedWorkflow, null);
          setIsFetchingRuntimeParameters(false);
          let reqParams = getRuntimeParameterInitialValues(response.Parameters);
          setRequiredParameters(response.Parameters);
          setSelectedRequiredParameters(reqParams);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [selectedWorkflow]);

  const changeWorkflowCurrentPage = cPage => {
    dispatch(
      updateRecordsCurrentPage({
        tableName: "workflowsCPage",
        cPage: cPage,
      }),
    );
  };

  const changeWorkflowRowsPerPage = rows => {
    dispatch(
      updateRecordsPerPage({
        tableName: "workflowsRecords",
        cPageRecords: rows,
      }),
    );
  };

  const handleWorkflowSort = (column, sortDirection) => {
    dispatch(
      updateSortColumn({
        tableName: "workflows",
        sortField: column?.selector,
        sortFieldDirection: sortDirection,
      }),
    );
  };

  const getValidationSchema = modal => {
    if (modal === "run") {
      return getRuntimeParametersValidationSchema(requiredParameters);
    } else if (modal === "schedule") {
      return Yup.object().shape({
        schedule_expression: Yup.string().required(
          "Please enter schedule expression",
        ),
      });
    }
  };

  const runNowClicked = wf => {
    setSelectedWorkflow(wf.DisplayName);
    setIsRunNowModalOpen(true);
  };

  const getCurrentSchedule = () => {
    let expression = fixedRate
      ? fixedRate
      : scheduleExpression
      ? `cron(${scheduleExpression.join(" ")})`
      : null;
    return {
      schedule_expression: expression,
    };
  };

  const setValues = (key, value) => {
    switch (key) {
      case "fixedRate":
        setFixedRate(value);
        break;
      case "scheduleExpression":
        setScheduleExpression(value);
        break;
    }
  };

  const closeScheduleModal = () => {
    setIsScheduleRunClicked(false);
    setIsScheduleRunModalOpen(false);
  };

  const runNow = formik => {
    /**
     * open the Schedule Modal if isScheduleRunClicked is true
     * without running the WF and use the saved runtime parameters
     * with schedule run
     */
    if (isScheduleRunClicked) {
      setSelectedRequiredParameters(formik);
      setIsRunNowModalOpen(false);
      setIsScheduleRunModalOpen(true);
    } else {
      /**
       * Run the WF
       */
      let payload = Object.keys(formik).reduce((acc, param) => {
        const val = formik[param];
        acc[param] = [val];
        return acc;
      }, {});
      payload["connection_id"] = websocketConnectionId;
      payload["task_id"] = taskId ? parseInt(taskId) : "";
      dispatch(doExecution(selectedWorkflow, payload));
      setIsRunNowModalOpen(false);
    }
  };

  const scheduleWorkflow = async () => {
    /**
     * Schedule the WF using selectedRequiredParameters
     */
    let parsedRuntimeParameters = getParsedRuntimeParameters(
      selectedRequiredParameters,
    );
    let wf = allWorkflows.find(w => w.Name === selectedWorkflow);
    let schedule = {};
    schedule["version"] = wf.DocumentVersion;
    schedule["expression"] = getCurrentSchedule().schedule_expression;
    delete schedule["schedule_expression"];
    schedule["task_id"] = taskId;
    schedule["params"] = parsedRuntimeParameters;
    try {
      await Api.scheduleRunbook(selectedWorkflow, schedule);
    } catch (e) {
      console.log(e);
    }
    closeScheduleModal();
  };

  return (
    <div className="mt-20-px workflows">
      {!isFetchingRuntimeParameters && isRunNowModalOpen && (
        <>
          <Formik
            initialValues={selectedRequiredParameters}
            onSubmit={runNow}
            validationSchema={getValidationSchema("run")}
            enableReinitialize={true}
          >
            {formik => {
              const { handleSubmit } = formik;
              return (
                <Modal
                  onClose={() => setIsRunNowModalOpen(false)}
                  title="Runtime Parameters"
                  showClose={true}
                  onSubmit={handleSubmit}
                  onCancel={() => setIsRunNowModalOpen(false)}
                  submitButtonText={isScheduleRunClicked ? "Next" : "Run Now"}
                >
                  <RuntimeParameters
                    formik={formik}
                    requiredParameters={requiredParameters}
                  />
                </Modal>
              );
            }}
          </Formik>
        </>
      )}
      {isScheduleRunModalOpen && (
        <>
          <Formik
            initialValues={getCurrentSchedule()}
            onSubmit={scheduleWorkflow}
            validationSchema={getValidationSchema("schedule")}
            enableReinitialize={true}
          >
            {formik => {
              const { handleSubmit } = formik;
              return (
                <Modal
                  onClose={closeScheduleModal}
                  title="Schedule Workflow Run"
                  showClose={true}
                  onSubmit={handleSubmit}
                  onCancel={closeScheduleModal}
                  submitButtonText={`Schedule`}
                >
                  <Schedule
                    currentSchedule={getCurrentSchedule()}
                    fixedRate={fixedRate}
                    scheduleExpression={scheduleExpression}
                    setValues={setValues}
                  />
                </Modal>
              );
            }}
          </Formik>
        </>
      )}
      {Array.isArray(workflows) && workflows.length > 0 ? (
        <DataTable
          columns={columns}
          data={workflows}
          className="w-100"
          fixedHeader={true}
          fixedScrollHeight={"60px"}
          pagination={true}
          currentPage={workflowsCPage}
          recordsPerPage={workflowsRecords}
          onChangeCurrentPage={changeWorkflowCurrentPage}
          onChangeRowsPerPage={changeWorkflowRowsPerPage}
          defaultSortField={sortField}
          defaultSortAsc={sortFieldDirection === "asc" ? true : false}
          onSort={handleWorkflowSort}
        />
      ) : (
        <NoResultFound
          message="There are no workflows yet"
          detail="To add a workflow, click the 'Add Workflow' button in the top-right of this screen."
          className="mt-100-px"
          errImgSrc="workflows-es"
        />
      )}
    </div>
  );
};

export default Workflows;
