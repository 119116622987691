import React, { useState } from "react";
import "./PaymentSuccess.scss";
import iconSuccess from "@assets/images/icons/icon-success-green.svg";

type PaymentSuccessProps = {
  message: string;
};

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ message = "" }) => {
  const [showNotification, setShowNotification] = useState(true);
  const handleCrossButtonClick = () => {
    setShowNotification(!showNotification);
  };

  return (
    <>
      {showNotification ? (
        <div className="ps-container">
          <div className="ps-container__inner-wrap">
            <img
              src={iconSuccess}
              alt="iconSuccess"
              width="20"
              height="20"
              className="mr-2"
            />
            {message}
          </div>
          <button type="button" className="ps-container-cross">
            <span onClick={handleCrossButtonClick} className="cross-icon">
              &nbsp;&nbsp;✕&nbsp;&nbsp;
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default PaymentSuccess;
