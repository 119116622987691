import React from "react";
import { getApiServerUrl } from "@lib/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./app-info.scss";

class AppInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: this.getTableContent(),
    };
  }
  getTableContent = () => {
    const propArray = Object.keys(this.props.configInfo);
    return (
      propArray.length &&
      propArray.map((config, id) => (
        <tr className="tableBorder" key={`tr_${id}`}>
          <td className="tableData tableBorder" key={`config_${id}`}>
            {config}
          </td>
          <td className="tableData tableBorder" key={`configInfo_${id}`}>
            {this.props.configInfo[config]}
          </td>
        </tr>
      ))
    );
  };
  render() {
    return (
      <div className="appInfoDiv">
        <table className="tableBorder tableStyle">
          <tbody>{this.state.tableContent}</tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  configInfo: {
    "Frontend Commit Hash": process.env.REACT_APP_HASH,
    "Backend Commit Hash": state.appReducer.appConfig.backendCommitHash,
    Tag: process.env.REACT_APP_TAG,
    "App Mode": process.env.NODE_ENV,
    Stage: state.appReducer.appConfig.stage,
    "User Pool ID": state.appReducer.appConfig.userPoolId,
    "User Pool Client Id": state.appReducer.appConfig.userPoolWebClientId,
    "Identity Pool Id": state.appReducer.appConfig.identityPoolId,
    "AWS Account ID": state.appReducer.appConfig.awsAccountId,
    Region: state.appReducer.appConfig.region,
    "Runbook Api Version": state.appReducer.appConfig.runbookApiVersion.replace(
      "/",
      "",
    ),
    "WebSocket URI": state.appReducer.appConfig.websocketURI,
    "API Server": getApiServerUrl(),
    "Boto Version": state.appReducer.appConfig.botoVersion,
  },
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AppInfo);
