import React from "react";
import { snakeToPascal } from "@lib/utils";
import AWSIcon from "@assets/images/snippets/icon-aws.svg";

const ServiceHeader = ({ serviceName }) => {
  const getImageURI = icon => {
    try {
      const sanitizedName = icon.toLowerCase();
      const image = require(`@assets/images/snippets/icon-${sanitizedName}.svg`);
      return image;
    } catch (e) {
      return AWSIcon;
    }
  };

  return (
    <div className="service-header">
      <div className="service-header-icon">
        <img src={getImageURI(serviceName)} alt={`${serviceName} logo`} />
      </div>
      <div className="service-header-text">{snakeToPascal(serviceName)}</div>
    </div>
  );
};

export default ServiceHeader;
