import React from "react";
import { OverflowMenu } from "@components/ui";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";

import { TypeSSHTarget } from "./index";

const tableColumns = (onRemove: any, onEdit: any, showSSHKey: any) => {
  return [
    {
      name: "Target Name",
      selector: "targetName",
      grow: 1,
    },
    {
      name: "Private Key",
      cell: (row: TypeSSHTarget) => {
        return (
          <div className="masked-key-value">
            <input type="password" value={row["privateKey"]} readOnly></input>
            <img
              src={invisibleIcon}
              alt="expandIcon"
              width="15"
              height="15"
              className="pointer"
              onClick={() => {
                showSSHKey(row);
              }}
            />
          </div>
        );
      },
      grow: 4,
    },
    {
      name: "Host Identifier",
      selector: "hostIdentifier",
      grow: 3,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-grey" onMouseDown={() => onEdit(row)}>
            Edit
          </div>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};

export default tableColumns;
