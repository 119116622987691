import React, { useEffect, useState } from "react";
import Api from "@lib/api";
import { Button } from "@components/ui";
import "./UpgradePlan.scss";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../../routes/Constants";
import HeaderWithNavigation from "@components/shared/HeaderWithNavigation/HeaderWithNavigation";
import { mapPlans } from "./Helper";

const UpgradePlan: React.FC = () => {
  const [plans, setPlans] = useState([]);
  const upgradePlan = async planName => {
    if (planName === "free") {
      return;
    }
    let { checkout_url } = await Api.fetchStripeCheckoutURL(planName);
    window.open(checkout_url, "_self");
  };

  useEffect(() => {
    (async () => {
      try {
        let res = await Api.fetchPlans();
        const mappedRes = mapPlans(
          typeof res.body === "string" ? JSON.parse(res.body) : res.body,
        );
        setPlans(mappedRes);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const renderPlan = (plan, index) => {
    return (
      <div className="pricing-plan" key={index}>
        <div className="plan-type">{plan.displayName}</div>

        <div className="price">
          <div>${plan.pricing}</div>
          <span className="text">/mo</span>
        </div>
        <div className="workflow-details-section">
          <div className="details">
            <span className="tick-icon"></span>
            {`${plan.workflow_limit} Workflows`}
          </div>
          <div className="details">
            <span className="tick-icon"></span>
            {`${plan.execution_limit} Workflow Runs/mo`}
          </div>
        </div>
        <div>
          <Button
            text={
              plan.name === "free"
                ? "Current Plan"
                : `Upgrade to ${plan.displayName}`
            }
            buttonStyle="primary"
            className={`upgrade-btn ${
              plan.name === "free" ? "current-selected" : ""
            }`}
            onClick={() => upgradePlan(plan.name)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <HeaderWithNavigation showLink={true} linkText="Account Plan" />
      <div className="section">
        <div className="header">
          <div className="header-text">Choose your plan</div>
          <div className="">
            <span>Have questions ? </span>
            <Link
              to={{ pathname: RouteConstants.contactUs.url }}
              target="_blank"
              className="instruction-link"
            >
              Contact us
            </Link>
          </div>
        </div>
        <div className="plans">
          <div className="plans-section">
            {plans.map((plan, index) => renderPlan(plan, index))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradePlan;
