import { connect } from "react-redux";
import ExecutionRunDetails from "../ExecutionRunDetails";
import { fetchExecutionDetails } from "@redux/actions/execution.action";

const mapState = ({ executionsReducer }) => {
  return {
    isFetchingDetails: executionsReducer.isFetchingDetails,
    isFetchingDetailsSuccess: executionsReducer.isFetchingDetailsSuccess,
    executionRunId: executionsReducer.executionRunId,
    executionDetails:
      executionsReducer.executionDetails[executionsReducer.executionId],
  };
};

const mapDispatch = dispatch => ({
  fetchExecutionDetails: exeuctionId =>
    dispatch(fetchExecutionDetails(exeuctionId)),
});

export default connect(mapState, mapDispatch)(ExecutionRunDetails);
