import React from "react";

/**
 *
 * @param inlineStyleObject
 */

export const getSelectStyle = ({
  input = {},
  indicatorSeparator = {},
  indicatorsContainer = {},
}) => ({
  input: styles => ({ ...styles, color: "#f3f3f3", ...input }),
  indicatorSeparator: styles => ({
    ...styles,
    marginRight: "3px",
    marginLeft: "3px",
    visibility: "hidden",
    ...indicatorSeparator,
  }),
  indicatorsContainer: styles => ({
    ...styles,
    marginRight: "0.5rem",
    ...indicatorsContainer,
  }),
});

export const formatOptionLabel = ({ label }, { inputValue }) => {
  let diplayValue = label;
  let searchText = inputValue.toLowerCase();

  let labelArr = label.split("");

  for (let i = 0; i < labelArr.length; i++) {
    if (!searchText) {
      break;
    }
    if (searchText.includes(labelArr[i].toLowerCase())) {
      searchText = searchText.replace(labelArr[i].toLowerCase(), "");
      labelArr[i] = `<span class="header-light-blue">${labelArr[i]}</span>`;
    }
  }
  diplayValue = labelArr.join("");

  return (
    <span
      data-test={label}
      dangerouslySetInnerHTML={{ __html: diplayValue }}
      className="rs-option-inner"
    />
  );
};
