import React from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";

// Actions
import { isFetching } from "@redux/actions/settingsPanel.action";

// Utils
import { isEmpty, snakeToPascal } from "@lib/utils";
import Api from "@lib/api/api";

// Components
import { FTNotification } from "@components/ui";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";

// Styles
import "./ConnectorFormOAuth.scss";

class ConnectorFormOAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      isEditMode: null,
      connectorName: null,
      initialValues: {},
      showForm: false,
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const isEditMode = url.searchParams.get("edit");
    const path = this.props.location?.pathname?.split("/");
    const connectorName = path?.[3];
    let showForm = true;

    if (connectorName === "slack") {
      this.getInitialValues();
      showForm = false;
    }

    this.setState({
      isEditMode,
      connectorName,
      showForm,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let search = this.props.location.search;
      const isEditMode = new URLSearchParams(search).get("edit");
      this.setState({
        isEditMode,
      });
      // Check if the URL change is due to successful finishOAuth call.
      if (
        this.state.connectorName === "slack" &&
        this.props.location?.state?.integrationSuccess
      ) {
        this.getInitialValues();
      }
    }
  }

  onSubmitHandle = async () => {
    try {
      const { connectorName } = this.state;
      let response = await Api.getConnectorIntegrationUrl(connectorName);
      const integrationUrl =
        !isEmpty(response) && !isEmpty(response.body)
          ? JSON.parse(response.body).integration_url
          : "";

      if (!!integrationUrl) {
        window.location.href = integrationUrl;
      } else {
        FTNotification.error({
          title: "Oops! Something went wrong !!!",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getIntegrationMessage = (connectorName, isEditMode) => {
    const message = {
      zoom: isEditMode
        ? "The Fylamynt Zoom app has been authorized."
        : "Click the button below to integrate Zoom app with Fylamynt.",
      slack: isEditMode
        ? "The Fylamynt Slack app has been authorized."
        : "Click the button below to add the Fylamynt Slack app to your Slack Workspace.",
    };
    return message[connectorName];
  };

  getImageURI = connectorName => {
    if (!connectorName) return;
    try {
      const imageURI = require(`@assets/images/screenshots/settings-${connectorName}/add-to-${connectorName}.svg`);
      return imageURI;
    } catch (e) {
      console.log(e);
    }
  };

  getInitialValues = async () => {
    const initialValues = {};
    try {
      let response = await Api.getConnectorConfigDetails("slack");
      if (response?.body?.constructor.name === "String") {
        let data = JSON.parse(response.body);
        if (data.constructor.name === "String") {
          data = JSON.parse(data);
        }
        Object.keys(data).forEach(item => {
          if (typeof data[item] === "object") {
            initialValues[item] = data[item]?.name;
          } else {
            initialValues[item] = data[item];
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({
      initialValues,
      showForm: true,
    });
  };

  getSlackConnectorForm = formik => {
    const { properties } = this.props.currentNodeSchema;
    const filteredFields = Object.keys(properties).filter(item =>
      properties[item]?.hidden ? false : true,
    );

    return filteredFields.map(field => {
      const { display_name, description, read_only } = properties[field];
      return (
        <FormInput
          fieldName={display_name}
          subText={`${description ? "(" + description + ")" : ""}`}
          name={field}
          id={field}
          fieldValue={formik.values[field]}
          touched={formik.touched}
          errors={formik.errors}
          placeholder={`Enter ${display_name}`}
          key={field}
          readOnly={read_only}
        />
      );
    });
  };

  render() {
    const {
      connectorName,
      doRedirect,
      redirectUrl,
      initialValues,
      showForm,
      isEditMode,
    } = this.state;
    return (
      <>
        {doRedirect && <Redirect to={redirectUrl} push />}
        {showForm && (
          <Formik initialValues={initialValues} onSubmit={this.onSubmitHandle}>
            {formik => (
              <div className="d-flex flex-column">
                <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
                {connectorName === "slack" &&
                  isEditMode &&
                  this.getSlackConnectorForm(formik)}
                <h4>
                  <b>{this.getIntegrationMessage(connectorName, isEditMode)}</b>
                </h4>
                {!isEditMode && (
                  <>
                    <div className="gs-form-footer">
                      <img
                        src={this.getImageURI(connectorName)}
                        alt={`Add to ${snakeToPascal(connectorName)}`}
                        onClick={formik.handleSubmit}
                        className={`connector-integration-add`}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
    },
    dispatch,
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectorFormOAuth),
);
