import React from "react";
import { Consumer } from "../execution-details-lib/execution-details.context";
import { Link } from "react-router-dom";
import { HeaderMain } from "@components/modules";
import { Button } from "@components/ui";
import { RouteConstants } from "../../../routes/Constants";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

const ExecutionDetailsHeader = props => {
  return (
    <Consumer>
      {({ executionDetails }) => (
        <div>
          <div className="pl-2" style={{ display: "inline-block" }}>
            {/* Added `PersistFilter` to maintain search results on Executions page
          when user clicks on breadcrumb */}
            <Link
              to={{
                pathname: RouteConstants.executions.url,
                state: { persistFilter: true },
              }}
              className="breadcrumb-link"
            >
              {"Executions"}
            </Link>
          </div>
          <div
            style={{
              display: "inline-block",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          >
            {"/"}
          </div>
          <div style={{ display: "inline-block" }}>
            <Link
              to={`${RouteConstants.executionDetails.url}/${executionDetails.AutomationExecutionId}/steps`}
              className="breadcrumb-link"
            >
              {`Execution Details`}
            </Link>
          </div>
          <HeaderMain
            title={`${replaceAllSpecialCharWithSpace(
              executionDetails.DocumentName,
            )}`}
            showBreadcrumbs={false}
          >
            <Button
              text="Cancel"
              buttonStyle="secondary"
              style={{ width: "120px" }}
              onClick={props.openCancelExecutionModal}
              disabled={
                !["InProgress", "Waiting"].includes(
                  props.AutomationExecutionStatus,
                )
              }
              icon={"icon-cancelled.svg"}
              type="button"
              size="medium"
            />
          </HeaderMain>
        </div>
      )}
    </Consumer>
  );
};

export default ExecutionDetailsHeader;
