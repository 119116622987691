import React from "react";
import visibleIcon from "@assets/images/icons/eye-icon__allowed.svg";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";
import { OverflowMenu } from "@components/ui";

const APIKeyListHelper = (onRemove, keys, toggleShow) => {
  return [
    {
      name: "Name",
      selector: "ApikeyName",
      grow: 2,
    },
    {
      name: "Key",
      selector: "Value",
      grow: 4,
      cell: row => {
        return (
          <div className="masked-key-value">
            {keys.hasOwnProperty(row.Value) ? (
              <input type="text" value={row.Value} readOnly></input>
            ) : (
              <input type="password" value={row.Value} readOnly></input>
            )}
            <img
              src={keys.hasOwnProperty(row.Value) ? visibleIcon : invisibleIcon}
              alt="expandIcon"
              width="15"
              height="15"
              className="gr-copy-icon"
              onClick={() => {
                toggleShow(row.Value);
              }}
            />
          </div>
        );
      },
    },
    {
      name: "Created Date (UTC)",
      selector: "CreatedDate",
      grow: 2,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
    },
  ];
};

export default APIKeyListHelper;
