import React from "react";
import { Consumer } from "./tabs.context";
import { useLocation } from "react-router-dom";

const Tab = props => {
  const search = useLocation().search;
  return (
    <Consumer>
      {({ handleChange, active, activeClassName, inactiveClassName }) => (
        <React.Fragment>
          <div
            className={`${props.disable ? "no-pointer" : ""}
              ${active === props.name ? activeClassName : inactiveClassName}
            `}
            onClick={() => handleChange(props.name, search)}
          >
            <div className="tabs__nav-link">
              <div>{props.title}</div>
              {props.isWorkflow ? (
                <span className="tabs__nav-link-workflow_count">
                  ({props.size || 0})
                </span>
              ) : null}
            </div>
          </div>
          {active === props.name
            ? React.cloneElement(props.children, { active: true })
            : React.cloneElement(props.children, { active: false })}
        </React.Fragment>
      )}
    </Consumer>
  );
};

export default Tab;
