export const mapResponse = (list = [], connector = "") => {
  if (!Array.isArray(list)) return;

  let result = [];
  list.forEach(item => {
    let incidentArr = item[connector] || [];
    delete item[connector];
    incidentArr.forEach(incident => {
      result.push({
        incidentId: incident,
        taskTypeName: item.meta?.name,
        ...item,
      });
    });
  });
  return result;
};

export const getServiceName = (incidentId, serviceList) => {
  if (!incidentId || !Array.isArray(serviceList)) return "";

  if (!serviceList.length) {
    return incidentId;
  }

  let result = serviceList.find(
    item => item.value.toString() === incidentId.toString(),
  );
  return result?.label || "Service Unavailable";
};
