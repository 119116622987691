import React from "react";
import { Formik } from "formik";
import FormInput from "../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getValidationSchema } from "./utils/ValidationSchema";
import { Modal, Button, FTNotification } from "@components/ui";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { isEmpty } from "@lib/utils";
import {
  createCloudFormationTemplate,
  editCloudFormationTemplate,
} from "@redux/actions/settingsPanel.action";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

type CloudFormationModalProps = {
  selectedTemplate?: any;
  closeForm: (flag: boolean) => void;
  mode: string;
  templateList: Array<object>;
};

const CloudFormationModal: React.FC<CloudFormationModalProps> = ({
  selectedTemplate,
  mode,
  closeForm,
  templateList,
}) => {
  const dispatch = useDispatch();

  const initialValues = selectedTemplate;
  const validationSchema = getValidationSchema(mode, templateList);
  const gitRepoList = useSelector(
    (state: RootStateOrAny) => state.GITReposReducer.gitRepoList,
  );
  const FOOTER_BTN_STYLE = { width: "50%" };

  const getFooter = handleSubmit => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={FOOTER_BTN_STYLE}
          size="large"
          type="button"
          onClick={() => closeForm(false)}
        />
        <Button
          text={
            mode === "create"
              ? "Save CloudFormation Template"
              : "Update CloudFormation Template"
          }
          style={FOOTER_BTN_STYLE}
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const onSubmitHandler = async (formik, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Saving CloudFormation Template"));
    const payload = formik;
    try {
      if (mode === "create") {
        await dispatch(createCloudFormationTemplate(payload));
        FTNotification.success({
          title: "CloudFormation Template Saved Successfully!",
        });
      } else {
        await dispatch(editCloudFormationTemplate(formik.templateId, payload));
        FTNotification.success({
          title: "CloudFormation Template Updated Successfully!",
        });
      }
    } catch (error) {
      if (mode === "create") {
        FTNotification.error({
          title: "Could not save CloudFormation Template",
          message: error.message,
        });
      } else {
        FTNotification.error({
          title: "Could not update CloudFormation Template",
          message: error.message,
        });
      }
    }
    dispatch(isFetching(false, ""));
    actions.setSubmitting(false);
    closeForm(false);
  };

  const selectRepoId = (repoId, setFieldValue) => {
    setFieldValue("repoId", repoId);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          return (
            <Modal
              onClose={() => {
                closeForm(false);
              }}
              title={
                mode === "create"
                  ? "Add CloudFormation Template"
                  : "Update CloudFormation Template"
              }
              footer={getFooter(formik.handleSubmit)}
              showClose={true}
            >
              <FormInput
                fieldName="Template Id"
                subText="(Name for the CloudFormation Template)"
                name="templateId"
                id="templateId"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                readOnly={mode === "create" ? false : true}
                autoComplete="off"
              />

              <div className="fi-item mb-2">
                <label className="label mb-0">Repo Id</label>
                <label className="label">
                  (Repository ID to pull CloudFormation config from)
                </label>
                <ReactSelect
                  id="cloudforamtion-repo-id"
                  name="cloudforamtion-repo-id"
                  value={{
                    value: formik.values.repoId,
                    label: formik.values.repoId
                      ? formik.values.repoId
                      : "Select from below",
                  }}
                  handleChange={data => {
                    if (data === null) {
                      selectRepoId("", formik.setFieldValue);
                    } else if (!isEmpty(data) && !!data.value) {
                      selectRepoId(data.value, formik.setFieldValue);
                    }
                  }}
                  selectOptions={(gitRepoList || []).map(repo => {
                    return {
                      value: repo.name,
                      label: repo.name,
                    };
                  })}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                  required
                />
                {formik.errors["repoId"] && formik.touched["repoId"] && (
                  <div className="input-feedback">
                    {formik.errors["repoId"]}
                  </div>
                )}
              </div>

              <FormInput
                fieldName="FilePath"
                subText="(FilePath in the repository to use for the root module to execute)"
                name="filePath"
                id="filePath"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />

              <FormInput
                fieldName="Ref"
                subText="(Branch, tag, or commit to checkout)"
                name="ref"
                id="ref"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />

              <div className="mt-2">
                {!!formik.status && formik.status.formSubmitMessage}
              </div>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default CloudFormationModal;
