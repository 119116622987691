let boxSizeTimer;

export const getExpandedBoxSize = element => {
  //copy element off-screen
  try {
    const div = element.cloneNode();
    let html = element.innerHTML;
    div.style.maxHeight = "initial";
    div.style.position = "absolute";
    div.style.left = "-5000px";
    div.style.height = "auto";
    document.body.appendChild(div);
    div.style.zIndex = -1;
    div.innerHTML = html;
    let { height } = div.getBoundingClientRect();
    div.parentNode.removeChild(div);
    return height;
  } catch (e) {
    return 0;
  }
};

export function listenForDivChange() {
  const mutationObserver = new MutationObserver(() => {
    /* give the div a chance to finish animating */
    clearTimeout(boxSizeTimer);

    boxSizeTimer = setTimeout(() => {
      if (this.mounted) {
        this.setState({
          height: getExpandedBoxSize(this.ref.current),
        });
      }
    }, 250);
  });

  const options = {
    childlist: true,
    attributes: true,
  };
  mutationObserver.observe(this.ref.current, options);
}
