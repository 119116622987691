import {
  lowerCaseRegex,
  numericRegex,
  specialCharRegex,
  upperCaseRegex,
} from "@lib/utils/constants";
import Auth from "@aws-amplify/auth";
import Bugsnag from "@bugsnag/js";
import axios from "axios";

declare var window: any;
export const setCustomIdentityId = async () => {
  let promises = [
    Auth.currentCredentials(),
    Auth.currentAuthenticatedUser(),
    Auth.currentUserInfo(),
  ];
  const response = await Promise.all(promises);
  const [credentials, userAuth, userAttributes] = response;

  if (!userAttributes.attributes["custom:identityid"]) {
    await Auth.updateUserAttributes(userAuth, {
      "custom:identityid": credentials.identityId,
    });
  }
};

export const keyUpHandler = (e, submitFn, isSubmitting) => {
  if ((e.key === "Enter" || e.keyCode === 13) && !isSubmitting) {
    submitFn();
  }
};

export const checkCognitoPasswordStrength = password => {
  if ([...password].length < 6 || !password.match(/[A-Z]/)) {
    return 1;
  } else if (
    ([...password].length > 5 &&
      password.match(upperCaseRegex) &&
      password.match(lowerCaseRegex) &&
      password.match(numericRegex) &&
      !password.match(specialCharRegex)) ||
    ([...password].length > 5 &&
      specialCharRegex.test(password) &&
      !password.match(numericRegex)) ||
    ([...password].length > 5 &&
      [...password].length < 8 &&
      password.match(upperCaseRegex) &&
      password.match(lowerCaseRegex) &&
      password.match(numericRegex) &&
      password.match(specialCharRegex))
  ) {
    return 3;
  } else if (
    [...password].length > 7 &&
    password.match(upperCaseRegex) &&
    password.match(lowerCaseRegex) &&
    password.match(numericRegex) &&
    password.match(specialCharRegex)
  ) {
    return 5;
  }
  return 1;
};

export const isPassed = (test, password) => {
  switch (test) {
    case "minLength":
      return password.length > 7 ? true : false;

    case "upperCase":
      return password.match(upperCaseRegex) ? true : false;

    case "lowerCase":
      return password.match(lowerCaseRegex) ? true : false;

    case "number":
      return password.match(numericRegex) ? true : false;

    case "specialCharacter":
      return password.match(specialCharRegex) ? true : false;

    default:
      break;
  }
};

export const trackIdentity = (
  { email, company, username },
  isSignup = false,
) => {
  const { heap } = window;
  // Track user identity for Heap analytics
  // @ts-ignore
  heap.identify(email); // Heap analytics user identifier
  // @ts-ignore
  heap.addUserProperties({ email });

  /**
   * Code to create HubSpot Contact
   */

  const isProduction =
    process.env.NODE_ENV === "production" ||
    window.location.href.includes("app.fylamynt.com");
  if (isSignup && isProduction) {
    let trialStartedDate = new Date();
    trialStartedDate.setDate(new Date().getDate());
    let formattedTrialStartedDate = trialStartedDate
      .toISOString()
      .split("T")[0];
    let trialEndDate = new Date();
    trialEndDate.setDate(new Date().getDate() + 30);
    let formattedTrialEndDate = trialEndDate.toISOString().split("T")[0];

    const apiEndpoint =
      "https://api.hsforms.com/submissions/v3/integration/submit/5419588/434a3fd2-b2b4-413e-932c-9b60a9f642dd";
    const postData = {
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: username,
        },
        {
          name: "lastname",
          value: "",
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "free_trial_user",
          value: true,
        },
        {
          name: "production",
          value: true,
        },
        {
          name: "trial_started_date",
          value: formattedTrialStartedDate,
        },
        {
          name: "trial_end_date",
          value: formattedTrialEndDate,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Fylamynt to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from Fylamynt.",
            },
          ],
        },
      },
    };

    axios
      .post(apiEndpoint, postData)
      .then(response => {
        console.log("Contact created ---  ", response);
      })
      .catch(e => {
        console.log("HubSpot contact not created with error - ", e);
      });
  }

  Bugsnag.setUser(email, email);
};
