import React, { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { snakeToPascal } from "@lib/utils";

const Header = () => {
  const [user, setUser] = useState(null);

  const getUserInfo = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(userInfo);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="uo-header">
      <div className="uo-title">
        Welcome, {snakeToPascal(user?.attributes?.name) || "User"}
      </div>
      <div className="uo-subtitle">Get started with Fylamynt</div>
    </div>
  );
};

export default Header;
