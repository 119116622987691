import Api from "@lib/api/api";
import {
  FETCH_QUOTAS_INFO_SUCCESS,
  FETCH_QUOTAS_INFO_ERROR,
  FETCHING_RUNBOOKS,
} from "@redux/types";

import { isEmpty } from "@lib/utils";

export function isFetching(_isFetching, msg) {
  return async dispatch =>
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: _isFetching,
      message: _isFetching ? msg : "",
    });
}

export function fetchAccountPlanInfo() {
  return async dispatch => {
    try {
      dispatch(isFetching(true, "Fetching Account Details"));
      const response = await Api.fetchAccountPlanInfo();
      if (!isEmpty(response)) {
        dispatch(isFetching(false, ""));
        const body =
          typeof response?.body === "string"
            ? JSON.parse(response?.body)
            : response?.body;

        if (!isEmpty(body)) {
          return dispatch({
            type: FETCH_QUOTAS_INFO_SUCCESS,
            payload: body,
          });
        }
      }
    } catch (e) {
      dispatch(isFetching(false, ""));
      return dispatch({
        type: FETCH_QUOTAS_INFO_ERROR,
        payload: "Error in fetching Quota Information",
      });
    }
  };
}
