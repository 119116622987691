import React from "react";
import { Provider } from "./drop-down.context";
import PropTypes from "prop-types";
import "./drop-down.scss";
import { generateId } from "@lib/utils";
import * as Lib from "./drop-down.lib.js";
import Toggle from "./toggle";
import DropDownSearch from "./drop-down-search";

class DropDown extends React.Component {
  state = {
    showExpanded: false,
    height: "0px",
    numberOfChildren: this.props.children ? this.props.children.length : 0,
    title: this.props.title,
    iconImage: null,
    searchText: "",
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.navRef = React.createRef();
    const id = generateId();
    this.toggleId = `toggle_${id}`;
    this.toggleTitleId = `tobble_title_${id}`;
    this.dropdownId = `dropdown_${id}`;
  }

  componentDidMount() {
    this.clickListener();
    this.keyPressListener();
    this.setState({
      iconImage: Lib.setIconImage(this.props.icon),
      iconLeft: Lib.setIconImage(this.props.iconLeft),
      iconRight: Lib.setIconImage(this.props.iconRight),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.children && prevProps.children !== this.props.children) {
      this.setState({
        numberOfChildren: this.props.children.length,
      });
    }
    if (prevProps.title !== this.props.title) {
      this.setState({ title: this.props.title });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._clickEvent, false);
    document.removeEventListener("keyup", this._keyPressEvent, false);
  }

  clickListener() {
    document.addEventListener("click", this._clickEvent, false);
  }

  _clickEvent = e => {
    if (!this.props.multi) {
      Lib.closeDropdown(e, this);
    }
  };

  keyPressListener = () => {
    if (this.props.enableSearch) {
      document.addEventListener("keyup", this._keyPressEvent, false);
    }
  };

  _keyPressEvent = e => {
    if (this.state.showExpanded) {
      e.cancelBubble = true;
    }
    Lib.searchDropDown(e, this);
  };

  render() {
    const toggleStyle = this.state.showExpanded
      ? Lib.openStyle
      : Lib.closedStyle;
    const contextValue = {
      clickHandler: (option, value) => {
        if (this.props.multi && this.props.onClick) {
          this.props.onClick(option, value);
        }
        Lib.optionClickHandler.call(this, option, value);
      },
      selectedTitle: this.state.title,
    };
    return (
      <React.Fragment>
        <div
          className="dropdown-sort w-dropdown"
          id={this.dropdownId}
          onClick={() => Lib.toggle(this)}
          ref={this.ref}
          style={({ width: this.props.width }, { ...this.props.style })}
        >
          <Toggle
            className={this.props.className}
            iconImage={this.state.iconImage}
            backgroundColor={this.props.backgroundColor}
            toggleId={this.toggleId}
            toggleStyle={toggleStyle}
            clickHandler={() => Lib.toggle(this)}
            toggleTitleId={this.toggleTitleId}
            title={this.state.title}
            showArrow={this.props.showArrow}
            iconRight={this.state.iconRight}
            iconLeft={this.state.iconLeft}
            tooltip={this.props.tooltip}
            tooltipPosition={this.props.tooltipPosition}
          />
          <nav
            style={{
              maxHeight: this.state.height,
              transition: toggleStyle.transition,
            }}
            className="dropdown-list w-dropdown-list"
            ref={this.navRef}
          >
            <Provider value={contextValue}>
              <div style={{ position: "relative", width: this.props.width }}>
                {this.props.enableSearch && this.state.showExpanded && (
                  <DropDownSearch searchText={this.state.searchText} />
                )}
                {this.props.children}
              </div>
            </Provider>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

DropDown.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  changeAble: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  showArrow: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enableSearch: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DropDown.defaultProps = {
  changeAble: true,
  className: "dropdown-default",
  showArrow: true,
  multi: false,
  enableSearch: false,
};

export default DropDown;
