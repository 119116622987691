import React from "react";
import "./Title.scss";
function Title({ text }) {
  return (
    <div className="db-title">
      <div>{text}</div>
    </div>
  );
}

export default Title;
