import * as Yup from "yup";
import { isEmpty } from "@lib/utils";
/**
 * Function to determine if the field should be shown
 * to be used to hide dependent field if parent is not selected yet
 * @param input input to be tested
 * @param values Formik values object
 */

export const shouldShowField = (input, values) => {
  const dependencies = input?.dependencies || [];
  if (!dependencies.length) return true;
  for (let i = 0; i < dependencies.length; i++) {
    const dependency = dependencies[i];

    if (!values[dependency]) {
      return false;
    }
  }
  return true;
};

/**
 * @function prepareUrl
 * @param endpoint api endpoint
 * @param params query params if any
 * @param values Form values
 * @returns url
 */
export const prepareUrl = (
  endpoint,
  params,
  values,
  dropdownOptions = [],
  use_key_as_dependent = false,
) => {
  let url = endpoint;
  let urlParams = "";
  /**
   * @case Params do not exist
   * Ex - Alias
   */
  if (isEmpty(params)) {
    return url;
  }
  Object.keys(params).forEach(p => {
    let value = values[p];
    // One off case for AWS EventBridge - FY-4852
    if (use_key_as_dependent) {
      value = dropdownOptions.find(o => o.value === value)?.label;
    }

    if (value) {
      if (urlParams) {
        urlParams = `${urlParams}&${p}=${value}`;
      } else {
        urlParams = `${p}=${value}`;
      }
    }
  });

  if (urlParams) {
    return `${url}?${urlParams}`;
  }
  return url;
};

/**
 * @function getValidationSchema Function to prepare basic validation schema
 * @param formObj form values
 * @param schema Schema obj
 */
export const prepareValidationSchema = (formObj, schema) => {
  let validationSchema = {};
  let initialValues = { ...formObj };
  for (const key in initialValues) {
    let val = schema[key];
    validationSchema[key] = Yup.string()
      .required(
        `${
          key === "taskTypeId"
            ? "Please select the Corresponding Incident Type"
            : (val?.display_name || "This field") + " is required"
        } `,
      )
      .trim(`This field is required`);
  }
  return Yup.object(validationSchema);
};

/**
 * @function getOptions Function to get options for dropdowns
 * @param api Api object inside input field
 * @param dependency
 * @param values Form values
 * @param options Existing options
 */
export const getOptions = (api, dependency = "", values, options) => {
  /**
   * @case if response is maplist, selector exists
   */

  if (!options) return [];
  /**
   * @case if options is dropdown options
   */
  const selector = api?.selector;
  if (options && options.constructor.name === "Array" && options.length) {
    if (selector) {
      /**
       * @case - selector is a string
       */
      if (typeof selector === "string") {
        const uniqueOptions = [];
        options.forEach(val => {
          const isValueDuplicate = !!uniqueOptions.find(
            o => o.value === val[selector],
          );
          if (!isValueDuplicate)
            uniqueOptions.push({
              value: val[selector],
              label: val[selector],
            });
        });
        return uniqueOptions;
      } else {
        /**
         * @case - selector is an object
         */
        const uniqueOptions = [];
        const isValueDuplicate = !!uniqueOptions.find(
          val => val.value === val[selector],
        );
        options.forEach(val => {
          /**
           * @case - selector is an object
           */
          if (!isValueDuplicate)
            uniqueOptions.push({
              value: val[selector.value],
              label: val[selector.name],
            });
        });
        return uniqueOptions;
      }
    }
    /**
     * @case - no selector, when response is stringList
     */
    const uniqueOptions = new Set(options) as any;
    return [...uniqueOptions].map(val => ({
      value: val,
      label: val,
    }));
  } else if (
    options &&
    options.constructor.name === "Object" &&
    !isEmpty(options)
  ) {
    if (selector.constructor.name === "Object") {
      if (selector.is_value_list && dependency) {
        const dependencyValue = values[dependency];
        return options[dependencyValue].map(o => ({ value: o, label: o }));
      }
      return Object.keys(options).map(val => {
        return {
          value: selector.value === "Key" ? val : options[val],
          label: selector.name === "Key" ? val : options[val],
        };
      });
    }
  }
};

/**
 * Specific Function to build Payload for Task Type Assinment
 * @param formData Form fields to be sent in payload
 */
export const getPayload = (formData, connector) => {
  let payload = {};
  payload["service"] = connector;
  payload["incident-id"] = "";
  Object.keys(formData).forEach(key => {
    if (key !== "taskTypeId") {
      if (!payload["incident-id"]) {
        payload["incident-id"] = formData[key];
      } else {
        payload["incident-id"] = payload["incident-id"] + "-" + formData[key];
      }
      payload["incident-id"] = payload["incident-id"].toString();
    }
  });
  return payload;
};
