import React from "react";
import PropTypes from "prop-types";

export default class TextArea extends React.PureComponent {
  state = {
    currentValue: this.props.value,
    initialValue: this.props.value,
    showSummaryWarning: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initialValue !== props.value) {
      return {
        initialValue: props.value,
        currentValue: props.value,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.focus) {
      this.ref.focus();
    }
  }

  handleChange = e => {
    this.setState({
      currentValue: e.target.value,
    });
  };

  render() {
    return (
      <>
        <textarea
          className={this.props.className}
          onKeyUp={e => {
            e.stopPropagation();
            this.props.maxLength &&
              this.setState({
                showSummaryWarning:
                  this.state.currentValue.length === this.props.maxLength
                    ? true
                    : false,
              });
            if (this.props.onKeyUp) {
              this.props.onKeyUp(e.target.value);
            }
          }}
          onBlur={value => this.props.onBlur && this.props.onBlur(value)}
          value={this.state.currentValue}
          onChange={this.handleChange}
          style={this.props.style}
          ref={textarea => {
            this.ref = textarea;
          }}
          maxLength={this.props.maxLength}
          required={this.props.required}
          readOnly={this.props.readOnly}
        ></textarea>
        {this.state.showSummaryWarning && (
          <span className="input-feedback">Max limit is 255 characters</span>
        )}
      </>
    );
  }
}

TextArea.propTypes = {
  value: PropTypes.string,
};

TextArea.defaultProps = {
  value: "",
  readOnly: false,
};
