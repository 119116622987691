import Api from "@lib/api/api";
import {
  AWS_TARGET_ACCOUNT_SAVE_SUCCESS,
  AWS_TARGET_ACCOUNTS_FETCH_ERROR,
  AWS_TARGET_ACCOUNTS_FETCH_SUCCESS,
  AWS_TARGET_ACCOUNT_UPDATE_SUCCESS,
  FETCHING_RUNBOOKS,
  AWS_TARGET_ACCOUNT_DELETE,
} from "@redux/types";

export function updateMessage(message = "") {
  return async dispatch =>
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: !!message,
      message: message,
    });
}

export function fetchDefaultAccounts() {
  return async dispatch => {
    dispatch(updateMessage("Fetching AWS Accounts ..."));
    const response = await Api.getAWSTargetAccounts().catch(e =>
      dispatch({ type: AWS_TARGET_ACCOUNTS_FETCH_ERROR, payload: e }),
    );
    if (response) {
      dispatch({
        type: AWS_TARGET_ACCOUNTS_FETCH_SUCCESS,
        payload: response,
      });
    }
    dispatch(updateMessage(""));
  };
}

export function saveDefaultAccount(data) {
  return dispatch => {
    dispatch({
      type: AWS_TARGET_ACCOUNT_SAVE_SUCCESS,
      payload: data,
    });
  };
}

export function deleteAWSTargetAccount(item) {
  return dispatch => {
    dispatch({
      type: AWS_TARGET_ACCOUNT_DELETE,
      payload: item,
    });
  };
}

export function editAWSTargetAccount(list) {
  return dispatch => {
    dispatch({
      type: AWS_TARGET_ACCOUNT_UPDATE_SUCCESS,
      payload: list,
    });
  };
}
