import React, { useState } from "react";
import { Consumer } from "../execution-run-details-lib/execution-run-details.context";
import * as moment from "moment";
import {
  dateTimeDisplayFormat,
  dateTimeOnHoverDisplayFormat,
} from "@lib/utils/constants";
import OnHover from "@components/ui/OnHover";
import { Link } from "react-router-dom";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

const ExecutionRunDetailsBlock = () => {
  const [nextStep, setNextStep] = useState("");
  const fetchNextStepDetails = (executionRunDetails, executionDetails) => {
    const nextStepName = executionRunDetails.ValidNextSteps
      ? executionRunDetails.ValidNextSteps[0]
      : "";

    if (nextStepName !== "") {
      setNextStep(
        executionDetails.StepExecutions &&
          executionDetails.StepExecutions.find(
            step => step.StepName === nextStepName,
          ),
      );
    }
  };
  return (
    <Consumer>
      {({ executionRunDetails, executionDetails }) => {
        fetchNextStepDetails(executionRunDetails, executionDetails);
        return (
          <div className="executiond-details execution-details-wrap">
            Details
            <div className="execution-details-block-wrap">
              <div className="execution-details-block-div">
                <div className="meta-wrap">
                  <div className="meta-title">Step Execution ID</div>
                  <div className="meta-text">
                    {executionRunDetails.StepExecutionId}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">Workflow</div>
                  <div className="meta-text">
                    {executionDetails.WorkflowDeleted ? (
                      <span>
                        {replaceAllSpecialCharWithSpace(
                          executionDetails.DocumentName,
                        )}
                      </span>
                    ) : (
                      <Link
                        to={`/workflow/${executionDetails.DocumentName}/parameters`}
                      >
                        {replaceAllSpecialCharWithSpace(
                          executionDetails.DocumentName,
                        )}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">Action</div>
                  <div className="meta-text">
                    {executionRunDetails.StepName}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">Valid Next Steps</div>
                  <div className="meta-text">
                    {nextStep ? (
                      <Link
                        className="link-light-blue"
                        to={`/executions/details/${executionDetails.AutomationExecutionId}/step/${nextStep.StepExecutionId}`}
                      >
                        {nextStep.StepName}
                      </Link>
                    ) : (
                      `No Next Step`
                    )}
                  </div>
                </div>
              </div>
              <div className="details-block">
                <div className="meta-wrap">
                  <div className="meta-title">Start Time</div>
                  <div className="meta-text">
                    {!!executionRunDetails.ExecutionStartTime && (
                      <OnHover
                        title={moment(
                          executionRunDetails.ExecutionStartTime,
                        ).format(dateTimeOnHoverDisplayFormat)}
                      >
                        {moment(executionRunDetails.ExecutionStartTime).format(
                          dateTimeDisplayFormat,
                        )}
                      </OnHover>
                    )}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">End Time</div>
                  <div className="meta-text">
                    {(executionRunDetails.StepStatus === "Success" ||
                      executionRunDetails.StepStatus === "Cancelled" ||
                      executionRunDetails.StepStatus === "Failed" ||
                      executionRunDetails.StepStatus === "TimedOut") &&
                    !!executionRunDetails.ExecutionEndTime ? (
                      <OnHover
                        title={moment(
                          executionRunDetails.ExecutionEndTime,
                        ).format(dateTimeOnHoverDisplayFormat)}
                      >
                        {moment(executionRunDetails.ExecutionEndTime).format(
                          dateTimeDisplayFormat,
                        )}
                      </OnHover>
                    ) : executionDetails?.AutomationExecutionStatus ===
                        "Cancelled" &&
                      executionRunDetails.StepStatus === "InProgress" ? (
                      <OnHover
                        title={moment(executionDetails.ExecutionEndTime).format(
                          dateTimeOnHoverDisplayFormat,
                        )}
                      >
                        {moment(executionDetails.ExecutionEndTime).format(
                          dateTimeDisplayFormat,
                        )}
                      </OnHover>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">onFailure</div>
                  <div className="meta-text">
                    {executionRunDetails.OnFailure}
                  </div>
                </div>
                <div className="meta-wrap">
                  <div className="meta-title">Max Attempts</div>
                  <div className="meta-text">
                    {executionRunDetails.MaxAttempts}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default ExecutionRunDetailsBlock;
