import React from "react";
import ReactTooltip from "react-tooltip";
import ServiceHeader from "./ServiceHeader";
import Status from "./Status";

const tableColumns = () => {
  return [
    {
      name: "Service",
      selector: "service",
      grow: 0.7,
      cell: row => <ServiceHeader serviceName={row.service} />,
    },
    {
      name: "Status",
      selector: "status",
      grow: 0.4,
      cell: row => <Status statusMessage={row?.status} />,
    },
    {
      name: "Title",
      selector: "title",
      grow: 1,
      cell: row => {
        return <div className="mt-5-px mb-5-px">{row.title}</div>;
      },
    },
    {
      name: "Last Updates",
      selector: "last_update",
      grow: 1,
      cell: row => {
        return <div className="mt-5-px mb-5-px">{row.last_update}</div>;
      },
    },
    {
      name: "Error",
      selector: "error",
      grow: 0.4,
      cell: row => {
        return (
          <>
            <ReactTooltip
              id="error"
              place="right"
              effect="solid"
              type="light"
              className="tooltip"
            />
            <div
              data-for="error"
              data-tip={row.error}
              className="mt-5-px mb-5-px text_overflow"
            >
              {row.error}
            </div>
          </>
        );
      },
    },
    {
      name: "Message",
      selector: "message",
      grow: 1.2,
      cell: row => {
        return <div className="mt-5-px mb-5-px">{row.message}</div>;
      },
    },
  ];
};

export default tableColumns;
