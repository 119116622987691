import produce from "immer";
import {
  CONNECTOR_CONFIGURATION_PANEL_SHOW,
  CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS,
  CONNECTOR_CONFIGURATION_FORM_SAVING,
  CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS,
  CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR,
  CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS,
  CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR,
  CONNECTORS_FETCH_SUCCESS,
  CONNECTORS_FETCH_ERROR,
  CONNECTOR_STATUS_UPDATE_ERROR,
  CONNECTOR_STATUS_UPDATE_SUCCESS,
  FETCH_TERRAFORM_CLI_MODULES_SUCCESS,
  CREATE_TERRAFORM_CLI_MODULE_SUCCESS,
  DELETE_TERRAFORM_CLI_MODULE_SUCCESS,
  UPDATE_TERRAFORM_CLI_MODULE_SUCCESS,
  FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS,
  CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS,
  CONNECTOR_ACTIONS_FETCH_SUCCESS,
} from "@redux/types";
import {
  convertToList,
  convertToModuleObject,
} from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/utils/helper";

const INITIAL_STATE = {
  connectors: {},
  currentNode: {},
  currentNodeSchema: {},
  showConfigPanel: false,
  formSaving: false,
  formSubmittedSuccess: false,
  formSubmittedError: false,
  currentNodeConfigInfo: {},
  responseMessage: "",
  errorMessage: "",
  notificationStatus: "",
  terraformCLIModuleList: [],
  cloudFormationTemplateList: [],
  actions: [],
};

const settingsPanelReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONNECTOR_CONFIGURATION_PANEL_SHOW:
        draft.showConfigPanel = action.payload;
        break;

      case CONNECTOR_CONFIGURATION_SCHEMA_FETCH_SUCCESS:
        draft.currentNodeSchema = action.payload.schema;
        break;

      case CONNECTOR_CONFIGURATION_FORM_SAVING:
        draft.formSaving = action.payload;
        break;

      case CONNECTOR_CONFIGURATION_FORM_SAVE_SUCCESS:
        draft.formSubmittedSuccess = action.payload;
        draft.formSubmittedError = false;
        draft.errorMessage = "";
        break;

      case CONNECTOR_CONFIGURATION_FORM_SAVE_ERROR:
        draft.errorMessage = action.payload;
        draft.formSubmittedError = true;
        draft.formSubmittedSuccess = false;
        break;

      case CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS:
        draft.currentNodeConfigInfo = action.payload;
        break;

      case CONNECTOR_CONFIGURATION_INFO_FETCH_ERROR:
        draft.errorMessage = action.payload;
        break;

      case CONNECTORS_FETCH_SUCCESS:
        draft.connectors = action.payload;
        draft.responseMessage = "";
        break;

      case CONNECTORS_FETCH_ERROR:
        draft.responseMessage = action.payload;
        break;

      case CONNECTOR_STATUS_UPDATE_SUCCESS:
        draft.notificationStatus = action.payload?.message;
        break;
      case CONNECTOR_STATUS_UPDATE_ERROR:
        draft.errorMessage = action.payload;
        break;

      case FETCH_TERRAFORM_CLI_MODULES_SUCCESS:
        draft.terraformCLIModuleList = convertToList(action.payload);
        break;
      case DELETE_TERRAFORM_CLI_MODULE_SUCCESS:
        draft.terraformCLIModuleList = draft.terraformCLIModuleList.filter(
          module => module.module_id !== action.payload,
        );
        break;
      case UPDATE_TERRAFORM_CLI_MODULE_SUCCESS:
        const index = draft.terraformCLIModuleList.findIndex(
          module => module.module_id === action.module,
        );
        if (index !== -1) {
          draft.terraformCLIModuleList[index] = convertToModuleObject(
            action.module,
            action.data,
          );
        }
        break;
      case CREATE_TERRAFORM_CLI_MODULE_SUCCESS:
        draft.terraformCLIModuleList = [
          ...state.terraformCLIModuleList,
          convertToModuleObject(action.module, action.data),
        ];
        break;

      case FETCH_CLOUDFORMATION_TEMPLATE_SUCCESS:
        draft.cloudFormationTemplateList = action.payload;
        break;
      case DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS:
        draft.cloudFormationTemplateList = draft.cloudFormationTemplateList.filter(
          template => template.templateId !== action.payload,
        );
        break;
      case UPDATE_CLOUDFORMATION_TEMPLATE_SUCCESS:
        const idx = draft.cloudFormationTemplateList.findIndex(
          template => template.templateId === action.templateId,
        );
        if (idx !== -1) {
          draft.cloudFormationTemplateList[idx] = action.payload;
        }
        break;
      case CREATE_CLOUDFORMATION_TEMPLATE_SUCCESS:
        draft.cloudFormationTemplateList.push(action.template);
        break;

      case CONNECTOR_ACTIONS_FETCH_SUCCESS:
        draft.actions = action.payload;
        break;

      default:
        break;
    }
  });

export default settingsPanelReducer;
