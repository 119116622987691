import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { convertPayloadSpecToJSON } from "../ssm/util";
import {
  readLambdaParam,
  RunbookStepInput,
  ActionNodeOutput,
  RunbookStepOutput,
} from "../ssm/nodeinputoutput";
import { ParameterType } from "../ssm/strings";
import { AwsApiField } from "../ssm/awsapi-types";
import { camelToSnake, kebabToCamel } from "../../../../lib/utils";
import { StepTypes } from "./strings";
import { Base64 } from "js-base64";
import { checkInvalidRequiredInput } from "../ssm/util";
import { Parameter } from "../ssm/parameters";

export class ActionNodeStep extends InvokeLambdaStep {
  static createNewActionNode(
    actionNodeDef,
    service,
    operation,
    operationDescription,
    parameterInputs = [],
  ) {
    const payload = ActionNodeStep._makeLambdaPayload(service, operation, []);
    const outputs = [];
    this._addNeurOpsDefaultOutputs(outputs);
    const json = {
      name: `${kebabToCamel(service)}_${operation}_${
        actionNodeDef.insertionOrder
          ? actionNodeDef.insertionOrder
          : Math.floor(1000 * Math.random())
      }`,
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: actionNodeDef.content.lambda_arn,
        Payload: payload,
      },
      outputs,
      maxAttempts: 1,
      onFailure: "Abort",
      editorNodeId: actionNodeDef.editorNodeId,
    };
    const ssmStep = new ActionNodeStep(json, actionNodeDef, parameterInputs);

    if (operationDescription) {
      const operationDetails = new ActionNodeOperationDetails(
        operationDescription,
      );
      ssmStep.setOperationDetails(operationDetails);
    }
    ssmStep.actionNodeDef = actionNodeDef;
    return ssmStep;
  }

  static _makeLambdaPayload(service, operation, inputs) {
    const json = {
      alias: "{{alias}}",
      region_name: "{{region_name}}",
      workflow_session: "{{ WorkflowSession}} ",
      aws_call: {
        service,
        operation,
        inputs: {},
      },
    };
    return JSON.stringify(json);
  }

  static _writeSnippetDetailsForContext(snippetDefinition) {
    const {
      description,
      version,
      tags,
      outputs,
      optional_inputs,
      required_inputs,
    } = snippetDefinition || {};
    const obj = {
      custom: {
        description,
        version,
        tags,
        outputs,
        required_inputs,
        optional_inputs,
      },
    };
    return Base64.encode(JSON.stringify(obj));
  }

  constructor(stepJSON, actionNodeDef, parameterInputs = []) {
    super(stepJSON);
    this.stepType = StepTypes.ActionNodeStep;
    this.editable = true;
    this.parameterInputs = parameterInputs;
    this.lambdaPayload = readLambdaPayload(stepJSON);
    this.region_name = this.lambdaPayload.region_name;
    this.alias = this.lambdaPayload.alias;
    this.service = this.lambdaPayload.aws_call?.service;
    this.operation = this.lambdaPayload.aws_call?.operation;
    this.stepType = "ActionNodeStep";
    this.actionNodeDef = actionNodeDef;
    this.lambda_arn = actionNodeDef ? actionNodeDef.content.lambda_arn : {};
    this.buildOutputs();

    window.setTimeout(() => {
      this.showHideWarning(!this.isHealthyStep());
    }, 500);
  }

  resetActionNode() {
    this.parameterInputs = [];
    this.outputs = [];
    this.operationDetails = {};
  }

  isHealthyStep() {
    if (!this.operation || !this.service) {
      return false;
    }
    return checkInvalidRequiredInput(this?.parameterInputs);
  }

  buildOutputs() {
    try {
      let newOutputs = [];
      if (this.outputs) {
        const { outputs } = this.lambdaPayload.aws_call;
        const lambdaOutputs = outputs || [];
        // eslint-disable-next-line no-unused-vars
        for (let output of this.outputs) {
          const lambdaOutput = lambdaOutputs.find(lambdaOut =>
            output.selector.endsWith(lambdaOut.Name),
          );
          if (lambdaOutput) {
            const newOutput = new ActionNodeOutput(
              this,
              lambdaOutput.Selector,
              lambdaOutput.Type,
              output,
            );
            newOutput.originalType = lambdaOutput.originalType;
            newOutputs.push(newOutput);
          } else {
            newOutputs.push(output);
          }
        }
      }
      /**
       * For the case when a saved workflow doesn't have the outputs following:
       *  1. "execution_status"
       *  2. "output"
       */
      ActionNodeStep._addNeurOpsDefaultOutputs(newOutputs);
      let outputs1 = newOutputs.filter(
        output => !["execution_status", "output"].includes(output.Name),
      );

      let outputs2 = newOutputs
        .filter(output => ["execution_status", "output"].includes(output.Name))
        .map(
          output =>
            new RunbookStepOutput(
              this,
              output.Name,
              output.Type,
              output.Selector,
            ),
        );
      newOutputs = [...outputs1, ...outputs2];
      this.outputs = newOutputs;
    } catch (e) {
      console.log(e, this);
    }
  }

  static _addNeurOpsDefaultOutputs(outputs) {
    const exeStatus = new RunbookStepOutput(
      null,
      "execution_status",
      "String",
    ).toSSM();
    const output = new RunbookStepOutput(null, "output", "String").toSSM();

    if (!outputs.find(out => out.name === "execution_status")) {
      outputs.push(exeStatus);
    }

    if (!outputs.find(out => out.name === "output")) {
      outputs.push(output);
    }
  }

  // called on reading from/writing to  SSM
  writeLambdaPayload = () => {
    return `{"workflow_session": "{{WorkflowSession}}", ${this.writeAliasAndRegionName()}, "aws_call": {"service":"${
      this.service
    }","operation":"${camelToSnake(
      this.operation,
    )}","inputs": ${this.writeInputParams()}, "outputs": ${this.writeRequestedOutputs()}}}`;
  };

  writeInputParams = () => {
    const inputlist = this.parameterInputs
      .filter(
        input => !(input.name === "alias" || input.name === "region_name"),
      )
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .join(", ");
    return `{ ${inputlist} }`;
  };

  writeAliasAndRegionName = () => {
    const inputlist = this.parameterInputs
      .filter(input => input.name === "alias" || input.name === "region_name")
      .map(input => input.writeInputParam())
      .join(", ");
    return `${inputlist}`;
  };

  writeRequestedOutputs = () => {
    const outputs = this.outputs
      ? this.outputs.filter(out => out.forLambdaPayload && out.isConsumed())
      : [];
    const outForLambda = outputs
      .map(out => {
        try {
          return out.forLambdaPayload();
        } catch (e) {
          console.warn(
            "ActionNodeStep has an output that is not an actionNodeOutput",
            e,
            out,
          );
          return null;
        }
      })
      .filter(out => !!out);
    return JSON.stringify(outForLambda);
  };

  readInputSources = runbook => {
    const inputsWithSources = readActionNodeInputSourcesFromSSM(
      this.lambdaPayload,
      runbook,
      this.alias,
      this.region_name,
    );
    this.parameterInputs = this.parameterInputs || [];

    for (let inputName of Object.keys(inputsWithSources)) {
      // -isarray is coming from readActionNodeInputSourcesFromSSM
      // and it;s used to know if the value of current key name was an array
      if (inputName.includes("-isarray")) continue;

      let inputType = inputsWithSources[`${inputName}-isarray`]
        ? ParameterType.StringList
        : ParameterType.String;
      if (typeof inputsWithSources[inputName].sourceValue === "object") {
        inputType = ParameterType.StringMap;
      }
      // eslint-disable-next-line no-loop-func
      let found = this.parameterInputs.find(input => input.name === inputName);
      if (!found) {
        let input;
        if (inputName === "region_name" || inputName === "alias") {
          input = new RunbookStepInput(
            this,
            inputName,
            ParameterType.String,
            true,
            inputsWithSources[inputName]?.sourceValue instanceof Parameter
              ? null
              : inputsWithSources[inputName],
          );
        } else {
          input = new RunbookStepInput(
            this,
            inputName,
            inputType,
            false, // we don't know if it is required yet, we'll set this when we get API data by setOperationDetails method
            inputsWithSources[inputName],
          );
        }
        this.parameterInputs.push(input);
      }
    }
  };

  setOperationDetails = operationDetails => {
    if (!operationDetails?.attachToSSMStep) {
      operationDetails = new ActionNodeOperationDetails(operationDetails);
      /**
       * check for empty input once the response to check if an
       * input is required is loaded
       * */
      this.showHideWarning(!this.isHealthyStep());
    }
    operationDetails.attachToSSMStep(this);
    this.operationDetails = operationDetails;
    this.parameterInputs = this.operationDetails.parameterInputs;
    this.outputs = this.outputs || []; // outputs are only created when consumed downstream
  };

  _isValid = () => {
    if (!(this.service && this.operation)) {
      return false;
    }

    if (this.operationDetails) {
      // eslint-disable-next-line no-unused-vars
      for (let required of this.operationDetails.required_inputs) {
        if (!this.inputs.find(input => input.name === required)) {
          return false;
        }
      }
    }
    return true;
  };

  toSSM = () => {
    let execution_status = null;
    if (this.outputs)
      execution_status = this.outputs.find(
        out => out.name === "execution_status",
      );

    const outputs = this.outputs
      ? this.outputs
          .filter(out => out.isConsumed())
          .filter(out => out.name !== "execution_status")
          .filter(out => out.name !== "output")
          .map(out => out.toSSM())
      : [];
    if (execution_status) outputs.push(execution_status.toSSM());

    // copy/modify actionNodeDef and add inputs from aws/operation http request
    const modifiedActionNodeDef = { ...this.actionNodeDef };
    const {required_inputs, optional_inputs}=modifiedActionNodeDef;
    if (this.operationDetails) {
      if(required_inputs && this.operationDetails.required_inputs){
        Object.assign(
          required_inputs,
          this.operationDetails.required_inputs,
        );
      }
     
      if(optional_inputs && this.operationDetails.input){
        Object.assign(
          optional_inputs,
          this.operationDetails.input,
        );
      }
     
    }

    return {
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: this.lambda_arn,
        Payload: this.writeLambdaPayload(),
        ClientContext: ActionNodeStep._writeSnippetDetailsForContext(
          this.actionNodeDef,
        ),
      },
      isEnd: !this.nextStep,
      maxAttempts: 1,
      name: this.name,
      nextStep: this.nextStep,
      onFailure: "Abort",
      outputs,
    };
  };
}

// Helper functions

export function readLambdaPayload(actionNodeStep) {
  try {
    return JSON.parse(
      convertPayloadSpecToJSON(actionNodeStep.inputs.Payload) || "{}",
    );
  } catch (ex) {
    console.error(`Error parsing JSON: ${actionNodeStep.inputs.Payload}`);
    console.error(ex);
    return [];
  }
}

function readActionNodeInputSourcesFromSSM(
  lambdaPayload,
  runbook,
  alias,
  region_name,
) {
  const sources = {};
  const payload = lambdaPayload;

  const inputs = {
    ...payload.aws_call?.inputs,
    alias,
    region_name,
  };
  let name;
  for (name of Object.keys(inputs || [])) {
    const readLambdaParamReturnValue = readLambdaParam(runbook, inputs[name]);

    const hasInput = name in inputs;
    const source = hasInput && readLambdaParamReturnValue[0];
    if (source) {
      sources[name] = source;
      sources[`${name}-isarray`] = readLambdaParamReturnValue[1];
    }
  }
  return sources;
}

export class ActionNodeOperationDetails {
  /**
   * An operationDescription has fields id, service, operation, input, output, required_inputs.
   *
   * id === ${service}_${operation}, service and operation are AWS names for API units and calls.
   *
   * input is a map from input name to a type descriptor which is sometimes an object or array
   * output is a map from output name to a type descriptor which is sometimes an object or array
   * required_inputs is an array of names of inputs that are required.
   *
   * @param {*} operationDescription
   */
  constructor(operationDescription) {
    Object.assign(this, operationDescription);
  }

  attachToSSMStep(ssmStep) {
    this.ssmStep = ssmStep;
    this._readInputs(ssmStep.parameterInputs);
    this._readOutputs();
  }

  _readInputs(ssmInputs) {
    this.parameterInputs = ssmInputs || [];
    let inputName;
    if (!this.input) return;

    for (inputName of Object.keys(this.input)) {
      const existingInput = ssmInputs.find(
        // eslint-disable-next-line no-loop-func
        input => input.name === inputName,
      );

      const isRequired = this.required_inputs.find(
        // eslint-disable-next-line no-loop-func
        name => name === inputName,
      );

      if (existingInput) {
        existingInput.required = isRequired;
      } else {
        let type =
          typeof this.input[inputName] === "string"
            ? this.input[inputName]
            : "String"; // JSON.stringify(this.input[inputName]);
        if (Array.isArray(this.input[inputName])) {
          type = "StringList";
        } else if (typeof this.input[inputName] === "object") {
          type = "StringMap";
        }
        this.parameterInputs.push(
          new RunbookStepInput(this.ssmStep, inputName, type, isRequired),
        );
      }
    }
  }
  _readOutputs() {
    this.outputs =
      (this.output &&
        AwsApiField.readJSON(null, "output", this.output).ssmOutputs(
          this.ssmStep,
        )) ||
      [];
  }
}
