import React from "react";
import CronReader from "@lib/utils/cron-reader";
import range from "lodash/range";
import "./Schedule.scss";

class Schedule extends React.PureComponent {
  constructor(props) {
    super(props);
    this.cronReader = new CronReader();
    let rateTime = 0;
    let rateTimeOption = "minutes";
    let fixedRate = null;
    let scheduleExpression = ["*", "*", "*", "*", "?", "*"];
    let expressionText = "";

    if (props.currentSchedule?.schedule_expression?.indexOf("rate(") > -1) {
      fixedRate = props.currentSchedule.schedule_expression;
      let match = props.currentSchedule.schedule_expression.match(
        /rate\((.*)\)/i,
      );
      rateTime = match[1].split(" ")[0];
      rateTimeOption = match[1].split(" ")[1];
      let { r, o } = this.generateFixedRateText(rateTime, rateTimeOption);
      expressionText = "Once every " + (r === 1 ? `` : r) + " " + o;
    } else if (
      props.currentSchedule?.schedule_expression?.indexOf("cron(") > -1
    ) {
      let match = props.currentSchedule.schedule_expression.match(
        /cron\((.*)\)/i,
      );
      scheduleExpression = match[1].split(" ");
      expressionText = this.cronReader.humanize(scheduleExpression.join(" "));
    }
    this.state = {
      rateTime: rateTime,
      rateTimeOption: rateTimeOption.endsWith("s")
        ? rateTimeOption
        : `${rateTimeOption}s`,
      expressionText:
        expressionText ||
        this.cronReader.humanize(scheduleExpression.join(" ")),
    };
    this.props.setValues("fixedRate", fixedRate);
    this.props.setValues("scheduleExpression", scheduleExpression);
  }

  componentDidMount() {}

  setRateTime = e => {
    if (e.target.value === "") return;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      e => {
        let { r, o } = this.generateFixedRateText(
          this.state.rateTime,
          this.state.rateTimeOption,
        );
        if (r === 0) return;
        this.setState({
          expressionText: "Once every " + (r === 1 ? `` : r) + " " + o,
        });
        this.props.setValues("fixedRate", `rate(${r} ${o})`);
        this.props.setValues("scheduleExpression", [
          "*",
          "*",
          "*",
          "*",
          "?",
          "*",
        ]);
      },
    );
  };

  generateFixedRateText = (rateTime, rateTimeOption) => {
    let r = parseInt(rateTime);
    let o = r < 2 ? rateTimeOption.replace("s", "") : rateTimeOption;
    return { r, o };
  };

  generateCronExpression = e => {
    let value = e.target.value;
    let exp = this.props.scheduleExpression;
    switch (e.target.name) {
      case "minutes":
        if (parseInt(value) < 0 || value === "") value = "*";
        exp[0] = value;
        break;
      case "hours":
        if (parseInt(value) < 0 || value === "") value = "*";
        exp[1] = value;
        break;
      case "day":
        exp[2] = value;
        exp[4] = "?";
        break;
      case "month":
        exp[3] = value;
        break;
      case "week-day":
        exp[4] = value;
        exp[2] = "?";
        break;
      case "year":
        exp[5] = value;
        break;

      default:
        break;
    }

    this.setState({
      expressionText: this.cronReader.humanize(exp.join(" ")),
      // reset
      rateTime: 0,
      rateTimeOption: "minutes",
    });
    this.props.setValues("scheduleExpression", exp);
    this.props.setValues("fixedRate", null);
  };

  setScheduleExpression = e => {
    this.setState({
      expressionText: this.cronReader.humanize(e.target.value),
      // reset
      fixedRate: null,
      rateTime: 0,
      rateTimeOption: "minutes",
    });
    this.props.setValues("scheduleExpression", e.target.value.split(" "));
    this.props.setValues("fixedRate", null);
  };

  render() {
    return (
      <div className="schedule-run">
        <div className="space-line">
          <span>Fixed Rate</span>
        </div>
        <div className="schedule-run-rate">
          Every:&nbsp;&nbsp;
          <input
            type="number"
            min={1}
            className="text-input number"
            name="rateTime"
            value={this.state.rateTime}
            onChange={this.setRateTime}
          />
          &nbsp;&nbsp;
          <select
            name="rateTimeOption"
            onChange={this.setRateTime}
            value={this.state.rateTimeOption}
          >
            <option value="minutes">Minutes</option>
            <option value="hours">Hours</option>
            <option value="days">Days</option>
          </select>
        </div>
        <div className="space-line">
          <span>Cron Expression</span>
        </div>
        <div className="">
          &nbsp;&nbsp;
          <div>
            <div className="cron-expression">
              <div>
                Minutes:
                <br />
                <input
                  type="number"
                  min={-1}
                  max={59}
                  name="minutes"
                  className="text-input"
                  defaultValue={this.props.scheduleExpression?.[0]}
                  onChange={this.generateCronExpression}
                />
              </div>
              <div>
                Hours:
                <br />
                <input
                  type="number"
                  min={-1}
                  max={24}
                  className="text-input"
                  name="hours"
                  defaultValue={this.props.scheduleExpression?.[1]}
                  onChange={this.generateCronExpression}
                />
              </div>
              <div>
                Day:
                <br />
                <select
                  onChange={this.generateCronExpression}
                  name="day"
                  value={this.props.scheduleExpression?.[2]}
                >
                  {Array.from(new Array(32).keys()).map(n => {
                    if (n === 0)
                      return (
                        <option value={`*`} key={"*"}>
                          Every Day
                        </option>
                      );
                    return (
                      <option value={n} key={n}>
                        {n}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                Month:
                <br />
                <select
                  name="month"
                  onChange={this.generateCronExpression}
                  value={this.props.scheduleExpression?.[3]}
                >
                  {Array.from(new Array(13).keys()).map(n => {
                    if (n === 0)
                      return (
                        <option value={`*`} key={"*"}>
                          Every Month
                        </option>
                      );
                    return (
                      <option value={n} key={n}>
                        {this.cronReader.monthNames[n]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                Day of week:
                <br />
                <select
                  name="week-day"
                  onChange={this.generateCronExpression}
                  value={this.props.scheduleExpression?.[4]}
                >
                  {Array.from(new Array(8).keys()).map(n => {
                    if (n === 0)
                      return (
                        <option value={`?`} key={"?"}>
                          Every Day
                        </option>
                      );
                    return (
                      <option value={n} key={n}>
                        {this.cronReader.dayNames[n]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                Year:
                <br />
                <select
                  name="year"
                  onChange={this.generateCronExpression}
                  value={this.props.scheduleExpression?.[5]}
                >
                  <option value={`*`}>All</option>
                  {range(
                    new Date().getFullYear(),
                    new Date().getFullYear() + 11,
                  ).map(n => {
                    return (
                      <option value={n} key={n}>
                        {n}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <br />
            Cron Expression:&nbsp;&nbsp;
            <input
              type="text"
              min={1}
              className="text-input expression-input"
              name="rate-time"
              defaultValue={this.props.scheduleExpression?.join(" ")}
              onChange={this.setScheduleExpression}
            />
            <br />
            <span>
              (Minutes Hours Day-of-month Month Day-of-week Year)
              <br />
              Example: "15 12 * * ? *" Run at 12:15 pm (UTC) every day
            </span>
            <br />
            <div className="d-flex">
              <div className="expression-text heading">Result:</div>
              <div className="expression-text exp">
                {this.state.expressionText}
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  }
}

export default Schedule;
