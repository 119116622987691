import React from "react";
import { OverflowMenu } from "@components/ui";

const tableColumns = (onEdit, onDelete) => {
  return [
    {
      name: "Module Name",
      selector: "name",
      grow: 1,
    },
    {
      name: "Target Account",
      selector: "alias",

      grow: 1,
    },
    {
      name: "Source (S3 Bucket/Git Repo)",
      selector: "source",
      grow: 2,
    },
    {
      name: "Root",
      selector: "root",
      grow: 1,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-grey" onMouseDown={() => onEdit(row)}>
            Edit
          </div>
          <div className="button-delete" onMouseDown={() => onDelete(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};

export default tableColumns;
