import React from "react";
import { Consumer } from "./tabs.context";

const TabContent = props => (
  <Consumer>
    {({ contentTop }) => (
      <div
        style={{ top: `${contentTop}px` }}
        className={`fade-in tab-content ${props.active ? "show" : "hidden"} ${
          props.className
        }`}
      >
        {props.children}
      </div>
    )}
  </Consumer>
);

export default TabContent;
