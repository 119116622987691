import React from "react";
import ExternalIcon from "@assets/images/icons/icon-external.svg";
import ReactTooltip from "react-tooltip";
import "./Header.scss";

type HeaderProps = {
  title: string;
  description: string;
  helpText: string;
  documentation?: any;
};
const Header: React.FC<HeaderProps> = ({
  title,
  description,
  helpText,
  documentation,
}) => {
  return (
    <div className="field-header">
      <div>
        <label className="editor-right-panel-field-label">{title}</label>
        {documentation?.enabled ? (
          <>
            <a
              href={documentation.href}
              target="_blank"
              rel="noopener noreferrer"
              data-for="documentation"
              data-tip
            >
              <img
                src={ExternalIcon}
                alt="external-link-icon"
                className="ext-link-icon"
              />
            </a>
            <ReactTooltip
              className="doc-tooltip"
              id="documentation"
              place="bottom"
              effect="solid"
              type="light"
            >
              Documentation
            </ReactTooltip>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="help-icon">
        <div className="help-tooltip">
          <span>{description || title}</span>
          {helpText && (
            <>
              {" "}
              <span className="example-title header">MORE INFO</span>
              <span className="mb-2-px">{helpText}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
