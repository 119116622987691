import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ModalTitle, ModalClose, ModalFooter } from "./";
import "./modal.scss";

class Modal extends React.Component {
  componentDidMount() {
    this.calcHeight();
    window.addEventListener("resize", this.calcHeight, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcHeight, false);
  }

  calcHeight = () => {
    try {
      const parent = document.querySelector(".modal-container");
      const container = document.querySelector(".modal-content-container");
      let max = window.innerHeight * 0.8;
      parent.style.maxHeight = `${max}px`;
      container.style.maxHeight = `${max - 50}px`;
    } catch (e) {}
  };

  closeModal = event => {
    if (
      event.target.id === "modal-cover" ||
      event.target.id === "modal-close"
    ) {
      document.querySelector("#portal").classList.remove("background-content");
      this.props.onClose();
    }
  };

  appendToPortal = () => {
    return ReactDOM.createPortal(
      this.contents(),
      document.getElementById("portal"),
    );
  };

  contents = () => {
    const {
      onCancel,
      onSubmit,
      submitButtonText,
      cancelButtonText,
      isDeleteModal,
      isEditor,
      disableSubmit,
      showClose,
    } = this.props;
    return (
      <div
        className={`modal-cover ${this.props.coverClass}`}
        id="modal-cover"
        onClick={event => {
          if (!this.props.backgroundCanClose) return;
          this.closeModal(event);
        }}
      >
        <div
          className={`modal-container modal-slide-in ${this.props.containerClass}`}
        >
          {this.props.title && (
            <ModalTitle
              customClass={`${this.props.titleClass}`}
              title={this.props.title}
            />
          )}
          {showClose && (
            <ModalClose
              customClass={`${this.props.closeClass}`}
              onClick={this.closeModal}
            />
          )}
          <div
            className={`modal-content-container ${this.props.contentContainerClass}`}
          >
            <div className={`modal-content ${this.props.contentClass}`}>
              {this.props.children}
            </div>
          </div>
          {this.props.showFooter &&
            (this.props.footer ? (
              <ModalFooter customClass={`${this.props.footerClass}`}>
                {this.props.footer}
              </ModalFooter>
            ) : isEditor ? (
              <ModalFooter customClass={`${this.props.footerClass}`}>
                <div
                  className={`modal-buttons-footer__editor ${
                    this.props.footerClass || ""
                  }`}
                >
                  <button
                    type={"button"}
                    className="footer-btn footer-btn-cancel footer-btn-cancel__editor"
                    onClick={onCancel}
                  >
                    {cancelButtonText}
                  </button>
                  <button
                    type="submit"
                    className={`footer-btn ${
                      isDeleteModal
                        ? "footer-btn__danger footer-btn__danger__editor"
                        : "footer-btn-save footer-btn-save__editor"
                    } ${disableSubmit ? "submit-disabled" : ""}`}
                    onClick={onSubmit}
                  >
                    {submitButtonText}
                  </button>
                </div>
              </ModalFooter>
            ) : (
              <ModalFooter customClass={`${this.props.footerClass}`}>
                <div className="modal-buttons-footer">
                  <button
                    type={"button"}
                    onClick={onCancel}
                    className={`btn-secondary`}
                  >
                    <div className="btn-inner-wrap">
                      <div>{cancelButtonText}</div>
                    </div>
                  </button>
                  <button
                    type={"submit"}
                    onClick={onSubmit}
                    className={`${
                      isDeleteModal ? "btn-warning" : "btn-primary"
                    } ${disableSubmit ? "submit-disabled" : ""}`}
                  >
                    <div className="btn-inner-wrap">
                      <div>{submitButtonText}</div>
                    </div>
                  </button>
                </div>
              </ModalFooter>
            ))}
        </div>
      </div>
    );
  };

  render() {
    if (this.props.appendToPortal) {
      return this.appendToPortal();
    } else {
      return this.contents();
    }
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  display: PropTypes.bool,
  backgroundCanClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showClose: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  style: PropTypes.object,
  appendToPortal: PropTypes.bool,
  containerClass: PropTypes.string,
  contentContainerClass: PropTypes.string,
  contentClass: PropTypes.string,
  coverClass: PropTypes.string,
  titleClass: PropTypes.string,
  closeClass: PropTypes.string,
  footerClass: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isEditor: PropTypes.bool.isRequired,
  isDeleteModal: PropTypes.bool,
  showFooter: PropTypes.bool,
  disableSubmit: PropTypes.bool,
};

Modal.defaultProps = {
  size: "medium",
  showClose: true,
  backgroundCanClose: false,
  style: {},
  appendToPortal: false,
  submitButtonText: "Submit",
  cancelButtonText: "Cancel",
  isEditor: false,
  showFooter: true,
  isDeleteModal: false,
  disableSubmit: false,
  onSubmit: () =>
    console.error("provide a submit and cancel handlers or footer component"),
  onCancel: () =>
    console.error("provide a submit and cancel handlers or footer component"),
};

export default Modal;
