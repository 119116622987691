import React, { useState, useEffect } from "react";
import Wait from "@components/ui/wait";
import { useSelector } from "react-redux";

const withSplashScreen = WrappedComponent => {
  return props => {
    const [isLoading, setIsLoading] = useState(true);
    const isSplashScreenDisabled = useSelector(
      state => state.APIKeysReducer.isSplashScreenDisabled,
    );

    useEffect(() => {
      /**
       * Hide Loader after 3 seconds pause
       */
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }, []);

    /**
     * Show Loader only for authentication pages
     * (if isSplashScreenDisabled is false)
     */
    if (isLoading && !isSplashScreenDisabled)
      return <Wait text={"Welcome to Fylamynt..."} />;
    return <WrappedComponent {...props} />;
  };
};

export default withSplashScreen;
