import React from "react";
import { removeFoundElement } from "@lib/utils";

export default class DropDownSearch extends React.Component {
  state = {
    searchText: "",
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.textRef = React.createRef();
  }
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState({
        searchText: this.props.searchText,
      });
    }
  }

  init() {
    removeFoundElement();
    this.setDivStyle();
    this.setFocus();
  }

  setDivStyle() {
    const child = this.ref.current;
    const parent = child.parentNode;
    const width = parent.getBoundingClientRect().width;
    child.style.width = width - 20 + "px";
    child.style.marginTop = "-35px";
    parent.style.marginTop = "35px";
  }

  onChange = e => {
    /* do nothing */
  };

  setFocus() {
    this.textRef.current.focus();
  }

  render() {
    return (
      <div className="dropdown-search" ref={this.ref}>
        <input
          type="text"
          name="dropdown-search"
          placeholder="Begin typing to search"
          value={this.state.searchText}
          ref={this.textRef}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
