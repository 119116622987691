import React from "react";

const CheckListItem = ({ title, description = "", url }) => {
  return (
    <div className="uo-checklist-item">
      <div className="uo-checklist-item-left">
        <div>
          <h3 className="uo-checklist-item-title">{title}</h3>
          <p className="uo-checklist-item-description">{description}</p>
        </div>
      </div>

      <div className="uo-checklist-item-right">
        <a
          className="uo-checklist-btn"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Review
        </a>
      </div>
    </div>
  );
};

const StarterCheckList = () => {
  const data = [
    {
      title: "Add an AWS Target Account",
      url:
        "https://docs.fylamynt.com/getting-started-1/1.-setting-up-your-first-cloud-service-target-account",
    },
    {
      title: "Getting to know workflows",
      url:
        "https://docs.fylamynt.com/getting-started-1/Getting-to-know-workflows",
    },
    {
      title: "Creating your first workflow using AWS Cloud Services",
      url:
        "https://docs.fylamynt.com/getting-started-1/3.-creating-your-first-workflow-using-aws-cloud-services",
    },
    {
      title: "Setting up your first Integration",
      url:
        "https://docs.fylamynt.com/getting-started-1/setting-up-your-first-integration",
    },
    {
      title: "Setting up your first Resource",
      url:
        "https://docs.fylamynt.com/getting-started-1/setting-up-your-first-resource",
    },
    {
      title: "Creating your first Incident Response workflow",
      url:
        "https://docs.fylamynt.com/getting-started-1/6.-creating-your-first-incident-response-workflow",
    },
    {
      title: "Incident Management - Automatic workflow execution",
      url:
        "https://docs.fylamynt.com/getting-started-1/7.-incident-management-automatic-workflow-execution",
    },
    {
      title: "Quickstart - Fylamynt sample workflows",
      url: "https://docs.fylamynt.com/getting-started-1/sample-workflows",
    },
  ];

  return (
    <div className="uo-wrapper">
      <div className="uo-checklist">
        <h3 className="uo-checklist-header">Starter Checklist</h3>
        <p className="uo-checklist-description">
          Set up your account to make full use of what Fylamynt has to offer
        </p>
        <div className="uo-checklist-wrapper">
          {data.map(item => (
            <CheckListItem key={item.title} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StarterCheckList;
