import Api from "@lib/api/api";
import {
  AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS,
  SHOW_CONFIRM,
  FETCHING_RUNBOOKS,
} from "@redux/types";
import { FTNotification } from "@components/ui";

export function fetchAWSTargetAccountInfo(alias) {
  return async dispatch => {
    dispatch(updateMessage("Fetching AWS Target Account Info ..."));
    const response = await Api.getAWSTargetAccountInfo(alias).catch(e =>
      FTNotification.error({
        title: "Could not fetch AWS Target Account Info",
      }),
    );
    if (response) {
      dispatch(updateMessage(""));
      dispatch({
        type: AWS_TARGET_ACCOUNTS_INFO_FETCH_SUCCESS,
        payload: response,
      });
    }
  };
}

export function updateMessage(message = "") {
  return async dispatch =>
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: !!message,
      message: message,
    });
}

export function toggleDeleteConfirmBox(showFlag) {
  return async dispatch => {
    dispatch({ type: SHOW_CONFIRM, payload: showFlag });
  };
}
