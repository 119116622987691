export const cleanData = data => {
  const keys = Object.keys(data);
  const output = [];
  keys.forEach(key => {
    const services = Object.keys(data[key]);
    services.forEach(item =>
      output.push({ ...data[key][item], service: item }),
    );
  });
  // Sort alphabetically based on service name
  output.sort((item1, item2) => item1?.service?.localeCompare(item2?.service, 'en', { sensitivity: 'base' }))
  return output;
};
