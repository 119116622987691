import * as Yup from "yup";

export const getValidationSchema = (mode, templateList) => {
  if (mode === "create") {
    return Yup.object({
      templateId: Yup.string()
        .required("Please enter Template Id")
        .notOneOf(
          templateList ? templateList.map(e => e.templateId) : [],
          "Template Id already exists",
        )
        .trim("Please enter Template Id"),
      repoId: Yup.string()
        .required("Please enter Repo Id")
        .trim("Please enter Repo Id"),
      ref: Yup.string().required("Please enter Ref").trim("Please enter Ref"),
      filePath: Yup.string()
        .required("Please enter File Path")
        .trim("Please enter File Path"),
    });
  } else {
    return Yup.object({
      repoId: Yup.string()
        .required("Please enter Repo Id")
        .trim("Please enter Repo Id"),
      ref: Yup.string().required("Please enter Ref").trim("Please enter Ref"),
      filePath: Yup.string()
        .required("Please enter File Path")
        .trim("Please enter File Path"),
    });
  }
};
