import Api from "@lib/api/api";
import {
  AWS_OPERATIONS_FETCHING,
  AWS_OPERATIONS_SET,
  AWS_OPERATION_DETAILS_SET,
  AWS_SERVICES_FETCHING,
  AWS_SERVICES_SET,
  AWS_INFO_ERROR,
  RUNBOOK_FETCHING_VERSION,
  RUNBOOK_SET_ACTIVE_VERSION,
  FETCH_RUNBOOK_VERSION_ERROR,
  FETCH_RUNBOOK_VERSION_SUCCESS,
  FETCH_RUNBOOK_DETAILS_ERROR,
  RUNBOOK_SET_ACTIVE,
  RUNBOOK_IS_FETCHING,
  RUNBOOK_NEW,
  RUNBOOK_IS_DELETING,
  RUNBOOK_SAVE_SUCCESS,
  RUNBOOK_SAVE_ERROR,
  REMOVE_RUNBOOK_FROM_LIST,
  UPDATE_RUNBOOK_DATA,
  RUNBOOK_DELETE_VERSION_SUCCESS,
  RESOURCES_SCANNED,
  SET_WAIT_MESSAGE,
  SHOW_WAIT,
  RUNBOOK_VERSION_UPDATE_SUCCESS,
  RUNBOOK_VERSION_UPDATE_FAILURE,
  RUNBOOK_IS_SAVING,
  RUNBOOK_DUPLICATE,
  RUNBOOK_SET_EXISTING,
  RUNBOOK_SCHEDULE,
  UPDATE_SNIPPET_FIELD_OPTIONS,
  RUNBOOK_FETCH_REGION_LIST,
} from "../types";
import { store } from "@redux/store";
import {
  getTrigger,
  cleanRunbooks,
  getConnectorList,
  getSnippetList,
} from "@lib/utils";
import { FTNotification } from "@components/ui";

export const fetchAwsServices = () => {
  return async dispatch => {
    _isFetchingAwsServices(dispatch, true);
    dispatch(setMessage(`Fetching list of AWS services`));
    const data = await Api.getAWSServices().catch(e => {
      dispatch(_fetchAwsInfoError(e));
    });

    if (data && data.ERROR) {
      dispatch(_fetchAwsInfoError(data.ERROR));
    } else if (!data) {
      dispatch(
        _fetchAwsInfoError("No data returned when fetching AWS Services"),
      );
    } else if (data) {
      const { services } = data;
      dispatch({ type: AWS_SERVICES_SET, payload: { services } });
      _isFetching(dispatch, false);
    }
  };
};

export const fetchAwsOperations = service => {
  return async dispatch => {
    try {
      _isFetchingAwsOperations(dispatch, true);
      dispatch(setMessage(`Fetching list of AWS operations`));
      const data = await Api.getAWSServiceOperations(service).catch(e => {
        dispatch(_fetchAwsInfoError(e));
      });

      if (data && data.ERROR) {
        dispatch(_fetchAwsInfoError(data.ERROR));
      } else {
        const { operations } = data;
        dispatch({
          type: AWS_OPERATIONS_SET,
          payload: { service, operations },
        });
        _isFetching(dispatch, false);
      }
    } catch (e) {
      console.log("Error with fetchAWS operations: " + e);
    }
  };
};
export const fetchAwsOperationDetails = (service, operation) => {
  return async dispatch => {
    _isFetchingAwsOperations(dispatch, true);
    dispatch(
      setMessage(`Fetching AWS operation details (${service}, ${operation})`),
    );
    const data = await Api.getAWSServiceOperationDetails(
      service,
      operation,
    ).catch(e => {
      dispatch(_fetchAwsInfoError(e));
    });
    if (data && data.ERROR) {
      dispatch(_fetchAwsInfoError(data.ERROR));
    } else {
      dispatch({
        type: AWS_OPERATION_DETAILS_SET,
        payload: { service, operation, data },
      });
      _isFetching(dispatch, false);
    }
  };
};
export const fetchActiveRunbook = (runbookId, version = null) => {
  return async dispatch => {
    dispatch({ type: FETCH_RUNBOOK_DETAILS_ERROR, payload: false });
    _isFetching(dispatch, true);
    const data = await Api.getRunbookContent(runbookId, version).catch(e => {
      dispatch(_fetchError(e));
    });
    if (data) {
      const runbook = Object.assign({ name: runbookId }, data);
      dispatch({ type: RUNBOOK_SET_ACTIVE, payload: runbook });
      if (!version) {
        version = _extractDefaultVersion(data);
      }
      _setActiveVersion(dispatch, version);
      dispatch(fetchRunbookSchedules(runbookId));
    } else {
      dispatch(createNewRunbook(runbookId));
    }
    _isFetching(dispatch, false);
  };
};

export function _fetchError(error) {
  //const message = `${error.response?.status} ${error.response?.data.message}`;
  return async dispatch => {
    dispatch({ type: FETCH_RUNBOOK_DETAILS_ERROR, payload: error });
    setTimeout(() => {
      _isFetching(dispatch, false);
    }, 1000);
  };
}

export function _fetchAwsInfoError(error) {
  const message = `ERROR: ${error} `;
  return async dispatch => {
    dispatch({ type: AWS_INFO_ERROR, payload: error });
    dispatch(setMessage(message));
    setTimeout(() => {
      _isFetchingAwsServices(dispatch, false);
      _isFetchingAwsOperations(dispatch, false);
    }, 1000);
  };
}

export function _saveError(error) {
  const message = `${error?.response?.status} ${
    error?.response?.data?.message || "Something went wrong!!!"
  }`;
  return async dispatch => {
    dispatch({ type: RUNBOOK_SAVE_ERROR, payload: error });
    dispatch(setMessage(message?.trim()));
  };
}
export const fetchActiveVersion = version => {
  return async dispatch => {
    _setActiveVersion(dispatch, version);
    _isFetchingVersion(dispatch, true);
    const data = await Api.getRunbookVersion(
      _getRunbookId(),
      version,
    ).catch(e => dispatch({ type: FETCH_RUNBOOK_VERSION_ERROR, payload: e }));
    _setVersionData(dispatch, data);
    _setActiveVersion(dispatch, version);
    _isFetchingVersion(dispatch, false);
  };
};

export function createDuplicateRunbook(runbookName, baseRunbookName) {
  return async dispatch => {
    dispatch(setMessage(`Creating duplicate workflow - ${runbookName}`, true));
    const data = await Api.createDuplicateRunbook(runbookName, baseRunbookName);
    if (data) {
      dispatch({
        type: RUNBOOK_DUPLICATE,
        payload: data.Name,
      });
    }
    dispatch(setMessage("", false));
  };
}

export const unsetDuplicateRunbook = () => {
  return async dispatch => {
    dispatch({ type: RUNBOOK_DUPLICATE, payload: false });
  };
};

export const unsetActiveRunbook = () => {
  return async dispatch => {
    _setActiveVersion(dispatch, null);
    _setVersionData(dispatch, null);
    dispatch({ type: RUNBOOK_SET_ACTIVE, payload: {} });
  };
};

export const onChangeVersionAction = value => {
  return async dispatch => {
    //console.log(value);
  };
};

export function setMessage(message = "", showMessage = true) {
  return async dispatch => {
    dispatch({
      type: SET_WAIT_MESSAGE,
      payload: message,
    });
    dispatch({
      type: SHOW_WAIT,
      payload: showMessage,
    });
  };
}

export function createNewRunbook(runbookName) {
  let runbook = _getBlankRunbook(runbookName);
  return async dispatch => {
    dispatch({
      type: RUNBOOK_SET_EXISTING,
      payload: runbookName,
    });

    dispatch({
      type: RUNBOOK_NEW,
      payload: runbookName,
    });
    dispatch({ type: RUNBOOK_SET_ACTIVE, payload: runbook });
    _setActiveVersion(dispatch, "Draft");
  };
}

export function clearNewRunbook(flag) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_SET_EXISTING,
      payload: flag,
    });
  };
}

export function setRunbookIsNew(isNew) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_NEW,
      payload: isNew,
    });
  };
}

export function updateRunbookData(key, value) {
  return async dispatch => {
    dispatch({
      type: UPDATE_RUNBOOK_DATA,
      payload: {
        key,
        value,
      },
    });
  };
}

/**
 * fetch runbook/info details and push results to runbookReducer.activeRunbook[key]
 * @param {runbook name} name
 * @param {optional version number} version
 * @param {key of the runbook data to be updated} key
 */
export function fetchRunbookInfoUpdates(name, version = null, key = null) {
  return async dispatch => {
    await Api.getRunbookDetails(name, version)
      .then(data => {
        // could key be optional ?
        // let me know if you have a suggestion
        if (key) {
          dispatch({
            type: UPDATE_RUNBOOK_DATA,
            payload: {
              key: key,
              value: data[key],
              DefaultVersion: data["DefaultVersion"],
              DocumentVersion: data["DocumentVersion"],
            },
          });
        }
      })
      .catch(e => {
        console.error(`workflow ${name} info fetch error`);
      });
  };
}

export function deleteRunbook(runbook, owner) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_IS_DELETING,
      payload: true,
      message: `Deleting workflow: ${runbook}`,
    });
    await Api.deleteRunbook(runbook);
    dispatch({
      type: REMOVE_RUNBOOK_FROM_LIST,
      owner: owner,
      payload: runbook,
    });
    dispatch({
      type: RUNBOOK_IS_DELETING,
      payload: false,
      message: "",
    });
  };
}

export function deleteRunbookVersion(name, version) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_IS_DELETING,
      payload: true,
      message: `Deleting version ${version} for workflow ${name}`,
    });
    await Api.deleteRunbook(name, version)
      .then(result => {
        dispatch(fetchRunbookInfoUpdates(name, null, "Versions"));
        dispatch({
          type: RUNBOOK_DELETE_VERSION_SUCCESS,
          payload: true,
          name: name,
          version: version,
        });
      })
      .catch(e => {
        dispatch({
          type: RUNBOOK_DELETE_VERSION_SUCCESS,
          payload: false,
          name: name,
          version: version,
        });
      });
    dispatch({
      type: RUNBOOK_IS_DELETING,
      payload: false,
      message: "",
    });
  };
}

export function updateDefaultVersion(name, version) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_FETCHING_VERSION,
      payload: true,
    });
    await Api.updateDefaultVersion(name, version)
      .then(result => {
        dispatch({ type: RUNBOOK_VERSION_UPDATE_SUCCESS, payload: true });
        dispatch(fetchRunbookInfoUpdates(name, null, "Versions"));
      })
      .catch(e => {
        // message error to the user
        dispatch({ type: RUNBOOK_VERSION_UPDATE_FAILURE, payload: true });
        console.error("could not set default version", e);
      });
    dispatch({
      type: RUNBOOK_FETCHING_VERSION,
      payload: false,
    });
  };
}

/**
 * Saves a new runbook
 * @param {string} name the name of the runbook
 * @param {string[]} tags an array of user supplied tags
 * @param {object} runbookObj NeurOpsRunbook object maintained editor
 * @param {object} connectors list of connectors
 */
export const saveNewRunbook = (runbookObj, connectors) => {
  return async dispatch => {
    try {
      const activeRunbook = store.getState().runbookReducer.activeRunbook;
      const name = activeRunbook.Name;
      const message = `Saving workflow ${name}`;
      const trigger = getTrigger(runbookObj);
      const snippetList = getSnippetList(runbookObj?.mainSteps);
      const connectorList = getConnectorList(runbookObj?.mainSteps, connectors);

      dispatch(setMessage(message, true));

      let data = await Api.saveNewRunbook(
        activeRunbook,
        runbookObj.toSSM(),
        connectorList,
        trigger,
        snippetList,
      );
      data = await Api.getRunbookContent(name, "1");
      const runbook = Object.assign({ name }, data);
      const version = data.DocumentVersion;

      dispatch({ type: RUNBOOK_SAVE_SUCCESS, payload: runbook });
      dispatch({ type: RUNBOOK_SET_ACTIVE, payload: runbook });
      dispatch({ type: RUNBOOK_NEW, payload: false });
      _setActiveVersion(dispatch, version);
      dispatch(runbookIsSaving(true));
      setTimeout(() => dispatch(runbookIsSaving(false)), 200);
      dispatch(setMessage("", false));
    } catch (e) {
      dispatch(_saveError(e));
    }
  };
};

export function runbookIsSaving(isSaving) {
  return async dispatch => {
    dispatch({
      type: RUNBOOK_IS_SAVING,
      payload: isSaving,
    });
  };
}

const customError = response => {
  const errObj = {
    response: {
      data: { message: "Duplicate content is provided" },
      status: 409,
    },
  };

  if (response.status === 409) {
    FTNotification.info({
      title: "Duplicate Content",
      message: `Duplicate content provided, no new version will be created. \
        Workflow schedule can still be updated`,
      timeout: 30,
    });
    return errObj;
  }
  if (response?.data?.error_message?.includes("must be the first step")) {
    FTNotification.info({
      title: "Invalid Workflow Content",
      message: response?.data?.error_message,
    });
    errObj.response = response;
    return errObj;
  }
};

export const updateRunbook = (runbookObj, connectors) => {
  runbookObj = cleanRunbooks(runbookObj);
  return async dispatch => {
    const activeRunbook = store.getState().runbookReducer.activeRunbook;
    const name = activeRunbook.Name;
    const message = `Updating ${name}`;
    const trigger = getTrigger(runbookObj);
    const snippetList = getSnippetList(runbookObj?.mainSteps);
    const connectorList = getConnectorList(runbookObj?.mainSteps, connectors);

    dispatch(setMessage(message));
    let updateData = null;
    try {
      updateData = await Api.updateRunbook(
        activeRunbook,
        runbookObj.toSSM(),
        connectorList,
        trigger,
        snippetList,
      );
    } catch (e) {
      let duplicateError = null;
      if (e.ERROR?.response) {
        duplicateError = customError(e.ERROR?.response);
      }
      const error = JSON.stringify(e.ERROR);
      const regEx = new RegExp(/\d{3}/);
      const match = error?.match(regEx);
      const status = match ? match[0] : "400";
      let errorObject = {
        response: {
          data: { message: "Error updating workflow" },
          status,
        },
      };
      console.log(e);
      dispatch(_saveError(duplicateError || errorObject));
    }

    if (updateData && updateData.ERROR) {
      dispatch(runbookIsSaving(false));
      dispatch(_saveError(updateData.ERROR));
    } else if (updateData) {
      let getUpdatedData = await Api.getRunbookContent(
        name,
        updateData.DocumentVersion,
      ).catch(e => {
        dispatch(_fetchError(e));
      });
      const runbook = Object.assign({ name }, getUpdatedData);
      const version = updateData.DocumentVersion;
      dispatch({ type: RUNBOOK_SAVE_SUCCESS, payload: runbook });
      dispatch({ type: RUNBOOK_SET_ACTIVE, payload: runbook });
      _setActiveVersion(dispatch, version);
      dispatch(runbookIsSaving(true));
      setTimeout(() => dispatch(runbookIsSaving(false)), 200);
    }
  };
};

/**
 Resources scanner
*/
export function getResourcesList() {
  return async dispatch => {
    let data = await Api.getResourcesList();
    dispatch({
      type: RESOURCES_SCANNED,
      payload: data,
    });
  };
}

/**
 fetch given runbook schedules list
 @param:  runbookId: Strings
 @return: list of schedules: Array
 */
export function fetchRunbookSchedules(runbookId) {
  return async dispatch => {
    const data = await Api.getSchedules(runbookId, null);
    dispatch({
      type: RUNBOOK_SCHEDULE,
      payload: data,
    });
    return data;
  };
}

/**
 * Function to save dynamic field values in reducer
 * @param {*} fieldOptions
 * @returns dispatch
 */
export function updateSnippetFieldOptions(fieldOptions) {
  return async dispatch => {
    dispatch({
      type: UPDATE_SNIPPET_FIELD_OPTIONS,
      payload: fieldOptions,
    });
  };
}

/**
 Fetch regions
 @param:  
 @return: list of regions: Array
 */
export function fetchRegionList(runbookId) {
  return async dispatch => {
    try {
      const data = await Api.fetchEc2Regions();
      dispatch({
        type: RUNBOOK_FETCH_REGION_LIST,
        payload: data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

/* internal methods */
function _isFetchingVersion(dispatch, fetching) {
  return dispatch({
    type: RUNBOOK_FETCHING_VERSION,
    payload: fetching,
  });
}

function _isFetching(dispatch, fetching) {
  return dispatch({
    type: RUNBOOK_IS_FETCHING,
    payload: fetching,
  });
}

function _isFetchingAwsServices(dispatch, fetching) {
  return dispatch({
    type: AWS_SERVICES_FETCHING,
    payload: fetching,
  });
}
function _isFetchingAwsOperations(dispatch, fetching) {
  return dispatch({
    type: AWS_OPERATIONS_FETCHING,
    payload: fetching,
  });
}

function _setActiveVersion(dispatch, version) {
  return dispatch({
    type: RUNBOOK_SET_ACTIVE_VERSION,
    payload: version,
  });
}

function _setVersionData(dispatch, data) {
  return dispatch({
    type: FETCH_RUNBOOK_VERSION_SUCCESS,
    payload: data,
  });
}

function _getRunbookId() {
  const state = store.getState().runbookReducer;
  return state.activeRunbook.Name;
}

function _getBlankRunbook(runbookName) {
  return {
    Content: {},
    CreatedDate: "",
    DefaultVersion: "Draft",
    Description: "",
    Name: runbookName,
    Parameters: [],
    Tags: [],
    Versions: [
      {
        DocumentVersion: "Draft",
        Name: runbookName,
      },
    ],
  };
}

function _extractDefaultVersion(data) {
  if (!data) {
    return;
  }
  let defaultVersion = data?.Versions.find(item => item.IsDefaultVersion);
  if (defaultVersion) {
    return defaultVersion.DocumentVersion;
  } else {
    console.log("ERROR: no default version set");
    return null;
  }
}
