import { ParameterType } from "./strings";
import { RunbookStepOutput } from "./nodeinputoutput";

export class AwsApiField {
  static readJSON(parent, name, json) {
    if (!json) {
      return null;
    }
    if (typeof json === "string") {
      return new SimpleAwsApiField(parent, name, json);
    } else if (typeof json === "object") {
      if (Array.isArray(json)) {
        const arrayField = new ArrayAwsApiField(parent, name, null);
        const memberField = AwsApiField.readJSON(arrayField, null, json[0]);
        arrayField.memberField = memberField;
        return arrayField;
      } else {
        const fields = {};
        const mapField = new MapAwsApiField(parent, name, fields);
        let fieldName = "";
        for (fieldName of Object.keys(json)) {
          fields[fieldName] = AwsApiField.readJSON(
            mapField,
            fieldName,
            json[fieldName],
          );
        }
        return mapField;
      }
    }
    return null;
  }

  constructor(parent, name) {
    this.parent = parent;
    this.name = name;
  }

  // default implementation for selector.  Needs to be changed for array types
  selector() {
    const prefix = (this.parent && this.parent.selector()) || "$";
    return this.name ? `${prefix}.${this.name}` : prefix;
  }

  ssmName() {
    const prefix = (this.parent && this.parent.ssmName() + "_") || "";
    return this.name || prefix;
  }

  ssmOutputs() {
    throw new Error("The function AwsApiField::ssmOutputs is not implemented");
  }
}

export class SimpleAwsApiField extends AwsApiField {
  static awsApiTypeToSsmType(awsApiType) {
    switch (awsApiType) {
      case SimpleAwsApiField.BOOLEAN:
        return ParameterType.Boolean;
      case SimpleAwsApiField.STRING:
        return ParameterType.String;
      case SimpleAwsApiField.INTEGER:
        return ParameterType.Integer;
      case SimpleAwsApiField.TIMESTAMP:
        return ParameterType.Integer;
      case SimpleAwsApiField.blob:
        return ParameterType.String;
      case SimpleAwsApiField.LONG:
        return ParameterType.Integer;
      case SimpleAwsApiField.DOUBLE:
        return ParameterType.Decimal
      default:
        return ParameterType.Boolean;
      // throw new Error(`Unrecognized AWS API type: ${awsApiType}`);
    }
  }

  static BOOLEAN = "boolean";
  static STRING = "string";
  static INTEGER = "integer";
  static TIMESTAMP = "timestamp";
  static LONG = "long";
  static DOUBLE = "double";

  constructor(parent, name, typeName) {
    super(parent, name);
    this.typeName = typeName;
  }

  _ssmOutput(ssmStep) {
    return new RunbookStepOutput(
      ssmStep,
      this.ssmName(),
      SimpleAwsApiField.awsApiTypeToSsmType(this.typeName),
      this.selector(),
    );
  }
  ssmOutputs(ssmStep) {
    return [this._ssmOutput(ssmStep)];
  }
}

export class ArrayAwsApiField extends AwsApiField {
  constructor(parent, name, memberField) {
    super(parent, name);
    this.memberField = memberField;
  }

  selector() {
    return `${super.selector()}[0]`;
  }

  ssmName() {
    return `${super.ssmName()}_0`;
  }

  ssmOutputs(ssmStep) {
    return this.memberField.ssmOutputs(ssmStep);
  }
}

export class MapAwsApiField extends AwsApiField {
  constructor(parent, name, map) {
    super(parent, name);
    this.map = map;
  }

  ssmOutputs(ssmStep) {
    return Object.keys(this.map)
      .map(field => this.map[field].ssmOutputs(ssmStep))
      .flat();
  }
}
