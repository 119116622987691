import produce from "immer";
import {
  FETCH_SNIPPET_IDS_SUCCESS,
  FETCH_SNIPPET_DETAILS_SUCCESS,
  FETCH_SNIPPET_DETAILS_ERROR,
  FETCH_SNIPPETS_SUCCESS,
  FETCH_SNIPPETS_ERROR,
  IS_FETCHING_SNIPPETS,
  SET_FETCHING_MESSAGE,
  CATEGORIZE_SNIPPETS,
  SEARCH_SNIPPETS,
  RESET_FILTERS,
  FILTER_BY_SERVICE,
  IS_FETCHING_SLACK_CHANNEL_LIST,
  FETCH_SLACK_CHANNELS_SUCCESS,
  FETCH_SLACK_CHANNELS_ERROR,
  FETCH_JIRA_DETAILS_ERROR,
  FETCH_JIRA_DETAILS_SUCCESS,
  FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS,
  FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS,
  FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS,
  CLEAR_SELECTED_ISSUE,
  IS_FETCHING_JIRA_DETAILS,
  FETCH_TERRAFORM_WORKSPACE,
  FETCHING_TERRAFORM_VARIABLES,
  FETCH_TERRAFORM_VARIABLES_SUCCESS,
  FETCHING_INSTANA_ENTITY_TYPES,
  FETCH_INSTANA_ENTITY_TYPES_SUCCESS,
  FETCHING_INSTANA_ENTITY_NAMES,
  FETCH_INSTANA_ENTITY_NAMES_SUCCESS,
  FETCH_DD_WH_PAYLOAD_SUCCESS,
  IS_FETCHING_SENDER_NUMBERS,
  FETCH_SENDER_NUMBERS_SUCCESS,
  FETCH_SENDER_NUMBERS_ERROR,
  FETCH_CW_TARGETS,
} from "@redux/types";

import { categorizeSnippets, getSnippetDisplayNameMap } from "@lib/utils";
import flatten from "lodash/flatten";

const INITIAL_STATE = {
  snippetIdsList: null,
  snippetsList: [],
  searchTerm: "",
  filteredServicesSet: new Set(),
  snippetDetailsList: null,
  snippetsByCategory: null,
  isFetchingSnippets: false,
  fetchingMessage: "",
  isFetchingSlackChannels: false,
  slackChannelList: [],
  isFetchingJiraChannels: false,
  jiraDetails: {},
  isFetchingJiraIssueList: false,
  fetchJiraIssueListComplete: false,
  terraformWorkspaces: [],
  isFetchingTerraformVariables: false,
  terraformVariables: null,
  isFetchingInstanaEntityTypes: false,
  instanaEntityTypes: null,
  isFetchingInstanaEntityNames: false,
  instanaEntityNames: null,
  datadogWebhookPayloadData: {},
  senderNumbers: [],
  isFetchingSenderNumbers: false,
  cloudwatchConfiguredTargetAccounts: [],
};

const snippetsReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case IS_FETCHING_SNIPPETS:
        draft.isFetchingSnippets = action.payload;
        draft.fetchingMessage = "Fetching Actions...";
        break;

      case SET_FETCHING_MESSAGE:
        draft.fetchingMessage = action.payload;
        break;

      case FETCH_SNIPPET_IDS_SUCCESS:
        draft.snippetIdsList = action.payload;
        draft.snippetDisplayNameMap = getSnippetDisplayNameMap(action.payload);
        break;

      case FETCH_SNIPPET_DETAILS_SUCCESS:
        draft.snippetDetailsList = action.payload;
        break;

      case FETCH_SNIPPET_DETAILS_ERROR:
        console.log("Could not fetch action details: ", action.payload);
        break;

      case CATEGORIZE_SNIPPETS:
        draft.snippetsByCategory = categorizeSnippets(action.payload);
        break;

      case FETCH_SNIPPETS_SUCCESS:
        draft.snippetsList = action.payload;
        draft.isFetchingSnippets = false;
        break;

      case FETCH_SNIPPETS_ERROR:
        draft.isFetchingSnippets = false;
        break;

      case FETCH_SLACK_CHANNELS_ERROR:
        draft.isFetchingSlackChannels = false;
        break;

      case FETCH_SLACK_CHANNELS_SUCCESS:
        draft.slackChannelList = action.payload;
        draft.isFetchingSlackChannels = false;
        break;

      case FETCH_JIRA_DETAILS_ERROR:
        draft.isFetchingJiraChannels = false;
        break;

      case FETCH_JIRA_DETAILS_SUCCESS:
        draft.jiraDetails.projects = action.payload.projects;
        draft.isFetchingJiraChannels = false;
        break;

      case FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS:
        draft.jiraDetails.issues = action.payload;
        draft.isFetchingJiraIssueList = false;
        draft.fetchJiraIssueListComplete = true;
        break;

      case FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS:
        let data = action.payload;
        let transitions = data.transitions;
        delete data.transitions;
        draft.jiraDetails.selectedIssue = data;
        draft.jiraDetails.transitions = flatten(
          transitions.map(transition => Object.values(transition)),
        );
        break;

      case CLEAR_SELECTED_ISSUE:
        draft.jiraDetails.selectedIssue = {};
        break;

      case FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS:
        draft.jiraDetails.issueTypes = action.payload.issueTypes;
        draft.jiraDetails.priorities = action.payload.priorities;
        draft.jiraDetails.reporters = action.payload.reporters;

        break;

      case SEARCH_SNIPPETS:
        draft.searchTerm = action.payload;
        break;

      case FILTER_BY_SERVICE:
        draft.filteredServicesSet = action.payload;
        break;

      case RESET_FILTERS:
        draft.searchTerm = "";
        draft.filteredSnippets = [];
        draft.filteredServicesSet = new Set();
        break;

      case IS_FETCHING_SLACK_CHANNEL_LIST:
        draft.isFetchingSlackChannels = action.payload;
        break;

      case IS_FETCHING_JIRA_DETAILS:
        draft.isFetchingJiraIssueList = action.payload;
        draft.fetchJiraIssueListComplete = !action.payload;
        break;

      case FETCH_TERRAFORM_WORKSPACE:
        draft.terraformWorkspaces = action.payload;
        break;

      case FETCHING_TERRAFORM_VARIABLES:
        draft.isFetchingTerraformVariables = action.payload;
        break;

      case FETCH_TERRAFORM_VARIABLES_SUCCESS:
        draft.terraformVariables = action.payload;
        break;

      case FETCHING_INSTANA_ENTITY_TYPES:
        draft.isFetchingInstanaEntityTypes = action.payload;
        break;
      case FETCH_INSTANA_ENTITY_TYPES_SUCCESS:
        draft.instanaEntityTypes = action.payload;
        break;
      case FETCHING_INSTANA_ENTITY_NAMES:
        draft.isFetchingInstanaEntityNames = action.payload;
        break;
      case FETCH_INSTANA_ENTITY_NAMES_SUCCESS:
        draft.instanaEntityNames = action.payload;
        break;
      case FETCH_DD_WH_PAYLOAD_SUCCESS:
        draft.datadogWebhookPayloadData = action.payload;
        break;

      case FETCH_SENDER_NUMBERS_SUCCESS:
        draft.senderNumbers = action.payload;
        draft.isFetchingSenderNumbers = false;
        break;

      case IS_FETCHING_SENDER_NUMBERS:
        draft.isFetchingSenderNumbers = action.payload;
        break;

      case FETCH_SENDER_NUMBERS_ERROR:
        draft.isFetchingSenderNumbers = action.payload;
        break;

      case FETCH_CW_TARGETS:
        draft.cloudwatchConfiguredTargetAccounts = action.payload;
        break;

      default:
        break;
    }
  });

export default snippetsReducer;
