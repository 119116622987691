import React from "react";
import DropDownIcon from "./drop-down-icon";
import IconLeft from "./icon-left";
import IconRight from "./icon-right";

export default class Toggle extends React.Component {
  render() {
    return (
      <div
        className={`${this.props.toggleStyle.toggleClass} ${this.props.className}`}
        tabIndex="0"
        aria-controls="w-dropdown-toggle-0"
        aria-haspopup="menu"
        style={{
          outline: "none",
          backgroundColor: this.props.backgroundColor,
        }}
        onClick={this.props.clickHandler}
        ref={this.ref}
      >
        <DropDownIcon
          iconImage={this.props.iconImage}
          toggleId={this.props.toggleId}
          toggle={this.props.clickHandler}
          toggleStyle={this.props.toggleStyle}
          showArrow={this.props.showArrow}
          tooltip={this.props.tooltip}
          tooltipPosition={this.props.tooltipPosition}
        />
        <div id={this.props.toggleTitleId} className="dropdown-main">
          <IconLeft
            iconLeft={this.props.iconLeft}
            toggle={this.props.clickHandler}
          />
          <div>{this.props.title}</div>
          <IconRight
            iconRight={this.props.iconRight}
            toggle={this.props.clickHandler}
          />
        </div>
      </div>
    );
  }
}
