import React from "react";
import { Consumer } from "../execution-details-lib/execution-details.context";
import Step from "./step";

export default class Steps extends React.Component {
  render() {
    return (
      <Consumer>
        {({ executionDetails }) => (
          <React.Fragment>
            {executionDetails && (
              <div className="mt-40-px">
                <div className="stepper-line" />
                {executionDetails.StepExecutions.map(step => {
                  if (
                    executionDetails?.AutomationExecutionStatus ===
                      "Cancelled" &&
                    step.StepStatus === "InProgress"
                  ) {
                    return { ...step, StepStatus: "Cancelled" };
                  }
                  return { ...step };
                }).map((step, index) => {
                  return (
                    <div key={`step-${index}`}>
                      <Step step={step} stepNumber={index + 1} />
                    </div>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
