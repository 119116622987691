import produce from "immer";
import {
  SSH_TARGETS_FETCH_SUCCESS,
  SSH_TARGETS_SAVE_SUCCESS,
  SSH_TARGETS_UPDATE_SUCCESS,
  SSH_TARGETS_DELETE_SUCCESS,
} from "../types";

const INITIAL_STATE = {
  sshTargets: [],
};

const SSHTargetReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SSH_TARGETS_FETCH_SUCCESS:
        draft.sshTargets = action.payload;
        break;

      case SSH_TARGETS_SAVE_SUCCESS:
        draft.sshTargets = [...state.sshTargets, action.payload];
        break;

      case SSH_TARGETS_UPDATE_SUCCESS:
        const repoList = state.sshTargets.filter(
          val => val?.targetName !== action.payload?.targetName,
        );
        repoList.push(action.payload);
        draft.sshTargets = repoList;
        break;

      case SSH_TARGETS_DELETE_SUCCESS:
        draft.sshTargets = state.sshTargets.filter(
          val => val?.targetName !== action.payload?.targetName,
        );
        break;

      default:
        break;
    }
  });

export default SSHTargetReducer;
