import React, { useState, useEffect } from "react";
import Select from "react-select/async";
import { components } from "react-select";
import { getSelectStyle, formatOptionLabel } from "./helper";
import arrowIcon from "@assets/images/icons/select-carrot.svg";
import closeIcon from "@assets/images/icons/icon-close.svg";
import IconRefresh from "@assets/images/icons/icon-refresh-blue.svg";
import Fuse from "fuse.js";
import debounce from "debounce-promise";

const fuzzyOptions = {
  keys: ["label"],
  includeScore: true,
  maxResults: 50,
  useExtendedSearch: true,
};

type ReactSelectProps = {
  id: string; // ID for Select Component
  name?: string; // Name of Select component
  value: any; // Newly Selected Value
  defaultValue?: any; // Default Value
  handleChange: (d) => void; // Callback function called on Change of option
  selectOptions: Array<any>; // List of Options
  isDisabled?: boolean; // Flag to disable dropdown
  isMulti?: boolean; // Flag to enable multiple select
  isLoading?: boolean; // Flag to show Loading message while options are loading
  required?: boolean; // Flag to notify if Option Selection is mandatory
  icon?: string;
  className?: string; // Custom Class for whole React Select Container
  customStyle?: object; // Inline styles passed from prop
  customControlClass?: string; // Custom class Select Control Container
  customValueContainerClass?: string; //Custom class for Value container in dropdown header
  customSingleValueClass?: string; // Custom class for label in Value container
  customMenuClass?: string; // Custom class for Select options list container
  customMenuListClass?: string; // Custom class for Select options container
  customOptionClass?: string; // Custom class for select options
  isSearchable?: boolean; // Flag to make Select Component Searchable
  isClearable?: boolean; // Flag to clear the selected value
  showPriorityError?: any;
  isDynamic?: boolean;
  fetchingData?: boolean;
  updateOptionsData?: (d) => void;
  api?: object;
  handleOptionHover?: (e, b) => void;
};

const ReactSelect: React.FC<ReactSelectProps> = React.memo(
  ({
    id = "",
    name = "",
    value = undefined,
    handleChange,
    selectOptions,
    isDisabled = false,
    isMulti = false,
    className = "",
    isLoading = false,
    required = false,
    icon = "",
    customStyle = {},
    customControlClass = "",
    customOptionClass = "",
    customValueContainerClass = "",
    customSingleValueClass = "",
    customMenuClass = "",
    customMenuListClass = "",
    isSearchable = true,
    showPriorityError,
    isClearable = true,
    isDynamic = false,
    fetchingData = false,
    updateOptionsData,
    api = {},
    handleOptionHover,
  }) => {
    const selectStyles = getSelectStyle(customStyle);
    const [dropdownOptions, setDropdownOptions] = useState(selectOptions);
    const [fuse, setFuse] = useState(null);

    const searchOptions = inputValue =>
      new Promise(resolve => {
        resolve(fuse.search(inputValue).map(c => ({ ...c.item })));
      });

    const loadOptions = inputValue => searchOptions(inputValue);

    const debouncedLoadOptions = debounce(loadOptions, 300);

    useEffect(() => {
      setDropdownOptions(selectOptions);
    }, [selectOptions]);

    useEffect(() => {
      setFuse(new Fuse(dropdownOptions, fuzzyOptions));
      return () => setFuse(null);
    }, [dropdownOptions]);

    useEffect(() => {
      if (dropdownOptions && fuse) {
        fuse.setCollection(dropdownOptions);
      }
    }, [fuse, dropdownOptions]);

    const CustomControl = ({ children, innerRef, innerProps }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={`rs-control ${customControlClass} ${
          showPriorityError !== null
            ? showPriorityError && "required-unfulfilled"
            : required && !value?.value
            ? "required-unfulfilled"
            : ""
        }`}
      >
        <>
          {icon && (
            <img
              src={require(`@assets/images/icons/${icon}`)}
              alt="arrowIcon"
              className={`rs-dropdown-icon ml-10-px`}
              width="16"
              height="16"
            />
          )}
          {children}
        </>
      </div>
    );

    const CustomValueContainer = ({ children, innerRef, innerProps }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={`rs-value-container ${customValueContainerClass}`}
      >
        {children}
      </div>
    );

    const CustomSingleValue = ({ children, innerRef, innerProps }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={`rs-value-single ${customSingleValueClass}`}
      >
        {children}
      </div>
    );

    const DropdownIndicator = props => (
      <components.DropdownIndicator {...props}>
        <img
          src={arrowIcon}
          alt="arrowIcon"
          className={`pointer rs-dropdown-icon ml-5-px ${
            props.selectProps.menuIsOpen
              ? "rs-dropdown-icon-expanded"
              : "rs-dropdown-icon-collapsed"
          }`}
          width="12"
          height="12"
        />
      </components.DropdownIndicator>
    );
    const ClearIndicator = props => (
      <components.ClearIndicator {...props}>
        <img
          src={closeIcon}
          alt="closeIcon"
          className={`pointer rs-dropdown-icon ml-5-px`}
          width="12"
          height="12"
        />
      </components.ClearIndicator>
    );

    const CustomMenu = ({ children, innerProps, innerRef, selectProps }) => {
      return (
        <div
          ref={innerRef}
          {...innerProps}
          onMouseLeave={e => {
            if (typeof handleOptionHover === "function") {
              handleOptionHover("", false);
            }
          }}
          className={`rs-menu ${customMenuClass}`}
        >
          {selectProps.fetchingData ? (
            <span className="rs-option">Fetching data...</span>
          ) : (
            <div>{children}</div>
          )}
          {isDynamic && (
            <div
              className="rs-option rs-option-static"
              onClick={async () => {
                const res = await selectProps.updateOptionsData({ api, name });
                setDropdownOptions(res);
              }}
            >
              <img
                src={IconRefresh}
                alt="IconRefresh"
                width="14"
                height="14"
                className="mr-10-px"
              />
              <span>Refresh Results</span>
            </div>
          )}
        </div>
      );
    };

    const CustomMenuList = ({ children, innerProps, innerRef }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={`rs-menu-list ${customMenuListClass}`}
      >
        {children}
      </div>
    );

    const CustomOption = ({ innerRef, innerProps, isSelected, children }) => (
      <div
        ref={innerRef}
        {...innerProps}
        onMouseEnter={e => {
          if (typeof handleOptionHover === "function") {
            const input = e.target as HTMLElement;
            handleOptionHover(input.innerText, true);
          }
        }}
        className={`rs-option ${
          isSelected ? "rs-option-selected" : ""
        } ${customOptionClass}`}
      >
        {children}
      </div>
    );

    return (
      <Select
        id={id || ""}
        components={{
          ValueContainer: CustomValueContainer,
          Control: CustomControl,
          DropdownIndicator,
          ClearIndicator,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          Menu: CustomMenu,
          MenuList: CustomMenuList,
        }}
        styles={selectStyles}
        className={`rs ${className}`}
        name={name}
        value={value?.value ? value : null}
        onChange={handleChange}
        defaultOptions={dropdownOptions}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isLoading={isLoading}
        formatOptionLabel={formatOptionLabel}
        classNamePrefix="rs"
        placeholder="Select from below"
        isSearchable={isSearchable}
        isClearable={isClearable}
        backspaceRemovesValue={false}
        fetchingData={fetchingData}
        updateOptionsData={updateOptionsData}
        loadOptions={value => debouncedLoadOptions(value)}
      />
    );
  },
  areEqual,
);

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default ReactSelect;
