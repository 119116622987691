import React from "react";
import JSONPretty from "react-json-pretty";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
require("react-json-pretty/themes/monikai.css");
require("./json-view.scss");

export default class JsonView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.showCopy && (
          <CopyToClipboard
            text={
              typeof this.props?.data === "string"
                ? this.props.data
                : JSON.stringify(this.props.data)
            }
            onCopy={e => {
              this.setState({ message: "Copied!" });
              setTimeout(() => {
                this.setState({ message: null });
              }, 1500);
            }}
          >
            <div className={`copy-outerwrap ${this.props.jsonIconClass}`}>
              {this.state.message && (
                <div className="message fade-slow">{this.state.message}</div>
              )}

              <ReactTooltip
                id={this.props.id || "json-copy-tootltip"}
                place={
                  this.props.tooltipPosition
                    ? this.props.tooltipPosition
                    : "bottom"
                }
                effect="solid"
                type="light"
                className={`json-icon-tooltip-container`}
              />
              <div
                data-for={this.props.id || "json-copy-tootltip"}
                data-tip={`${this.props?.title || "Copy JSON"}`}
                className={this.props.showIconWrapper ? "icon-copy-wrapper":""}
              >
                <div className={`${this.props.iconCopyClass} icon-copy`} />
              </div>
            </div>
          </CopyToClipboard>
        )}

        <JSONPretty
          id="json-pretty"
          className={`json-pretty ${this.props.jsonViewClass}`}
          data={this.props.data}
          theme={{
            main:
              "padding:1em",
            error:
              "padding:1em",
          }}
        ></JSONPretty>
      </div>
    );
  }
}
