import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { getValidationSchema } from "./utils/ValidationSchema";
import { Modal, FTNotification } from "@components/ui";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { isEmpty } from "@lib/utils";
import Api from "@lib/api";
import { mapResponse } from "@containers/SettingsPanel/GIT/utils/helper";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import Bugsnag from "@bugsnag/js";
import "./ManageTerraformCLI.scss";
import {
  addTerraformModule,
  updateTerraformModule,
} from "@redux/actions/terraformCLI.actions";

type ManageTerraformCLIProps = {
  selectedTerraformModule: any;
  editMode: string;
  closeForm: (flag: boolean) => void;
  terraformCLIList: any;
};

const ManageTerraformCLI: React.FC<ManageTerraformCLIProps> = ({
  selectedTerraformModule,
  editMode,
  closeForm,
  terraformCLIList,
}) => {
  // State
  const isMounted = useRef(true);
  const [alias, setAlias] = useState(
    selectedTerraformModule.alias ? selectedTerraformModule.alias : "",
  );
  const [gitRepoList, setGitRepoList] = useState([]);
  const [isGitRepoLoading, setIsGitRepoLoading] = useState(false);
  const [isGitRepoError, setIsGitRepoError] = useState(false);
  const [s3BucketList, setS3BucketList] = useState([]);
  const [isS3BucketLoading, setIsS3BucketLoading] = useState(false);
  const [isS3BucketError, setIsS3BucketError] = useState(false);

  const optionList = [
    { value: "git_repo", label: "Git Repo" },
    { value: "s3_bucket", label: "S3 Bucket" },
  ];
  const validationSchema = getValidationSchema(editMode, terraformCLIList);

  // Redux
  const aliasList = useSelector(
    (state: RootStateOrAny) => state.getStartedReducer.userDefaultAccounts,
  );

  const dispatch = useDispatch();

  // Functions

  const getGitRepo = useRef(() => {});
  getGitRepo.current = async () => {
    try {
      setIsGitRepoLoading(true);
      setIsGitRepoError(false);
      let parsedData = [];
      const response = await Api.getGitRepoList();
      if (!isEmpty(response)) {
        const body =
          typeof response?.body === "string"
            ? JSON.parse(response?.body)
            : response?.body;
        const repos =
          typeof body === "string" ? JSON.parse(body).repos : body?.repos;

        if (!isEmpty(repos)) {
          parsedData = mapResponse(repos);
        }
      }
      if (isMounted.current) {
        setIsGitRepoLoading(false);
        setGitRepoList([...parsedData]);
        if (!parsedData.length) {
          setIsGitRepoError(true);
        }
      }
    } catch (error) {
      setIsGitRepoLoading(false);
      setIsGitRepoError(true);
      Bugsnag.notify(new Error(error?.message));
    }
  };

  const getS3Bucket = useRef(() => {});
  getS3Bucket.current = async () => {
    if (!alias) {
      return;
    }
    try {
      setIsS3BucketLoading(true);
      setIsS3BucketError(false);
      const data = await Api.fetchSelectedS3BucketList(alias);
      const parsedData = JSON.parse(data.body);
      if (isMounted.current) {
        setIsS3BucketLoading(false);
        setS3BucketList([...parsedData]);
        if (!parsedData.length) {
          setIsS3BucketError(true);
        }
      }
    } catch (error) {
      setIsS3BucketLoading(false);
      setIsS3BucketError(true);
      Bugsnag.notify(new Error(error?.message));
    }
  };

  const onSubmitHandler = async (formik, actions) => {
    let message =
      editMode === "create"
        ? "Adding Terraform Module"
        : "Updating Terraform Module";
    try {
      dispatch(isFetching(true, message));
      let payload = {
        name: formik.name,
        alias: formik.alias,
        source: formik.source,
        root: formik.root,
      };
      await Api.editTerraformCLIModule(formik.name, formik.alias, payload);
      dispatch(isFetching(false, ""));
      if (editMode === "create") {
        dispatch(addTerraformModule(payload));
        FTNotification.success({
          title: "Terraform Module Added Successfully!",
        });
      }
      if (editMode === "edit") {
        dispatch(updateTerraformModule(payload));
        FTNotification.success({
          title: "Terraform Module Updated Successfully!",
        });
      }
    } catch (error) {
      dispatch(isFetching(false, ""));
      if (editMode === "create") {
        FTNotification.error({
          title: "Could not add Terraform Module",
          message: error.message,
        });
      }
      if (editMode === "edit") {
        FTNotification.error({
          title: "Could not update Terraform Module",
          message: error.message,
        });
      }
    }
    closeForm(false);
  };

  const setFieldValue = (key, value, setFieldValue) => {
    setFieldValue(key, value);
  };

  const getOptionLabel = selectedOption =>
    optionList.filter(i => i.value === selectedOption)[0].label;

  const getContent = formik => {
    const { values, touched } = formik;
    let input = values?.selectedOption;
    if (!input) return;

    if (input === "git_repo") {
      return (
        <div className="terraform-input">
          {isGitRepoLoading ? (
            <label className="terraform-input-label loading-text mt-2">
              <i>Loading Git Repos...</i>
            </label>
          ) : (
            <>
              <label className="terraform-input-label mt-2">
                Select Git Repo
              </label>
              <ReactSelect
                id="source-git"
                name="source-git"
                value={{
                  value: formik.values.source,
                  label: formik.values.source
                    ? formik.values.source
                    : "Select from below",
                }}
                handleChange={data => {
                  if(data === null){
                    setFieldValue("source", "", formik.setFieldValue)
                  } else if (!isEmpty(data) && !!data.value) {
                    setFieldValue("source", data.value, formik.setFieldValue);
                  }
                }}
                selectOptions={gitRepoList.map(repo => {
                  return {
                    value: repo.name,
                    label: repo.name,
                  };
                })}
                customMenuClass="default-select-options-container"
                customMenuListClass="default-select-options-list"
                customValueContainerClass="default-select-value-container"
                customControlClass="default-select-control"
                customOptionClass="default-select-list-item"
                required
              />
            </>
          )}
          {isGitRepoError && touched["source"] && (
            <div className="input-feedback pt-5-px">
              Please set up and select a Git Repo {"  "}
              <Link className="link-light-blue" to="/settings/git-settings">
                Here
              </Link>
            </div>
          )}
        </div>
      );
    }

    if (input === "s3_bucket") {
      if (!alias) {
        return (
          <div className="terraform-input">
            <label className="terraform-input-label loading-text mt-2">
              Please select alias first!
            </label>
          </div>
        );
      }
      return (
        <div className="terraform-input">
          {isS3BucketLoading ? (
            <label className="terraform-input-label loading-text mt-2">
              <i>Loading S3 Buckets...</i>
            </label>
          ) : (
            <>
              <label className="terraform-input-label mt-2">
                Select S3 Bucket
              </label>
              <ReactSelect
                id="source-s3"
                name="source-s3"
                value={{
                  value: formik.values.source,
                  label: formik.values.source
                    ? formik.values.source
                    : "Select from below",
                }}
                handleChange={data => {
                  if(data === null){
                    setFieldValue("source", "", formik.setFieldValue)
                  } else if (!isEmpty(data) && !!data.value) {
                    setFieldValue("source", data.value, formik.setFieldValue);
                  }
                }}
                selectOptions={s3BucketList.map(bucket => {
                  return {
                    value: bucket.Name,
                    label: bucket.Name,
                  };
                })}
                customMenuClass="default-select-options-container"
                customMenuListClass="default-select-options-list"
                customValueContainerClass="default-select-value-container"
                customControlClass="default-select-control"
                customOptionClass="default-select-list-item"
                required
              />
            </>
          )}
          {isS3BucketError && touched["source"] && (
            <div className="input-feedback pt-5-px">
              Please set up and select a S3 Bucket {"  "}
              <Link className="link-light-blue" to="/settings/s3-buckets">
                Here
              </Link>
            </div>
          )}
        </div>
      );
    }
  };

  // useEffect

  useEffect(() => {
    getGitRepo.current();
    getS3Bucket.current();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Refresh S3 buckets
    getS3Bucket.current();
  }, [alias]);

  return (
    <>
      <Formik
        initialValues={selectedTerraformModule}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          const { touched, errors, handleChange, handleSubmit } = formik;

          return (
            <Modal
              onClose={() => closeForm(false)}
              title={
                editMode === "create"
                  ? "Add Terraform Module"
                  : "Update Terraform Module"
              }
              onCancel={() => {
                closeForm(false);
              }}
              onSubmit={handleSubmit}
              submitButtonText={
                editMode === "create"
                  ? "Add Terraform Module"
                  : "Update Terraform Module"
              }
              showClose={true}
              containerClass={`w-50`}
              contentClass={`terraform-modal-content`}
              contentContainerClass={`overflow-y`}
            >
              <div className="terraform-input">
                <FormInput
                  fieldName="Module Name"
                  name={"name"}
                  id="name"
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  readOnly={editMode === "create" ? false : true}
                  autoComplete="off"
                />
              </div>
              <div className="terraform-input">
                <label className="terraform-input-label">
                  Select Target Account
                </label>
                <ReactSelect
                  id="alias"
                  name="alias"
                  value={{
                    value: formik.values.alias,
                    label: formik.values.alias
                      ? formik.values.alias
                      : "Select from below",
                  }}
                  handleChange={data => {
                    if(data === null){
                      setFieldValue("alias", "", formik.setFieldValue);
                      setAlias("");
                      setFieldValue("selectedOption", "", formik.setFieldValue);
                      setFieldValue("source", "", formik.setFieldValue);
                    } else if (!isEmpty(data) && !!data.value) {
                      setFieldValue("alias", data.value, formik.setFieldValue);
                      setAlias(data.value);
                      setFieldValue("selectedOption", "", formik.setFieldValue);
                      setFieldValue("source", "", formik.setFieldValue);
                    }
                  }}
                  selectOptions={aliasList.map(r => {
                    return {
                      value: r.alias,
                      label: r.alias,
                    };
                  })}
                  isDisabled={editMode === "create" ? false : true}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                  required
                />
                {errors["alias"] && touched["alias"] && (
                  <div className="input-feedback">{errors["alias"]}</div>
                )}
              </div>
              {formik.values.alias && (
                <div className="terraform-input">
                  <label className="terraform-input-label mt-2">
                    Select Input
                  </label>
                  <ReactSelect
                    id="selected-option"
                    name="selected-option"
                    value={{
                      value: formik.values.selectedOption,
                      label: formik.values.selectedOption
                        ? getOptionLabel(formik.values.selectedOption)
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if(data === null){
                        setFieldValue("source", "", formik.setFieldValue);
                        setFieldValue(
                          "selectedOption",
                          "",
                          formik.setFieldValue,
                        );
                      } else if (!isEmpty(data) && !!data.value) {
                        if (
                          formik.values.selectedOption !==
                          selectedTerraformModule.selectedOption
                        ) {
                          setFieldValue("source", "", formik.setFieldValue);
                        }
                        setFieldValue(
                          "selectedOption",
                          data.value,
                          formik.setFieldValue,
                        );
                      }
                    }}
                    selectOptions={optionList.map(option => {
                      return {
                        value: option.value,
                        label: option.label,
                      };
                    })}
                    customMenuClass="default-select-options-container"
                    customMenuListClass="default-select-options-list"
                    customValueContainerClass="default-select-value-container"
                    customControlClass="default-select-control"
                    customOptionClass="default-select-list-item"
                    required
                  />
                  {errors["selectedOption"] && touched["selectedOption"] && (
                    <div className="input-feedback">
                      {errors["selectedOption"]}
                    </div>
                  )}
                </div>
              )}
              {formik.values.selectedOption && getContent(formik)}
              <br />
              <div className="terraform-input">
                <FormInput
                  fieldName="Root (Optional)"
                  name={"root"}
                  id="root"
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  autoComplete="off"
                />
              </div>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageTerraformCLI;
