import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "APIKeysReducer",
    "userReducer",
    "getStartedReducer",
    "commonReducer",
  ], //persist only this reducer list
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);
const persistor = persistStore(store);
export { persistor, store };
