import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import { isEmpty, getRedirectUrl } from "@lib/utils";
import * as Yup from "yup";
import {
  isFetching,
  fetchConnectorConfigDetails,
  saveConnecterSchema,
  updateConnecterSchema,
} from "@redux/actions/settingsPanel.action";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { FormFooter } from "./Helpers";
import Api from "@lib/api";
// import { RouteConstants } from "../../../../routes/Constants";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import uuidv1 from "uuid/v1";
import { FTNotification } from "@components/ui";
import { withRouter } from "react-router";
import { RouteConstants } from "../../../../routes/Constants";
import { keyUpHandler } from "@components/authentication/utils/helpers";

type DefaultFormProps = {
  currentNodeSchema: any;
  displayName: string;
  connector: string;
  loadingMessage: string;
  isFetching: (flag, message) => void;
  saveConnecterSchema: (data, connector) => void;
  updateConnecterSchema: (data, connector) => void;
  fetchConnectorConfigDetails: (connector: string) => void;
  isEditMode: boolean;
  setShowWarning?: (flag: boolean) => void;
  resetForm: boolean;
  setResetForm: (flag: boolean) => void;
  isNewTaskTypeAssignmentOpen: boolean;
  isDeleteTaskTypeAssignmentOpen: boolean;
  history: any;
  formSubmittedSuccess: boolean;
  formSubmittedError: boolean;
};
type DefaultFormStateProps = {
  showForm: boolean;
  initialValues: any;
  validationSchema: any;
  redirectUrl: string;
  fieldOptions: object;
  defaultInitialValues: any;
};
class DefaultForm extends Component<DefaultFormProps, DefaultFormStateProps> {
  formikRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.formikRef = React.createRef();
    this.state = {
      showForm: false,
      initialValues: {},
      validationSchema: {},
      redirectUrl: "",
      fieldOptions: {},
      defaultInitialValues: {},
    };
  }

  /**
   * LIFE CYCLE METHODS
   */
  componentDidMount() {
    if (!isEmpty(this.props.currentNodeSchema)) {
      this.getInitialValues();
    }
  }

  componentDidUpdate(prevProps) {
    /**
     * Reset the formik form when remove integration is successfull
     * from GetStartedForm.tsx
     */
    if (
      prevProps.resetForm !== this.props.resetForm &&
      this.props.resetForm === true
    ) {
      this.formikRef.current?.resetForm({
        values: this.state.defaultInitialValues,
      });
    }
  }

  /**
   * Function to redirect the user to Settings Page
   */
  goBack = () => {
    this.props.history.push({
      pathname: getRedirectUrl(RouteConstants["integration"].defaultUrl, [
        { key: "connector", value: this.props.connector.toLowerCase() },
      ]),
      search: "?edit=true",
      state: { from: "Integrations" },
    });
  };

  filterFields = formData => {
    let filteredFields = {};
    for (const key in formData) {
      if (!!key && !this.props.currentNodeSchema?.properties?.[key]?.hidden) {
        filteredFields[key] = formData[key];
      }
    }
    return filteredFields;
  };

  /**
   * Function to prepare options for dependent/independent dropdowns
   * and save them in state object "fieldOptions"
   * @param formSchema Formik form schema
   * @param param1 Dependencies object spreaded from connector schema
   */
  prepareFieldOptionList = async (formSchema, { dependencies }) => {
    for (const fieldKey in formSchema) {
      if (formSchema[fieldKey].hasOwnProperty("api")) {
        let { endpoint, key } = formSchema[fieldKey]["api"];
        // case endpoint is static
        if (endpoint === "static") {
          //logic for preparing option list from parents api response in this.state.fieldOptions
          const dependency = dependencies[fieldKey][0];
          const dependentOptions = this.state.fieldOptions[dependency];
          const finalOptions = { [fieldKey]: dependentOptions };
          return { ...this.state.fieldOptions, ...finalOptions };
        }

        //case endpoint is an api
        this.props.isFetching(true, `Fetching Dependencies`);
        try {
          const response = await Api._getData(endpoint);
          let options =
            typeof response?.body === "string"
              ? JSON.parse(response.body)
              : response.body;

          // If key is present, assign the MapList assigned against key in response body
          if (key) {
            options = options[key];
          }
          const finalOptions = { [fieldKey]: options };
          return { ...this.state.fieldOptions, ...finalOptions };
        } catch (error) {
          console.log(error);
        }
        this.props.isFetching(false, ``);
      }
    }
  };

  /**
   * Function to get options for dropdowns
   * @param field String field key
   * @param props connector schema recieved from the reducer
   */
  getOptions = (field, props) => {
    let fieldOptions = { ...this.state.fieldOptions };
    const selector = props?.api?.selector;
    let options = fieldOptions[field];
    if (selector && options) {
      return options.map(val => ({
        value: val[selector],
        label: val[selector],
      }));
    }
    return [];
  };

  /**
   * Function to set the current and dependent field value in Formik
   * @param field String field key
   * @param fieldValue String field value
   * @param setFieldValue Formik method
   * @param dependencies Dependency mapping
   * @param api Map with api details like endpoint and selector
   * @param props connector schema recieved from the reducer
   */
  setSelectedValue = (
    field,
    fieldValue,
    setFieldValue,
    dependencies,
    api,
    props,
  ) => {
    // set selected field value in formik
    setFieldValue(field, fieldValue);

    // case - set dependent field value
    const dependent = Object.keys(dependencies).find(
      k => dependencies[k][0] === field,
    );
    if (!!dependent && !!fieldValue) {
      // case - dependent is static
      if (props[dependent].api.endpoint === "static") {
        const options = this.state.fieldOptions[field];
        const dependentSelector = props[dependent].api.selector;

        // find dependent field value from pre-fetched options from state `fieldOptions` object
        const dependentValue = options.find(v => {
          return v[api.selector] === fieldValue;
        });

        // set dependent field value in formik
        setFieldValue(dependent, dependentValue[dependentSelector]);
      }
    }
  };

  /**
   * Function to initialize the Form Fields
   */
  getInitialValues = async () => {
    let rawInitialValues = {};
    let formSchema = { ...this.props.currentNodeSchema.properties };
    let fieldOptions = await this.prepareFieldOptionList(
      formSchema,
      this.props.currentNodeSchema,
    );
    Object.keys(formSchema).forEach(
      val => (rawInitialValues[val] = formSchema[val]?.default || ""),
    );

    if (
      this.props.currentNodeSchema?.static?.length > 0 ||
      this.props.isEditMode
    ) {
      // Code to fetch the configuration details
      try {
        let response = await Api.getConnectorConfigDetails(
          this.props.connector.toLowerCase(),
        );
        if (response?.body?.constructor.name === "String") {
          let data = JSON.parse(response.body);
          if (data.constructor.name === "String") {
            data = JSON.parse(data);
          }
          Object.keys(data).forEach(key => (rawInitialValues[key] = data[key]));
        }
      } catch (error) {}
    }
    // hardcode unique value for `external_id`
    if (
      rawInitialValues.hasOwnProperty("external_id") &&
      !rawInitialValues["external_id"]
    ) {
      rawInitialValues["external_id"] = uuidv1();
    }

    let defaultInitialValues = Object.keys(formSchema).reduce((acc, val) => {
      /**
       * if condition for readOnly fields, such that
       * they must not be set to empty values
       */
      if (!formSchema[val]?.read_only)
        acc[val] = formSchema[val]?.default || "";
      else acc[val] = rawInitialValues[val] || "";
      return acc;
    }, {});

    this.setState(
      { initialValues: rawInitialValues, fieldOptions, defaultInitialValues },
      () => {
        this.getValidationSchema();
      },
    );
  };

  /**
   * Function to build validation schema
   */
  getValidationSchema = () => {
    const props = { ...this.props.currentNodeSchema.properties };
    let validationSchema = {};
    let initialValues = { ...this.state.initialValues };
    for (const key in initialValues) {
      let val = props[key];
      if (val?.is_required) {
        validationSchema[key] = Yup.string()
          .required(`${val?.display_name} is required`)
          .trim(`This field can't be empty`);
      }
    }
    this.setState({ validationSchema: Yup.object(validationSchema) }, () => {
      this.setState({ showForm: true });
    });
  };

  /**
   * Function to determine if the field should be shown
   * to be used to hide dependent field if parent is not selected yet
   * @param field string key to be tested
   * @param dependencies Dependency mappings
   * @param values Formik values object
   */
  shouldShowField = (field: string, dependencies: Object, values: object) => {
    if (!dependencies.hasOwnProperty(field)) return true;

    const depArr = dependencies[field];

    if (!depArr.length) return true;

    for (let i = 0; i < depArr.length; i++) {
      let parent = depArr[i];
      /* Check if the values of parent field in formik is filled and return false if not found*/
      if (!values[parent]) {
        return false;
      }
    }
    return true;
  };

  /**
   * Function to prepare Form Fields
   * @param param0 Formik values spreaded
   * @param param1 Formik touched method spreaded
   * @param param2 Formik errors object spreaded
   * @param param3 Formik method to set field value spreaded
   */
  getFormFields = ({
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  }) => {
    return (
      !isEmpty(this.state.initialValues) &&
      Object.keys(this.state.initialValues).map((field, key) => {
        const props = { ...this.props.currentNodeSchema.properties };
        const dependencies = { ...this.props.currentNodeSchema.dependencies };
        if (!props[field]) return null;
        const {
          display_name,
          description,
          masked,
          copyable,
          input_option,
          read_only,
          is_required,
          api,
        } = props[field];

        let fieldValue = values[field];

        try {
          fieldValue = JSON.parse(values[field]);
        } catch (err) {}

        if (!this.shouldShowField(field, dependencies, values)) {
          return null;
        }

        switch (input_option) {
          case "textarea":
            return (
              <FormInput
                fieldName={display_name}
                subText={`(${description})`}
                name={field}
                showJson={true}
                id={field}
                fieldValue={fieldValue}
                touched={touched}
                errors={errors}
                placeholder={`Enter ${display_name}`}
                key={key}
                autoComplete="new-password"
                isMasked={masked}
                copyable={copyable}
                as={`textarea`}
                readOnly={read_only}
                onKeyUp={e => keyUpHandler(e, handleSubmit, isSubmitting)}
              />
            );

          case "singleselect":
            return (
              <div className="gs-panel-right-field-wrapper" key={key}>
                <label className="label mb-0">{display_name}</label>
                <span className="fi-subtext mb-1">
                  {description ? "(" + description + ")" : ""}
                </span>
                <ReactSelect
                  id="webhook_api_key_name"
                  name="webhook_api_key_name"
                  className={`mb-1 ${
                    this.props.isNewTaskTypeAssignmentOpen ||
                    this.props.isDeleteTaskTypeAssignmentOpen
                      ? "unset_position"
                      : ""
                  }`}
                  value={{
                    value: fieldValue || "",
                    label: fieldValue || "Select from below",
                  }}
                  handleChange={data => {
                    this.setSelectedValue(
                      field,
                      data ? data.value : "",
                      setFieldValue,
                      dependencies,
                      api,
                      props,
                    );
                  }}
                  selectOptions={this.getOptions(field, props[field])}
                  icon={field.includes("webhook") ? "icon-filter.svg" : ""}
                  customMenuClass="default-select-options-container"
                  customMenuListClass="default-select-options-list"
                  customValueContainerClass="default-select-value-container"
                  customControlClass="default-select-control"
                  customOptionClass="default-select-list-item"
                  required={is_required}
                />
                {errors[field] && touched[field] && (
                  <div className="input-feedback">{errors[field]}</div>
                )}
              </div>
            );

          case "toggle":
            const toggleValue = fieldValue === "ON" ? true : false;
            return (
              <div className="mb-2" key={key}>
                <div className="enable-notifications-toggle">
                  <label className="label mb-0">{display_name}</label>
                  <>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name={field}
                        id={field}
                        checked={toggleValue}
                        onChange={() => {
                          const updatedToogle = !toggleValue;
                          const status = updatedToogle ? "ON" : "OFF";
                          setFieldValue(field, status);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </>
                </div>
              </div>
            );

          case "text":
          default:
            return (
              <FormInput
                fieldName={display_name}
                subText={`${description ? "(" + description + ")" : ""}`}
                name={field}
                showJson={true}
                id={field}
                fieldValue={fieldValue}
                touched={touched}
                errors={errors}
                placeholder={`Enter ${display_name}`}
                key={key}
                className={`${field === "external_id" ? "copy-text" : ""}`}
                autoComplete="new-password"
                isMasked={masked}
                copyable={copyable}
                readOnly={read_only}
                onKeyUp={e => keyUpHandler(e, handleSubmit, isSubmitting)}
              />
            );
        }
      })
    );
  };

  /**
   * Function to build Payload (Camel to Snake case keys)
   * @param formData Form fields to be sent in payload
   */
  getPayload = formData => {
    let payload = {};
    Object.keys(formData).forEach(key => {
      if (formData[key].trim().length === 0) return;
      const type = this.props.currentNodeSchema.properties[key].type;
      if (type === "integer" || type === "number")
        payload[key] = Number(formData[key]);
      else payload[key] = formData[key];
    });
    return { credentials: payload };
  };

  /**
   * Function to submit the payload
   * @param values Formik values object
   * @param actions Formik actions object
   */
  onSubmitHandle = async (values, actions) => {
    actions.setSubmitting(true);
    const payload = this.getPayload(values);
    try {
      if (this.props.isEditMode) {
        await this.props.updateConnecterSchema(payload, this.props.connector);
      } else {
        await this.props.saveConnecterSchema(payload, this.props.connector);
      }
      if (this.props.formSubmittedSuccess && !this.props.formSubmittedError) {
        FTNotification.success({
          title: "Integration Authorized Successfully!",
        });
        this.props.setResetForm(false);
        this.goBack();
        /**
         * Reset form to reset formik.dirty to false
         */
        actions.resetForm({ values });
      }
    } catch (error) {
      FTNotification.error({
        title: error?.message || "Oops! Something went wrong !!!",
      });
    }
    actions.setSubmitting(false);
  };
  render() {
    return (
      <>
        {this.state.showForm && (
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={this.state.validationSchema}
            onSubmit={this.onSubmitHandle}
            innerRef={this.formikRef}
          >
            {formik => (
              <div className="d-flex flex-column w-100">
                {this.props.setShowWarning(formik.dirty)}
                <h2 className="pt-10-px pb-10-px mb-2">Authorize Fylamynt</h2>
                {this.getFormFields(formik)}

                <FormFooter
                  onSubmit={formik.handleSubmit}
                  isEditMode={this.props.isEditMode}
                  disableSubmit={!formik.dirty}
                  isNewTaskTypeAssignmentOpen={
                    this.props.isNewTaskTypeAssignmentOpen
                  }
                />
              </div>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.settingsPanelReducer.formSaving,
  loadingMessage: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
  formSubmittedError: state.settingsPanelReducer.formSubmittedError,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
      fetchConnectorConfigDetails,
      saveConnecterSchema,
      updateConnecterSchema,
    },
    dispatch,
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultForm),
);
