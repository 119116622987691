import Api from "@lib/api/api";
import { FETCH_APP_CONFIG_SUCCESS } from "@redux/types";

export function getAppConfig() {
  return async dispatch => {
    const data = await Api.getAppConfig();
    dispatch({
      type: FETCH_APP_CONFIG_SUCCESS,
      payload: data,
    });
  };
}
