import * as Yup from "yup";

export const getValidationSchema = showAuthCode => {
  if (showAuthCode) {
    return Yup.object({
      activationCode: Yup.string()
        .required("Please enter confirmation code")
        .matches(/^[0-9]+$/, "Invalid confirmation code")
        .min(6, "Invalid confirmation code")
        .max(6, "Invalid confirmation code"),
      password: Yup.string().required("Please enter a new password"),
      confirmPassword: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    });
  } else {
    return Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    });
  }
};
