import React from "react";
import "./CustomLabel.scss";

const LabelRight = props => (
  <div className="label-inline">
    {props.children}
    <label className={`label-right ${props.labelClassName}`}>
      {props.label}
    </label>
  </div>
);

export default LabelRight;
