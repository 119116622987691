import React from "react";
import { PropTypes } from "prop-types";
import * as JsonGridLib from "./json-to-grid.lib";

export default class JsonToGrid extends React.Component {
  state = {
    outputString: "",
  };
  constructor(props) {
    super(props);
    this.outputString = "";
    this.outputObject = {};
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.json) {
      JsonGridLib.init(this.ref, this.props.json, this.props.leafOnclick);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.json !== this.props.json && this.props.json) {
      JsonGridLib.init(this.ref, this.props.json, this.props.leafOnclick);
    }
  }

  render() {
    return <div className="json-grid" ref={this.ref} />;
  }
}

JsonToGrid.propTypes = {
  json: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  seedValue: PropTypes.string,
};

JsonToGrid.defaultProps = {
  json: {},
  seedValue: "",
};
