import React from "react";
import { Form, Modal, TextInput, Button } from "@components/ui";
import { connect } from "react-redux";
import { Base64 } from "js-base64";
import { snakeToPascal, sortResourceList } from "@lib/utils";
import { RunbookStepInput } from "../../containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { parameterIsDisplayed } from "../../containers/RunbookEditor/runbook-editor-lib/ssm/parameters";
import { getParsedRuntimeParameters } from "@containers/TaskManagement/utils/CreateTask/utils/Helpers";
import { replaceAllSpecialCharWithSpace } from "@lib/utils";

const getDefaultValue = (param, resourcesListArr) => {
  if (!resourcesListArr || !param) {
    return "";
  }
  // Value already exists in configuration
  if (param.DefaultValue) {
    return param.DefaultValue;
  }
  // If ResourcesList has only one target account, assume it as default for better UX
  if (resourcesListArr.length === 1) {
    // Destructure Array into Object
    let resource = resourcesListArr[0];
    if (param.Name === "alias") {
      return resource?.alias || "";
    }
    if (param.Name === "regionName") {
      return resource?.preferred_region || "";
    }
  }

  // If there are multiple target accounts, then use the account with is_default = true
  if (resourcesListArr.length > 1) {
    let resource = resourcesListArr.find(item => item.is_default === true);
    if (!resource) {
      return "";
    }
    if (param.Name === "alias") {
      return resource?.alias || "";
    }
    if (param.Name === "regionName") {
      return resource?.preferred_region || "";
    }
  }
};

// Function to fetch params in required orders
const InputList = React.memo(props => {
  let { params, submitted, resourcesList } = props;
  let requiredParamsArr = [];

  let resourcesListArr = resourcesList?.targets
    ? [...resourcesList.targets]
    : [];
  params
    .filter(param => parameterIsDisplayed(param.Name))
    .forEach(param => {
      let autocompleteParams = param.Type.split("|").slice(1, 3);
      if (param.Name.startsWith("alias") && !!autocompleteParams) {
        autocompleteParams = ["targets", "alias"];
      }

      requiredParamsArr.push(
        <div
          key={param.Name}
          className={`autocomplete-list-input-${param.Name}`}
        >
          <TextInput
            name={param.Name}
            value={getDefaultValue(param, resourcesListArr)}
            label={`${param.Name}: ${param.Description.replace(
              "Optional - ",
              "",
            )
              .replace("Required - ", "")
              .replace("(Optional)", "")} ${
              param.Description.includes("StringList")
                ? ' (Enter an array, e.g. ["i1", "i2"])'
                : ""
            }`}
            className="text-input w-100 text-input-mb-20"
            labelClassName="label"
            labelPosition="top"
            id={param.Name}
            maxLength={2000}
            required={true}
            disabled={submitted}
            onChange={event => {
              props.searchResourcesList(autocompleteParams, param, event);
            }}
            onClick={() => props.searchResourcesList(autocompleteParams, param)}
            options={props.provideOptions(autocompleteParams, param)}
          />
        </div>,
      );
    });
  return requiredParamsArr;
});

const getFooter = (toggleRunbookModal, isLoading, runModalAsSave) => (
  <div className="modal-buttons-footer">
    <Button
      text="Cancel"
      buttonStyle="secondary"
      style={{ width: "50%" }}
      onClick={toggleRunbookModal}
      type="button"
    />
    <Button
      text={runModalAsSave ? "Save" : "Run"}
      buttonStyle="primary"
      style={{ width: "50%" }}
      type={"submit"}
      size="large"
      isLoading={isLoading}
    />
  </div>
);

class RunbookRun extends React.PureComponent {
  state = {
    startLoading: false,
    refs: null,
    params: null,
    submitted: false,
    resourcesListSearch: [],
  };

  provideDropdownOptions = (autocompleteParams, param) => {
    if (autocompleteParams?.length !== 2) return [];
    let resourcesService = autocompleteParams[0];
    if (!(resourcesService in this.props.resourcesList)) return [];
    return this.props.resourcesList[resourcesService];
  };

  searchResourcesList = (autocompleteParams, param, selectedValue) => {
    if (autocompleteParams?.length !== 2) return;
    let resourcesService = autocompleteParams[0];
    if (!(resourcesService in this.props.resourcesList)) return;
    let resourcesAttribute = autocompleteParams[1];
    const element = this.getInputElement(param);
    let value = element.value;
    if (!value) {
      value = selectedValue;
    }

    // if current input name not in service dict
    if (
      !Object.keys(this.props.resourcesList[resourcesService]?.[0]).includes(
        resourcesAttribute,
      )
    ) {
      console.log(
        `resource list does not have ${resourcesAttribute}`,
        Object.keys(this.props.resourcesList[resourcesService]?.[0]),
      );
      return;
    }

    let found = this.props.resourcesList[resourcesService].filter(item => {
      let values;
      //eslint-disable-next-line
      for (let i in item) {
        values += item[i];
      }
      if (!value) return true;
      return values.includes(value);
    });

    // Sort found array to prioritize array item with is_default property
    if (found && found.length > 0) {
      found = sortResourceList(found);
    }

    const parentPosition = document
      .querySelector(".modal-container")
      .getBoundingClientRect();

    const childPosition = element.getBoundingClientRect();
    this.setState({
      resourcesListSearch: found,
      autocompleteListCssTop: parseFloat(childPosition.y - parentPosition.y),
      currentParam: param,
    });
  };

  selectResource = item => {
    const resourcesAttribute = this.state.currentParam.Type.split("|").slice(
      -1,
    )?.[0];

    const element = document.querySelector(`#${this.state.currentParam.Name}`);
    element.value =
      item[
        resourcesAttribute === "String"
          ? "alias" || this.state.currentParam.Name
          : resourcesAttribute
      ];
    const stepName = this.state.currentParam.Name.substring(5);
    const regionElement = document.querySelector(`#regionName${stepName}`);

    if (regionElement && regionElement.value === "") {
      regionElement.value = item.preferred_region || "";
    }

    this.setState({
      resourcesListSearch: [],
    });
  };

  componentDidMount() {
    let params = [],
      refs = {};
    if (this.props.runModalAsSave) {
      const result = this._buildSaveRefs(this.props.runbookObj.toSSM());
      params = result.params;
      refs = result.refs;
    } else {
      let result = this._buildRefs(this.props.runbook);
      params = result.params;
      refs = result.refs;
    }

    this.setState({
      refs,
      params,
    });
  }

  _buildRefs = runbook => {
    if (!runbook) {
      return { params: null, refs: null };
    }
    const params = runbook ? runbook.Parameters : {};
    runbook.Content.mainSteps.forEach(step => {
      // example of ClientContext=>
      // {"custom":{"description":"This action creates a new S3 bucket.","version":"1.0.0",
      // "tags":["s3","create","bucket"],"outputs":{"bucket_location":"String","bucket_name":"String"},
      // "required_inputs":{"acl":"String|s3|ACL","bucket_name":"String|s3|Bucket","location":"String"},
      // "optional_inputs":{"alias":"String"}}}
      if (step.inputs.ClientContext) {
        const clientContext = JSON.parse(
          Base64.decode(step.inputs.ClientContext),
        );
        const clientContextObject = {
          ...clientContext.custom?.required_inputs,
          ...clientContext.custom?.optional_inputs,
        };
        // eslint-disable-next-line no-unused-vars
        for (const item of Object.entries(clientContextObject)) {
          params.forEach(p => {
            // input names from ClientContext comes as snake_case
            if (p.Name.toUpperCase() === snakeToPascal(item[0]).toUpperCase()) {
              p.Type = item[1];
            }
          });
        }
      }
    });
    const refs = {};
    let param;
    for (param of params) {
      if (parameterIsDisplayed(param.Name)) {
        refs[param.Name] = React.createRef();
      }
    }
    return { params, refs };
  };

  _buildSaveRefs = runbook => {
    if (!runbook) {
      return { params: [], refs: {} };
    }
    const params = [];
    const refs = {};
    let param;
    for (param of Object.keys(runbook?.parameters)) {
      if (parameterIsDisplayed(param.Name)) {
        refs[param] = React.createRef();
        params.push({
          Name: param,
          DefaultValue: runbook.parameters[param].default,
          Description: runbook.parameters[param].description,
          Type: runbook.parameters[param].type,
        });
      }
    }
    return { params: params, refs };
  };

  getInputElement = param => {
    const type = typeof param;
    let name = type === "object" ? param.Name : param;
    return document.querySelector(`#${name}`);
  };

  submitForm = (refs, runbook, callBack) => {
    const paramValues = {};
    let param;
    let runtimeParameters = {};
    for (param of Object.keys(refs)) {
      const element = this.getInputElement(param);
      if (element && !element.value) {
        element.value = element.innerText.substring(
          element.innerText.indexOf("\n") + 1,
        );
      }
      if (this.props.runModalAsSave) {
        if (element?.value) {
          runtimeParameters[param] = element?.value;
        }
      }
      if (element && element.value) {
        const hasBrackets =
          typeof val === "string" && /^\[.*\]$/.test(element.value.trim());
        paramValues[param] = hasBrackets ? element.value : [element.value];
      } else if (
        // eslint-disable-next-line no-loop-func
        this.props.runbook.Parameters.find(p => {
          return p.Name === param;
        })
      ) {
        paramValues[param] = [];
      } else {
        paramValues[param] = [];
      }
    }

    paramValues["connection_id"] = this.props.websocketConnectionId;
    if (this.props.runModalAsSave) {
      this.updateDefaultValues(paramValues);
      this.props.setParsedRuntimeParameters(
        getParsedRuntimeParameters(runtimeParameters),
      );
      this.props.toggleScheduleModal();
    } else {
      callBack(runbook.Name, paramValues, this.props.version);
    }
    this.setState({
      startLoading: true,
      submitted: true,
    });
  };

  updateDefaultValues = paramValues => {
    //eslint-disable-next-line
    for (let step of this.props.runbookObj.mainSteps) {
      if (!step?.parameterInputs) continue;
      //eslint-disable-next-line
      for (let param of step.parameterInputs) {
        const paramName = RunbookStepInput.parameterName(param.name, param);
        if (paramValues[paramName]) {
          param.source.default = paramValues[paramName][0];
        }
      }
    }
    // action node adds alias and regionName by default
    let ssmParams = this.props.runbookObj.ssmDoc.parameters.parameters;
    console.log("ssmParams->", ssmParams);
    if (
      ssmParams["alias"] &&
      ssmParams["alias"].spec.default === "" &&
      paramValues["alias"]
    ) {
      this.props.runbookObj.ssmDoc.parameters.parameters.alias.spec.default =
        paramValues["alias"][0];
    }
    if (
      ssmParams["regionName"] &&
      ssmParams["regionName"].spec.default === "" &&
      paramValues["regionName"]
    ) {
      this.props.runbookObj.ssmDoc.parameters.parameters.regionName.spec.default =
        paramValues["regionName"][0];
    }
  };

  render() {
    const { toggleRunbookModal, runExecution, runbook } = this.props;
    const runbookName = runbook.name || runbook.Name;
    const { params, refs, submitted } = this.state;
    return (
      <React.Fragment>
        {this.props.runbook && (
          <Form
            name="run-runbook"
            onSubmit={() => {
              this.submitForm(refs, runbook, runExecution);
            }}
          >
            <Modal
              onClose={toggleRunbookModal}
              title={
                this.props.runModalAsSave
                  ? `Save default values for executing ${replaceAllSpecialCharWithSpace(
                      runbookName,
                    )}`
                  : `Run:${replaceAllSpecialCharWithSpace(runbookName)}`
              }
              backgroundCanClose={false}
              showClose={true}
              footer={getFooter(
                this.props.toggleRunbookModal,
                this.state.startLoading,
                this.props.runModalAsSave,
              )}
            >
              <div id="inputs-holder">
                {params && (
                  <InputList
                    params={params}
                    refs={refs}
                    submitted={submitted}
                    searchResourcesList={this.searchResourcesList}
                    resourcesList={this.props.resourcesList}
                    key={1}
                    provideOptions={this.provideDropdownOptions}
                  />
                )}
              </div>
            </Modal>
          </Form>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ runbookReducer, websocketReducer }) => {
  return {
    runbook: runbookReducer.activeRunbook,
    version: runbookReducer.activeRunbookVersion,
    websocketConnectionId: websocketReducer.connectionId,
    resourcesList: runbookReducer.resourcesList,
  };
};
export default connect(mapState)(RunbookRun);
