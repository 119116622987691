import React from "react";

export default class MetaTag extends React.Component {
  componentDidMount() {
    this.headElement = document.getElementsByTagName("head")[0];
    this.addMetaTags();
  }

  addMetaTags = () => {
    if (this.props.tags) {
      Object.keys(this.props.tags).forEach(key => {
        const tag = this.props.tags[key];
        this.removeTag(tag);
        let tagElement = document.createElement(key);
        this.setAttributes(tagElement, tag);
        this.headElement.appendChild(tagElement);
      });
    }
  };

  removeTag = item => {
    let element = this.headElement.querySelector(`[name="${item.name}"]`);
    if (element) {
      this.headElement.removeChild(element);
    }
  };

  setAttributes = (tag, itemList) => {
    if (itemList) {
      Object.keys(itemList).forEach(key => {
        tag.setAttribute(key, itemList[key]);
      });
    }
  };

  render() {
    return <React.Fragment />;
  }
}
