import React from "react";

// Assets
import WarningIcon from "@assets/images/icons/icon-warning.svg";

import "./EmptyChart.scss";

const EmptyMessage = ({ emptyStateSubtext }) => {
  return (
    <div className="emptystate-message-container">
      <div className="d-flex">
        <img
          className="emptystate-warning-icon"
          src={WarningIcon}
          alt="No data warning"
        />
        <div>
          <p className="emptystate-message">No data available</p>
          <p className="emptystate-subtext">{emptyStateSubtext}</p>
        </div>
      </div>
    </div>
  );
};

const EmptyChart = ({ type = "line", emptyStateSubtext }) => {
  if (type === "donut") {
    return (
      <div className="emptystate-circle-wrapper">
        <div className="emptystate-circle"></div>
        <EmptyMessage emptyStateSubtext={emptyStateSubtext} />
      </div>
    );
  }

  if (type === "bar") {
    return (
      <div className="emptystate-barchart-wrapper">
        <div className="emptystate-barchart-filler"></div>
        <div className="emptystate-barchart-filler"></div>
        <div className="emptystate-barchart-filler"></div>
        <div className="emptystate-barchart-filler"></div>
        <div className="emptystate-barchart-filler"></div>
        <EmptyMessage emptyStateSubtext={emptyStateSubtext} />
      </div>
    );
  }

  return (
    <div className="emptystate-container">
      <div className="emptystate-divider"></div>
      <div className="emptystate-divider"></div>
      <div className="emptystate-divider"></div>
      <EmptyMessage emptyStateSubtext={emptyStateSubtext} />
    </div>
  );
};

export default EmptyChart;
