import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import "./TaskDetails.scss";
import {
  TabCollection,
  TabContent,
  Tab,
  Modal,
  FTNotification,
} from "@components/ui";
import TaskDetailsHeader from "./utils/TaskDetailsHeader/TaskDetailsHeader";
import { RouteConstants } from "../../routes/Constants";
import TaskInformation from "./utils/TaskInformation";
import Executions from "./utils/Executions";
import Workflows from "./utils/Workflows";
import Schedules from "./utils/Schedules";
import AlertBody from "./utils/AlertBody";
import TaskStatus from "@components/shared/TaskStatus/TaskStatus";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  fetchTask,
  setSelectedTask,
} from "@redux/actions/taskManagement.actions";
import Api from "@lib/api";
import { replaceAllSpecialCharWithSpace, isEmpty } from "@lib/utils";
import { MultiSelect } from "carbon-components-react";
import { fetchRunbooks } from "@redux/actions/runbooks.actions";
import CollaborationDetails from "./utils/CollaborationDetails";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { taskPrioritiesList } from "@lib/utils/constants";

type TaskDetailsProps = {};

const breadCrumbList = [
  { title: "All Incidents", url: RouteConstants.taskManagement.url },
  { title: "Incident Details", url: "" },
];
const editTaskValidationSchema = Yup.object({
  name: Yup.string().required("Please enter a valid name"),
  description: Yup.string().required("Please enter a valid description"),
  task_priority: Yup.string().required("Please enter a valid priority"),
});

const AddWFValidationSchema = Yup.object({
  workflows: Yup.array().required("Please select a Workflow"),
});

const TaskDetails: React.FC<TaskDetailsProps> = () => {
  const dispatch = useDispatch();
  const { taskId, subTab } = useParams();
  let history = useHistory();

  const [selectedTab, setSelectedTab] = useState(subTab);
  const selectedTask = useSelector(
    (state: RootStateOrAny) => state.taskManagementReducer.selectedTask,
  );

  const workflowList = useSelector(
    (state: RootStateOrAny) => state.runbooksReducer.runbookList,
  );

  const [isEditTaskDetailsModalOpen, setIsEditTaskDetailsModalOpen] = useState(
    false,
  );
  const [isAddWorkflowModalOpen, setIsAddWorkflowModalOpen] = useState(false);

  const [
    isCloseTaskDetailsModalOpen,
    setIsCloseTaskDetailsModalOpen,
  ] = useState(false);
  const [
    isDeleteTaskDetailsModalOpen,
    setIsDeleteTaskDetailsModalOpen,
  ] = useState(false);

  const editTask = async (formik, actions) => {
    actions.setSubmitting(true);
    let payload = {
      update: formik,
      task_ids: [taskId],
    };
    try {
      await Api.patchTask(payload);
      dispatch(fetchTask(taskId));
    } catch (e) {
      console.log(e);
    }
    actions.setSubmitting(false);
    setIsEditTaskDetailsModalOpen(false);
  };

  const closeTask = async () => {
    setIsCloseTaskDetailsModalOpen(false);
    let payload = {
      close: true,
      task_ids: [selectedTask.TaskId],
    };
    try {
      await Api.patchTask(payload);
      dispatch(fetchTask(taskId));
    } catch (e) {
      console.log(e);
    }
  };

  const openTask = async () => {
    let payload = {
      close: false,
      task_ids: [selectedTask.TaskId],
    };
    try {
      await Api.patchTask(payload);
      dispatch(fetchTask(taskId));
    } catch (e) {
      console.log(e);
    }
  };

  const deleteTask = async () => {
    setIsDeleteTaskDetailsModalOpen(false);
    try {
      await Api.deleteTask(selectedTask.TaskId);
      history.push(RouteConstants.taskManagement.url);
      FTNotification.success({
        title: "Incident Deleted successfully!",
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      let action = await dispatch(fetchTask(taskId));
      /**
       * Redirect user to tasks list if the task object
       * can't be fetched
       */
      if (!action) {
        history.push(RouteConstants.taskManagement.url);
      }
    })();
  }, [dispatch, taskId, history]);

  useEffect(() => {
    if (!(workflowList.Self.concat(workflowList.Fylamynt).length > 0)) {
      dispatch(fetchRunbooks("Self"));
    }
  }, [dispatch, workflowList.Self, workflowList.Fylamynt]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedTask(null));
    };
  }, [dispatch]);

  const addWorkflowsToTask = async (formik, actions) => {
    actions.setSubmitting(true);
    let payload = {
      workflows: formik?.workflows.map(wf => wf.Name),
      task_ids: [selectedTask.TaskId],
    };
    try {
      await Api.patchTask(payload);
      dispatch(fetchTask(taskId));
    } catch (e) {
      console.log(e);
    }
    actions.setSubmitting(false);
    setIsAddWorkflowModalOpen(false);
  };

  const setFieldValue = async (key, value, formik) => {
    formik.setFieldValue(key, value);
  };

  return (
    <div className="td-container">
      {isAddWorkflowModalOpen && (
        <>
          <Formik
            initialValues={{
              workflows: selectedTask?.workflows?.map(wf => {
                return {
                  Name: wf.DisplayName,
                };
              }),
            }}
            onSubmit={addWorkflowsToTask}
            validationSchema={AddWFValidationSchema}
          >
            {formik => {
              const { errors, touched, handleSubmit } = formik;
              return (
                <Modal
                  onClose={() => setIsAddWorkflowModalOpen(false)}
                  title="Add Workflows to Incident"
                  showClose={true}
                  onSubmit={handleSubmit}
                  onCancel={() => setIsAddWorkflowModalOpen(false)}
                  submitButtonText={`Add Workflows`}
                  contentClass="add-wf-content"
                  contentContainerClass="add-wf-content-container"
                >
                  <label className="label">Select Workflows</label>
                  <MultiSelect.Filterable
                    ariaLabel="MultiSelect"
                    placeholder="Search a Workflow"
                    default
                    id="multiselect-filterable"
                    onChange={({ selectedItems }) => {
                      setFieldValue("workflows", selectedItems, formik);
                    }}
                    className={`multiselect-filterable ${
                      !(formik.values.workflows.length > 0) ? "error" : ""
                    }`}
                    initialSelectedItems={formik.values.workflows.map(
                      workflow => {
                        return {
                          Name: workflow.Name,
                          Label: replaceAllSpecialCharWithSpace(workflow.Name),
                        };
                      },
                    )}
                    items={workflowList.Fylamynt.concat(workflowList.Self).map(
                      ({ Name }) => {
                        return {
                          Name: Name,
                          Label: replaceAllSpecialCharWithSpace(Name),
                        };
                      },
                    )}
                    label="Select Workflows"
                    itemToString={item => (item ? item.Label : "")}
                  />
                  {errors["workflows"] && touched["workflows"] && (
                    <div className="input-feedback">{errors["workflows"]}</div>
                  )}
                </Modal>
              );
            }}
          </Formik>
        </>
      )}
      {isEditTaskDetailsModalOpen && (
        <>
          <Formik
            initialValues={{
              name: selectedTask?.name,
              description: selectedTask?.description,
              task_priority: selectedTask?.task_priority,
            }}
            onSubmit={editTask}
            validationSchema={editTaskValidationSchema}
          >
            {formik => {
              const { touched, errors, handleChange, handleSubmit } = formik;
              return (
                <Modal
                  onClose={() => setIsEditTaskDetailsModalOpen(false)}
                  title="Edit Incident"
                  showClose={true}
                  onSubmit={handleSubmit}
                  onCancel={() => setIsEditTaskDetailsModalOpen(false)}
                  submitButtonText={`Update Incident`}
                >
                  <FormInput
                    fieldName="Name"
                    name={"name"}
                    id="name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    autoComplete="off"
                  />
                  <FormInput
                    fieldName="Description"
                    name="description"
                    id="description"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    as={`textarea`}
                    autoComplete="off"
                  />
                  <div className="create-task-select">
                    <label className="label">Priority</label>
                    <ReactSelect
                      id="task_priority"
                      name="task_priority"
                      value={{
                        value: formik.values.task_priority,
                        label: formik.values.task_priority
                          ? formik.values.task_priority
                          : "Select from below",
                      }}
                      handleChange={data => {
                        if (data === null) {
                          formik.setFieldValue("task_priority", "");
                        } else if (
                          !isEmpty(data) &&
                          !!data.value &&
                          !!data.label
                        ) {
                          formik.setFieldValue("task_priority", data.value);
                        }
                      }}
                      selectOptions={taskPrioritiesList.map(option => {
                        return {
                          value: option,
                          label: option || "",
                        };
                      })}
                      customMenuClass="default-select-options-container menu-open-top"
                      customMenuListClass="default-select-options-list"
                      customValueContainerClass="default-select-value-container"
                      customControlClass="default-select-control"
                      customOptionClass="default-select-list-item"
                    />
                    {formik.errors.task_priority &&
                    formik.touched.task_priority ? (
                      <div className="input-feedback">
                        {formik.errors.task_priority}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Modal>
              );
            }}
          </Formik>
        </>
      )}
      {isDeleteTaskDetailsModalOpen && (
        <Modal
          title="Delete Incident"
          showClose={true}
          onClose={() => setIsDeleteTaskDetailsModalOpen(false)}
          isDeleteModal
          submitButtonText={`Delete Incident`}
          onCancel={() => setIsDeleteTaskDetailsModalOpen(false)}
          onSubmit={deleteTask}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `The Incident <span class="bold">${selectedTask?.name}</span> will be closed and permanently deleted.
              All pending and scheduled workflows associated with this Incident will be
              cancelled.`,
            }}
          ></div>
        </Modal>
      )}
      {isCloseTaskDetailsModalOpen && (
        <Modal
          title="Close Incident"
          showClose={true}
          onClose={() => setIsCloseTaskDetailsModalOpen(false)}
          isDeleteModal
          submitButtonText={`Close Incident`}
          onCancel={() => setIsCloseTaskDetailsModalOpen(false)}
          onSubmit={closeTask}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `The Incident <span class="bold">${selectedTask?.name}</span> will be closed. All pending and scheduled workflows associated with this Incident will be cancelled.`,
            }}
          ></div>
        </Modal>
      )}
      <TaskDetailsHeader
        breadcrumbList={breadCrumbList}
        id={selectedTask?.TaskId}
        title={selectedTask?.name}
        onEditTask={setIsEditTaskDetailsModalOpen}
        onCloseTask={setIsCloseTaskDetailsModalOpen}
        onDeleteTask={setIsDeleteTaskDetailsModalOpen}
        onOpenTask={openTask}
        onAddWorkflowsToTask={setIsAddWorkflowModalOpen}
        taskStatus={selectedTask?.status}
      />
      <div className="status-block">
        <TaskStatus className="status-color" status={selectedTask?.status} />
      </div>
      <div className="detail-block">
        <TaskInformation selectedTask={selectedTask} />
        <CollaborationDetails
          name={selectedTask?.name}
          incidentId={selectedTask?.TaskId}
          description={selectedTask?.description}
          incidentResponse={selectedTask?.incident_response}
        />
      </div>
      <TabCollection
        className="mt-40-px w-100"
        contentTop={60}
        tabChange={tab => {
          setSelectedTab(tab);
        }}
        tabs={["workflows", "executions", "schedules", "alert_body"]}
      >
        <Tab title="Workflows" name="workflows">
          <TabContent>
            {selectedTab === "workflows" && (
              <Workflows
                workflows={
                  selectedTask?.workflows ? selectedTask.workflows : []
                }
                allWorkflows={workflowList.Fylamynt.concat(workflowList.Self)}
                taskId={taskId}
              />
            )}
          </TabContent>
        </Tab>
        <Tab title="Executions" name="executions">
          <TabContent>
            {selectedTab === "executions" && <Executions />}
          </TabContent>
        </Tab>
        <Tab title="Schedules" name="schedules">
          <TabContent>
            {selectedTab === "schedules" && <Schedules />}
          </TabContent>
        </Tab>
        {selectedTask?.alert_body &&
          Object.keys(selectedTask?.alert_body).length > 0 && (
            <Tab title="Alert Body" name="alert_body">
              <TabContent>
                {selectedTab === "alert_body" && (
                  <AlertBody alertBody={selectedTask?.alert_body} />
                )}
              </TabContent>
            </Tab>
          )}
      </TabCollection>
    </div>
  );
};

export default TaskDetails;
