import produce from "immer";
import moment from "moment";
import { UPDATE_DATE_RANGE_SELECTION } from "@redux/actions/dashboard.actions";

const INITIAL_STATE = {
  dateRange: {
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().toDate(),
  },
};
const dashboardReducer = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_DATE_RANGE_SELECTION:
        draft.dateRange = action.payload;
        break;
      default:
        break;
    }
  });

export default dashboardReducer;
