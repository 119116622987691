import React from "react";
import { Consumer } from "./radio.context";

export default class Radio extends React.PureComponent {
  updatePropValue = callBack => {
    if (callBack) {
      callBack(this.props.value);
    }
  };

  setBgColor = defaultSelected => {
    console.log(defaultSelected);
    const bg = defaultSelected === this.props.value ? "blue" : "transparent";
    return bg;
  };

  getClassName = defaultSelected => {
    return defaultSelected === this.props.value
      ? "checkmark radio-checked"
      : "checkmark";
  };
  render() {
    return (
      <Consumer>
        {({ name, radioClassName, onChange, onLoad, defaultSelected }) => (
          <div className="label-width">
            <label className={`container ${radioClassName}`}>
              {this.props.children}
              <input
                type="radio"
                name={name}
                value={this.props.value}
                onClick={() => {
                  this.updatePropValue(onChange);
                }}
                onChange={() => onChange(this.props.value)}
                checked={
                  this.props.checked || defaultSelected === this.props.value
                }
              />
              <span className={this.getClassName(defaultSelected)}></span>
              {this.props.value && defaultSelected === this.props.value && (
                <TriggerOnchange onLoad={onLoad} value={this.props.value} />
              )}
            </label>
          </div>
        )}
      </Consumer>
    );
  }
}

class TriggerOnchange extends React.Component {
  componentDidMount() {
    this.props.onLoad(this.props.value);
  }
  render() {
    return <React.Fragment />;
  }
}
