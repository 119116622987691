import React from "react";
import { JsonView } from "@components/ui";

type AlertBodyProps = {
  alertBody: object;
};

const AlertBody: React.FC<AlertBodyProps> = ({ alertBody }) => {
  return (
    <>
      <div className={"alert-body-content"}>
        Alert Body from 3rd party integration:
      </div>
      <div className={"alert-body-wrap"}>
        <JsonView
          showCopy={true}
          className="mb-10-px position-relative w-100"
          jsonViewClass={"alert-body-scroll-height"}
          jsonIconClass="alert-body-json-copy-outerwrap"
          iconCopyClass={"alert-body-icon-copy"}
          showIconWrapper={false}
          data={alertBody}
        ></JsonView>
      </div>
    </>
  );
};

export default AlertBody;
