import flatMap from "lodash/flatMap";
import { SSMActionNames, ComparisonOperators, ParameterType } from "./strings";
import { Step } from "./steps";
import { SchemaError } from "../exceptions";
import { readLambdaParam, RunbookStepInput } from "./nodeinputoutput";

export class BranchStep extends Step {
  static ACTION = SSMActionNames.BRANCH;
  constructor(stepJSON) {
    super(stepJSON);
    this.defaultNextStep = this.inputs.Default;
    this.choices = this.inputs.Choices.map(ssm => {
      const choice = Choice.fromSSM(ssm);
      choice.ssmStep = this;
      return choice;
    });
  }

  readInputSources(runbook) {
    let choice;
    for (choice of this.choices) {
      choice.readInputSources(runbook, this);
    }
  }

  choiceInputs() {
    return flatMap(this.choices, choice => choice.inputs());
  }

  consumedOutputs() {
    return this.choiceInputs()
      .filter(
        input =>
          input.source.type === "snippetOutput" ||
          input.source.type === "actionNode",
      )
      .map(input => input.source.sourceValue);
  }

  getDefault = () => {
    return this.defaultNextStep;
  };

  // this is not editable so we do not override toSSM()
}

export class Choice {
  static fromSSM(ssm) {
    const nextStep = ssm.NextStep;
    const condition = Condition.fromSSM(ssm);

    return new Choice(nextStep, condition);
  }

  constructor(nextStep, condition) {
    this.nextStep = nextStep;
    this.condition = condition;
    this.condition.choice = this;
    if (!condition) {
      throw new Error("constructing choice without condition");
    }
  }

  toSSM = () => {
    return {
      NextStep: this.nextStep,
      ...this.condition.toSSM(),
    };
  };

  readInputSources = (runbook, step) => {
    this.condition.readInputSources(runbook, step);
  };

  inputs = () => {
    return this.condition.inputs();
  };
}

export class Condition {
  static fromSSM(ssm) {
    const operator = Object.keys(ssm).find(key =>
      ComparisonOperators.isOperator(key),
    );
    if (!operator) {
      throw new SchemaError("Choice::Condition", ssm);
    }
    if (ComparisonOperators.isCompoundOperator(operator)) {
      // It's a compound so it has an array of sub-conditions
      const components = ssm[operator].map(condition =>
        Condition.fromSSM(condition),
      );
      return new CompoundCondition(operator, components);
    } else {
      return new ComparisonCondition(ssm.Variable, operator, ssm[operator]);
    }
  }
}

export class ComparisonCondition extends Condition {
  constructor(variable, operator, value) {
    super();
    this.variable = variable;
    this.operator = operator;
    this.value = value;
  }

  toSSM = () => {
    let { value } = this;
    switch (ComparisonOperators.argumentType(this.operator)) {
      case ParameterType.String:
        if (typeof this.value !== "string") {
          value = `${this.value}`;
        }
        break;
      case ParameterType.Integer:
        value = Number(this.value);
        break;
      case ParameterType.Boolean:
        value = Boolean(this.value);
        break;
      default:
        value = this.value || "";
    }
    return {
      Variable: (this.input && this.input.getSSMChoiceValue()) || this.variable,
      [this.operator]: value,
    };
  };

  // Set the RunbookNodeInput
  setInput = input => {
    this.input = input;
    this.input.choice = this.choice;
    this.updateForInput();
  };

  updateForInput = () => {
    this.variable = (this.input && this.input.getSSMChoiceValue()) || "";
  };

  variableType = () => {
    return ComparisonOperators.argumentType(this.operator);
  };

  readInputSources = (runbook, step) => {
    const inputSource = readLambdaParam(runbook, this.variable)[0];
    const input = new RunbookStepInput(
      step,
      "Variable",
      this.variableType(),
      true,
      inputSource,
    );
    this.setInput(input);
  };

  inputs = () => {
    if (!this.input) {
      console.error(this);
      throw new Error("input not defined");
    }
    return [this.input];
  };
}

export class CompoundCondition extends Condition {
  /**
   * @param {*} operator - one of And, Or, or Not
   * @param {*} components - An array of Condition objects
   */
  constructor(operator, components) {
    super();
    this.operator = operator;
    this.components = components;
  }

  toSSM() {
    return {
      [this.operator]: this.components.map(condition => condition.toSSM()),
    };
  }

  readInputSources(runbook) {
    let component;
    for (component of this.components) {
      component.readInputSources(runbook);
    }
  }
  inputs() {
    return flatMap(this.components, component => component.inputs());
  }
}
