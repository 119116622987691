import React from "react";

const ModalClose = props => (
  <button
    className={`modal-close ${props.customClass}`}
    id="modal-close"
    onClick={props.onClick}
    type="button"
  ></button>
);

export default ModalClose;
