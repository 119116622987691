import React from "react";
import { Provider } from "./tabs.context";
import { PropTypes } from "prop-types";
import "./tabs.scss";
import { withRouter } from "react-router";

class TabCollection extends React.PureComponent {
  state = {
    active: this.props.active,
    search: this.props.location.search,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.tabs &&
      this.props.match.params.subTab &&
      !this.props.tabs.includes(this.props.match.params.subTab)
    ) {
      this.props.history.push("/workflows/my-workflows");
    }
    if (prevProps.match.params.subTab !== this.props.match.params.subTab) {
      this.setActiveTab(
        this.props.match.params.subTab,
        this.props.location.search,
      );
    }
  }

  componentDidMount() {
    if (
      this.props.match.params.subTab &&
      !this.props.match.path.includes("*")
    ) {
      this.setActiveTab(this.props.match.params.subTab, this.state.search);
    }
  }

  setActiveTab = (tabName, search = "") => {
    this.setState({ active: tabName });
    let path =
      this.props.location.pathname.substring(
        0,
        this.props.location.pathname.lastIndexOf("/"),
      ) + "/";
    let subPath = search ? tabName + search : tabName;
    let updatedLocation = path + subPath;
    if (
      this.props.match.path !== "/workflow/editor/:runbookId" &&
      this.props.location.pathname !== updatedLocation
    ) {
      this.props.history.push(updatedLocation);
    }
    if (this.props.tabChange) {
      this.props.tabChange(tabName);
    }
  };

  render() {
    const contextValue = {
      handleChange: this.setActiveTab,
      active: this.state.active,
      activeClassName: this.props.activeClassName,
      inactiveClassName: this.props.inactiveClassName,
      contentTop: this.props.contentTop,
    };
    return (
      <div
        data-duration-in="300"
        data-duration-out="100"
        className={`${this.props.className} tabs-wrap w-tabs`}
      >
        <div className={`tabs w-tab-menu ${this.props.menuClassName || ""}`}>
          <Provider value={contextValue}>{this.props.children}</Provider>
        </div>
      </div>
    );
  }
}

TabCollection.propTypes = {
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  contentTop: PropTypes.number,
  className: PropTypes.string,
  menuClassName: PropTypes.string,
};

TabCollection.defaultProps = {
  activeClassName:
    "tabs__nav-item-dark d-inline-block w-tab-link tabs__nav-item-dark-active",
  inactiveClassName:
    "tabs__nav-item-dark d-inline-block w-tab-link tabs__nav-item-dark-inactive",
  contentTop: 88,
};

export default withRouter(TabCollection);
