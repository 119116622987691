import React from "react";
import { HeaderMain } from "@components/modules";
import { Consumer } from "../snippet-details-lib/snippet-details.context";

const SnippetDetailsHeader = () => (
  <Consumer>
    {({ snippet, selectedSnippet }) => (
      <React.Fragment>
        <div>
          <HeaderMain
            title={selectedSnippet?.display_name || snippet.name}
            breadCrumbText="Actions"
            navigateTo="/actions"
          />
          <div className="snippet-details-header">
            {snippet?.tags?.map((tag, i) => {
              return (
                <span className="tag" key={i}>
                  {tag}
                </span>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    )}
  </Consumer>
);

export default SnippetDetailsHeader;
