import React from "react";
import PropTypes from "prop-types";
import { default as DT } from "react-data-table-component";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";
import "./data-table.scss";

const darkTheme = {
  contextMenu: {
    style: {
      backgroundColor: "#E91E63",
      fontColor: "#FFFFFF",
    },
  },
  header: {
    style: {
      minHeight: 0,
      fontColor: "#f3f3f3",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
    },
  },
  rows: {
    style: {
      color: "#bebebe",
      backgroundColor: "#282828",
      hoverFontColor: "black",
      hoverBackgroundColor: "rgba(0, 0, 0, .24)",
    },
  },
  pagination: {
    style: {
      color: "#f3f3f3",
    },
  },
  noData: {
    style: {
      backgroundColor: "transparent",
    },
  },
  table: {
    style: {
      backgroundColor: "transparent",
    },
  },
};

export default class DataTable extends React.Component {
  render() {
    return (
      <div className="data-table-container">
        <DT
          title=""
          theme="dark"
          columns={this.props.columns}
          data={this.props.data}
          customStyles={this.props.theme}
          fixedHeader={this.props.fixedHeader}
          fixedHeaderScrollHeight={this.props.fixedHeaderScrollHeight}
          pagination={this.props.pagination}
          paginationDefaultPage={this.props.currentPage}
          paginationPerPage={this.props.recordsPerPage}
          onChangeRowsPerPage={this.props.onChangeRowsPerPage}
          defaultSortField={this.props.defaultSortField}
          defaultSortAsc={this.props.defaultSortAsc}
          onSort={this.props.onSort}
          onChangePage={(page, totalRows) =>
            this.props.onChangeCurrentPage(page)
          }
          selectableRows={this.props.selectableRows}
          selectableRowsVisibleOnly={this.props.selectableRowsVisibleOnly}
          onSelectedRowsChange={this.props.onSelectedRowsChange}
          clearSelectedRows={this.props.clearSelectedRows}
          noDataComponent={
            this.props?.noDataComponent || (
              <NoResultFound
                message="No results found"
                detail="There are no items to display."
                className="mt-100-px"
              />
            )
          }
        />
      </div>
    );
  }
}

/* columns should look like this:
columns = [{
  name: 'Display Title',
  selector: 'title',
  sortable: true
}]
*/

DataTable.propTypes = {
  theme: PropTypes.object,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
};

DataTable.defaultProps = {
  theme: darkTheme,
  columns: [],
  data: [],
  defaultSortField: "",
  recordsPerPage: 10,
  currentPage: 1,
  defaultSortAsc: true,
  onSort: () => {},
  selectableRows: false,
  onSelectedRowsChange: () => {},
};
