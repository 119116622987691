import { useEffect, useRef } from "react";

const usePreviousLocation = location => {
  const previousLocationRef = useRef(location);

  useEffect(() => {
    previousLocationRef.current = location;
  }, [location]);

  return previousLocationRef.current;
};

export default usePreviousLocation;
