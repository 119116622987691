import React from "react";
import { OverflowMenu } from "@components/ui";
import { getServiceName } from "./HelperFunctions";

const tableColumns = (displayName, serviceList, onDelete) => {
  return [
    {
      name: displayName,
      selector: "incidentId",
      grow: 1.5,
      cell: row => {
        return getServiceName(row?.incidentId, serviceList);
      },
    },
    {
      name: "Incident Type",
      selector: "taskTypeName",
      grow: 1,
    },

    {
      name: "",
      selector: "options",
      grow: 0.5,
      cell: row => (
        <OverflowMenu>
          <div className="button-delete" onMouseDown={() => onDelete(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
    },
  ];
};

export default tableColumns;
